import { useContext, useEffect, useState } from "react"
import { ThemeContext } from "../../../context/ThemeContext"
import Logo from "../../../assets/images/kikkin_green_logo.svg"
import { Link } from "react-router-dom"
import { AuthContext } from "../../../context/AuthProvider"
import "./styles.scss"
import SVGInject from "@iconfu/svg-inject"

const Header = ({ bgColor = `` }) => {
  const { logo } = useContext(ThemeContext)
  const { logout } = useContext(AuthContext)
  const [isPrimary, setIsPrimary] = useState<boolean>()
  console.log(bgColor)

  useEffect(() => {
    setIsPrimary(bgColor ? true : false)
  }, [bgColor])

  return (
    <div
      className="primary-header"
      style={bgColor ? { backgroundColor: bgColor } : undefined}
    >
      <div className="flex-space-between">
        <Link to="/banking">
          <img
            className="primary-header-logo"
            src={logo || Logo}
            onLoad={(e) => SVGInject(e.target)}
          />
        </Link>

        <div className="flex-center">
          <Link
            to="/banking"
            className={
              isPrimary ? `primary-header-link` : `secondary-header-link`
            }
          >
            Início
          </Link>
          <div
            className={
              isPrimary
                ? `primary-navbar-division-green`
                : `secondary-navbar-division-green`
            }
          ></div>
          <div
            onClick={logout}
            className={
              isPrimary ? `primary-header-link` : `secondary-header-link`
            }
          >
            Sair
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
