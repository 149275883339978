import dayjs from "dayjs"
import dayjsBusinessTime from "dayjs-business-time"
import { useContext, useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { BMP_BANK_NUMBER } from "../../../../../../assets/constants"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { TransferContext } from "../../../../../../context/TransferContext"
import { formattedCurrency } from "../../../../../../services/banking"
import {
  CustomModal,
  FailureModal,
  SecondaryButton,
  PrimaryButton,
} from "k4n-components"
import pencilIcon from "../../../../../../assets/images/pencil.svg"
import SVGInject from "@iconfu/svg-inject"
import arrow_right from "../../../../../../assets/images/arrow-right-yellow.svg"
import dolarIcon from "../../../../../../assets/images/dolar.svg"

import "./styles.scss"
import { BalanceContext } from "../../../../../../context/BalanceProvider"
import { useNavigate } from "react-router-dom"
dayjs.extend(dayjsBusinessTime)

const TransferStep3Mobile = ({
  showPaymentDate,
  setShowPaymentDate,
  handleNext,
  getHolidays,
  saveFavoriteAndChangeContext,
  customModalAlert,
  setCustomModalAlert,
  setSaveFavorite,
  message,
  setMessage,
  failureModalAlert,
  scheduledDate,
  setScheduledDate,
  saveFavorite,
  setFailureModalAlert,
}) => {
  const {
    changeTransferStep,
    newContactTransferStep,
    changeNewContactTransferStep,
    changeIsScheduling,
  } = useContext(BankingNavigationContext)
  const { favoriteData, transferValue } = useContext(TransferContext)
  const [showMessage, setShowMessage] = useState(false)
  const { balance, loadingBalance, refreshBalance } = useContext(BalanceContext)
  const navigate = useNavigate()
  useEffect(() => {
    getHolidays()
    refreshBalance()
  }, [])

  return (
    <div className="transfer-mobile-header">
      <div className="panel-mobile-content">
        <button
          className="transfer-mobile-close-btn"
          onClick={() => {
            navigate(`/banking`)
          }}
        ></button>
        <div>
          <span className="info-span-grey-16">
            Transferindo para: &nbsp;
            <span className="transfer-step3-payment-step3-h1-mobile">
              {favoriteData.nome}
            </span>
          </span>
        </div>
        <div className="step-3-mobile-info-div">
          <div className="step-3-mobile-info-div-flex">
            <div className="step-3-mobile-value-info">
              <span className="info-step-3-mobile-span-blue-24 step-3-mobile-flex-gap-10">
                {formattedCurrency(transferValue)}
                <div onClick={() => changeTransferStep(1)}>
                  <img
                    src={pencilIcon}
                    alt="spinner"
                    width={24}
                    height={24}
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </div>
              </span>
            </div>
            <div className="transfer-step3-payment-table-mobile">
              <h1 className="transfer-step3-payment-step3-h1-mobile">
                Pagar com
              </h1>
              <h1 className="transfer-step3-payment-step3-txt-mobile">
                {loadingBalance ? (
                  <div className="emptySpace-pix gradient"></div>
                ) : (
                  <span className="content-mobile-table-step2-pix-txt">
                    Saldo: {formattedCurrency(balance)}
                  </span>
                )}
              </h1>
            </div>
          </div>

          <div
            className="transfer-payment-date-mobile"
            onClick={() => setShowPaymentDate(true)}
          >
            <div>
              <h1 className="transfer-payment-step3-h1-mobile">
                Data da Transfer&ecirc;ncia
              </h1>
              <h1 className="transfer-payment-step3-txt-mobile">
                {!scheduledDate
                  ? `Hoje`
                  : `Agendada para ` +
                    dayjs(scheduledDate).format(`DD/MM/YYYY`)}
              </h1>
            </div>
            <img
              className="transfer-payment-img"
              src={arrow_right}
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </div>
          <div
            className="transfer-payment-date-mobile"
            onClick={() => setShowMessage(true)}
          >
            <div className="transfer-payment-step3-message-mobile">
              <h1 className="transfer-step3-payment-step3-h1-mobile">
                Enviar uma mensagem
              </h1>
              <h1
                className={
                  `transfer-step3-payment-step3-txt-mobile ` +
                  (message && `nowrap-ellipsis`)
                }
              >
                {message ? message : `Use este espaço para mandar um recado`}
              </h1>
            </div>
            <img
              className="transfer-payment-img"
              src={arrow_right}
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </div>
          {newContactTransferStep != 1 && newContactTransferStep != 0 && (
            <div className="transfer-mobile-step-3-checkbox-div">
              <input
                className="form-check-input checkbox-mobile-transfer-step-3"
                type="checkbox"
                onClick={() => setSaveFavorite(!saveFavorite)}
              />
              <span className="transfer-mobile-step-3-checkbox-span">
                Salvar como favorito
              </span>
            </div>
          )}
        </div>
        <div className="step3-transfer-mobile-contact-div">
          <div className="step-3-mobile-flex-gap-10">
            <img
              src={dolarIcon}
              alt="dolarIcon"
              width={24}
              height={24}
              onLoad={(e) => SVGInject(e.target)}
            />
            <h1 className="transfer-step3-destino">Destino</h1>
          </div>
          <div className="step3-info-mobile-div">
            <h1 className="info-span-bold-16">Ag&ecirc;ncia:</h1>
            <h1 className="info-span-grey-16 align-right">{`${
              favoriteData.agencia
            }-${favoriteData.agencia_digito || `0`}`}</h1>
          </div>
          <div className="step3-info-mobile-div">
            <h1 className="info-span-bold-16">Conta:</h1>
            <h1 className="info-span-grey-16 align-right">{`${favoriteData.conta}-${favoriteData.conta_digito}`}</h1>
          </div>
          <div className="step3-info-mobile-div">
            <h1 className="info-span-bold-16">Instituição:</h1>
            <h1 className="info-span-grey-16 align-right">
              {favoriteData.instituicao}
            </h1>
          </div>
        </div>

        <div className="transfer-mobile-step-3-btns-div">
          <PrimaryButton width={`245px`} height={`60px`} onClick={handleNext}>
            Próximo
          </PrimaryButton>
          <button
            className="cancel-btn"
            onClick={() => {
              changeTransferStep(2)
              if (newContactTransferStep === 2) changeNewContactTransferStep(0)
            }}
          >
            <span className="cancel-span">Voltar</span>
          </button>
        </div>
      </div>
      <CustomModal
        isOpen={showPaymentDate}
        onClose={() => setShowPaymentDate(false)}
        containerBg={`#FFFFFF`}
      >
        <div className="pix-payment-modal-mobile">
          <h1 className="pix-payment-step3-h1-mobile size-24">
            Quando você quer
            <br /> realizar o Pix?
          </h1>
          <DatePicker
            className="payment-date-picker-mobile"
            selected={scheduledDate}
            onChange={(date) => {
              if (date) {
                setScheduledDate(date)
                changeIsScheduling(true)
              }
            }}
            locale="pt-BR"
            dateFormat="dd/MM/yyyy"
            required={false}
            minDate={dayjs().add(1, `days`).toDate()}
            inline
          />
          <PrimaryButton
            width={`312px`}
            height={`56px`}
            margin_top={`50px`}
            onClick={() => {
              setShowPaymentDate(false)
            }}
          >
            Confirmar
          </PrimaryButton>
        </div>
      </CustomModal>
      <CustomModal
        isOpen={showMessage}
        onClose={() => setShowMessage(false)}
        containerBg={`#FFFFFF`}
      >
        <div className="pix-payment-modal-mobile">
          <h1 className="pix-payment-step3-h1-mobile size-24">
            Escreva sua mensagem
          </h1>
          <textarea
            id="pix-payment-message"
            // type="text"
            maxLength={140}
            className="pix-textarea-message-modal-mobile"
            placeholder="Digite sua mensagem aqui..."
            onBlur={(e) => {
              setMessage(e.currentTarget.value)
            }}
          />
          <PrimaryButton
            width={`312px`}
            height={`56px`}
            margin_top={`50px`}
            onClick={() => setShowMessage(false)}
          >
            Confirmar
          </PrimaryButton>
        </div>
      </CustomModal>
      <FailureModal
        isOpen={!!failureModalAlert}
        width={`937px`}
        height={`350px`}
        onClose={() => {
          setFailureModalAlert(``)
        }}
      >
        <h1 className="transfer-mobile-modal-sucess-h1">{failureModalAlert}</h1>
      </FailureModal>
      <CustomModal
        isOpen={!!customModalAlert}
        onClose={() => setCustomModalAlert(``)}
      >
        <div className="modal-message transfer-mobile-custom-modal">
          {customModalAlert}
        </div>
        <div className="transfer-mobile-schedule-alert">
          <SecondaryButton
            text="Não"
            width="200px"
            height="40px"
            OnClick={() => setCustomModalAlert(``)}
          />
          <PrimaryButton
            width="200px"
            height="40px"
            onClick={() => {
              const isTedNotInTheFutureButPast430PM =
                favoriteData.numero_banco !== BMP_BANK_NUMBER &&
                !dayjs(scheduledDate).isAfter(dayjs(), `d`) &&
                dayjs().isAfter(
                  dayjs().hour(16).minute(30).second(0).millisecond(0),
                  `ms`,
                )
              saveFavoriteAndChangeContext(
                isTedNotInTheFutureButPast430PM
                  ? dayjs().nextBusinessDay().toDate()
                  : scheduledDate,
              )
            }}
          >
            Sim
          </PrimaryButton>
        </div>
      </CustomModal>
    </div>
  )
}

export default TransferStep3Mobile
