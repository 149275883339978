import transfer from "../../../../../../assets/images/transfer_yellow.svg"
import share from "../../../../../../assets/images/share_yellow.svg"
import "./styles.scss"
import html2canvas from "html2canvas"
import { useContext, useEffect } from "react"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { PixStorageContext } from "../../../../../../context/PixStorageProvider"
import { AuthContext } from "../../../../../../context/AuthProvider"
import { formattedCurrency } from "../../../../../../services/banking"
import SVGInject from "@iconfu/svg-inject"
import close from "../../../../../../assets/images/clear_x.svg"
import { useResposive } from "../../../../../../hooks/useResponsive"
import PixReceiptMobile from "./Mobile"
import dayjs from "dayjs"

const PixReceipt = () => {
  const { changePixEnvironment, changePixPayment } = useContext(
    BankingNavigationContext,
  )
  const { pixPaymentStep2, pixPaymentStep3 } = useContext(PixStorageContext)
  const { user, current_account } = useContext(AuthContext)
  const { isTabletOrMobile } = useResposive()

  const handleDownloadImage = async () => {
    html2canvas(document.querySelector(`#capture`)!).then((canvas) => {
      const data = canvas.toDataURL(`image/jpge`)
      const link = document.createElement(`a`)

      if (typeof link.download === `string`) {
        link.href = data
        link.download = `PIX_` + pixPaymentStep3.codigoTransacao

        link.click()
      } else {
        window.open(data)
      }
    })
  }

  const handlePageChange = (newPage: number) => {
    changePixEnvironment(newPage)
    changePixPayment(1)
  }

  useEffect(
    function () {
      console.log(pixPaymentStep3)
    },
    [pixPaymentStep3],
  )

  return (
    <>
      {!isTabletOrMobile ? (
        <div className="pix-payment-step3-container">
          <div className="pix-receipt-content">
            <div id="capture" className="pix-receipt-capture">
              <div className="pix-receipt-nav-img">
                <span
                  className="pix-receipt-img-close"
                  onClick={() => handlePageChange(1)}
                >
                  <img
                    src={close}
                    width="24"
                    height="24"
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </span>
                <span onClick={handleDownloadImage}>
                  <img
                    className="pix-receipt-img-share"
                    src={share}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </span>
              </div>
              <div>
                <div className="pix-receipt-content-title">
                  <h1 className="pix-payment-step3-title mg-bottom-17">
                    {pixPaymentStep3.agendamento === true
                      ? `Comprovante de agendamento do Pix`
                      : `Comprovante de Pix`}
                  </h1>
                  {pixPaymentStep3.agendamento === true ? (
                    <span className="pix-payment-step3-txt">
                      Agendado para{` `}
                      <b>{dayjs().format(`DD/MM/YYYY`)}</b>
                    </span>
                  ) : (
                    <span className="pix-payment-step3-txt">
                      Realizado em{` `}
                      <b>{dayjs().format(`DD/MM/YYYY`)}</b>
                    </span>
                  )}
                </div>
                <div className="pix-payment-step3-content-table">
                  <span className="pix-payment-step3-txt-bold">
                    Valor da transferência
                  </span>
                  <span className="pix-payment-step3-txt-value">
                    {formattedCurrency(pixPaymentStep3.valor)}
                  </span>
                </div>
                <div className="pix-receipt-message">
                  <span className="pix-payment-step3-txt-bold">Mensagem</span>
                  <span className="pix-payment-step3-txt">
                    {pixPaymentStep2.message}
                  </span>
                </div>
                <div className="pix-receipt-img-title">
                  <img
                    className="pix-content-img"
                    src={transfer}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                  <span className="pix-content-txt">Destino</span>
                </div>
                <div className="pix-payment-step3-content-table">
                  <div className="row align-items-start">
                    <div className="col direction-column">
                      <span className="pix-payment-step3-txt-bold">Para</span>
                      <span className="pix-payment-step3-txt">
                        {pixPaymentStep2.nome}
                      </span>
                    </div>
                    <div className="col direction-column">
                      <span className="pix-payment-step3-txt-bold">Chave</span>
                      <span className="pix-payment-step3-txt">
                        {pixPaymentStep2.chave}
                      </span>
                    </div>
                    <div className="col direction-column">
                      <span className="pix-payment-step3-txt-bold">CPF</span>
                      <span className="pix-payment-step3-txt">
                        {pixPaymentStep2.cpf}
                      </span>
                    </div>
                  </div>
                  <div className="row align-items-start">
                    <div className="col direction-column">
                      <span className="pix-payment-step3-txt-bold">
                        Instituição
                      </span>
                      <span className="pix-payment-step3-txt">
                        {pixPaymentStep2.numeroBanco}
                      </span>
                    </div>
                    <div className="col direction-column">
                      <span className="pix-payment-step3-txt-bold">
                        Agência
                      </span>
                      <span className="pix-payment-step3-txt">
                        {pixPaymentStep2.agencia}
                      </span>
                    </div>
                    <div className="col direction-column">
                      <span className="pix-payment-step3-txt-bold">Conta</span>
                      <span className="pix-payment-step3-txt">
                        {pixPaymentStep2.conta}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="pix-receipt-img-title">
                  <img
                    className="pix-content-img"
                    src={transfer}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                  <span className="pix-content-txt">Origem</span>
                </div>
                <div className="pix-payment-step3-content-table">
                  <div className="row align-items-start">
                    <div className="col direction-column">
                      <span className="pix-payment-step3-txt-bold">Nome</span>
                      <span className="pix-payment-step3-txt">
                        {user?.nome}
                      </span>
                    </div>
                    <div className="col direction-column">
                      <span className="pix-payment-step3-txt-bold">CPF</span>
                      <span className="pix-payment-step3-txt">{user?.cpf}</span>
                    </div>
                    <div className="col"></div>
                  </div>
                  <div className="row align-items-start">
                    <div className="col direction-column">
                      <span className="pix-payment-step3-txt-bold">
                        Instituição
                      </span>
                      <span className="pix-payment-step3-txt">
                        {current_account?.numeroBanco}
                      </span>
                    </div>
                    <div className="col direction-column">
                      <span className="pix-payment-step3-txt-bold">
                        Agência
                      </span>
                      <span className="pix-payment-step3-txt">
                        {current_account?.agencia}
                      </span>
                    </div>
                    <div className="col direction-column">
                      <span className="pix-payment-step3-txt-bold">Conta</span>
                      <span className="pix-payment-step3-txt">
                        {current_account?.conta}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="pix-payment-step3-content-table">
                  <div className="direction-column">
                    <span className="pix-payment-step3-txt-bold">
                      ID da transação
                    </span>
                    <span className="pix-payment-step3-txt">
                      {pixPaymentStep3.codigoTransacao}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PixReceiptMobile
          handlePageChange={handlePageChange}
          handleDownloadImage={handleDownloadImage}
        />
      )}
    </>
  )
}
export default PixReceipt
