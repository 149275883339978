export interface Step1Data {
  cpf?: string
  fullName?: string
  birthDate?: Date
  gender?: number
  email?: string
  phone?: string
  maritalStatus?: number
  representativeCode?: number
  lat?: number
  long?: number
}

export interface Step2Data {
  street?: string
  number?: string
  complement?: string
  city?: string
  district?: string
  fu?: string
  zipCode?: string
}

export enum FormOfPayment {
  CC = 0,
  PIX = 1,
  BOLETO = 2,
}

export const acceptedCreditCards: any = {
  visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  mastercard:
    /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
  amex: /^3[47][0-9]{13}$/,
  discover:
    /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
  elo: /^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
  hipercard: /^606282|^3841(?:[0|4|6]{1})0/,
}
