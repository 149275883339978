import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import { PrimaryButton } from "k4n-components"
import "./styles.scss"
import { useContext } from "react"
import Extrato from "../../extrato"
import { useNavigate } from "react-router-dom"

const HomeBankingMobile = () => {
  const navigate = useNavigate()

  return (
    <>
      <h1 className="home-mobile-txt">Últimas transações</h1>
      <div className="home-mobile-content-div">
        <div className="home-mobile-content">
          <Extrato isHome={true} />
        </div>
        <PrimaryButton
          height={`50px`}
          width={`80%`}
          onClick={() => {
            navigate(`/banking/extrato`)
          }}
        >
          Ver histórico completo
        </PrimaryButton>
      </div>
    </>
  )
}

export default HomeBankingMobile
