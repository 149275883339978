import { useContext } from "react"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import next from "../../../../../../assets/images/next.svg"
import close from "../../../../../../assets/images/clear_x.svg"
import SVGInject from "@iconfu/svg-inject"
import "./styles.scss"
import { PrimaryButton } from "k4n-components"

const PixCopyPasteMobile = ({
  handleSubmit,
  getReadQRCode,
  register,
  errors,
  errorMessage,
  btnLoading,
}) => {
  const { changePixEnvironment } = useContext(BankingNavigationContext)

  return (
    <div className="pix-payment-wrapper-mobile">
      <div className="pix-payment-step1-mobile">
        <form onSubmit={handleSubmit(getReadQRCode)}>
          <span
            className="pix-receipt-img-close"
            onClick={() => changePixEnvironment(1)}
          >
            <img
              src={close}
              width="24"
              height="24"
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </span>
          <h1 className="pix-receive-title-mobile">
            Insira o código a ser pago
          </h1>
          <input
            type="text"
            className="pix-payment-step1-input-mobile"
            placeholder="Cole o código aqui"
            {...register(`pixCodeCopyPaste`, {
              required: true,
            })}
          />
          {errors.pixCodeCopyPaste && (
            <p className="pix-error-msg">Por favor, verifique o código.</p>
          )}
          {errorMessage && !errors.pixCodeCopyPaste && (
            <p className="pix-error-msg">{errorMessage}</p>
          )}
          <div className="pix-align-btn">
            <PrimaryButton width={`95px`} height={`70px`} margin_top={`27px`}>
              {!btnLoading ? (
                <span className={`pix-receive-img-mobile`}>
                  <img src={next} alt="/" onLoad={(e) => SVGInject(e.target)} />
                </span>
              ) : (
                <div className="spinner-border spinner-pix" role="status"></div>
              )}
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  )
}
export default PixCopyPasteMobile
