import { useContext } from "react"
import { OnboardingContext } from "../../../context/OnboardingContext"
import defaultLogo from "../../../assets/images/logo_kikkin_white.svg"
import InputMask from "react-input-mask"
import { FieldValues, useForm } from "react-hook-form"
import { validatePhone } from "../../../utils/validation"
import { ThemeContext } from "../../../context/ThemeContext"

const Phone = () => {
  const { goBack, savePhase2, phone, loading } = useContext(OnboardingContext)
  const { logo } = useContext(ThemeContext)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      phone: phone,
    },
  })

  const handleSavePhone = (data: FieldValues) => {
    // TODO: validate phone
    savePhase2(data.phone)
  }

  return (
    <form
      className="onboarding-form-container"
      onSubmit={handleSubmit(handleSavePhone)}
    >
      <button className="onboarding-go-back" onClick={goBack} type="button" />

      <img
        style={{ marginTop: `120px` }}
        src={logo || defaultLogo}
        width="80px"
        height="27px"
      />
      <div className="onboarding-question-primary">Qual seu celular?</div>
      <InputMask
        mask="(99) 99999-9999"
        {...register(`phone`, {
          pattern: /^\(((\d{2})|(\d{3}))\)\s(\d{5}|\d{4})-\d{4}$/,
          required: true,
          validate: validatePhone,
        })}
        className={
          errors?.phone
            ? `onboarding-input input-invalid phone-input`
            : `onboarding-input phone-input`
        }
        type="tel"
        placeholder="Digite o nº do seu celular"
      />
      {errors.phone && (
        <div className="input-error">Número de celular inválido</div>
      )}
      <div className="flex-end">
        <button
          className={`onboarding-btn-next` + (loading ? ` loading` : ``)}
          disabled={loading}
          type="submit"
        />
      </div>
    </form>
  )
}

export default Phone
