import { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import nextIcon from "../../../../../../assets/images/next.svg"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { formattedCurrency } from "../../../../../../services/banking"
import { price_to_number } from "../../../../../../utils/validation"
import { CurrencyInput, CustomModal, PrimaryButton } from "k4n-components"
import SVGInject from "@iconfu/svg-inject"
import key from "../../../../../../assets/images/key.svg"
import "./styles.scss"
import { BalanceContext } from "../../../../../../context/BalanceProvider"

const TransferStep1Mobile = ({ onSubmit, setTransferValue, transferValue }) => {
  const navigate = useNavigate()
  const [digitalSignatureModal, setDigitalSignatureModal] = useState(false)

  const { balance, loadingBalance, refreshBalance } = useContext(BalanceContext)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransferValue(price_to_number(e.target.value))
  }

  useEffect(() => {
    refreshBalance()
  }, [])

  return (
    <div className="transfer-mobile-header">
      <CustomModal
        isOpen={digitalSignatureModal}
        onClose={() => {
          setDigitalSignatureModal(false)
        }}
        isBanking={true}
      >
        <div style={{ width: `120px`, margin: `0 auto` }}>
          <img
            src={key}
            alt="/"
            width="120"
            height="120"
            className="key-vector"
            onLoad={(e) => SVGInject(e.target)}
          />
        </div>
        <h1 className="digital-signature-h1">
          Crie agora sua assinatura digital <br /> e aproveite todos os recursos
          de sua conta!
        </h1>
        <div className="flex-center mt-3">
          <Link style={{ textDecoration: `none` }} to="/digital-signature">
            <button className="home-bold-btn">Criar</button>
          </Link>
        </div>
      </CustomModal>
      <div className="panel-mobile-content">
        <button
          className="transfer-mobile-close-btn"
          onClick={() => {
            navigate(`/banking`)
          }}
        ></button>
        <div className="panel-mobile-title">
          <span className="title-mobile-blue">
            Qual valor da transferência?
          </span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="transfer-mobile-value-div">
            <div className="transfer-mobile-value-box">
              <span className="transfer-mobile-value-label">R$</span>
              <Controller
                name="transferValue"
                control={control}
                defaultValue="0,00"
                rules={{
                  required: true,
                  validate: () => {
                    return balance >= transferValue && transferValue > 0
                  },
                  onChange: onValueChange,
                }}
                render={({ field }) => (
                  <CurrencyInput
                    value={field.value}
                    options={{ style: `decimal`, allowNegative: false }}
                    onChangeEvent={(_, maskedValue) => {
                      field.onChange(maskedValue)
                    }}
                    required={true}
                    className="transfer-mobile-value-input"
                  />
                )}
              />
            </div>
            <div className="info-div">
              <h1 className="info-span-bold-16">Saldo Kikkin:</h1>
              <h1 className="info-span-grey-16">
                {loadingBalance ? (
                  <div className="emptySpace-pix gradient"></div>
                ) : (
                  <span className="content-table-step2-pix-txt">
                    {formattedCurrency(balance)}
                  </span>
                )}
              </h1>
            </div>
          </div>
          {errors.transferValue && (
            <p className="bc-error-msg">Por favor, verifique o valor.</p>
          )}
          <div className="transfer-mobile-step-1-btn">
            <PrimaryButton width={`100px`} height={`60px`}>
              <img src={nextIcon} alt="/" />
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  )
}

export default TransferStep1Mobile
