import {
  BeneficiaryInfo,
  InsuranceProposal,
  PlanDescription,
} from "k4n-svcmesh-sdk"
import {
  createContext,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react"
import services from "../services/svcmesh"
import { FormOfPayment, Step1Data } from "../typings/insurance.dto"

type InsuranceContextType = {
  plans: Array<PlanDescription>
  selectedPlan: PlanDescription
  productId: number
  step1Data: Step1Data
  step1Valid: boolean
  hasBeneficiary: boolean
  beneficiaries: Array<BeneficiaryInfo>
  paymentDay: number
  formOfPayment: FormOfPayment
  step3Valid: boolean
  activePlan: PlanDescription
  activeProposal: InsuranceProposal
  renew: boolean
  searchedForActiveProposal: boolean
  addStep1: (data: Step1Data) => void
  addStep1Valid: (data: boolean) => void
  addPlans: (data: Array<PlanDescription>) => void
  addSelectedPlan: (data: PlanDescription) => void
  addHasBeneficiary: (data: boolean) => void
  addBeneficiaries: (data: Array<BeneficiaryInfo>) => void
  addPaymentDay: (data: number) => void
  addFormOfPayment: (data: FormOfPayment) => void
  addStep3Valid: (data: boolean) => void
  addActivePlan: (data: PlanDescription) => void
  addActiveProposal: (data: InsuranceProposal) => void
  addRenew: (data: boolean) => void
  addSearchedForActiveProposal: (data: boolean) => void
}

const contextDefaultValues: InsuranceContextType = {
  plans: [],
  selectedPlan: {},
  productId: 1,
  step1Data: {},
  step1Valid: false,
  hasBeneficiary: false,
  beneficiaries: [],
  paymentDay: 15,
  formOfPayment: 0,
  step3Valid: false,
  activePlan: {},
  activeProposal: {},
  renew: false,
  searchedForActiveProposal: false,
  addPlans: () => {},
  addSelectedPlan: () => {},
  addStep1: () => {},
  addStep1Valid: () => {},
  addHasBeneficiary: () => {},
  addBeneficiaries: () => {},
  addPaymentDay: () => {},
  addFormOfPayment: () => {},
  addStep3Valid: () => {},
  addActivePlan: () => {},
  addActiveProposal: () => {},
  addRenew: () => {},
  addSearchedForActiveProposal: () => {},
}

export const InsuranceContext =
  createContext<InsuranceContextType>(contextDefaultValues)

const InsuranceProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const [plans, setPlans] = useState<Array<PlanDescription>>(
    contextDefaultValues.plans,
  )
  const [selectedPlan, setSelectedPlan] = useState<PlanDescription>(
    contextDefaultValues.selectedPlan,
  )

  const [step1Data, setStep1] = useState<Step1Data>(
    contextDefaultValues.step1Data,
  )
  const [step1Valid, setStep1Valid] = useState<boolean>(
    contextDefaultValues.step1Valid,
  )

  const [hasBeneficiary, setBeneficiary] = useState<boolean>(
    contextDefaultValues.hasBeneficiary,
  )
  const [beneficiaries, setBeneficiaries] = useState<Array<BeneficiaryInfo>>(
    contextDefaultValues.beneficiaries,
  )

  const [paymentDay, setPaymentDay] = useState<number>(
    contextDefaultValues.paymentDay,
  )
  const [formOfPayment, setFormOfPayment] = useState<FormOfPayment>(
    contextDefaultValues.formOfPayment,
  )
  const [step3Valid, setStep3Valid] = useState<boolean>(
    contextDefaultValues.step3Valid,
  )
  const [activePlan, setActivePlan] = useState<PlanDescription>(
    contextDefaultValues.activePlan,
  )

  const [activeProposal, setActiveProposal] = useState<InsuranceProposal>(
    contextDefaultValues.activeProposal,
  )

  const [renew, setRenew] = useState<boolean>(contextDefaultValues.renew)
  const [searchedForActiveProposal, setSearchedForActiveProposal] =
    useState<boolean>(contextDefaultValues.searchedForActiveProposal)

  const [productId] = useState<number>(contextDefaultValues.productId)

  const addPlans = (data: Array<PlanDescription>) => setPlans(data)
  const addSelectedPlan = (data: PlanDescription) => setSelectedPlan(data)
  const addStep1 = (data: Step1Data) => setStep1(data)
  const addStep1Valid = (data: boolean) => setStep1Valid(data)
  const addHasBeneficiary = (data: boolean) => setBeneficiary(data)
  const addBeneficiaries = (data: Array<BeneficiaryInfo>) =>
    setBeneficiaries(data)
  const addPaymentDay = (data: number) => setPaymentDay(data)
  const addFormOfPayment = (data: FormOfPayment) => setFormOfPayment(data)
  const addStep3Valid = (data: boolean) => setStep3Valid(data)
  const addActivePlan = (data: PlanDescription) => setActivePlan(data)
  const addActiveProposal = (data: InsuranceProposal) => setActiveProposal(data)
  const addRenew = (data: boolean) => setRenew(data)
  const addSearchedForActiveProposal = (data: boolean) =>
    setSearchedForActiveProposal(data)

  useEffect(function () {
    async function fetchPlans() {
      try {
        const res = await services.bankingService.insurancePlans(productId)
        addPlans(res.data)
        addSelectedPlan(res.data[0])
      } catch (error) {
        console.log(error)
      }
    }

    if (plans.length === 0) fetchPlans()
  }, [])

  return (
    <InsuranceContext.Provider
      value={{
        plans,
        selectedPlan,
        productId,
        step1Data,
        step1Valid,
        hasBeneficiary,
        beneficiaries,
        paymentDay,
        formOfPayment,
        step3Valid,
        activePlan,
        activeProposal,
        renew,
        searchedForActiveProposal,
        addBeneficiaries,
        addHasBeneficiary,
        addPlans,
        addSelectedPlan,
        addStep1,
        addStep1Valid,
        addPaymentDay,
        addFormOfPayment,
        addStep3Valid,
        addActivePlan,
        addActiveProposal,
        addRenew,
        addSearchedForActiveProposal,
      }}
    >
      {children}
    </InsuranceContext.Provider>
  )
}

export default InsuranceProvider
