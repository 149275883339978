import { Account } from "k4n-svcmesh-sdk"
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import services from "../services/svcmesh"
import { AuthContext } from "./AuthProvider"

type BalanceContextType = {
  balance: number
  loadingBalance: boolean
  lastBalanceUpdate: Date
  refreshBalance: () => void
}

const contextDefaultValues: BalanceContextType = {
  balance: 0,
  loadingBalance: false,
  lastBalanceUpdate: new Date(),
  refreshBalance: () => {},
}

export const BalanceContext =
  createContext<BalanceContextType>(contextDefaultValues)

const BalanceProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const { user, current_account } = useContext(AuthContext)
  const [lastBalanceUpdate, setLastBalanceUpdate] = useState<Date>(
    contextDefaultValues.lastBalanceUpdate,
  )
  const [balance, setCurrentBalance] = useState<number>(
    contextDefaultValues.balance,
  )
  const [loadingBalance, setLoadingBalance] = useState(
    contextDefaultValues.loadingBalance,
  )

  useEffect(() => {
    console.log(`Triggering consultaSaldo...`)
    if (user && current_account) {
      console.log(
        `🚀 ~ file: BalanceProvider.tsx ~ line 48 ~ useEffect ~ current_account`,
        current_account,
      )
      _checkBalance(current_account)
    }
  }, [user])

  const _checkBalance = async (a: Account) => {
    setLoadingBalance(true)
    let accountLength = 0
    let contaPgt: string
    contaPgt = ``
    if (current_account?.conta && current_account?.contaDigito) {
      accountLength = (current_account?.conta + current_account?.contaDigito)
        .length
    }
    if (accountLength == 5)
      contaPgt = `00` + current_account?.conta + current_account?.contaDigito
    if (accountLength == 6)
      contaPgt = `0` + current_account?.conta + current_account?.contaDigito
    if (accountLength == 7)
      contaPgt = `` + current_account?.conta + current_account?.contaDigito

    a.contaPgto = contaPgt
    const b = await services.bankingService.getBalance(a)
    console.log(
      `🚀 ~ file: BalanceProvider.tsx ~ line 61 ~ const_checkBalance= ~ b`,
      b,
    )
    if (b) setLoadingBalance(false)
    setCurrentBalance(Number(b?.data?.vlrSaldo))
    setLastBalanceUpdate(new Date())
  }

  const refreshBalance = async () => {
    if (user && current_account) {
      setLoadingBalance(true)
      await _checkBalance(current_account)
    }
  }

  return (
    <BalanceContext.Provider
      value={{
        balance,
        loadingBalance,
        lastBalanceUpdate,
        refreshBalance,
      }}
    >
      {children}
    </BalanceContext.Provider>
  )
}

export default BalanceProvider
