import { ChangeEvent, useContext } from "react"
import defaultLogo from "../../../assets/images/logo_kikkin_white.svg"
import { OnboardingContext } from "../../../context/OnboardingContext"
import { ThemeContext } from "../../../context/ThemeContext"

const PhoneVerify = () => {
  const { goBack, phone, verifyPhone, loading } = useContext(OnboardingContext)
  const { logo } = useContext(ThemeContext)

  const handleVerifyPhone = async () => {
    let code = ``
    for (let i = 0; i < 5; i++) {
      const d = (document.getElementById(`code-` + i) as HTMLInputElement)
        ?.value
      code += d
    }
    console.log(code)
    if (code.length !== 5) {
      console.log(`ERRO NO CODIGO`)
    } else {
      await verifyPhone(code)
    }
    clearInputs()
  }

  const nextInput = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const digit = e.target.value
    if (digit) {
      if (Number(digit) || Number(digit) === 0) {
        if (index < 4) {
          document.getElementById(`code-` + (index + 1))?.focus()
        } else {
          handleVerifyPhone()
        }
      } else {
        e.target.value = ``
      }
    }
  }

  const clearInputs = () => {
    for (let i = 0; i < 5; i++) {
      const inp = document.getElementById(`code-` + i) as HTMLInputElement
      inp.value = ``
    }
  }

  return (
    <div className="onboarding-form-container">
      <button className="onboarding-go-back" onClick={goBack} />

      <img
        style={{ marginTop: `120px` }}
        src={logo || defaultLogo}
        width="80px"
        height="27px"
      />
      <div className="onboarding-question-primary">
        Digite o código que enviamos por SMS.
      </div>
      <div>Enviamos para o nº {phone}</div>
      <form className="d-flex mt-3">
        <input
          className="phone-verify-input"
          id="code-0"
          onChange={(e) => nextInput(e, 0)}
          inputMode="numeric"
          maxLength={1}
          size={1}
          min={0}
          max={9}
          pattern="[0-9]{1}"
        />
        <input
          className="phone-verify-input"
          id="code-1"
          onChange={(e) => nextInput(e, 1)}
          inputMode="numeric"
          maxLength={1}
          size={1}
          min={0}
          max={9}
          pattern="[0-9]{1}"
        />
        <input
          className="phone-verify-input"
          id="code-2"
          onChange={(e) => nextInput(e, 2)}
          inputMode="numeric"
          maxLength={1}
          size={1}
          min={0}
          max={9}
          pattern="[0-9]{1}"
        />
        <input
          className="phone-verify-input"
          id="code-3"
          onChange={(e) => nextInput(e, 3)}
          inputMode="numeric"
          maxLength={1}
          size={1}
          min={0}
          max={9}
          pattern="[0-9]{1}"
        />
        <input
          className="phone-verify-input"
          id="code-4"
          onChange={(e) => nextInput(e, 4)}
          inputMode="numeric"
          maxLength={1}
          size={1}
          min={0}
          max={9}
          pattern="[0-9]{1}"
        />
      </form>
      <div className="flex-end mt-4">
        <button
          className={`onboarding-btn-next` + (loading ? ` loading` : ``)}
          disabled={loading}
          onClick={handleVerifyPhone}
        />
      </div>
    </div>
  )
}

export default PhoneVerify
