import { useContext } from "react"
import { FieldValues, useForm } from "react-hook-form"
import { AuthContext } from "../../../context/AuthProvider"
import services from "../../../services/svcmesh"
import Header from "../../features/Header"
import "./styles.scss"
import { useNavigate } from "react-router-dom"

const ChangeAddress = () => {
  const { user, loading } = useContext(AuthContext)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    defaultValues: user?.addresses[0],
  })

  const navigate = useNavigate()

  const handleConfirmAddress = async (data: FieldValues) => {
    if (user) {
      await services.bankingService.updateAddress(data, user.cpf)
      user.addresses[0] = data
      navigate(`/account-management`)
    }
  }

  const handleCompleteAddress = async (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    clearErrors(`cep`)
    clearErrors(`logradouro`)
    clearErrors(`uf`)
    clearErrors(`cidade`)
    clearErrors(`bairro`)
    const zipCode = event.currentTarget.value.replace(`-`, ``)
    const res = await services.zipCodeService.consultZipCode(zipCode)
    if (res.data.erro) {
      /* setIsEditing(true) */
      setError(`cep`, { message: `Por favor, verifique o CEP.` })
    }

    setValue(`logradouro`, res.data.logradouro)
    setValue(`uf`, res.data.uf)
    setValue(`cidade`, res.data.localidade)
    setValue(`bairro`, res.data.bairro)
  }

  return (
    <div className="change-address-container">
      <Header bgColor={`var(--k4n-secondary-color)`} />
      <div className="change-address-content">
        <h1 className="change-address-h1">Altere seu endereço</h1>
        <form
          className="change-address-form-container"
          onSubmit={handleSubmit(handleConfirmAddress)}
        >
          <div className="change-address-row change-address-div">
            <div className="col-7 change-address-margin">
              <label className="change-address-input-label">CEP</label>
              <input
                onKeyPress={(event) => {
                  if (event.key === `Enter`) {
                    event.preventDefault()
                  }
                }}
                {...register(`cep`, {
                  required: true,
                  minLength: 4,
                  maxLength: 250,
                  onBlur: (e) => {
                    handleCompleteAddress(e)
                  },
                })}
                type="text"
                className={
                  errors.cep
                    ? `change-address-input input-invalid`
                    : `change-address-input`
                }
              />
            </div>
          </div>

          <div className="change-address-row change-address-div">
            <div className="col-11">
              <label className="change-address-input-label">Endereço</label>
              <input
                onKeyPress={(event) => {
                  if (event.key === `Enter`) {
                    event.preventDefault()
                  }
                }}
                {...register(`logradouro`, {
                  required: true,
                  minLength: 4,
                  maxLength: 250,
                })}
                disabled={true}
                type="text"
                className={
                  errors.logradouro
                    ? `change-address-input input-invalid`
                    : `change-address-input`
                }
              />
            </div>
          </div>

          <div className="change-address-row change-address-div">
            <div className="col-4 change-address-margin">
              <label className="change-address-input-label">Número</label>
              <input
                onKeyPress={(event) => {
                  if (event.key === `Enter`) {
                    event.preventDefault()
                  }
                }}
                {...register(`numero`, { required: false, maxLength: 250 })}
                type="text"
                className={
                  errors.numero
                    ? `change-address-input input-invalid`
                    : `change-address-input`
                }
              />
            </div>
            <div className="col-7">
              <label className="change-address-input-label">Complemento</label>
              <input
                onKeyPress={(event) => {
                  if (event.key === `Enter`) {
                    event.preventDefault()
                  }
                }}
                {...register(`complemento`, { maxLength: 250 })}
                type="text"
                className={
                  errors.complemento
                    ? `change-address-input input-invalid`
                    : `change-address-input`
                }
              />
            </div>
          </div>

          <div className="change-address-row change-address-div">
            <div className="col-5">
              <label className="change-address-input-label">Bairro</label>
              <input
                onKeyPress={(event) => {
                  if (event.key === `Enter`) {
                    event.preventDefault()
                  }
                }}
                {...register(`bairro`, {
                  required: true,
                  minLength: 4,
                  maxLength: 250,
                })}
                disabled={true}
                type="text"
                className={
                  errors.bairro
                    ? `change-address-input input-invalid`
                    : `change-address-input`
                }
              />
            </div>
          </div>

          <div className="change-address-row change-address-div">
            <div className="col-9 change-address-margin">
              <label className="change-address-input-label">Cidade</label>
              <input
                onKeyPress={(event) => {
                  if (event.key === `Enter`) {
                    event.preventDefault()
                  }
                }}
                {...register(`cidade`, {
                  required: true,
                  minLength: 4,
                  maxLength: 250,
                })}
                disabled={true}
                type="text"
                className={
                  errors.cidade
                    ? `change-address-input input-invalid`
                    : `change-address-input`
                }
              />
            </div>
            <div className="col-2">
              <label className="change-address-input-label">UF</label>
              <input
                onKeyPress={(event) => {
                  if (event.key === `Enter`) {
                    event.preventDefault()
                  }
                }}
                {...register(`uf`, {
                  required: true,
                  minLength: 2,
                  maxLength: 2,
                })}
                disabled={true}
                type="text"
                className={
                  errors.uf
                    ? `change-address-input input-invalid`
                    : `change-address-input`
                }
              />
            </div>
          </div>

          <div className="flex-end">
            <button
              className={
                `change-address-btn-next` + (loading ? ` loading` : ``)
              }
              disabled={loading}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ChangeAddress
