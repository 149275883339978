import { useContext } from "react"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import TransferStep3 from "../TransferStep3"
import TransferStep4 from "../TransferStep4"
import NewContactTransferStep1 from "./step1"

const NewContactTransfer = () => {
  const { newContactTransferStep } = useContext(BankingNavigationContext)
  return (
    <>
      {newContactTransferStep === 1 && <NewContactTransferStep1 />}
      {newContactTransferStep === 2 && <TransferStep3 />}
      {newContactTransferStep === 3 && <TransferStep4 />}
    </>
  )
}

export default NewContactTransfer
