import { TipoPagamentoPix, UserPixKeys } from "k4n-svcmesh-sdk"
import { createContext, ReactElement, ReactNode, useState } from "react"
import { Step1PixCodePaste } from "../typings/step1-pix-copy-paste.dto"
import { Step1PixPayment } from "../typings/step1-pix-payment.dto"
import { Step2PixPayment } from "../typings/step2-pix-payment.dto"
import { Step3PixPayment } from "../typings/step3-pix-payment.dto"

export enum PixKeyType {
  CPF = 0,
  CNPJ = 1,
  CELULAR = 2,
  EMAIL = 3,
  EVP = 4,
}

type PixStorageContextType = {
  userPixKeys: UserPixKeys
  addUserPixKeys: (data: UserPixKeys) => void
  pixPaymentStep1: Step1PixPayment
  addPixPaymentStep1: (data: Step1PixPayment) => void
  pixPaymentStep2: Step2PixPayment
  addPixPaymentStep2: (data: Step2PixPayment) => void
  pixPaymentStep3: Step3PixPayment
  addPixPaymentStep3: (data: Step3PixPayment) => void
  pixCopyPasteStep1: Step1PixCodePaste
  addPixCopyPasteStep1: (data: Step1PixCodePaste) => void
  pixPaymentType: TipoPagamentoPix
  addPixPaymentType: (tipo: TipoPagamentoPix) => void
}

const contextDefaultValues: PixStorageContextType = {
  userPixKeys: {},
  addUserPixKeys: () => {},
  pixPaymentStep1: {},
  addPixPaymentStep1: () => {},
  pixPaymentStep2: {},
  addPixPaymentStep2: () => {},
  pixPaymentStep3: {},
  addPixPaymentStep3: () => {},
  pixCopyPasteStep1: {},
  addPixCopyPasteStep1: () => {},
  pixPaymentType: 0,
  addPixPaymentType: () => {},
}

export const PixStorageContext =
  createContext<PixStorageContextType>(contextDefaultValues)

const PixStorageProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const [userPixKeys, setUserPixKeys] = useState<UserPixKeys>(
    contextDefaultValues.userPixKeys,
  )
  const [pixPaymentStep1, setPixPaymentStep1] = useState<Step1PixPayment>(
    contextDefaultValues.pixPaymentStep1,
  )
  const [pixPaymentStep2, setPixPaymentStep2] = useState<Step2PixPayment>(
    contextDefaultValues.pixPaymentStep2,
  )
  const [pixPaymentStep3, setPixPaymentStep3] = useState<Step3PixPayment>(
    contextDefaultValues.pixPaymentStep3,
  )
  const [pixCopyPasteStep1, setPixCopyPasteStep1] = useState<Step1PixCodePaste>(
    contextDefaultValues.pixCopyPasteStep1,
  )
  const [pixPaymentType, setPixPaymentType] = useState<TipoPagamentoPix>(
    contextDefaultValues.pixPaymentType,
  )

  const addUserPixKeys = (data: UserPixKeys) => setUserPixKeys(data)
  const addPixPaymentStep1 = (data: Step1PixPayment) => setPixPaymentStep1(data)
  const addPixPaymentStep2 = (data: Step2PixPayment) => setPixPaymentStep2(data)
  const addPixPaymentStep3 = (data: Step3PixPayment) => setPixPaymentStep3(data)
  const addPixCopyPasteStep1 = (data: Step1PixCodePaste) =>
    setPixCopyPasteStep1(data)
  const addPixPaymentType = (tipo: TipoPagamentoPix) => setPixPaymentType(tipo)

  return (
    <PixStorageContext.Provider
      value={{
        userPixKeys,
        pixPaymentStep1,
        pixPaymentStep2,
        pixPaymentStep3,
        pixCopyPasteStep1,
        pixPaymentType,
        addUserPixKeys,
        addPixPaymentStep1,
        addPixPaymentStep2,
        addPixPaymentStep3,
        addPixCopyPasteStep1,
        addPixPaymentType,
      }}
    >
      {children}
    </PixStorageContext.Provider>
  )
}

export default PixStorageProvider
