import { useContext, useEffect } from "react"
import { BankingNavigationContext } from "../../../../context/BankingNavigationContext"
import { useResposive } from "../../../../hooks/useResponsive"
import PixPanel from "./Panel"
import PixCopyPaste from "./PixCopyPaste"
import PixKeys from "./PixKeys"
import PixPayment from "./PixPayment"
import PixQRCode from "./PixQRCode"
import PixReceive from "./PixReceive"
import PixRegister from "./PixRegister"
import "./styles.scss"

const Pix = () => {
  const { pixEnvironment, changePixPayment, changePixEnvironment } = useContext(
    BankingNavigationContext,
  )
  const { isTabletOrMobile } = useResposive()

  useEffect(() => {
    changePixEnvironment(1)
    changePixPayment(1)
  }, [])

  return (
    <div
      className={
        isTabletOrMobile !== true ? `pix-wrapper` : `pix-wrapper-mobile`
      }
    >
      {pixEnvironment === 1 && <PixPanel />}
      {pixEnvironment === 2 && <PixPayment />}
      {pixEnvironment === 3 && <PixReceive />}
      {pixEnvironment === 4 && <PixCopyPaste />}
      {pixEnvironment === 5 && <PixQRCode />}
      {pixEnvironment === 6 && <PixKeys />}
      {pixEnvironment === 7 && <PixRegister />}
    </div>
  )
}
export default Pix
