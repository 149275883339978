import { useContext, useEffect, useState } from "react"
import amex from "../../../../../assets/images/cards/amex.png"
import discover from "../../../../../assets/images/cards/discover.png"
import elo from "../../../../../assets/images/cards/elo.png"
import hipercard from "../../../../../assets/images/cards/hipercard.png"
import master from "../../../../../assets/images/cards/master.png"
import visa from "../../../../../assets/images/cards/visa.svg"
import { AuthContext } from "../../../../../context/AuthProvider"
import { InsuranceContext } from "../../../../../context/InsuranceContext"
import "../purchase/step4"
import CardPayment from "../purchase/step4/card"
import "./styles.scss"
interface Payment {
  method?: string
  date?: number
  creditcard_number?: string
  flag?: string
}

export const PaymentData = () => {
  const [payment, setPayment] = useState<Payment>({})
  const { activeProposal } = useContext(InsuranceContext)
  const { user } = useContext(AuthContext)
  const [bestPaymentDate, setBestPaymentDate] = useState(payment.date || 0)
  const [showChangeDay] = useState(false)
  const [showChangePayment, setShowChangePayment] = useState(false)

  const handlePaymentDateChange = (newDate: number) => {
    setBestPaymentDate(newDate)
  }

  useEffect(() => {
    let paymentMethod = ``
    if (activeProposal && activeProposal.method_payment === `cartao`)
      paymentMethod = `cartao`
    else if (activeProposal && activeProposal.method_payment === `boleto`)
      paymentMethod = `boleto`

    const data = {
      method: paymentMethod,
      date: activeProposal?.billing_day,
      creditcard_number: user?.numero_cartao,
      flag: user?.bandeira_cartao,
    }
    setPayment(data)
    console.log(
      `🚀 ~ file: payment_data.tsx ~ line 34 ~ useEffect ~ data`,
      data,
    )
    console.log(
      `🚀 ~ file: payment_data.tsx ~ line 23 ~ useEffect ~ proposal.method_payment`,
      activeProposal.method_payment,
    )
  }, [user, activeProposal])

  const cardFlagImg = {
    MASTERCARD: master,
    VISA: visa,
    ELO: elo,
    HIPERCARD: hipercard,
    DISCOVER: discover,
    AMEX: amex,
  }

  return (
    <div className="payment-data-data-container">
      <div className="row">
        <div className="col col-md-6">
          <div className="mt-4">
            Forma de Pagamento
            {payment.method === `cartao` && (
              <>
                {showChangePayment ? (
                  <a
                    className="Boleto-href mx-4 payment-data-cursor-p"
                    onClick={() => setShowChangePayment(false)}
                  >
                    Fechar
                  </a>
                ) : (
                  <a
                    className="Boleto-href mx-4 payment-data-cursor-p"
                    onClick={() => setShowChangePayment(true)}
                  >
                    Alterar
                  </a>
                )}
              </>
            )}
          </div>
          <div className="insurance-method-payment mt-3">
            {payment.method === `cartao` && <>Cartão de crédito</>}
            {payment.method === `boleto` && <>Boleto</>}
          </div>
          <div className={showChangePayment ? `col mt-4` : `col col-md-6`}>
            {payment.method === `cartao` && (
              <>
                {!showChangePayment ? (
                  <div className="card-flag-div">
                    <div>
                      <div className="payment-data-data-label">
                        Número do cartão
                      </div>
                      <div className="Data-content">
                        {payment.creditcard_number}
                      </div>
                    </div>
                    <img
                      src={
                        cardFlagImg[payment.flag as keyof typeof cardFlagImg]
                      }
                      className="card-flag-img"
                    />
                  </div>
                ) : (
                  <CardPayment isChange={true} user={user} />
                )}
              </>
            )}
          </div>

          <div className="col col-md-6">
            <div className="mt-4">
              Data do Pagamento
              {/* {showChangeDay ?
                            <a className="Boleto-href mx-4 payment-data-cursor-p" onClick={() => {setShowChangeDay(false); setBestPaymentDate(0)}} >Cancelar</a>
                            :
                            <a className="Boleto-href mx-4 payment-data-cursor-p" onClick={() => setShowChangeDay(true)} >Alterar data</a>
                        } */}
            </div>
            {!showChangeDay && <div className="pill mt-3">{payment.date}</div>}

            {showChangeDay && (
              <>
                <div className="row">
                  <div className="d-grid gap-2 d-md-block">
                    <button
                      onClick={() => handlePaymentDateChange(10)}
                      className={
                        `Payment-date-button ` +
                        (bestPaymentDate === 10 ? `Toggle-active-step4` : ``)
                      }
                    >
                      10
                    </button>
                    <button
                      onClick={() => handlePaymentDateChange(15)}
                      className={
                        `Payment-date-button ` +
                        (bestPaymentDate === 15 ? `Toggle-active-step4` : ``)
                      }
                    >
                      15
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="d-grid gap-2 d-md-block">
                    <button
                      onClick={() => handlePaymentDateChange(20)}
                      className={
                        `Payment-date-button ` +
                        (bestPaymentDate === 20 ? `Toggle-active-step4` : ``)
                      }
                    >
                      20
                    </button>
                    <button
                      onClick={() => handlePaymentDateChange(25)}
                      className={
                        `Payment-date-button ` +
                        (bestPaymentDate === 25 ? `Toggle-active-step4` : ``)
                      }
                    >
                      25
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* <div className="col-12 col-md-6">
                    <div className="payment-data-data-input mt-4" >
                        <div className="payment-data-data-label" >Nome como está no cartao</div>
                        <div className="Data-content" >{payment.card_name}</div>
                    </div>
                </div>
                <div className="col-6 col-md-3">
                    <div className="payment-data-data-input mt-4" >
                        <div className="payment-data-data-label" >Validade</div>
                        <div className="Data-content" >{payment.expires_at}</div>
                    </div>
                </div>
                <div className="col-6 col-md-3">
                    <div className="payment-data-data-input mt-4" >
                        <div className="payment-data-data-label" >CVV</div>
                        <div className="Data-content" >{payment.cvv}</div>
                    </div>
                </div> */}
        </div>
      </div>
      {bestPaymentDate !== 0 && (
        <button className="Save-data-btn">
          <span className="Save-data-btn-txt">Salvar</span>
        </button>
      )}
    </div>
  )
}
