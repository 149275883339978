import { AxiosError } from "axios"
import dayjs from "dayjs"
import { BoletoRecipient, Contact, findBoletos } from "k4n-svcmesh-sdk"
import { useEffect } from "react"
import { useContext } from "react"
import { createContext, ReactElement, ReactNode, useState } from "react"
import services from "../services/svcmesh"
import { BcStep1, BcStep2 } from "../typings/boleto-creation.dto"
import { Boleto, BoletoStatusTypeSum } from "../typings/boleto-manager.dto"
import { buildAddress } from "../utils/utils"
import { AuthContext } from "./AuthProvider"

type BoletoContextType = {
  boletoCreationStep1: BcStep1
  boletoCreationStep2: BcStep2
  favorites: Contact[]
  recipient: BoletoRecipient
  searchedFavorites: boolean
  statusFilter: string[]
  filteringByStatus: boolean
  filteringByName: boolean
  loadingData: boolean
  page: number
  totalPages: number
  pagination: number
  fullData: Boleto[]
  dataArray: Boleto[]
  nameFilter: string
  showErrorModal: boolean
  boletoStatusSum: BoletoStatusTypeSum
  dateFilterError: boolean
  loadingDropdown: boolean
  showModal: boolean
  modalType: number
  loadingSendingMail: boolean
  tableReady: boolean
  dropdownError: boolean
  modalErrorMessage: string
  addTableReady: (data: boolean) => void
  addLoadingSendingMail: (data: boolean) => void
  addModalType: (data: number) => void
  addShowModal: (data: boolean) => void
  addLoadingDropdown: (data: boolean) => void
  addDateFilterError: (data: boolean) => void
  addBoletoStatusSum: (data: BoletoStatusTypeSum) => void
  addShowErrorModal: (data: boolean) => void
  addNameFilter: (data: string) => void
  addDataArray: (data: Boleto[]) => void
  addFullData: (data: Boleto[]) => void
  addPagination: (data: number) => void
  addTotalPages: (data: number) => void
  addPage: (data: number) => void
  addLoadingData: (data: boolean) => void
  filterByStatus: (data: string) => void
  addFilteringByStatus: (data: boolean) => void
  addFilteringByName: (data: boolean) => void
  addBcStep1: (data: BcStep1) => void
  addBcStep2: (data: BcStep2) => void
  addFavorites: (data: Contact[]) => void
  addRecipient: (data: BoletoRecipient) => void
  addSearchedFavorites: (data: boolean) => void
  changeBoletoPage: (i: number) => void
  handlePages: (updatePage: number) => void
  findBoletosInPeriod: (dateStart: Date, dateEnd: Date) => void
  sumValueByStatus: (data: Boleto[]) => void
  fillWithLastWeek: () => void
  applyFilter: () => void
  applyDateFilter: (dates: any) => void
  applyNameFilter: (e: React.ChangeEvent<HTMLInputElement>) => void
  filterNewDataByStatus: (boletos: Boleto[], isClearName?: boolean) => void
  filterNewDataByPayerName: (
    boletos: Boleto[],
    name: string,
    isClearFilter?: boolean,
  ) => void
  clearFilter: () => void
  cancel: (barCode: string) => void
  sendMail: (email: string, barCode: string) => void
}

const contextDefaultValues: BoletoContextType = {
  boletoCreationStep1: {},
  boletoCreationStep2: {},
  recipient: {},
  favorites: [],
  searchedFavorites: false,
  statusFilter: [],
  filteringByStatus: false,
  filteringByName: false,
  loadingData: false,
  page: 1,
  totalPages: 1,
  pagination: 15,
  fullData: [],
  dataArray: [],
  nameFilter: ``,
  showErrorModal: false,
  boletoStatusSum: {
    paid: `0`,
    pending: `0`,
    overdue: `0`,
    canceled: `0`,
  },
  dateFilterError: false,
  loadingDropdown: false,
  showModal: false,
  modalType: 1,
  loadingSendingMail: false,
  tableReady: false,
  dropdownError: false,
  modalErrorMessage: ``,
  addTableReady: () => {},
  addLoadingSendingMail: () => {},
  addModalType: () => {},
  addDateFilterError: () => {},
  addBoletoStatusSum: () => {},
  addShowErrorModal: () => {},
  addNameFilter: () => {},
  addDataArray: () => {},
  addFullData: () => {},
  addPagination: () => {},
  addTotalPages: () => {},
  addPage: () => {},
  addLoadingData: () => {},
  filterByStatus: () => {},
  addFilteringByStatus: () => {},
  addFilteringByName: () => {},
  addBcStep1: () => {},
  addBcStep2: () => {},
  addFavorites: () => {},
  addRecipient: () => {},
  addSearchedFavorites: () => {},
  changeBoletoPage: () => {},
  handlePages: () => {},
  findBoletosInPeriod: () => {},
  sumValueByStatus: () => {},
  fillWithLastWeek: () => {},
  applyFilter: () => {},
  applyDateFilter: () => {},
  applyNameFilter: () => {},
  filterNewDataByStatus: () => {},
  filterNewDataByPayerName: () => {},
  clearFilter: () => {},
  addLoadingDropdown: () => {},
  addShowModal: () => {},
  cancel: () => {},
  sendMail: () => {},
}

export const BoletoContext =
  createContext<BoletoContextType>(contextDefaultValues)

const BoletoProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const [boletoCreationStep1, setBoletoCreationStep1] = useState<BcStep1>(
    contextDefaultValues.boletoCreationStep1,
  )
  const [boletoCreationStep2, setBoletoCreationStep2] = useState<BcStep2>(
    contextDefaultValues.boletoCreationStep2,
  )
  const [recipient, setRecipient] = useState<BoletoRecipient>(
    contextDefaultValues.recipient,
  )
  const [favorites, setFavorites] = useState<Contact[]>(
    contextDefaultValues.favorites,
  )
  const [statusFilter, setStatusFilter] = useState<string[]>(
    contextDefaultValues.statusFilter,
  )
  const [filteringByStatus, setFilteringByStatus] = useState<boolean>(
    contextDefaultValues.filteringByStatus,
  )
  const [filteringByName, setFilteringByName] = useState<boolean>(
    contextDefaultValues.filteringByStatus,
  )
  const [loadingData, setLoadingData] = useState<boolean>(
    contextDefaultValues.loadingData,
  )
  const [page, setPage] = useState<number>(contextDefaultValues.page)
  const [totalPages, setTotalPages] = useState<number>(
    contextDefaultValues.totalPages,
  )
  const [pagination, setPagination] = useState<number>(
    contextDefaultValues.pagination,
  )
  const [fullData, setFullData] = useState<Boleto[]>(
    contextDefaultValues.fullData,
  )
  const [dataArray, setDataArray] = useState<Boleto[]>(
    contextDefaultValues.dataArray,
  )
  const [nameFilter, setNameFilter] = useState<string>(
    contextDefaultValues.nameFilter,
  )
  const [showErrorModal, setShowErrorModal] = useState<boolean>(
    contextDefaultValues.showErrorModal,
  )
  const [boletoStatusSum, setBoletoStatusSum] = useState<BoletoStatusTypeSum>(
    contextDefaultValues.boletoStatusSum,
  )
  const [dateFilterError, setDateFilterError] = useState<boolean>(
    contextDefaultValues.dateFilterError,
  )
  const [loadingDropdown, setLoadingDropdown] = useState<boolean>(
    contextDefaultValues.loadingDropdown,
  )
  const [showModal, setShowModal] = useState<boolean>(
    contextDefaultValues.showModal,
  )
  const [modalType, setModalType] = useState<number>(
    contextDefaultValues.modalType,
  )
  const [loadingSendingMail, setLoadingSendingMail] = useState<boolean>(
    contextDefaultValues.loadingSendingMail,
  )
  const [tableReady, setTableReady] = useState<boolean>(
    contextDefaultValues.tableReady,
  )
  const [dropdownError, setDropdownError] = useState<boolean>(
    contextDefaultValues.dropdownError,
  )
  const [modalErrorMessage, setModalErrorMessage] = useState<string>(
    contextDefaultValues.modalErrorMessage,
  )
  const [searchedFavorites, setSearchedFavorites] = useState(false)
  const { user, current_account } = useContext(AuthContext)
  const addBcStep1 = (data: BcStep1) => setBoletoCreationStep1(data)
  const addBcStep2 = (data: BcStep2) => setBoletoCreationStep2(data)
  const addRecipient = (data: BoletoRecipient) => setRecipient(data)
  const addFavorites = (data: Contact[]) => setFavorites(data)
  const addSearchedFavorites = (data: boolean) => setSearchedFavorites(data)
  const filterByStatus = (type: string) => {
    if (statusFilter.includes(type))
      setStatusFilter(statusFilter.filter((e) => e !== type))
    else setStatusFilter([...statusFilter, type])
  }
  const addLoadingData = (data: boolean) => setLoadingData(data)
  const addPagination = (data: number) => setPagination(data)
  const addPage = (data: number) => setPage(data)
  const addTotalPages = (data: number) => setTotalPages(data)
  const addFilteringByStatus = (data: boolean) => setFilteringByStatus(data)
  const addFilteringByName = (data: boolean) => setFilteringByName(data)
  const addFullData = (data: Boleto[]) => setFullData(data)
  const addDataArray = (data: Boleto[]) => setDataArray(data)
  const addNameFilter = (data: string) => setNameFilter(data)
  const addShowErrorModal = (data: boolean) => setShowErrorModal(data)
  const addBoletoStatusSum = (data: BoletoStatusTypeSum) =>
    setBoletoStatusSum(data)
  const addDateFilterError = (data: boolean) => setDateFilterError(data)
  const addLoadingDropdown = (data: boolean) => setLoadingDropdown(data)
  const addShowModal = (data: boolean) => setShowModal(data)
  const addModalType = (data: number) => setModalType(data)
  const addLoadingSendingMail = (data: boolean) => setLoadingSendingMail(data)
  const addTableReady = (data: boolean) => setTableReady(data)

  useEffect(
    function () {
      async function fetchFavorites() {
        try {
          const res = await services.bankingService.getCustomerFavorites({
            clientePfId: user?.id,
            type: `boleto`,
          })
          console.log(
            `🚀 ~ file: BoletoContext.tsx ~ line 61 ~ fetchFavorites ~ res`,
            res,
          )
          addFavorites(res.contacts)
        } catch (error) {
          console.log(error)
        }
        setSearchedFavorites(true)
      }

      if (!searchedFavorites && user && user.id) fetchFavorites()

      if (
        !recipient.cpfCnpjBeneficiario &&
        user &&
        user.cpf &&
        user.nome &&
        user.addresses.length > 0
      ) {
        const recipientObj: BoletoRecipient = {
          nomeBeneficiario: user.nome,
          cpfCnpjBeneficiario: user.cpf,
          enderecoBeneficiario: buildAddress(user.addresses[0]),
        }
        addRecipient(recipientObj)
      }
      if (!tableReady && user && user.accounts && user.accounts.length > 0) {
        setLoadingData(true)
        fillWithLastWeek()
        setTableReady(true)
      }
    },
    [searchedFavorites],
  )

  const changeBoletoPage = (i: number) => {
    const index = i * pagination
    const start = index - pagination
    let newArray: Boleto[] = []

    if (filteringByStatus) {
      let data: Boleto[] = []
      if (filteringByName) {
        statusFilter.forEach((element) => {
          const array: Boleto[] = fullData.filter(
            (e) =>
              e.status === element &&
              e.nom_RzSocPagdr.toLowerCase().includes(nameFilter),
          )
          data = [...data, ...array]
        })
      } else {
        statusFilter.forEach((element) => {
          const array: Boleto[] = fullData.filter((e) => e.status === element)
          data = [...data, ...array]
        })
      }

      const slicedData: Boleto[] = data.slice(start, index)

      newArray = slicedData
    } else if (filteringByName) {
      const array: Boleto[] = fullData.filter((e) =>
        e.nom_RzSocPagdr.toLowerCase().includes(nameFilter),
      )
      const slicedData: Boleto[] = array.slice(start, index)
      newArray = slicedData
    } else newArray = fullData.slice(start, index) as Boleto[]

    setDataArray(newArray)
  }

  const handlePages = (updatePage: number) => {
    setPage(updatePage)
    changeBoletoPage(updatePage)
  }

  const fillStatusField = (data: Boleto[]) => {
    const updatedList: Boleto[] = []
    const today = dayjs()

    data.forEach((element) => {
      const situation = element.situacao
      let status = ``
      switch (situation) {
        case 4:
          status = `paid`
          break
        case 5:
          status = `canceled`
          break
        case 6:
          status = `expired`
          break
        case 2:
          if (dayjs(element.dtVencimento).diff(today, `days`) < 0)
            status = `overdue`
          else status = `pending`
          break
        case 7:
          if (dayjs(element.dtVencimento).diff(today, `days`) < 0)
            status = `overdue`
          else status = `pendingCancellation`
          break
      }
      element.status = status
      updatedList.push(element)
    })
    return updatedList
  }

  const findBoletosInPeriod = async (dateStart: Date, dateEnd: Date) => {
    let accountLength = 0
    let contaPgt: string
    contaPgt = ``
    if (user?.accounts[0].conta && user?.accounts[0].contaDigito) {
      accountLength = (user?.accounts[0].conta + user?.accounts[0].contaDigito)
        .length
    }
    if (accountLength == 5)
      contaPgt = `00` + user?.accounts[0].conta + user?.accounts[0].contaDigito
    if (accountLength == 6)
      contaPgt = `0` + user?.accounts[0].conta + user?.accounts[0].contaDigito
    if (accountLength == 7)
      contaPgt = `` + user?.accounts[0].conta + user?.accounts[0].contaDigito

    const sendObj: findBoletos = {
      carteiraInicial: 1,
      carteiraFinal: 2,
      dtRegistroInicial: dateStart,
      dtRegistroFinal: dateEnd,
      agencia:
        user && user.accounts.length > 0 ? user?.accounts[0].agencia : ``,
      conta: user && user.accounts.length > 0 ? user?.accounts[0].conta : ``,
      contaPgto: user && user.accounts.length > 0 ? contaPgt : ``,
      numeroBanco:
        user && user.accounts.length > 0 ? user?.accounts[0].numeroBanco : ``,
    }
    try {
      const res = await services.bankingService.findBoletosInDateRange(sendObj)
      const boletos: Boleto[] = res.data?.cedentes[0]?.boletosRegistrados
      if (boletos && boletos.length > 0) {
        const updatedList = fillStatusField(boletos)
        return updatedList
      } else {
        setLoadingData(false)
        return []
      }
    } catch (error: any) {
      const err = error as AxiosError<any>
      console.log(
        `🚀 ~ file: BoletoContext.tsx ~ line 415 ~ findBoletosInPeriod ~ error`,
        err,
      )
      if (err.code === `ERR_BAD_REQUEST`)
        setModalErrorMessage(
          error?.response?.data?.message || error?.message || error,
        )
      else setModalErrorMessage(``)
      setShowErrorModal(true)
      return []
    }
  }

  const sumValueByStatus = (data: Boleto[]) => {
    let sumPaid = 0
    let sumPending = 0
    let sumOverdue = 0
    let sumCanceled = 0

    data.forEach((element) => {
      switch (element.status) {
        case `paid`:
          sumPaid = sumPaid + element.vlrBoleto
          break
        case `pending`:
          sumPending = sumPending + element.vlrBoleto
          break
        case `overdue`:
          sumOverdue = sumOverdue + element.vlrBoleto
          break
        case `expired`:
          sumOverdue = sumOverdue + element.vlrBoleto
          break
        case `canceled`:
          sumCanceled = sumCanceled + element.vlrBoleto
          break
      }
    })

    setBoletoStatusSum({
      paid: sumPaid.toFixed(2),
      pending: sumPending.toFixed(2),
      overdue: sumOverdue.toFixed(2),
      canceled: sumCanceled.toFixed(2),
    })
  }

  const fillWithLastWeek = async () => {
    const startDate = dayjs().add(-7, `days`).toDate()
    const endDate = dayjs().toDate()
    const boletos: Boleto[] = await findBoletosInPeriod(startDate, endDate)
    if (boletos.length > 0) {
      if (boletos.length >= pagination)
        setDataArray(boletos.slice(0, pagination))
      else setDataArray(boletos)

      setFullData(boletos)
      const count = Math.ceil(boletos.length / pagination)
      setTotalPages(count)
      sumValueByStatus(boletos)
    }
    setLoadingData(false)
  }

  const applyFilter = () => {
    if (statusFilter.length > 0) {
      let data: Boleto[] = []
      if (filteringByName) {
        statusFilter.forEach((element) => {
          const array: Boleto[] = fullData.filter(
            (e) =>
              e.status === element &&
              e.nom_RzSocPagdr.toLowerCase().includes(nameFilter),
          )
          data = [...data, ...array]
        })
      } else {
        statusFilter.forEach((element) => {
          const array: Boleto[] = fullData.filter((e) => e.status === element)
          data = [...data, ...array]
        })
      }

      if (data.length > 0) {
        if (data.length >= pagination) {
          setDataArray(data.slice(0, pagination))
        } else setDataArray(data)

        const count = Math.ceil(data.length / pagination)
        setTotalPages(count)
        setPage(1)
        sumValueByStatus(data)
      } else {
        setDataArray([])
        setTotalPages(0)
        setPage(1)
        setBoletoStatusSum({
          paid: `0.00`,
          pending: `0.00`,
          overdue: `0.00`,
          canceled: `0.00`,
        })
      }
      setFilteringByStatus(true)
    }
  }

  const filterNewDataByPayerName = (
    boletos: Boleto[],
    name: string,
    isClearFilter?: boolean,
  ) => {
    const filteredDataByName = boletos.filter((f) =>
      f.nom_RzSocPagdr.toLowerCase().includes(name.toLowerCase()),
    )
    setFilteringByName(true)
    setNameFilter(name.toLowerCase())
    if (filteredDataByName.length > 0) {
      if (filteringByStatus && !isClearFilter)
        filterNewDataByStatus(filteredDataByName)
      else {
        if (filteredDataByName.length >= pagination) {
          setDataArray(filteredDataByName.slice(0, pagination))
          const count = Math.ceil(filteredDataByName.length / pagination)
          setTotalPages(count)
          setPage(1)
        } else {
          setDataArray(filteredDataByName)
          setTotalPages(0)
          setPage(1)
        }
        sumValueByStatus(filteredDataByName)
      }
      setFilteringByName(true)
      setNameFilter(name.toLowerCase())
    } else {
      setDataArray([])
      setTotalPages(0)
      setPage(1)
      setBoletoStatusSum({
        paid: `0.00`,
        pending: `0.00`,
        overdue: `0.00`,
        canceled: `0.00`,
      })
    }
  }

  const applyDateFilter = async (dates: any) => {
    if (dates[0] !== null && dates[1] !== null) {
      const dateStart = dates[0]
      const dateEnd = dates[1]

      if (dayjs(dateStart).diff(dateEnd, `days`) <= -31)
        setDateFilterError(true)
      else {
        setDateFilterError(false)
        setLoadingData(true)
        const boletos: Boleto[] = await findBoletosInPeriod(dateStart, dateEnd)
        if (boletos.length > 0) {
          if (filteringByStatus) {
            filterNewDataByStatus(boletos)
          } else {
            if (filteringByName) {
              filterNewDataByPayerName(boletos, nameFilter)
            } else {
              if (boletos.length >= pagination) {
                setDataArray(boletos.slice(0, pagination))
                const count = Math.ceil(boletos.length / pagination)
                setTotalPages(count)
              } else {
                setDataArray(boletos)
                setTotalPages(0)
              }
              sumValueByStatus(boletos)
            }
          }
          setFullData(boletos)
        } else {
          setFullData([])
          setDataArray([])
          setTotalPages(0)
          setBoletoStatusSum({
            paid: `0.00`,
            pending: `0.00`,
            overdue: `0.00`,
            canceled: `0.00`,
          })
        }
        setLoadingData(false)
      }
    }
  }

  const applyNameFilter = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingData(true)
    const value = e?.target.value
    console.log(
      `🚀 ~ file: index.tsx ~ line 388 ~ applyNameFilter ~ value`,
      value,
    )
    if (e.target.value === ``) {
      setFilteringByName(false)
      setNameFilter(``)
      if (filteringByStatus) {
        filterNewDataByStatus(fullData, true)
      } else {
        if (fullData.length >= pagination) {
          setPage(1)
          setDataArray(fullData.slice(0, pagination))
          const count = Math.ceil(fullData.length / pagination)
          setTotalPages(count)
        } else {
          setDataArray(fullData)
        }
        sumValueByStatus(fullData)
      }
    } else {
      filterNewDataByPayerName(fullData, value)
    }
    setLoadingData(false)
  }

  const filterNewDataByStatus = (boletos: Boleto[], isClearName?: boolean) => {
    let data: Boleto[] = []
    if (filteringByName && !isClearName) {
      statusFilter.forEach((element) => {
        const array: Boleto[] = boletos.filter(
          (e) =>
            e.status === element &&
            e.nom_RzSocPagdr.toLowerCase().includes(nameFilter),
        )
        data = [...data, ...array]
      })
    } else {
      statusFilter.forEach((element) => {
        const array: Boleto[] = boletos.filter((e) => e.status === element)
        data = [...data, ...array]
      })
    }

    if (data.length > 0) {
      if (data.length >= pagination) {
        setDataArray(data.slice(0, pagination))
        const count = Math.ceil(data.length / pagination)
        setTotalPages(count)
      } else setDataArray(data)
      sumValueByStatus(data)
    } else {
      setDataArray([])
      setBoletoStatusSum({
        paid: `0.00`,
        pending: `0.00`,
        overdue: `0.00`,
        canceled: `0.00`,
      })
    }
  }

  const clearFilter = () => {
    setStatusFilter([])
    setFilteringByStatus(false)
    if (filteringByName) {
      filterNewDataByPayerName(fullData, nameFilter, true)
    } else {
      if (fullData.length >= pagination) {
        setDataArray(fullData.slice(0, pagination))
        const count = Math.ceil(fullData.length / pagination)
        setTotalPages(count)
        setPage(1)
      } else {
        setDataArray(fullData)
        setTotalPages(0)
      }
      if (fullData.length > 0) sumValueByStatus(fullData)
      else {
        setBoletoStatusSum({
          paid: `0.00`,
          pending: `0.00`,
          overdue: `0.00`,
          canceled: `0.00`,
        })
      }
    }
  }

  const cancel = async (barCode: string) => {
    setLoadingDropdown(true)
    try {
      const res = await services.bankingService.cancelBoleto(
        barCode,
        current_account!.conta,
        current_account!.contaDigito,
      )
      console.log(`🚀 ~ file: index.tsx ~ line 328 ~ cancel ~ res`, res)
      if (res && res.data.sucesso) {
        setShowModal(true)
        setModalType(2)
        setLoadingDropdown(false)
        dataArray.forEach((element) => {
          if (element.codigoBarras === barCode)
            element.status = `pendingCancellation`
        })
      }
    } catch (error) {
      setLoadingDropdown(false)
      setDropdownError(true)
      setTimeout(function () {
        setDropdownError(false)
      }, 3000)
    }
  }

  async function sendMail(email: string, barCode: string) {
    setLoadingSendingMail(true)
    setLoadingDropdown(true)
    try {
      const res = await services.bankingService.sendBoletoAttachmentMail(
        email,
        barCode,
        recipient,
      )
      console.log(`🚀 ~ file: index.tsx ~ line 379 ~ sendMail ~ res`, res)
      if (res && res?.data.sucesso) {
        setShowModal(true)
        setModalType(3)
        setLoadingSendingMail(false)
        setLoadingDropdown(false)
      }
    } catch (error) {
      setLoadingSendingMail(false)
      setLoadingDropdown(false)
      setDropdownError(true)
      setTimeout(function () {
        setDropdownError(false)
      }, 3000)
    }
  }

  return (
    <BoletoContext.Provider
      value={{
        boletoCreationStep1,
        boletoCreationStep2,
        favorites,
        recipient,
        searchedFavorites,
        statusFilter,
        filteringByStatus,
        filteringByName,
        loadingData,
        page,
        totalPages,
        pagination,
        fullData,
        dataArray,
        nameFilter,
        showErrorModal,
        boletoStatusSum,
        dateFilterError,
        loadingDropdown,
        showModal,
        modalType,
        loadingSendingMail,
        tableReady,
        dropdownError,
        modalErrorMessage,
        addTableReady,
        addLoadingSendingMail,
        addModalType,
        addShowModal,
        addLoadingDropdown,
        filterNewDataByStatus,
        addDateFilterError,
        addBoletoStatusSum,
        addFullData,
        addDataArray,
        addNameFilter,
        addPagination,
        addTotalPages,
        addPage,
        addLoadingData,
        filterByStatus,
        addFilteringByStatus,
        addFilteringByName,
        addBcStep1,
        addBcStep2,
        addFavorites,
        addRecipient,
        addSearchedFavorites,
        changeBoletoPage,
        handlePages,
        addShowErrorModal,
        findBoletosInPeriod,
        sumValueByStatus,
        fillWithLastWeek,
        applyFilter,
        applyNameFilter,
        filterNewDataByPayerName,
        applyDateFilter,
        clearFilter,
        cancel,
        sendMail,
      }}
    >
      {children}
    </BoletoContext.Provider>
  )
}

export default BoletoProvider
