import SVGInject from "@iconfu/svg-inject"
import { AxiosError } from "axios"
import dayjs from "dayjs"
import { useContext, useEffect, useState } from "react"
import X from "../../../../../assets/images/x.svg"
import { AuthContext } from "../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import { InsuranceContext } from "../../../../../context/InsuranceContext"
import { useResposive } from "../../../../../hooks/useResponsive"
import services from "../../../../../services/svcmesh"
import { formatCpf } from "../../../../../utils/validation"
import { CustomModal, ErrorModal, SuccessModal } from "k4n-components"
import "./styles.scss"

interface Latlong {
  lat?: number
  long?: number
}

const Cancelar = () => {
  const { activeProposal, addSearchedForActiveProposal } =
    useContext(InsuranceContext)
  const { changeInsurancePage } = useContext(BankingNavigationContext)
  const { user } = useContext(AuthContext)
  const [cancelInput, setCancelInput] = useState(``)
  useEffect(() => {
    if (`geolocation` in navigator) {
      navigator.geolocation.watchPosition(
        (position) => {
          const location = {
            lat: position.coords.latitude,
            long: position.coords.longitude,
          } as Latlong

          setLatLong(location)
          setLocationError(false)
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) setLocationError(true)
        },
      )
    } else {
      setLocationError(true)
    }
  }, [])

  const [showModal, setShowModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [latlong, setLatLong] = useState<Latlong>({})
  const [locationError, setLocationError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(``)
  const { isTabletOrMobile } = useResposive()
  const [inputError, setInputError] = useState(``)

  async function cancelInsurance() {
    if (cancelInput === `cancelar`) {
      setLoading(true)
      try {
        const res = await services.bankingService.insuranceManualCancelation(
          {
            cancelled_device_ip: `192.0.0.1`,
            // cancelled_device_ip: (
            //   await services.publicIpService.consultPublicIp()
            // ).data.ip,
            cancelled_lat_long: `${latlong.lat},${latlong.long}`,
          },
          Number(activeProposal.id),
          user?.cpf ? user.cpf : ``,
        )
        if (res?.data?.message === `success`) {
          setShowModal(false)
          setShowSuccessModal(true)
        } else {
          setErrorMessage(
            `Não foi possivel finalizar o cancelamento do seu seguro. Tente novamente mais tarde`,
          )
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        const err = error as AxiosError<any>
        // eslint-disable-next-line no-unused-expressions
        setErrorMessage(
          err?.response?.data?.message ??
            `Não foi possivel finalizar o cancelamento do seu seguro. Tente novamente mais tarde`,
        )
      }
    } else {
      if (cancelInput === ``) {
        setInputError(`Por favor escreva "cancelar" no campo acima`)
      } else {
        setInputError(`A palavra escrita não é "cancelar"`)
      }
    }
  }

  const onCloseSuccessModal = () => {
    addSearchedForActiveProposal(false)
    changeInsurancePage(1)
  }

  const onCloseModal = () => {
    setCancelInput(``)
    setInputError(``)
    setShowModal(false)
  }

  return (
    <>
      {activeProposal?.status?.slice(-24) === `but_pending_tokio_cancel` ? (
        <h2 className="insurance-cancellation-in-progress">
          Sua solicitação de cancelamento está em andamento...
        </h2>
      ) : (
        <>
          {!locationError ? (
            <div className="cancel-insurance-container">
              <CustomModal
                isOpen={showModal}
                onClose={onCloseModal}
                isBanking={true}
              >
                <div>
                  <div className="input-container">
                    <p className="input-text">
                      Para cancelar seu seguro digite <strong>cancelar </strong>
                      no campo abaixo
                    </p>
                    <div className="row justify-content-center">
                      <input
                        className="input"
                        onChange={(e) => setCancelInput(e.target.value)}
                        value={cancelInput}
                        type="text"
                        placeholder=""
                      />
                    </div>
                  </div>
                  {cancelInput !== `cancelar` && (
                    <div className="input-error-insurance">{inputError}</div>
                  )}

                  <div className="custom-modal-content">
                    <div>
                      <button
                        disabled={loading}
                        className="cancel-insurance-button cancel-button"
                        onClick={cancelInsurance}
                      >
                        {loading ? `Carregando...` : `Confirmar`}
                      </button>
                    </div>
                  </div>
                </div>
              </CustomModal>

              <SuccessModal
                isOpen={showSuccessModal}
                width={isTabletOrMobile ? `100%` : `500px`}
                height="auto"
                onClose={onCloseSuccessModal}
              >
                <h1 className="pix-modal-sucess-h1">
                  Solicitação de cancelamento do seguro enviada com sucesso!
                </h1>
              </SuccessModal>

              <ErrorModal
                isOpen={!!errorMessage}
                width={isTabletOrMobile ? `100%` : `500px`}
                height="auto"
                onClose={() => setErrorMessage(``)}
              >
                <h1 className="pix-modal-sucess-h1">{errorMessage}</h1>
              </ErrorModal>

              <div className="cancel-insurance-content">
                <div className="cancel-insurance-header">
                  <div className="insurance-cancel-description">
                    Atenção, este é o canal para cancelamento do seu seguro. Se
                    deseja mesmo continuar, confira abaixo os dados do seguro a
                    ser cancelado.
                  </div>
                </div>
                <div className=" my-4 pt-4">
                  <div className="row px-4">
                    <div className="col-6  info-insurance">
                      <label className="info-insurance-bold">Nome:</label>
                      <p>{user?.nome}</p>
                    </div>
                    <div className="col-6  info-insurance">
                      <label className="info-insurance-bold">CPF:</label>
                      <p>{formatCpf(user?.cpf)}</p>
                    </div>
                    <div className="col-6  info-insurance">
                      <label className="info-insurance-bold">Bilhete: </label>
                      <p>{activeProposal.id}</p>
                    </div>
                    <div className="col-6  info-insurance">
                      <label className="info-insurance-bold">Vence em:</label>
                      <p>
                        {dayjs(activeProposal.created_at)
                          .add(Number(activeProposal.deadline), `day`)
                          .format(`DD/MM/YYYY`)}
                      </p>
                    </div>
                    {activeProposal?.tokio_marine_id_segurado && (
                      <div className="col-4 info-insurance">
                        <label className="info-insurance-bold">Termo: </label>
                        <p>{`T` + activeProposal.id}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    className="cancel-button"
                    type="button"
                    onClick={() => setShowModal(true)}
                  >
                    Cancelar seguro
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="row" style={{ margin: 0, width: 600, height: 80 }}>
              <div className="col-12 no-padding-flx">
                <div className="col-1 dp-table">
                  <div className="location-error-red-bar"></div>
                  <div className="dp-table-cell pl-15">
                    <div className="location-error-red-circle">
                      <div className="location-error-x">
                        <img
                          src={X}
                          className="location-error-x-img"
                          onLoad={(e) => SVGInject(e.target)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-11 dp-table location-error-msg-div pl-15">
                  <span className="location-error-txt">
                    Por favor, ative a localização para continuar o cancelamento
                    do seguro.
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default Cancelar
