import eye from "../../../assets/images/navbar-banking/navbar_eye.svg"
import eyeClosed from "../../../assets/images/navbar-banking/navbar_eye_closed.svg"
import SVGInject from "@iconfu/svg-inject"
import { useState } from "react"
import "./styles.scss"

const NumericPasswordInput = ({ canToggle = true, register, ...rest }) => {
  const [show, setShow] = useState(false)

  return (
    <div
      className="password-input-container"
      /* onClick={(e) =>
        (e.currentTarget.childNodes[0] as HTMLInputElement).focus()
      } */
    >
      <input
        type={show ? `text` : `password`}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault()
          }
        }}
        {...register()}
        {...rest}
      />
      {canToggle && (
        <div>
          <div
            className={`password-input-button ${!show ? `d-none` : `d-block`}`}
            onClick={() => setShow(!show)}
          >
            <img src={eye} onLoad={(e) => SVGInject(e.target)} />
          </div>
          <div
            className={`password-input-button ${show ? `d-none` : `d-block`}`}
            onClick={() => setShow(!show)}
          >
            <img src={eyeClosed} onLoad={(e) => SVGInject(e.target)} />
          </div>
        </div>
      )}
    </div>
  )
}

export default NumericPasswordInput
