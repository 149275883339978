import SVGInject from "@iconfu/svg-inject"
import { AxiosError } from "axios"
import dayjs from "dayjs"
import { ClientePf } from "k4n-svcmesh-sdk"
import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import Arrow from "../../../../../../../assets/images/arrow.svg"
import { AuthContext } from "../../../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../../../context/BankingNavigationContext"
import { InsuranceContext } from "../../../../../../../context/InsuranceContext"
import { useResposive } from "../../../../../../../hooks/useResponsive"
import services from "../../../../../../../services/svcmesh"
import { acceptedCreditCards } from "../../../../../../../typings/insurance.dto"
import { formatCep } from "../../../../../../../utils/validation"
import { FailureModal, SuccessModal, PrimaryButton } from "k4n-components"
import "./styles.scss"

interface InsuranceCardPaymentProps {
  isChange?: boolean
  user?: ClientePf | null
}

const InsuranceCardPayment = (props: InsuranceCardPaymentProps) => {
  const [device] = useState(`desktop`)
  const [btnLoading, setBtnLoading] = useState(false)
  const [modalPurchase, setModalPurchase] = useState(false)
  const [modalPurchaseError, setModalPurchaseError] = useState(false)
  const [purchaseError, setPurchaseError] = useState(``)
  const [creditCardNumber, setCreditCardNumber] = useState(``)
  const [modalUpdateCCSuccess, setModalUpdateCCSuccess] = useState(false)
  const { isTabletOrMobile } = useResposive()

  const { user } = useContext(AuthContext)

  const { changeInsuranceStep } = useContext(BankingNavigationContext)

  const {
    step1Data,
    hasBeneficiary,
    paymentDay,
    selectedPlan,
    beneficiaries,
    addSearchedForActiveProposal,
  } = useContext(InsuranceContext)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const validateCC = (cc: string) => {
    const value = cc.replace(/\D/g, ``)
    let accepted = false

    // loop through the keys (visa, mastercard, amex, etc.)
    Object.keys(acceptedCreditCards).forEach(function (key) {
      const regex = acceptedCreditCards[key]
      if (regex.test(value)) {
        accepted = true
      }
    })
    return accepted
  }

  const onCloseSuccessModal = () => {
    addSearchedForActiveProposal(false)
    changeInsuranceStep(1)
  }

  const onSubmit = async (data: any) => {
    console.log(`🚀 ~ file: index.tsx ~ line 65 ~ onSubmit ~ data`, data)
    if (props.isChange) {
      setBtnLoading(true)
      const updateCCObj = {
        creditCard: {
          holderName: data.creditCardName,
          number: creditCardNumber,
          expiryMonth: data.expiresIn.substring(0, 2),
          expiryYear: data.expiresIn.substring(3, 5),
          ccv: data.ccv,
        },
        creditCardHolderInfo: {
          name: props.user?.nome,
          email: props.user?.emails[0]?.email,
          cpfCnpj: props.user?.cpf,
          postalCode: formatCep(props.user?.addresses[0]?.cep),
          addressNumber: props.user?.addresses[0]?.numero,
          addressComplement: props.user?.addresses[0]?.complemento,
          phone: props.user?.phones[0]?.phone,
          mobilePhone: props.user?.phones[0]?.phone,
        },
        remoteIp: (await services.publicIpService.consultPublicIp()).data.ip,
        customer: props.user?.asaas_cliente_id,
        customerId: props.user?.id,
      }
      console.log({ updateCCObj })
      try {
        const updateCC = await services.bankingService.updateCreditCard(
          updateCCObj,
          user?.cpf ? user.cpf : ``,
        )
        if (updateCC?.data?.message === `success`) {
          setBtnLoading(false)
          setModalUpdateCCSuccess(true)
        }
      } catch (error) {
        const err = error as AxiosError<any>
        setBtnLoading(false)
        setPurchaseError(
          err?.response?.data?.message ??
            `Não foi possivel atualizar seu cartão de crédito. Tente novamente mais tarde`,
        )
        setModalPurchaseError(true)
      }
    } else {
      setBtnLoading(true)
      try {
        const sendObj = {
          first_due_date:
            dayjs().date() >= paymentDay
              ? dayjs().add(1, `M`).date(paymentDay).format(`YYYY-MM-DD`)
              : dayjs().date(paymentDay).format(`YYYY-MM-DD`),
          method_payment: `cartao`,
          billing_day: paymentDay,
          total_installments: selectedPlan?.max_installments,
          installments_value: selectedPlan?.monthly_payment,
          accepted_lat_long: step1Data.lat + `,` + step1Data.long,
          product: {
            id: selectedPlan?.product?.id,
            deadline: selectedPlan?.product?.deadline,
            kikkin_commission: Number(selectedPlan?.product?.kikkin_commission),
            name: selectedPlan?.product?.name,
          },
          plan: {
            id: selectedPlan?.id,
            max_installments: selectedPlan?.max_installments,
            monthly_payment: parseFloat(
              selectedPlan?.monthly_payment?.toString()!,
            ),
            carencia: selectedPlan?.carencia,
            coverages: selectedPlan!.coverages!.map((c) => ({
              id: c.id,
              description: c.description,
              coverage: c.coverage,
              franchise: c.franchise,
              carencia: c.carencia,
              value: Number(c.value),
              description_note: c.description_note,
              description_note_id: Number(c.description_note_id),
            })),
            ramo: selectedPlan?.ramo,
            net_value: Number(selectedPlan?.net_value),
            iof: Number(selectedPlan?.iof),
          },
          affiliate: step1Data.representativeCode
            ? { id: step1Data?.representativeCode }
            : undefined,
          customer: {
            nome: step1Data.fullName,
            dtNasc: dayjs(step1Data.birthDate).format(`YYYY-MM-DD`),
            sexo: step1Data.gender === 0 ? `F` : `M`,
            estadoCivil: parseInt(step1Data.maritalStatus?.toString()!),
            cpf: step1Data.cpf,
            addresses: [
              {
                cep: user?.addresses[0].cep,
                logradouro: user?.addresses[0].logradouro,
                numero: user?.addresses[0].numero,
                complemento: user?.addresses[0].complemento,
                bairro: user?.addresses[0].bairro,
                cidade: user?.addresses[0].cidade,
                uf: user?.addresses[0].uf,
              },
            ],
            phones: [
              {
                phone: step1Data.phone,
              },
            ],
            emails: [
              {
                email: step1Data.email,
              },
            ],
            creditCard: {
              holderName: data.creditCardName,
              number: creditCardNumber,
              expiryMonth: data.expiresIn.substring(0, 2),
              expiryYear: data.expiresIn.substring(3, 5),
              expiresIn: data.expiresIn,
              ccv: data.ccv,
            },
            creditCardHolderInfo: {
              name: step1Data?.fullName,
              email: step1Data.email,
              cpfCnpj: step1Data.cpf,
              postalCode: user?.addresses[0]
                .cep!.replace(/\D/g, ``)
                .replace(/(\d{5})(\d)/, `$1-$2`)
                .replace(/(-\d{3})\d+?$/, `$1`),
              addressNumber: user?.addresses[0].numero,
              addressComplement: user?.addresses[0].complemento,
              phone: step1Data.phone,
              mobilePhone: step1Data.phone,
            },
          },
          ip: (await services.publicIpService.consultPublicIp()).data.ip,
        }
        if (hasBeneficiary) {
          sendObj[`beneficiaries`] = beneficiaries.map((b) => ({
            name: b.name,
            percentage: b.pct,
            kinship: b.kinship,
          }))
        }
        console.log({ sendObj })
        const response = await services.bankingService.purchase(sendObj)
        if (response?.data?.message === `success`) {
          console.log(`🚀 InsurancePlanProposal`, response)
          setBtnLoading(false)
          setModalPurchase(true)
        }
      } catch (error) {
        const err = error as AxiosError<any>
        setBtnLoading(false)
        setPurchaseError(
          err?.response?.data?.message ??
            `Não foi possivel finalizar a contratação do seu seguro. Tente novamente mais tarde`,
        )
        setModalPurchaseError(true)
      }
    }
  }

  return (
    <>
      {device && device !== `mobile` && (
        <div className="insurance-step3-card">
          <h1 className="insurance-step3-card-h1">
            Insira os dados do cartão de crédito:
          </h1>
          <form onSubmit={handleSubmit(onSubmit)} className="row Form-step3">
            <div className="col-12">
              <InputMask
                // autochange to Amex format if starts with 34 or 37
                mask={
                  /^3[47]/.test(creditCardNumber)
                    ? `9999 999999 99999`
                    : `9999 9999 9999 9999`
                }
                value={creditCardNumber}
                type="text"
                placeholder="Número do cartão"
                className="insurance-input"
                {...register(`creditCardNumber`, {
                  onChange: (e) => setCreditCardNumber(e.target.value),
                  required: `Preencha o número do cartão`,
                  validate: (cc) =>
                    validateCC(cc) ||
                    `Bandeira de cartão de crédito não aceita. Por favor tente outra opção.`,
                })}
              />
              {errors.creditCardNumber && (
                <p className="insurance-error-msg Mg-negative">
                  {errors.creditCardNumber.message as string}
                </p>
              )}

              <div className="w-100"></div>

              <input
                type="text"
                maxLength={120}
                placeholder="Nome como está no cartão"
                className="insurance-input Mg-top-32"
                {...register(`creditCardName`, {
                  required: true,
                  pattern: /^(([a-zA-Zà-ùÀ-Ù]+)(\ )?){0,}$/,
                })}
              />
              {errors.creditCardName && (
                <p className="insurance-error-msg Mg-negative">
                  Por favor, verifique o nome informado.
                </p>
              )}

              <div className="row">
                <div className="d-grid gap-2 d-md-block">
                  <InputMask
                    mask="99/99"
                    maskPlaceholder={null}
                    type="text"
                    placeholder="Validade"
                    className="insurance-input card-input Mg-top-32 Mg-right-16"
                    {...register(`expiresIn`, {
                      required: true,
                      pattern: /^(\d{2})\/(\d{2})$/,
                    })}
                  />
                  <input
                    type="text"
                    maxLength={4}
                    placeholder="CVV"
                    className="insurance-input card-input Mg-right-7"
                    {...register(`ccv`, {
                      required: true,
                      pattern: /^[0-9]{3,4}$/,
                    })}
                  />
                </div>
                {errors.expiresIn && (
                  <p className="insurance-error-msg Mg-negative Padding-bottom-10">
                    Preencha a validade corretamente
                  </p>
                )}
                {props.isChange ? (
                  <>
                    {errors.ccv && (
                      <p className="insurance-error-msg Mg-negative">
                        CVV inválido
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    {errors.ccv && (
                      <p className="insurance-error-msg Mg-negative">
                        Preencha o ccv corretamente
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="insurance-completed ">
              <button
                type="submit"
                className="insurance-next-btn"
                disabled={btnLoading}
              >
                {btnLoading === true ? (
                  <div
                    className="spinner-border spinner-pix"
                    role="status"
                  ></div>
                ) : (
                  <span>
                    <img
                      src={Arrow}
                      className="insurance-next-arrow"
                      alt=">"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  </span>
                )}
              </button>
            </div>
          </form>
          <SuccessModal
            isOpen={modalPurchase}
            width={isTabletOrMobile ? `100%` : `937px`}
            height="auto"
            onClose={onCloseSuccessModal}
          >
            <>
              <h1 className="pix-modal-sucess-h1">
                Seguro solicitado com <br /> sucesso!
              </h1>
              {isTabletOrMobile ? (
                ``
              ) : (
                <h1 className="pix-modal-sucess-3p">• • •</h1>
              )}
              <h1 className="pix-modal-sucess-txt">
                Tudo certo! O pagamento do
                <br />
                seu seguro foi realizado.
              </h1>
              <div className="pix-align-center">
                <PrimaryButton
                  width={`312px`}
                  height={`60px`}
                  onClick={onCloseSuccessModal}
                >
                  OK!
                </PrimaryButton>
              </div>
            </>
          </SuccessModal>
          <SuccessModal
            isOpen={modalUpdateCCSuccess}
            width={isTabletOrMobile ? `100%` : `937px`}
            height="auto"
            onClose={() => setModalUpdateCCSuccess(false)}
          >
            <>
              <h1 className="pix-modal-sucess-h1">
                Cartão atualizado com <br /> sucesso!
              </h1>
              {isTabletOrMobile ? (
                ``
              ) : (
                <h1 className="pix-modal-sucess-3p">• • •</h1>
              )}
              <h1 className="pix-modal-sucess-txt">
                As alterações serão aplicadas ao atualizar a página.
              </h1>
              <div className="pix-align-center">
                <PrimaryButton
                  width={`312px`}
                  height={`60px`}
                  onClick={() => setModalUpdateCCSuccess(false)}
                >
                  OK!
                </PrimaryButton>
              </div>
            </>
          </SuccessModal>
          <FailureModal
            isOpen={modalPurchaseError}
            width={isTabletOrMobile ? `100%` : `937px`}
            height="auto"
            onClose={() =>
              props.isChange
                ? setModalPurchaseError(false)
                : changeInsuranceStep(1)
            }
          >
            <h1 className="pix-modal-sucess-h1">Ocorreu um Erro</h1>
            {isTabletOrMobile ? (
              ``
            ) : (
              <h1 className="pix-modal-sucess-3p">• • •</h1>
            )}
            <h1 className="pix-modal-sucess-txt">{purchaseError}</h1>
            <div className="pix-align-center">
              <PrimaryButton
                width={`312px`}
                height={`60px`}
                onClick={() =>
                  props.isChange
                    ? setModalPurchaseError(false)
                    : changeInsuranceStep(1)
                }
              >
                OK!
              </PrimaryButton>
            </div>
          </FailureModal>
        </div>
      )}
    </>
  )
}

export default InsuranceCardPayment
