import { useContext, useEffect } from "react"
import next from "../../../../../../assets/images/next.svg"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { TransferContext } from "../../../../../../context/TransferContext"
import { formattedCurrency } from "../../../../../../services/banking"
import { SecondaryButton, PrimaryButton } from "k4n-components"
import Favorites, { FavoriteScreenEnum } from "../../../../Favorites"
import "./styles.scss"
import SVGInject from "@iconfu/svg-inject"
import { useResposive } from "../../../../../../hooks/useResponsive"
import { BalanceContext } from "../../../../../../context/BalanceProvider"
import { useNavigate } from "react-router-dom"

const TransferStep2Mobile = ({
  favorites,
  getUserFavorites,
  loadingFavorites,
}) => {
  const { isTabletOrMobile } = useResposive()

  const { changeTransferStep, changeNewContactTransferStep } = useContext(
    BankingNavigationContext,
  )
  const navigate = useNavigate()
  const { transferValue, addFavoriteData } = useContext(TransferContext)
  const { balance, loadingBalance, refreshBalance } = useContext(BalanceContext)

  useEffect(() => {
    getUserFavorites()
    refreshBalance()
  }, [])

  return (
    <div className="transfer-mobile-header">
      <div className="panel-mobile-content">
        <button
          className="transfer-mobile-close-btn"
          onClick={() => {
            navigate(`/banking`)
          }}
        ></button>
        <div className="panel-mobile-title-step-2 mb-2">
          <h1 className="title-mobile-blue">Para quem transferir</h1>
          <span className="info-span-bold-16 mb-2">
            Selecione um contato da sua lista ou busque por uma nova chave.
          </span>
        </div>
        <div className="transfer-mobile-value-div-2">
          <div className="steps-2-mobile-value-info">
            <h1 className="info-span-bold-16">Valor a pagar</h1>
            <span className="info-span-grey-24">
              {formattedCurrency(transferValue)}
            </span>
          </div>
          <div className="info-div">
            <h1 className="info-span-bold-16">Saldo Kikkin:</h1>
            <h1 className="info-span-grey-16">
              {loadingBalance ? (
                <div className="emptySpace-pix gradient"></div>
              ) : (
                <span className="content-mobile-table-step2-pix-txt">
                  {formattedCurrency(balance)}
                </span>
              )}
            </h1>
          </div>
        </div>
        <div className="new-contact-btn-div-2">
          <PrimaryButton
            width={`100%`}
            height={`56px`}
            onClick={() => {
              changeTransferStep(0)
              changeNewContactTransferStep(1)
            }}
          >
            <div className="flex-space-evenly">
              <span className="p-16">Transferir para novo contato</span>
              <img
                className="transfer-arrow-margin"
                src={next}
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </div>
          </PrimaryButton>
        </div>
        {!loadingFavorites ? (
          <Favorites
            favorites={favorites}
            title={`Transferir a um favorito`}
            screen={FavoriteScreenEnum.TRANSFER}
            changeStep={changeTransferStep}
            addData={addFavoriteData}
            isMobile={isTabletOrMobile}
          />
        ) : (
          <h6 style={{ color: `black` }}>Carregando favoritos</h6>
        )}
        <div className="transfer-mobile-step-2-btns-div">
          <SecondaryButton
            width={`180px`}
            height={`56px`}
            text={`Voltar`}
            OnClick={() => changeTransferStep(1)}
          />
        </div>
      </div>
    </div>
  )
}

export default TransferStep2Mobile
