import "./styles.scss"

interface ButtonProps {
  step: number
}
const PaymentNav = ({ step }: ButtonProps) => {
  return (
    <div className="payment-nav">
      <h1 className="payment-nav-title">Pagamento</h1>
      <div className="payment-nav-buttons">
        {step >= 1 ? (
          <button className="steps-btn-active">1</button>
        ) : (
          <button className="steps-btn">1</button>
        )}
        {step >= 2 ? (
          <button className="steps-btn-active">2</button>
        ) : (
          <button className="steps-btn">2</button>
        )}
      </div>
    </div>
  )
}

export default PaymentNav
