import { useContext, useEffect } from "react"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import { useResposive } from "../../../../../hooks/useResponsive"
import "./styles.scss"

export const InsuranceSwitcher = () => {
  const { insurancePage, changeInsurancePage } = useContext(
    BankingNavigationContext,
  )
  const { isTabletOrMobile } = useResposive()
  useEffect(function () {}, [insurancePage])

  return (
    <div
      className={
        isTabletOrMobile !== true
          ? `Switcher-kikkin-logged`
          : `Switcher-kikkin-logged-mobile`
      }
    >
      <div className={`Switcher-border-bottom`}>
        <div className="Switcher-row-logged">
          <div
            onClick={() => changeInsurancePage(1)}
            className={
              insurancePage === 1
                ? `Switcher-item-text-active`
                : `Switcher-item-text`
            }
          >
            <span
              className={
                insurancePage === 1
                  ? `Switcher-item-text-active`
                  : `Switcher-item-text`
              }
            >
              Bilhete e termos
            </span>
          </div>
          <div
            onClick={() => changeInsurancePage(2)}
            className={
              insurancePage === 2
                ? `Switcher-item-div-active Switcher-mg-left`
                : `Switcher-item-div Switcher-mg-left`
            }
          >
            <span
              className={
                insurancePage === 2
                  ? `Switcher-item-text-active`
                  : `Switcher-item-text`
              }
            >
              Alterar pagamento
            </span>
          </div>
          <div
            onClick={() => changeInsurancePage(3)}
            className={
              insurancePage === 3
                ? `Switcher-item-div-active Switcher-mg-left`
                : `Switcher-item-div Switcher-mg-left`
            }
          >
            <span
              className={
                insurancePage === 3
                  ? `Switcher-item-text-active`
                  : `Switcher-item-text`
              }
            >
              Canais de atendimento
            </span>
          </div>
          <div
            onClick={() => changeInsurancePage(4)}
            className={
              insurancePage === 4
                ? `Switcher-item-div-active Switcher-mg-left`
                : `Switcher-item-div Switcher-mg-left`
            }
          >
            <span
              className={
                insurancePage === 4
                  ? `Switcher-item-text-active`
                  : `Switcher-item-text`
              }
            >
              Cancelar seguro
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
