/* eslint-disable prettier/prettier */
import { useContext, useState } from "react"
import "./styles.scss"
import SVGInject from "@iconfu/svg-inject"
import close from "../../../../../../assets/images/clear_x.svg"
import card1 from "../../../../../../assets/images/card-loading.svg"
import card2 from "../../../../../../assets/images/card-loading-2.svg"
import arrow_right from "../../../../../../assets/images/arrow-right-yellow.svg"
import cell from "../../../../../../assets/images/cell.svg"
import userImg from "../../../../../../assets/images/user.svg"
import mail from "../../../../../../assets/images/mail_yellow.svg"
import random_key from "../../../../../../assets/images/random_key.svg"
import copy from "../../../../../../assets/images/copy_green.svg"
import bin from "../../../../../../assets/images/bin_green.svg"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import {
  PixKeyType,
  PixStorageContext,
} from "../../../../../../context/PixStorageProvider"
import { PrimaryButton, CustomModal } from "k4n-components"

const PixKeysMobile = ({ copyPixKey, setPixKeyType, setOpenModalDelete }) => {
  const { changePixEnvironment } = useContext(BankingNavigationContext)
  const { userPixKeys } = useContext(PixStorageContext)
  const [showModalCelular, setShowModalCelular] = useState(false)
  const [showModalCpf, setShowModalCpf] = useState(false)
  const [showModalEmail, setShowModalEmail] = useState(false)
  const [showModalEvp, setShowModalEvp] = useState(false)

  return (
    <div className="pix-payment-wrapper-mobile">
      <div className="pix-payment-step1-mobile">
        <span
          className="pix-receipt-img-close"
          onClick={() => changePixEnvironment(1)}
        >
          <img
            src={close}
            width="24"
            height="24"
            alt="/"
            onLoad={(e) => SVGInject(e.target)}
          />
        </span>
        <h1 className="pix-receive-title-mobile">Minhas chaves</h1>
        <h1 className="pix-payment-step1-h1-mobile mg-top-8">
          Gerencie suas chaves para receber <br /> e fazer transferências pelo
          Pix.
        </h1>
        {!userPixKeys.cpf &&
        !userPixKeys.celular &&
        !userPixKeys.email &&
        !userPixKeys.evp ? (
            <div className="empty-pix-keys-mobile">
              <span className="empty-pix-keys-card-mobile">
                <img
                  src={card2}
                  width="258"
                  height="76"
                  alt="/"
                  onLoad={(e) => SVGInject(e.target)}
                />
              </span>
              <span className="empty-pix-keys-card-1-mobile">
                <img
                  src={card1}
                  width="258"
                  height="76"
                  alt="/"
                  onLoad={(e) => SVGInject(e.target)}
                />
              </span>
              <span className="pix-content-txt bold mg-top-48">
              Não há nenhuma chave <br /> resgistrada ainda
              </span>
            </div>
          ) : (
            <>
              {userPixKeys.celular && (
                <div
                  className="pix-panel-config-table-mobile"
                  onClick={() => setShowModalCelular(true)}
                >
                  <span className={`pix-keys-img-mobile`}>
                    <img
                      className="pix-payment-step1-key-img-mobile"
                      src={cell}
                      alt="/"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  </span>
                  <div className="pix-panel-config-table-align-mobile">
                    <span className="pix-content-txt bold">Celular</span>
                    <span className="pix-tabel-txt">{userPixKeys.celular}</span>
                  </div>
                  <img
                    className="pix-content-img"
                    src={arrow_right}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </div>
              )}
              {userPixKeys.cpf && (
                <div
                  className="pix-panel-config-table-mobile"
                  onClick={() => setShowModalCpf(true)}
                >
                  <span className={`pix-keys-img-mobile`}>
                    <img
                      className="pix-payment-step1-key-img-mobile"
                      src={userImg}
                      alt="/"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  </span>
                  <div className="pix-panel-config-table-align-mobile">
                    <span className="pix-content-txt bold">CPF</span>
                    <span className="pix-tabel-txt">{userPixKeys.cpf}</span>
                  </div>
                  <img
                    className="pix-content-img"
                    src={arrow_right}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </div>
              )}
              {userPixKeys.email && (
                <div
                  className="pix-panel-config-table-mobile"
                  onClick={() => setShowModalEmail(true)}
                >
                  <span className={`pix-keys-img-mobile`}>
                    <img
                      className="pix-payment-step1-key-img-mobile"
                      src={mail}
                      alt="/"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  </span>
                  <div className="pix-panel-config-table-align-mobile">
                    <span className="pix-content-txt bold">E-mail</span>
                    <span className="pix-tabel-txt">{userPixKeys.email}</span>
                  </div>
                  <img
                    className="pix-content-img"
                    src={arrow_right}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </div>
              )}
              {userPixKeys.evp && (
                <div
                  className="pix-panel-config-table-mobile"
                  onClick={() => setShowModalEvp(true)}
                >
                  <span className={`pix-keys-img-mobile`}>
                    <img
                      className="pix-payment-step1-key-img-mobile"
                      src={random_key}
                      alt="/"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  </span>
                  <div className="pix-panel-config-table-align-mobile">
                    <span className="pix-content-txt bold">Chave aleatória</span>
                    <span className="pix-tabel-txt">{userPixKeys.evp}</span>
                  </div>
                  <img
                    className="pix-content-img"
                    src={arrow_right}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </div>
              )}
            </>
          )}
        {(!userPixKeys.celular ||
          !userPixKeys.cpf ||
          !userPixKeys.email ||
          !userPixKeys.evp) && (
          <PrimaryButton
            width={`312px`}
            height={`56px`}
            margin_top={`40px`}
            onClick={() => changePixEnvironment(7)}
          >
            Registrar chave
          </PrimaryButton>
        )}
      </div>
      <CustomModal
        isOpen={showModalCelular}
        onClose={() => setShowModalCelular(false)}
        modalBottom={true}
        containerBg={`#ffffff`}
      >
        <div className="pix-keys-modal-mobile">
          <span className={`pix-keys-img-mobile width-height-64`}>
            <img
              width={`32px`}
              height={`32px`}
              src={cell}
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </span>
          <span className="pix-content-txt bold font-18">Celular</span>
          <span className="pix-payment-step1-h1-mobile mg-top-8">
            {userPixKeys.celular}
          </span>
          <div className="pix-modal-bin-copy-mobile">
            <span
              className={`pix-keys-img-mobile width-height-64`}
              onClick={() => copyPixKey(userPixKeys.celular!)}
            >
              <img
                width={`32px`}
                height={`32px`}
                src={copy}
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
            <span
              className={`pix-keys-img-mobile width-height-64`}
              onClick={() => {
                setPixKeyType(PixKeyType.CELULAR)
                setOpenModalDelete(true)
              }}
            >
              <img
                width={`32px`}
                height={`32px`}
                src={bin}
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        isOpen={showModalCpf}
        onClose={() => setShowModalCpf(false)}
        modalBottom={true}
        containerBg={`#ffffff`}
      >
        <div className="pix-keys-modal-mobile">
          <span className={`pix-keys-img-mobile width-height-64`}>
            <img
              width={`32px`}
              height={`32px`}
              src={userImg}
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </span>
          <span className="pix-content-txt bold font-18">CPF</span>
          <span className="pix-payment-step1-h1-mobile mg-top-8">
            {userPixKeys.cpf}
          </span>
          <div className="pix-modal-bin-copy-mobile">
            <span
              className={`pix-keys-img-mobile width-height-64`}
              onClick={() => copyPixKey(userPixKeys.cpf!)}
            >
              <img
                width={`32px`}
                height={`32px`}
                src={copy}
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
            <span
              className={`pix-keys-img-mobile width-height-64`}
              onClick={() => {
                setPixKeyType(PixKeyType.CPF)
                setOpenModalDelete(true)
              }}
            >
              <img
                width={`32px`}
                height={`32px`}
                src={bin}
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        isOpen={showModalEmail}
        onClose={() => setShowModalEmail(false)}
        modalBottom={true}
        containerBg={`#ffffff`}
      >
        <div className="pix-keys-modal-mobile">
          <span className={`pix-keys-img-mobile width-height-64`}>
            <img
              width={`32px`}
              height={`32px`}
              src={cell}
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </span>
          <span className="pix-content-txt bold font-18">E-mail</span>
          <span className="pix-payment-step1-h1-mobile mg-top-8">
            {userPixKeys.email}
          </span>
          <div className="pix-modal-bin-copy-mobile">
            <span
              className={`pix-keys-img-mobile width-height-64`}
              onClick={() => copyPixKey(userPixKeys.email!)}
            >
              <img
                width={`32px`}
                height={`32px`}
                src={copy}
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
            <span
              className={`pix-keys-img-mobile width-height-64`}
              onClick={() => {
                setPixKeyType(PixKeyType.EMAIL)
                setOpenModalDelete(true)
              }}
            >
              <img
                width={`32px`}
                height={`32px`}
                src={bin}
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        isOpen={showModalEvp}
        onClose={() => setShowModalEvp(false)}
        modalBottom={true}
        containerBg={`#ffffff`}
      >
        <div className="pix-keys-modal-mobile">
          <span className={`pix-keys-img-mobile width-height-64`}>
            <img
              width={`32px`}
              height={`32px`}
              src={cell}
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </span>
          <span className="pix-content-txt bold font-18">Chave aleatória</span>
          <span className="pix-payment-step1-h1-mobile mg-top-8">
            {userPixKeys.evp}
          </span>

          <div className="pix-modal-bin-copy-mobile">
            <span
              className={`pix-keys-img-mobile width-height-64`}
              onClick={() => copyPixKey(userPixKeys.evp!)}
            >
              <img
                width={`32px`}
                height={`32px`}
                src={copy}
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
            <span
              className={`pix-keys-img-mobile width-height-64`}
              onClick={() => {
                setPixKeyType(PixKeyType.EVP)
                setOpenModalDelete(true)
              }}
            >
              <img
                width={`32px`}
                height={`32px`}
                src={bin}
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}
export default PixKeysMobile
