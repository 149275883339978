import dayjs from "dayjs"
import { createContext, ReactElement, ReactNode, useState } from "react"
import OnboardingModal from "../components/features/OnboardingModal"
import { onboardingNavigation } from "../components/pages/Onboarding"
import services from "../services/svcmesh"
import { unformatCpf } from "../utils/validation"

export const TOTAL_STEPS = 7

type Step = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

export type Address = {
  logradouro?: string
  cidade?: string
  bairro?: string
  uf?: string
  cep?: string
  numero?: string
  complemento?: string
}

type ModalAlert = {
  show: boolean
  message?: string
  buttons?: ModalButton[]
}

type ModalButton = {
  text: string
  onClick: () => void
  type?: `primary` | `link`
}

type OnboardingContextType = {
  loading: boolean
  step: Step
  subStep: Step
  id: number
  name: string
  alias: string
  email: string
  isPublicPerson: boolean | undefined
  cpf: string
  birthday: string
  photo: string
  phone: string
  address: Address
  saveName: (name: string) => void
  saveAlias: (alias: string) => void
  saveEmail: (email: string) => void
  saveIsPublic: (isPublic: boolean) => void
  saveCpf: (cpf: string) => Promise<void>
  goBack: () => void
  next: () => void
  startSteps: () => void
  startPhoto: () => void
  savePhase1: (liveness: any) => Promise<void>
  saveBirthday: (birth: string) => void
  savePhase2: (phone: string) => Promise<void>
  verifyPhone: (code: string) => Promise<void>
  startIdentity: () => void
  continueIdentity: (initialPhoto?: string) => void
  saveIdentity: () => void
  startAddress: () => void
  getAddressFromPic: (photoUrl: string) => Promise<void>
  confirmAddress: (address: Address) => Promise<void>
  completeOnboarding: () => void
}

const contextDefaultValues: OnboardingContextType = {
  loading: false,
  step: 0,
  subStep: 0,
  id: 0,
  name: ``,
  alias: ``,
  email: ``,
  isPublicPerson: undefined,
  cpf: ``,
  birthday: ``,
  phone: ``,
  photo: ``,
  address: {},
  next: () => {},
  goBack: () => {},
  startSteps: () => {},
  saveName: () => {},
  saveAlias: () => {},
  saveEmail: () => {},
  saveIsPublic: () => {},
  saveCpf: async () => {},
  startPhoto: () => {},
  savePhase1: async () => {},
  saveBirthday: () => {},
  savePhase2: async () => {},
  verifyPhone: async () => {},
  startIdentity: () => {},
  continueIdentity: () => {},
  saveIdentity: () => {},
  startAddress: () => {},
  getAddressFromPic: async () => {},
  confirmAddress: async () => {},
  completeOnboarding: () => {},
}

export const OnboardingContext =
  createContext<OnboardingContextType>(contextDefaultValues)

const OnboardingProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const [step, setStep] = useState<Step>(contextDefaultValues.step)
  const [subStep, setSubStep] = useState<Step>(contextDefaultValues.subStep)
  const [loading, setLoading] = useState(false)
  const [modalAlert, setModalAlert] = useState<ModalAlert>({ show: false })

  const [id, setId] = useState<number>(0)
  const [name, setName] = useState(``)
  const [alias, setAlias] = useState(``)
  const [isPublicPerson, setIsPublicPerson] = useState<boolean>()
  const [cpf, setCpf] = useState(``)
  const [birthday, setBirthday] = useState(``)
  const [phone, setPhone] = useState(``)
  const [photo, setPhoto] = useState(``)
  const [address, setAddress] = useState<Address>({})
  const [email, setEmail] = useState(``)

  const startSteps = () => {
    if (!cpf) clear()
    next()
  }

  const saveName = (name: string) => {
    setName(name)
    next()
  }

  const saveAlias = (alias: string) => {
    setAlias(alias)
    next()
  }

  const saveEmail = async (email: string) => {
    setLoading(true)
    try {
      await services.bankingService.checkIfEmailIsAvailable(email)
      setEmail(email)
      next()
    } catch (error: any) {
      const message =
        error.response?.data?.message ||
        `Ocorreu um erro inesperado. Tente novamente mais tarde`
      showAlertModal(message, {
        show: true,
        buttons: [
          {
            text: `Ir para o login`,
            onClick: () => (window.location.href = `/`),
          },
        ],
      })
    }
    setLoading(false)
  }

  const saveIsPublic = (isPublic: boolean) => {
    setIsPublicPerson(isPublic)
    next()
  }

  const saveCpf = async (cpf: string) => {
    setLoading(true)
    try {
      const res = await services.bankingService.checkIfExistByCpf(cpf)
      if (res.data && res.data.exist) {
        if (res.data.partial && res.data.user && res.data.user.id) {
          const user = res.data.user
          setId(user.id)
          setCpf(user.cpf)
          setName(user.nome)
          setAlias(user.apelido || ``)
          setEmail(user.emails[0]?.email)
          setBirthday(dayjs(user.dtNasc).format(`DD/MM/YYYY`))
          setPhone(user.phones[0]?.phone || ``)
          if (step) next()
        } else {
          showAlertModal(res.data.message || `Este CPF já está sendo usado`, {
            show: true,
            buttons: [
              {
                text: `Ir para o login`,
                onClick: () => (window.location.href = `/`),
              },
            ],
          })
        }
      } else {
        // Success
        setCpf(cpf)
        if (step) next()
      }
    } catch (error: any) {
      showAlertModal(error.message)
    }
    setLoading(false)
  }

  const startPhoto = () => {
    next()
  }

  const savePhase1 = async (livenessData: any) => {
    try {
      // if (livenessData) {
      const data = {
        apelido: alias,
        nome: name,
        cpf: cpf.replaceAll(`.`, ``).replace(`-`, ``),
        pessoaPublica: isPublicPerson || false,
        aprovado: livenessData === `erro` ? 0 : null,
      }
      const res = await services.bankingService.saveOnboarding1(data)
      if (res.data && res.data.id) {
        setId(res.data.id)
        next()
      }
      // }
    } catch (error: any) {
      showAlertModal(error.message)
    }
  }

  const saveBirthday = (bd: string) => {
    setBirthday(bd)
    next()
  }

  const savePhase2 = async (phone: string) => {
    setLoading(true)
    try {
      if (phone && id) {
        setPhone(phone)
        const data = {
          id: id,
          phones: [{ phone: phone, phoneType: 0 }],
          emails: [{ email: email, emailType: 0 }],
          dtNasc: dayjs(birthday, `DD-MM-YYYY`).toISOString(),
        }
        if (window.Cypress) {
        } else {
          await services.bankingService.saveOnboarding2(data)
        }
        setSubStep(2)
      } else {
        throw new Error(`Desculpe, houve um erro durante o seu cadastro`)
      }
    } catch (error: any) {
      showAlertModal(error.message)
    }
    setLoading(false)
  }

  const verifyPhone = async (code: string) => {
    setLoading(true)
    try {
      if (phone && id) {
        if (window.Cypress) {
        } else {
          await services.bankingService.validatePhone(id, phone, code)
        }
        next()
      } else {
        throw new Error(`Desculpe, houve um erro durante o seu cadastro`)
      }
    } catch (error: any) {
      showAlertModal(error.response?.data?.message)
    }
    setLoading(false)
  }

  const startIdentity = () => {
    next()
  }

  const continueIdentity = (initialPhoto = ``) => {
    setPhoto(initialPhoto)
    setSubStep(2) // in case there is initial photo
  }

  const saveIdentity = () => {
    setPhoto(``)
    next()
  }

  const startAddress = () => {
    next()
  }

  const getAddressFromPic = async (photoUrl: string) => {
    const res = await services.ocrService.verifyAddress(photoUrl, id)
    console.log(res.data)
    if (res.data?.status === 1) {
      const aux = res.data
      delete aux.status
      setAddress(aux)
      setSubStep(0)
      setStep(7) // Jump to step 7 if in landing page
    } else {
      showAlertModal(`Não foi possível validar os dados da imagem.`)
    }
  }

  const confirmAddress = async (addr: Address) => {
    setLoading(true)
    try {
      if (addr) {
        const res = await services.bankingService.saveOnboardingAddress(
          addr,
          unformatCpf(cpf),
        )
        if (res.data) setAddress(res.data)
        next()
      } else {
        throw new Error(`Desculpe, houve um erro durante o seu cadastro`)
      }
    } catch (error: any) {
      showAlertModal(error.message)
    }
    setLoading(false)
  }

  const completeOnboarding = () => {
    next()
  }

  const next = () => {
    if (step < TOTAL_STEPS + 1) {
      const subSteps = onboardingNavigation[step as Step].length - 1
      if (subStep < subSteps) {
        setSubStep((subStep + 1) as Step)
      } else {
        setStep((step + 1) as Step)
        setSubStep(0)
      }
    } else {
      clear()
      // Exit on boarding
    }
  }

  const goBack = () => {
    if (step) {
      if (subStep > 0) {
        setSubStep((subStep - 1) as Step)
      } else {
        const nextSubStep = onboardingNavigation[(step - 1) as Step].length - 1
        setSubStep(nextSubStep as Step)
        setStep((step - 1) as Step)
      }
    } else {
      clear()
    }
  }

  const clear = () => {
    console.log(`Clear on boarding context`)
    setStep(0)
    setSubStep(0)
    setName(``)
    setAlias(``)
    setCpf(``)
    setIsPublicPerson(undefined)
  }

  const showAlertModal = (
    message = `Ocorreu um erro inesperado. Por favor tente mais tarde.`,
    options: ModalAlert = { show: true },
  ) => {
    setModalAlert({
      show: options.show,
      message: message,
      buttons: options.buttons,
    })
  }

  return (
    <OnboardingContext.Provider
      value={{
        loading,
        step,
        subStep,
        id,
        name,
        alias,
        email,
        isPublicPerson,
        cpf,
        birthday,
        phone,
        photo,
        address,
        goBack,
        next,
        startSteps,
        saveName,
        saveAlias,
        saveEmail,
        saveIsPublic,
        saveCpf,
        startPhoto,
        savePhase1,
        saveBirthday,
        savePhase2,
        verifyPhone,
        startIdentity,
        continueIdentity,
        saveIdentity,
        startAddress,
        getAddressFromPic,
        confirmAddress,
        completeOnboarding,
      }}
    >
      {children}
      <OnboardingModal
        isOpen={modalAlert.show}
        onClose={() => setModalAlert({ show: false })}
      >
        <div className="px-4 pt-1 pb-3">{modalAlert.message}</div>
        <div className="flex-center">
          {modalAlert.buttons?.map((btn, i) => (
            <button
              key={i}
              onClick={btn.onClick}
              className="onboarding-btn-primary"
            >
              {btn.text}
            </button>
          ))}
        </div>
      </OnboardingModal>
    </OnboardingContext.Provider>
  )
}

export default OnboardingProvider
