import { useContext } from "react"
import { OnboardingContext } from "../../../../context/OnboardingContext"
import "./identity.scss"

const IdentityTips = () => {
  const { goBack, continueIdentity } = useContext(OnboardingContext)

  return (
    <div>
      <div className="onboarding-form-container">
        <button className="onboarding-go-back-white" onClick={goBack} />

        <div className="mb-5 mt-5">
          <div>Dicas para uma boa foto do documento</div>
          <div
            className="onboarding-btn-link"
            onClick={() => continueIdentity()}
          >
            Pular
          </div>
        </div>

        <div className="tips-text-title mb-2">
          <span className="text-yellow">1.</span> Sem reflexos!
        </div>
        <div className="text-yellow mb-5">
          Retire a capa de plástico e tire a foto com o documento aberto.
        </div>

        <div className="tips-text-title mb-2">
          <span className="text-yellow">2.</span> Acendeu as luzes?
        </div>
        <div className="text-yellow mb-5">
          O ambiente deve estar bem iluminado.
        </div>

        <div className="tips-text-title mb-2">
          <span className="text-yellow">3.</span> Nitidez é legal.
        </div>
        <div className="text-yellow mb-5">
          Confira a foto que você tirou, veja se todos os dados estão legiveis.
        </div>

        <div className="tips-text-title mb-2">
          <span className="text-yellow">4.</span> Queremos te conhecer!
        </div>
        <div className="text-yellow mb-5">
          Os documentos precisam estar em seu nome.
        </div>

        <div className="flex-end">
          <button
            className="onboarding-btn-next"
            onClick={() => continueIdentity()}
          />
        </div>
      </div>
    </div>
  )
}

export default IdentityTips
