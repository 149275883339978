import { useContext, useState } from "react"
import "./styles.scss"
import SVGInject from "@iconfu/svg-inject"
import { PixStorageContext } from "../../../../../../../context/PixStorageProvider"
import {
  CurrencyInput,
  PrimaryButton,
  CustomModal,
  FailureModal,
  SuccessModal,
} from "k4n-components"
import { Controller } from "react-hook-form"
import { formattedCurrency } from "../../../../../../../services/banking"
import arrow_right from "../../../../../../../assets/images/arrow-right-yellow.svg"
import key from "../../../../../../../assets/images/key.svg"
import arrow from "../../../../../../../assets/images/arrow_yellow.svg"
import { BankingNavigationContext } from "../../../../../../../context/BankingNavigationContext"
import DatePicker from "react-datepicker"
import dayjs from "dayjs"
import { AuthContext } from "../../../../../../../context/AuthProvider"
import { Step3PixPayment } from "../../../../../../../typings/step3-pix-payment.dto"
import { AxiosError } from "axios"
import { BalanceContext } from "../../../../../../../context/BalanceProvider"
import services from "../../../../../../../services/svcmesh"
import {
  ScheduledPixPayment,
  TipoPagamentoPix,
  TransferPixResource,
} from "k4n-svcmesh-sdk"
import DigitalSignature from "../../../../../DigitalSignature/Form"

const PixPaymentStep2Mobile = ({
  validatePaymentCurrency,
  onValueChange,
  control,
  errors,
  paymentValue,
  handleSubmit,
  startDate,
  setStartDate,
  register,
}) => {
  const {
    pixPaymentStep1,
    pixPaymentStep2,
    pixPaymentType,
    pixCopyPasteStep1,
    addPixPaymentStep3,
  } = useContext(PixStorageContext)
  const { changePixPayment, changePixEnvironment } = useContext(
    BankingNavigationContext,
  )
  const { current_account, user } = useContext(AuthContext)
  const { balance, loadingBalance } = useContext(BalanceContext)
  const [step3, setStep3] = useState(
    pixPaymentType === TipoPagamentoPix.PIX_COPYPASTE ? true : false,
  )
  const [showDate, setShowDate] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState(``)
  const [btnLoading, setBtnLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModalError, setShowModalError] = useState(false)
  const [errorPayment, setErrorPayment] = useState(``)
  const [time, setTime] = useState(``)
  const [agendamento, setAgendamento] = useState(false)
  const [digitalSignatureModal, setDigitalSignatureModal] = useState(false)

  const onPixPayment = async () => {
    setBtnLoading(true)
    const dadosEnvioPorChave = {
      ticket: pixPaymentStep1.ticket,
      chave: pixPaymentStep1.chave,
      tipoChave: pixPaymentStep1.tipoChave,
    }
    const contaOrigem = {
      agencia: current_account?.agencia,
      agenciaDigito: current_account?.agenciaDigito,
      conta: current_account?.conta,
      contaDigito: current_account?.contaDigito,
      contaPgto: current_account?.contaPgto,
      tipoConta: current_account?.tipoConta,
    }
    const sendPayment = {
      dadosEnvioPorChave: dadosEnvioPorChave,
      valor: null,
      codigoOperacaoCliente: ``,
      descricaoCliente: message,
      prioridade: 0,
      contaOrigem: contaOrigem,
      informacoesAdicionais: ``,
      idConciliacaoRecebedor: ``,
      tpQRCode: null,
    } as unknown as TransferPixResource
    console.log(`PIX TYPE ->>>`, pixPaymentType)
    if (pixPaymentType === 1) {
      try {
        sendPayment.valor = pixCopyPasteStep1.valor!
        sendPayment.idConciliacaoRecebedor =
          pixCopyPasteStep1.idConciliacaoRecebedor!
        sendPayment.tpQRCode = pixCopyPasteStep1.tpQRCode!
        sendPayment.codigoLeituraQRCode = pixCopyPasteStep1.codigoLeituraQRCode!
        console.log(sendPayment)
        const res = await services.bankingService.tranferPixResource(
          sendPayment,
        )
        console.log(res.data)
        if (res.data.sucesso === true) {
          const dayTime = dayjs().format(`DD/MM/YYYY - HH:mm`)
          setTime(dayTime)
          const saveObj = {
            codigoTransacao: res.data.codigoTransacao,
            data: dayTime,
            valor: sendPayment.valor,
          } as Step3PixPayment
          setShowModal(true)
          addPixPaymentStep3(saveObj)
          setBtnLoading(false)
        }
      } catch (error: any) {
        const err = error as AxiosError<any>
        setErrorPayment(err.response?.data.message)
        setBtnLoading(false)
        setShowModalError(true)
      }
    } else {
      try {
        sendPayment.valor = paymentValue
        console.log(sendPayment)
        const res = await services.bankingService.tranferPixResource(
          sendPayment,
        )
        console.log(res.data)
        if (res.data.sucesso === true) {
          const dayTime = dayjs().format(`DD/MM/YYYY - HH:mm`)
          setTime(dayTime)
          const saveObj = {
            codigoTransacao: res.data.codigoTransacao,
            data: dayTime,
            valor: sendPayment.valor,
          } as Step3PixPayment
          // saveFavoritePix()
          setShowModal(true)
          addPixPaymentStep3(saveObj)
          setBtnLoading(false)
        }
      } catch (error: any) {
        const err = error as AxiosError<any>
        setErrorPayment(err.response?.data.message)
        setBtnLoading(false)
        setShowModalError(true)
      }
    }
  }

  const scheduledPixPayment = async () => {
    setBtnLoading(true)
    const sendScheduledPix = {
      customer_pf: { id: user?.id },
      chave: pixPaymentStep1.chave,
      tipo_chave: pixPaymentStep1.tipoChave,
      valor: paymentValue,
      prioridade: 1,
      conta_origem_agencia: current_account?.agencia,
      conta_origem_agencia_digito: current_account?.agenciaDigito || ``,
      conta_origem_conta: current_account?.conta,
      conta_origem_conta_digito: current_account?.contaDigito,
      conta_origem_conta_pgto:
        current_account?.conta! + current_account?.contaDigito!,
      conta_origem_tipo_conta: current_account?.tipoConta,
      codigo_operacao_cliente: ``,
      descricao_cliente: message,
      informacoes_adicionais: ``,
      id_conciliacao_recebedor: ``,
      tp_qr_code: 1,
      scheduled_date: dayjs(startDate).format(`YYYY-MM-DD`),
    } as unknown as ScheduledPixPayment
    console.log(sendScheduledPix)
    try {
      const res = await services.bankingService.scheduledPix(sendScheduledPix)
      if (res.data.sucesso === true) {
        const saveObj = {
          data: dayjs(startDate).format(`YYYY-MM-DD`),
          valor: paymentValue,
          agendamento: agendamento,
        } as Step3PixPayment
        saveFavoritePix()
        addPixPaymentStep3(saveObj)
        setShowModal(true)
        setBtnLoading(false)
      }
    } catch (error: any) {
      const err = error as AxiosError<any>
      setErrorPayment(err.response?.data.message)
      setBtnLoading(false)
      setShowModalError(true)
    }
  }

  const saveFavoritePix = async () => {
    if (pixPaymentStep2.save_favorite === true) {
      try {
        const res = await services.bankingService.createCustomerFavorite({
          clientePf: { id: user?.id },
          nome: pixPaymentStep2.nome,
          cpf: pixPaymentStep2.cpf,
          chave_pix: pixPaymentStep2.chave,
        })
        if (res?.sucesso) {
          console.log(res)
        }
      } catch (error) {
        const err = error as AxiosError<any>
        console.log(err.response?.data.message)
      }
    }
  }

  const validateValue = () => {
    setStep3(true)
  }

  const returnPixPanel = () => {
    changePixEnvironment(1)
    changePixPayment(1)
  }

  const authorize = () => {
    setDigitalSignatureModal(false)
    if (pixPaymentStep2.agendamento === true) {
      scheduledPixPayment()
    } else {
      onPixPayment()
    }
  }

  return (
    <div className="pix-payment-step1-mobile">
      <CustomModal
        isOpen={digitalSignatureModal}
        onClose={() => {
          setDigitalSignatureModal(false)
        }}
        isBanking={true}
      >
        <div style={{ width: `120px`, margin: `0 auto` }}>
          <img
            src={key}
            alt="/"
            width="120"
            height="120"
            className="key-vector"
            onLoad={(e) => SVGInject(e.target)}
          />
        </div>
        <DigitalSignature handleSuccess={authorize} />
      </CustomModal>
      {!step3 ? (
        <>
          <form onSubmit={handleSubmit(validateValue)}>
            <span onClick={() => changePixPayment(1)}>
              <img
                src={arrow}
                width="27"
                height="15"
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
            <h1 className="pix-payment-step1-h1-mobile">
              Qual o valor que você deseja pagar para {pixPaymentStep1.nome}?
            </h1>
            <div className="pix-value-box pix-payment-step2-input-mobile">
              <span className="pix-value-label">R$</span>
              <Controller
                name="receiveValue"
                control={control}
                defaultValue="0,00"
                rules={{
                  required: true,
                  validate: validatePaymentCurrency,
                  onChange: onValueChange,
                }}
                render={({ field }) => (
                  <CurrencyInput
                    value={field.value}
                    options={{
                      style: `decimal`,
                      allowNegative: false,
                    }}
                    onChangeEvent={(_, maskedValue) => {
                      field.onChange(maskedValue)
                    }}
                    required={true}
                    className="pix-value-input-mobile"
                  />
                )}
              />
            </div>
            {errors.receiveValue && (
              <p className="pix-error-msg ">Por favor, verifique o valor.</p>
            )}
            <h1 className="pix-payment-step1-h1-mobile align-center-mobile">
              limite de R$ 1.000,00
            </h1>
            <PrimaryButton width={`312px`} height={`56px`} margin_top={`72px`}>
              Próximo
            </PrimaryButton>
          </form>
        </>
      ) : (
        <>
          {pixPaymentType === TipoPagamentoPix.PIX_COPYPASTE ? (
            <span onClick={() => changePixEnvironment(4)}>
              <img
                src={arrow}
                width="27"
                height="15"
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
          ) : (
            <span onClick={() => setStep3(false)}>
              <img
                src={arrow}
                width="27"
                height="15"
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
          )}
          <h1 className="pix-payment-step3-value-mobile">
            {formattedCurrency(
              pixPaymentType === TipoPagamentoPix.PIX_COPYPASTE
                ? pixCopyPasteStep1.valor
                : paymentValue,
            )}
          </h1>
          <h1 className="pix-payment-step3-h1-mobile margin-top-10">
            Para: {pixPaymentStep1.nome}
          </h1>
          <h1 className="pix-payment-step3-txt-mobile">
            {pixPaymentStep1.numeroBanco} - Chave PIX {pixPaymentStep1.chave}
          </h1>
          <div className="pix-payment-table-mobile">
            <h1 className="pix-payment-step3-h1-mobile">Pagar com</h1>
            {loadingBalance ? (
              <div className="emptySpace-pix gradient mg-top-16"></div>
            ) : (
              <h1 className="pix-payment-step3-txt-mobile">
                Saldo:{` `}
                <span className="bold">{formattedCurrency(balance)}</span>
              </h1>
            )}
          </div>
          <div
            className="pix-payment-date-mobile"
            onClick={() => setShowDate(true)}
          >
            <div>
              <h1 className="pix-payment-step3-h1-mobile">Data do Pix</h1>
              <h1 className="pix-payment-step3-txt-mobile">
                {!startDate
                  ? `Hoje`
                  : `Agendado para ` + dayjs(startDate).format(`DD/MM/YYYY`)}
              </h1>
            </div>
            <img
              className="pix-payment-img"
              src={arrow_right}
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </div>
          <div
            className="pix-payment-date-mobile"
            onClick={() => setShowMessage(true)}
          >
            <div className="pix-payment-step3-message-mobile">
              <h1 className="pix-payment-step3-h1-mobile">
                Enviar uma mensagem
              </h1>
              <h1
                className={
                  `pix-payment-step3-txt-mobile ` +
                  (message && `nowrap-ellipsis`)
                }
              >
                {message ? message : `Use este espaço para mandar um recado`}
              </h1>
            </div>
            <img
              className="pix-payment-img"
              src={arrow_right}
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </div>
          <PrimaryButton
            width={`312px`}
            height={`56px`}
            margin_top={`50px`}
            onClick={() => {
              setDigitalSignatureModal(true)
            }}
          >
            {!btnLoading ? (
              `Confirmar`
            ) : (
              <div className="spinner-border spinner-pix" role="status"></div>
            )}
          </PrimaryButton>
        </>
      )}
      <CustomModal
        isOpen={showDate}
        onClose={() => setShowDate(false)}
        containerBg={`#FFFFFF`}
      >
        <div className="pix-payment-modal-mobile">
          <h1 className="pix-payment-step3-h1-mobile size-24">
            Quando você quer
            <br /> realizar o Pix?
          </h1>
          <DatePicker
            className="payment-date-picker-mobile"
            selected={startDate}
            onChange={(date) => {
              setStartDate(date)
            }}
            locale="pt-BR"
            dateFormat="dd/MM/yyyy"
            required={false}
            minDate={dayjs().add(1, `days`).toDate()}
            inline
          />
          <PrimaryButton
            width={`312px`}
            height={`56px`}
            margin_top={`50px`}
            onClick={() => {
              setAgendamento(true)
              setShowDate(false)
            }}
          >
            Confirmar
          </PrimaryButton>
        </div>
      </CustomModal>
      <CustomModal
        isOpen={showMessage}
        onClose={() => setShowMessage(false)}
        containerBg={`#FFFFFF`}
      >
        <div className="pix-payment-modal-mobile">
          <h1 className="pix-payment-step3-h1-mobile size-24">
            Escreva sua mensagem
          </h1>
          <textarea
            id="pix-payment-message"
            type="text"
            maxLength={140}
            className="pix-textarea-message-modal-mobile"
            placeholder="Digite sua mensagem aqui..."
            {...register(`message`, {
              required: false,
              onBlur: (e) => {
                setMessage(e.currentTarget.value)
              },
            })}
          />
          <PrimaryButton
            width={`312px`}
            height={`56px`}
            margin_top={`50px`}
            onClick={() => setShowMessage(false)}
          >
            Confirmar
          </PrimaryButton>
        </div>
      </CustomModal>
      <FailureModal
        isOpen={showModalError}
        width={`100vw`}
        height={`100%`}
        paddingTop={`80px`}
        onClose={() => setShowModalError(false)}
      >
        <h1 className="pix-modal-sucess-h1">Ocorreu um Erro</h1>
        <h1 className="pix-modal-sucess-3p">• • •</h1>
        <h1 className="pix-modal-sucess-txt">{errorPayment}</h1>
        <div className="pix-align-center">
          <PrimaryButton
            width={`312px`}
            height={`60px`}
            onClick={() => setShowModalError(false)}
          >
            OK!
          </PrimaryButton>
        </div>
      </FailureModal>
      <SuccessModal
        isOpen={showModal}
        width={`100vw`}
        height={`100%`}
        paddingTop={`80px`}
        onClose={() => returnPixPanel()}
      >
        {agendamento ? (
          <>
            <h1 className="pix-modal-sucess-h1">
              Pix agendado com <br /> sucesso!
            </h1>
            <h1 className="pix-modal-sucess-3p">• • •</h1>
            <h1 className="pix-modal-sucess-txt">
              Tudo certo! Agendamos o pagamento de
              <br />
              <b>{formattedCurrency(paymentValue)}</b>
              {` `}
              para <b>{pixPaymentStep1.nome}</b>.
            </h1>
            <h1 className="pix-modal-sucess-txt">{time}</h1>
            <div className="pix-align-center">
              <PrimaryButton
                width={`312px`}
                height={`60px`}
                onClick={() => changePixPayment(4)}
              >
                Ver comprovante
              </PrimaryButton>
            </div>
          </>
        ) : (
          <>
            <h1 className="pix-modal-sucess-h1">
              Pix realizado com <br /> sucesso!
            </h1>
            <h1 className="pix-modal-sucess-3p">• • •</h1>
            <h1 className="pix-modal-sucess-txt">
              Tudo certo! Enviamos
              {` `}
              <b>
                {formattedCurrency(
                  pixPaymentType === TipoPagamentoPix.PIX_COPYPASTE
                    ? pixCopyPasteStep1.valor
                    : paymentValue,
                )}
              </b>
              <br />
              para <b>{pixPaymentStep1.nome}</b> agora mesmo.
            </h1>
            <h1 className="pix-modal-sucess-txt">
              {` `}
              {time}
            </h1>
            <div className="pix-align-center">
              <PrimaryButton
                width={`312px`}
                height={`60px`}
                onClick={() => changePixPayment(4)}
              >
                Ver comprovante
              </PrimaryButton>
            </div>
          </>
        )}
      </SuccessModal>
    </div>
  )
}
export default PixPaymentStep2Mobile
