import { useContext } from "react"
import { OnboardingContext } from "../../../context/OnboardingContext"
import defaultLogo from "../../../assets/images/logo_kikkin_white.svg"
import { FieldValues, useForm } from "react-hook-form"
import { ThemeContext } from "../../../context/ThemeContext"

const Email = () => {
  const { goBack, saveEmail, email, loading } = useContext(OnboardingContext)
  const { logo } = useContext(ThemeContext)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: email,
    },
  })

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email,
    )

  const handleEmailValidation = (email: string) => {
    console.log(`ValidateEmail was called with`, email)
    const isValid = isValidEmail(email)

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid)
    if (validityChanged) {
      console.log(`Fire tracker with`, isValid ? `Valid` : `Invalid`)
    }
    if (!isValid) return `Email inválido`
    return true
  }

  const handleSaveEmail = async (data: FieldValues) => {
    saveEmail(data.email)
  }

  return (
    <form
      className="onboarding-form-container"
      onSubmit={handleSubmit(handleSaveEmail)}
    >
      <button className="onboarding-go-back" onClick={goBack} type="button" />

      <img
        style={{ marginTop: `120px` }}
        src={logo || defaultLogo}
        width="80px"
        height="27px"
      />
      <div className="onboarding-question-primary">Qual seu email?</div>

      <input
        id="email-input"
        {...register(`email`, {
          required: true,
          validate: handleEmailValidation,
        })}
        className={
          errors?.email ? `onboarding-input input-invalid` : `onboarding-input`
        }
        inputMode="email"
        onInput={(event) => {
          event.currentTarget.value = event.currentTarget.value.toLowerCase()
        }}
        autoCapitalize="off"
        placeholder="Digite seu email"
      />

      {errors.email ? (
        errors.email.message ? (
          <div className="input-error">{errors.email.message}</div>
        ) : (
          <div className="input-error">O campo email é obrigatório </div>
        )
      ) : (
        <></>
      )}

      <div className="flex-end">
        <button
          className={`onboarding-btn-next` + (loading ? ` loading` : ``)}
          disabled={loading}
          type="submit"
        />
      </div>
    </form>
  )
}

export default Email
