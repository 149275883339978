import dayjs from "dayjs"
import { useContext } from "react"
import { BankingNavigationContext } from "../../../../../../../context/BankingNavigationContext"
import { BoletoContext } from "../../../../../../../context/BoletoContext"
import { formattedCurrency } from "../../../../../../../services/banking"
import { PrimaryButton } from "k4n-components"
import "./styles.scss"

const BoletoCreationStep3Mobile = ({ createBoleto, loading, handleCancel }) => {
  const { boletoCreationStep1, boletoCreationStep2 } = useContext(BoletoContext)

  return (
    <>
      <div className="bc-step-mobile-content">
        <div className="bc-step-mobile">
          <button
            className="payment-mobile-close-btn"
            onClick={handleCancel}
          ></button>
          <div className="payment-mobile-title">
            <label className="payment-mobile-title-h1">
              Confira os dados do Boleto
            </label>
          </div>
          <div className="payment-mobile-info">
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Destinatário:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep1 && boletoCreationStep1.recipient && (
                  <span className="payment-mobile-span-content-grey">
                    {boletoCreationStep1.recipient}
                  </span>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>E-mail:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep1 && boletoCreationStep1.email && (
                  <span className="payment-mobile-span-content-grey">
                    {boletoCreationStep1.email}
                  </span>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>CPF/CNPJ:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep1 && boletoCreationStep1.cpfCnpj && (
                  <span className="payment-mobile-span-content-grey">
                    {boletoCreationStep1.cpfCnpj}
                  </span>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Telefone:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep1 && boletoCreationStep1.phone && (
                  <span className="payment-mobile-span-content-grey">
                    {boletoCreationStep1.phone}
                  </span>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>CEP:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep1 && boletoCreationStep1.cep && (
                  <span className="payment-mobile-span-content-grey">
                    {boletoCreationStep1.cep}
                  </span>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Endereço:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep1 && boletoCreationStep1.logradouro && (
                  <span className="payment-mobile-span-content-grey">
                    {boletoCreationStep1.logradouro}
                  </span>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Complemento:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep1 && boletoCreationStep1.complemento && (
                  <span className="payment-mobile-span-content-grey">
                    {boletoCreationStep1.complemento}
                  </span>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Cidade:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep1 && boletoCreationStep1.cidade && (
                  <span className="payment-mobile-span-content-grey">
                    {boletoCreationStep1.cidade}
                  </span>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Número:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep1 && boletoCreationStep1.numero && (
                  <span className="payment-mobile-span-content-grey">
                    {boletoCreationStep1.numero}
                  </span>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Bairro:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep1 && boletoCreationStep1.bairro && (
                  <span className="payment-mobile-span-content-grey">
                    {boletoCreationStep1.bairro}
                  </span>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Estado:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep1 && boletoCreationStep1.uf && (
                  <span className="payment-mobile-span-content-grey">
                    {boletoCreationStep1.uf}
                  </span>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Data e hora da cobrança:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {dayjs().format(`DD/MM/YYYY`)}, às
                {` ` + dayjs().format(`HH:mm`)}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Data de vencimento:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep2 && boletoCreationStep2.dueDate && (
                  <span className="payment-mobile-span-content-grey">
                    {dayjs(boletoCreationStep2.dueDate).format(`DD/MM/YYYY`)}
                  </span>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Data limite:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep2 && boletoCreationStep2.deadline && (
                  <span className="payment-mobile-span-content-grey">
                    {dayjs(boletoCreationStep2.deadline).format(`DD/MM/YYYY`)}
                  </span>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Valor original:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep2 ? (
                  boletoCreationStep2.nominalValue && (
                    <span className="payment-mobile-span-content-grey">
                      {formattedCurrency(boletoCreationStep2.nominalValue)}
                    </span>
                  )
                ) : (
                  <></>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Abatimento:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep2 && boletoCreationStep2.reduction && (
                  <>
                    <span className="payment-mobile-span-content-grey">
                      {formattedCurrency(boletoCreationStep2.reduction)}
                    </span>
                  </>
                )}
              </span>
            </div>
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Juros:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep2.fee?.code === `1` && (
                  <span className="payment-mobile-span-content-grey">
                    Valor (dias corridos)
                  </span>
                )}
                {boletoCreationStep2.fee?.code === `3` && (
                  <span className="payment-mobile-span-content-grey">
                    Percentual ao mês (dias corridos)
                  </span>
                )}
                {boletoCreationStep2.fee?.code === `6` && (
                  <span className="payment-mobile-span-content-grey">
                    Valor (dias úteis)
                  </span>
                )}
                {boletoCreationStep2.fee?.code === `8` && (
                  <span className="payment-mobile-span-content-grey">
                    Percentual ao mês (dias úteis)
                  </span>
                )}
                {boletoCreationStep2.fee?.code === `5` && (
                  <span className="payment-mobile-span-content-grey">
                    Isento
                  </span>
                )}
              </span>
            </div>
            {boletoCreationStep2.fee?.code !== `5` && (
              <>
                <div className="payment-mobile-row">
                  <span className="payment-mobile-span-title-black">Valor</span>
                  {(boletoCreationStep2.fee?.code === `1` ||
                    boletoCreationStep2.fee?.code === `6`) && (
                    <span className="payment-mobile-span-content-grey">
                      {formattedCurrency(boletoCreationStep2.fee?.value)}
                    </span>
                  )}
                  {(boletoCreationStep2.fee?.code === `3` ||
                    boletoCreationStep2.fee?.code === `8`) && (
                    <span className="payment-mobile-span-content-grey">
                      {boletoCreationStep2.fee?.value}%
                    </span>
                  )}
                </div>

                <div className="payment-mobile-row">
                  <span className="payment-mobile-span-title-black">
                    Data inicial:
                  </span>
                  <span className="payment-mobile-span-content-grey">
                    {dayjs(boletoCreationStep2.fee?.date).format(`DD/MM/YYYY`)}
                  </span>
                </div>
              </>
            )}
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Multa:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep2.fine?.code === `1` && (
                  <span className="payment-mobile-span-content-grey">
                    Valor fixo
                  </span>
                )}
                {boletoCreationStep2.fine?.code === `2` && (
                  <span className="payment-mobile-span-content-grey">
                    Percentual
                  </span>
                )}
                {boletoCreationStep2.fine?.code === `3` && (
                  <span className="payment-mobile-span-content-grey">
                    Isento
                  </span>
                )}
              </span>
            </div>
            {boletoCreationStep2.fine?.code !== `3` && (
              <>
                <div className="payment-mobile-row">
                  <span className="payment-mobile-span-title-black">Valor</span>
                  {boletoCreationStep2.fine?.code === `1` && (
                    <span className="payment-mobile-span-content-grey">
                      {formattedCurrency(boletoCreationStep2.fine?.value)}
                    </span>
                  )}

                  {boletoCreationStep2.fine?.code === `2` && (
                    <span className="payment-mobile-span-content-grey">
                      {boletoCreationStep2.fine?.value}%
                    </span>
                  )}
                </div>

                <div className="payment-mobile-row">
                  <span className="payment-mobile-span-title-black">
                    Data inicial:
                  </span>
                  <span className="payment-mobile-span-content-grey">
                    {dayjs(boletoCreationStep2.fine?.date).format(`DD/MM/YYYY`)}
                  </span>
                </div>
              </>
            )}
            <div className="payment-mobile-row">
              <span className="payment-mobile-span-title-black">
                <b>Desconto:</b>
              </span>
              <span className="payment-mobile-span-content-grey">
                {boletoCreationStep2.discount?.code === `0` && (
                  <span className="payment-mobile-span-content-grey">
                    Isento
                  </span>
                )}
                {boletoCreationStep2.discount?.code === `1` && (
                  <span className="payment-mobile-span-content-grey">
                    Valor fixo até a data informada
                  </span>
                )}
                {boletoCreationStep2.discount?.code === `2` && (
                  <span className="payment-mobile-span-content-grey">
                    Percentual até a data informada
                  </span>
                )}
                {boletoCreationStep2.discount?.code === `3` && (
                  <span className="payment-mobile-span-content-grey">
                    Valor por antecipação dia corrido
                  </span>
                )}
                {boletoCreationStep2.discount?.code === `5` && (
                  <span className="payment-mobile-span-content-grey">
                    Percentual por antecipação dia corrido
                  </span>
                )}
              </span>
            </div>
            {boletoCreationStep2.discount?.code !== `0` && (
              <>
                <div className="payment-mobile-row">
                  <span className="payment-mobile-span-title-black">Valor</span>
                  {(boletoCreationStep2.discount?.code === `1` ||
                    boletoCreationStep2.discount?.code === `3`) && (
                    <span className="payment-mobile-span-content-grey">
                      {formattedCurrency(boletoCreationStep2.discount?.value)}
                    </span>
                  )}

                  {(boletoCreationStep2.discount?.code === `2` ||
                    boletoCreationStep2.discount?.code === `5`) && (
                    <span className="payment-mobile-span-content-grey">
                      {boletoCreationStep2.discount?.value}%
                    </span>
                  )}
                </div>
                <div className="payment-mobile-row">
                  <span className="payment-mobile-span-title-black">
                    Data final
                  </span>
                  <span className="payment-mobile-span-content-grey">
                    {dayjs(boletoCreationStep2.discount?.date).format(
                      `DD/MM/YYYY`,
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="boleto-btn-div">
            <PrimaryButton
              width={`50%`}
              height={`50px`}
              onClick={createBoleto}
              disabled={loading}
            >
              {!loading ? (
                `Emitir`
              ) : (
                <div className="spinner-border spinner-pix" role="status"></div>
              )}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default BoletoCreationStep3Mobile
