import SVGInject from "@iconfu/svg-inject"
import Mail from "../../../../../assets/images/mail.svg"
import Phone from "../../../../../assets/images/phone.svg"
import "./styles.scss"

export const ServiceChannels = () => {
  return (
    <>
      <div className={`Content-channels-logged container`}>
        <div className={`H1-Channels-title-div`}>
          <h1 className={`H1-Channels`}>Nossos canais de atendimento</h1>
        </div>
        <div className={`Channels-kikkin`}>
          <div className={`Channel-div`}>
            <div className="Channel-div-title-container">
              <span className="Channel-div-title">Nossos telefones</span>
            </div>
            <div className="Channel-icon-div">
              <div className="Channel-img-div">
                <img
                  src={Phone}
                  width="56"
                  height="56"
                  onLoad={(e) => SVGInject(e.target)}
                />
              </div>
              <span className="Channel-icon-txt">(11) 3090-7090</span>
            </div>
            <div className="Channel-info">
              <span className="Channel-info-txt">
                Atendimento de segunda a sábado, dás 9:00 ás 16:00
              </span>
            </div>
          </div>

          <div className={`Channel-div Mg-left-70`}>
            <div className="Channel-div-title-container">
              <span className="Channel-div-title">E-mail</span>
            </div>
            <div className="Channel-icon-div">
              <div className="Channel-img-div">
                <img
                  src={Mail}
                  width="57"
                  height="35"
                  onLoad={(e) => SVGInject(e.target)}
                />
              </div>
              <span className="Channel-icon-txt">contato@kikkin.com.br</span>
            </div>
            <div className="Channel-info">
              <span className="Channel-info-txt">
                Atendimento de segunda a sábado, dás 9:00 ás 16:00
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
