import { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { BalanceContext } from "../../../context/BalanceProvider"
import { BankingNavigationContext } from "../../../context/BankingNavigationContext"
import { useResposive } from "../../../hooks/useResponsive"
import Navbar from "../../features/Navbar"
import NavbarMobile from "../../features/Navbar/Mobile"
import Boleto from "./boleto"
import BoletoManagerMobile from "./boleto/mobile/manage"
import Cards from "./cards"
import Extrato from "./extrato"
import HomeBanking from "./Home"
import Payment from "./Payment"
import Pix from "./Pix"
import InsuranceArea from "./seguro"
import "./styles.scss"
import Transfer from "./Transfer"
import Withdraw from "./Withdraw"

type BankingProps = {
  component: string
}

const Banking = (props: BankingProps) => {
  const { isTabletOrMobile } = useResposive()
  const { refreshBalance } = useContext(BalanceContext)
  const location = useLocation()
  useEffect(() => {
    refreshBalance()
  }, [])

  return (
    <div className={isTabletOrMobile === true ? `` : `banking-wrapper`}>
      <div>
        {!isTabletOrMobile ? (
          <Navbar isBanking={true} />
        ) : (
          <NavbarMobile
            isBanking={true}
            hide={location.pathname !== `/banking`}
          />
        )}
      </div>
      {props.component === `home` && <HomeBanking />}
      {props.component === `pix` && <Pix />}
      {props.component === `transferir` && <Transfer />}
      {props.component === `extrato` && <Extrato />}
      {props.component === `boleto` && <Boleto />}
      {props.component === `pagar` && <Payment />}
      {props.component === `seguro` && <InsuranceArea />}
      {props.component === `boletoManagerMobile` && <BoletoManagerMobile />}
      {props.component === `cartao` && <Cards />}
      {props.component === `saque` && <Withdraw />}
    </div>
  )
}
export default Banking
