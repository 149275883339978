import { useContext, useEffect } from "react"
import { BankingNavigationContext } from "../../../../context/BankingNavigationContext"
import { useResposive } from "../../../../hooks/useResponsive"
import NewContactTransfer from "./NewContactTransfer"
import "./styles.scss"
import TransferReceipt from "./TransferReceipt"
import TransferStep1 from "./TransferStep1"
import TransferStep2 from "./TransferStep2"
import TransferStep3 from "./TransferStep3"
import TransferStep4 from "./TransferStep4"

const Transfer = () => {
  const { isTabletOrMobile } = useResposive()
  const {
    changeTransferStep,
    transferStep,
    newContactTransferStep,
    changeNewContactTransferStep,
  } = useContext(BankingNavigationContext)

  useEffect(() => {
    changeTransferStep(1)
    changeNewContactTransferStep(0)
  }, [])

  return (
    <>
      <div className={!isTabletOrMobile ? `transfer-wrapper` : ``}>
        {transferStep === 1 && <TransferStep1 />}
        {transferStep === 2 && <TransferStep2 />}
        {transferStep === 3 && <TransferStep3 />}
        {transferStep === 4 && <TransferStep4 />}
        {transferStep === 5 && <TransferReceipt />}
        {newContactTransferStep === 1 && <NewContactTransfer />}
      </div>
    </>
  )
}

export default Transfer
