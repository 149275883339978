import "./styles.scss"
import { useContext, useState } from "react"
import { CustomModal, QrcodeReader } from "k4n-components"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import { Step1PixCodePaste } from "../../../../../typings/step1-pix-copy-paste.dto"
import { AuthContext } from "../../../../../context/AuthProvider"
import { PixStorageContext } from "../../../../../context/PixStorageProvider"
import { Step1PixPayment } from "../../../../../typings/step1-pix-payment.dto"
import { AxiosError } from "axios"
import { useResposive } from "../../../../../hooks/useResponsive"
import close from "../../../../../assets/images/clear_x.svg"
import SVGInject from "@iconfu/svg-inject"
import { ConsultPixKey, ReadQRCode, TipoPagamentoPix } from "k4n-svcmesh-sdk"
import services from "../../../../../services/svcmesh"

const PixQRCode = () => {
  const { changePixEnvironment, changePixPayment } = useContext(
    BankingNavigationContext,
  )
  const { current_account } = useContext(AuthContext)
  const {
    addPixCopyPasteStep1,
    addPixPaymentType,
    addPixPaymentStep1,
    addPixPaymentStep2,
  } = useContext(PixStorageContext)
  const [loading, setLoading] = useState(false)
  const [openScan, setOpenScan] = useState(true)
  const { isTabletOrMobile } = useResposive()

  const getReadQRCode = async (data: any) => {
    let accountLength = 0
    let contaPgt: string | undefined
    if (current_account?.conta && current_account?.contaDigito) {
      accountLength = (current_account?.conta + current_account?.contaDigito)
        .length
    }
    if (accountLength == 5)
      contaPgt = `00` + current_account?.conta + current_account?.contaDigito
    if (accountLength == 6)
      contaPgt = `0` + current_account?.conta + current_account?.contaDigito
    if (accountLength == 7)
      contaPgt = `` + current_account?.conta + current_account?.contaDigito

    const sendObj = {
      ContaDtoAgencia: current_account?.agencia,
      ContaDtoAgenciaDigito: current_account?.agenciaDigito,
      ContaDtoConta: current_account?.conta,
      ContaDtoContaDigito: current_account?.contaDigito,
      ContaDtoContaPgto: contaPgt,
      ContaDtoTipoConta: current_account?.tipoConta,
      EMV: data,
    } as ReadQRCode
    try {
      const res = await services.bankingService.readQRCode(sendObj)
      if (res.data.sucesso === true) {
        const saveObj = {
          chave: res.data.chave,
          valor: res.data.valor,
          idConciliacaoRecebedor: res.data.idConciliacaoRecebedor,
          tpQRCode: res.data.tpQRCode,
          codigoLeituraQRCode: res.data.codigoLeituraQRCode,
        } as Step1PixCodePaste
        consultPix(saveObj.chave)
        console.log(saveObj)
        addPixCopyPasteStep1(saveObj)
        addPixPaymentType(TipoPagamentoPix.PIX_COPYPASTE)
        changePixEnvironment(2)
        changePixPayment(2)
      }
    } catch (error: any) {
      const err = error as AxiosError<any>
    }
  }

  const consultPix = async (chave: any) => {
    try {
      let accountLength = 0
      let contaPgt: string | undefined
      if (current_account?.conta && current_account?.contaDigito) {
        accountLength = (current_account?.conta + current_account?.contaDigito)
          .length
      }
      if (accountLength == 5)
        contaPgt = `00` + current_account?.conta + current_account?.contaDigito
      if (accountLength == 6)
        contaPgt = `0` + current_account?.conta + current_account?.contaDigito
      if (accountLength == 7)
        contaPgt = `` + current_account?.conta + current_account?.contaDigito
      const sendObj = {
        Chave: chave,
        ContaDtoAgencia: current_account?.agencia,
        ContaDtoAgenciaDigito: current_account?.agenciaDigito,
        ContaDtoConta: current_account?.conta,
        ContaDtoContaDigito: current_account?.contaDigito,
        ContaDtoContaPgto: contaPgt,
        ContaDtoTipoConta: current_account?.tipoConta,
      } as ConsultPixKey
      const res = await services.bankingService.consultPixKey(sendObj)
      if (res.data.sucesso === true) {
        const saveObj = {
          nome: res.data.nomeCorrentista,
          chave: res.data.chave,
          tipoChave: res.data.tipoChave,
          cpf: res.data.documentoFederal,
          numeroBanco: res.data.banco.descricao,
          agencia: res.data.conta.agencia,
          conta: res.data.conta.conta,
          valor: 0,
          message: ``,
          ticket: res.data.ticket,
        } as Step1PixPayment
        addPixPaymentStep1(saveObj)
        addPixPaymentStep2(saveObj)
        changePixPayment(2)
      }
    } catch (error: any) {
      const err = error as AxiosError<any>
    }
  }

  return (
    <>
      {!isTabletOrMobile ? (
        <>
          <CustomModal
            isOpen={true}
            onClose={() => {
              setOpenScan(false)
              changePixEnvironment(1)
            }}
          >
            <div className="pix-qr-code-modal">
              {!loading ? (
                <>
                  <h1 className="pix-qr-code-h1">
                    Posicione o QR Code frente à câmera!
                  </h1>
                  <QrcodeReader
                    qrCodeSuccessCallback={(value: string) => {
                      setLoading(true)
                      getReadQRCode(value)
                    }}
                    start={openScan}
                  />
                </>
              ) : (
                <div className="pix-qr-code-align-center">
                  <div
                    className="spinner-border pix-qr-code-spinner"
                    role="status"
                  />
                </div>
              )}
            </div>
          </CustomModal>
        </>
      ) : (
        <div className="pix-qr-code-mobile">
          <span
            className="pix-qr-code-img-close"
            onClick={() => {
              setOpenScan(false)
              changePixEnvironment(1)
            }}
          >
            <img
              src={close}
              width="24"
              height="24"
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </span>
          <div className="pix-qr-code-reader-mobile">
            <QrcodeReader
              qrCodeSuccessCallback={(value: string) => {
                setLoading(true)
                getReadQRCode(value)
              }}
              start={openScan}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default PixQRCode
