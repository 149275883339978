import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { BankingNavigationContext } from "../../../../context/BankingNavigationContext"
import { InsuranceContext } from "../../../../context/InsuranceContext"
import { useResposive } from "../../../../hooks/useResponsive"
import Cancelar from "./cancelar"
import { PaymentData } from "./ChangePaymentMethod"
import { Product } from "./product"
import ProductMobile from "./product/mobile"
import Renew from "./renovar"
import { ServiceChannels } from "./service_channels"
import "./styles.scss"
import { InsuranceSwitcher } from "./switcher"
import arrow from "../../../../assets/images/arrow_yellow.svg"
import SVGInject from "@iconfu/svg-inject"

const InsuranceArea = () => {
  const navigate = useNavigate()
  const { insurancePage } = useContext(BankingNavigationContext)
  const { activeProposal, renew, searchedForActiveProposal } =
    useContext(InsuranceContext)
  const { isTabletOrMobile } = useResposive()

  return (
    <>
      {!isTabletOrMobile ? (
        <div className="insurance-wrapper">
          {insurancePage === 6 ? (
            <Renew />
          ) : (
            <div className="insurance-container mt-5">
              {Object.entries(activeProposal).length !== 0 && (
                <InsuranceSwitcher />
              )}
              <div className="insurance-content">
                {insurancePage === 1 && <Product />}

                {insurancePage === 2 && <PaymentData />}

                {insurancePage === 3 && <ServiceChannels />}

                {insurancePage === 4 && <Cancelar />}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="insurance-mobile-wrapper">
          <div className="insurance-mobile-header">
            <span
              className="insurance-mobile-close-btn"
              onClick={() => {
                navigate(`/banking`)
              }}
            >
              <img
                src={arrow}
                width="27"
                height="15"
                alt="/"
                onLoad={(e) => SVGInject(e.target)}
              />
            </span>
            <div className="insurance-content-mobile">
              <h1 className="insurance-title-mobile vertical-center">Seguro</h1>
              {searchedForActiveProposal && !renew ? (
                <h2 className="insurance-subtitle-mobile">
                  Contrate aqui seu seguro
                </h2>
              ) : (
                <h2 className="insurance-subtitle-mobile">
                  Plano de vida kikkin
                </h2>
              )}
            </div>
          </div>

          {insurancePage === 6 ? (
            <Renew />
          ) : (
            <div className="insurance-mobile-content">
              {Object.entries(activeProposal).length !== 0 && (
                <InsuranceSwitcher />
              )}
              {insurancePage === 1 && <ProductMobile />}

              {insurancePage === 2 && <PaymentData />}

              {insurancePage === 3 && <ServiceChannels />}

              {insurancePage === 4 && <Cancelar />}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default InsuranceArea
