import { useContext } from "react"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import "./styles.scss"

const BoletoCreationNavigation = () => {
  const { boletoCreationStep } = useContext(BankingNavigationContext)

  return (
    <>
      <div className="new-boleto-navigation vertical-center">
        <div
          className={
            boletoCreationStep === 1
              ? `navigation-item-active`
              : `navigation-item`
          }
        >
          <div
            className={
              boletoCreationStep === 1
                ? `navigation-item-txt-active`
                : `navigation-item-txt`
            }
          >
            1
          </div>
        </div>
        <div
          className={
            boletoCreationStep === 2
              ? `navigation-item-active`
              : `navigation-item`
          }
        >
          <div
            className={
              boletoCreationStep === 2
                ? `navigation-item-txt-active`
                : `navigation-item-txt`
            }
          >
            2
          </div>
        </div>
        <div
          className={
            boletoCreationStep === 3
              ? `navigation-item-active`
              : `navigation-item`
          }
        >
          <div
            className={
              boletoCreationStep === 3
                ? `navigation-item-txt-active`
                : `navigation-item-txt`
            }
          >
            3
          </div>
        </div>
      </div>
    </>
  )
}
export default BoletoCreationNavigation
