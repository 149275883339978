import { useContext, useEffect } from "react"
import barcode_yellow from "../../../../assets/images/navbar-banking/barcode_yellow.svg"
import extract from "../../../../assets/images/navbar-banking/extract.svg"
import extract_yellow from "../../../../assets/images/navbar-banking/extract_yellow.svg"
import house_yellow from "../../../../assets/images/navbar-banking/house_yellow.svg"
import insurance_yellow from "../../../../assets/images/navbar-banking/insurance_yellow.svg"
import card from "../../../../assets/images/navbar-banking/card_icon.svg"
import pix_yellow from "../../../../assets/images/navbar-banking/pix_yellow.svg"
import transfer_yellow from "../../../../assets/images/navbar-banking/transfer_yellow.svg"
import payment_yellow from "../../../../assets/images/navbar-banking/payment_yellow.svg"
import withdraw from "../../../../assets/images/navbar-banking/withdraw.svg"
import SVGInject from "@iconfu/svg-inject"
import "./styles.scss"
import { useResposive } from "../../../../hooks/useResponsive"
import { useLocation, useNavigate } from "react-router-dom"
import { BalanceContext } from "../../../../context/BalanceProvider"
import { AuthContext } from "../../../../context/AuthProvider"

const Switcher = () => {
  const { isTablet, isTabletOrMobile } = useResposive()
  const { refreshBalance } = useContext(BalanceContext)
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  const navigateTo = (route: string) => {
    refreshBalance()
    navigate(route)
  }

  return (
    <div className={isTablet ? `switcher-wrapper m-auto` : `switcher-wrapper`}>
      <div className="row switcher-row">
        <div className="col switcher-col">
          <div
            onClick={() => navigate(`/`)}
            className={
              location.pathname === `/banking`
                ? `switcher-item-active img-filter home-switcher`
                : `switcher-item home-switcher`
            }
          >
            <div className="switcher-item-img">
              <img
                className="switcher-img"
                src={house_yellow}
                onLoad={(e) => SVGInject(e.target)}
              />
            </div>
            <span className="switcher-item-span">Home</span>
          </div>
        </div>
        {user && user.allowed_features.pix && (
          <div className="col switcher-col">
            <div
              onClick={() => navigateTo(`/banking/pix`)}
              className={
                location.pathname === `/banking/pix`
                  ? `switcher-item-active img-filter pix-switcher`
                  : `switcher-item pix-switcher`
              }
            >
              <div className="switcher-item-img">
                <img
                  className="switcher-img"
                  src={pix_yellow}
                  onLoad={(e) => SVGInject(e.target)}
                />
              </div>
              <span className="switcher-item-span">PIX</span>
            </div>
          </div>
        )}
        {user && user.allowed_features.transfer && (
          <div className="col switcher-col">
            <div
              onClick={() => navigate(`/banking/transferir`)}
              className={
                location.pathname === `/banking/transferir`
                  ? `switcher-item-active img-filter transfer-switcher`
                  : `switcher-item transfer-switcher`
              }
            >
              <div className="switcher-item-img">
                <img
                  className="switcher-img"
                  src={transfer_yellow}
                  onLoad={(e) => SVGInject(e.target)}
                />
              </div>
              <span className="switcher-item-span">Transferir</span>
            </div>
          </div>
        )}
        {user && user.allowed_features.transfer && (
          <div className="col switcher-col">
            <div
              onClick={() => navigate(`/banking/extrato`)}
              className={
                location.pathname === `/banking/extrato`
                  ? `switcher-item-active img-filter extrato-switcher`
                  : `switcher-item extrato-switcher`
              }
            >
              <div className="switcher-item-img">
                {location.pathname === `/banking/extrato` ? (
                  <img
                    className="switcher-img"
                    src={extract}
                    onLoad={(e) => SVGInject(e.target)}
                  />
                ) : (
                  <img
                    className="switcher-img"
                    src={extract_yellow}
                    onLoad={(e) => SVGInject(e.target)}
                  />
                )}
              </div>
              <span className="switcher-item-span">Extrato</span>
            </div>
          </div>
        )}
        {user && user.allowed_features.boleto && (
          <div className="col switcher-col">
            <div
              onClick={() => navigateTo(`/banking/boleto`)}
              className={
                location.pathname === `/banking/boleto`
                  ? `switcher-item-active img-filter boleto-switcher`
                  : `switcher-item boleto-switcher`
              }
            >
              <div className="switcher-item-img">
                <img
                  className="switcher-img"
                  src={barcode_yellow}
                  onLoad={(e) => SVGInject(e.target)}
                />
              </div>
              <span className="switcher-item-span">Boleto</span>
            </div>
          </div>
        )}
        {user && user.allowed_features.boleto_payment && (
          <div className="col switcher-col">
            <div
              onClick={() => navigate(`/banking/pagar`)}
              className={
                location.pathname === `/banking/pagar`
                  ? `switcher-item-active img-filter payment-switcher`
                  : `switcher-item payment-switcher`
              }
            >
              <div className="switcher-item-img">
                <img
                  className="switcher-img"
                  src={payment_yellow}
                  onLoad={(e) => SVGInject(e.target)}
                />
              </div>
              <span className="switcher-item-span">Pagar</span>
            </div>
          </div>
        )}
        {user && user.allowed_features.insurance && (
          <div className="col switcher-col">
            <div
              onClick={() => navigateTo(`/banking/seguro`)}
              className={
                location.pathname === `/banking/seguro`
                  ? `switcher-item-active img-filter insurance-switcher`
                  : `switcher-item insurance-switcher`
              }
            >
              <div className="switcher-item-img">
                <img
                  className="switcher-img"
                  src={insurance_yellow}
                  onLoad={(e) => SVGInject(e.target)}
                />
              </div>
              <span className="switcher-item-span">Seguro</span>
            </div>
          </div>
        )}
        {user && user.allowed_features.cards && (
          <div className="col switcher-col">
            <div
              onClick={() => navigate(`/banking/cartao`)}
              className={
                location.pathname === `/banking/cartao`
                  ? `switcher-item-active img-filter cartao-switcher`
                  : `switcher-item cartao-switcher`
              }
            >
              <div className="switcher-item-img">
                <img
                  className="switcher-img"
                  width={35}
                  height={20}
                  src={card}
                  onLoad={(e) => SVGInject(e.target)}
                />
              </div>
              <span className="switcher-item-span">Cartão</span>
            </div>
          </div>
        )}
        {user && user.allowed_features.withdraw && (
          <>
            {isTabletOrMobile ? (
              <div className="col switcher-col">
                <div
                  onClick={() => navigate(`/banking/saque`)}
                  className={
                    location.pathname === `/banking/saque`
                      ? `switcher-item-active img-filter saque-switcher`
                      : `switcher-item saque-switcher`
                  }
                >
                  <div className="switcher-item-img">
                    <img
                      className="switcher-img"
                      width={35}
                      height={20}
                      src={withdraw}
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  </div>
                  <span className="switcher-item-span">Saque</span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Switcher
