import { ReactNode } from "react"
import "./styles.scss"

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
}

const CustomModal = ({ isOpen, onClose, children }: ModalProps) => {
  return isOpen ? (
    <div className="onboarding-modal-wrapper">
      <div className="onboarding-modal-overlay" onClick={onClose}></div>
      <div className="onboarding-modal-container">
        <div className="flex-end mb-3">
          <div className="onboarding-modal-close-button" onClick={onClose} />
        </div>
        {children}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default CustomModal
