import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../../context/AuthProvider"
import noFavoritesImg from "../../../assets/images/user.svg"
import {
  formatCnpj,
  formatCpf,
  formatPhone,
  validateCNPJ,
  validateCpf,
} from "../../../utils/validation"
import "./styles.scss"
import arrow_right from "../../../assets/images/arrow-right-yellow.svg"
import trash from "../../../assets/images/trash.svg"
import SVGInject from "@iconfu/svg-inject"
import {
  Contact,
  GetBanksResponse,
  GetCustomerFavoritesResponse,
} from "k4n-svcmesh-sdk"
import services from "../../../services/svcmesh"

export enum FavoriteScreenEnum {
  TRANSFER = `transfer`,
  PIX = `pix`,
}

export type FavoritesProps = {
  favorites: GetCustomerFavoritesResponse
  title: string
  screen: FavoriteScreenEnum
  isMobile?: boolean
  changeStep: (data: number) => void
  addData: (data) => void
}

const Favorites = ({
  favorites,
  title,
  screen,
  changeStep,
  addData,
  isMobile,
}: FavoritesProps) => {
  const [favoriteSearchInput, setFavoriteSearchInput] = useState<string>(``)
  const [searchedFavorites, setSearchedFavorites] = useState<Contact[]>([])
  const [banks, setBanks] = useState<GetBanksResponse>({ banks: [] })
  const [loadingBanks, setLoadingBanks] = useState<boolean>(true)
  const [searchingOnMobile, setSearchingOnMobile] = useState<boolean>(false)
  const [favoriteLoading, setFavoriteLoading] = useState<number>()
  const user = useContext(AuthContext)

  const getK4nBanks = async () => {
    try {
      setBanks(await services.bankingService.getBanks())
      setLoadingBanks(false)
    } catch (error) {
      console.log(error)
      setLoadingBanks(false)
    }
  }

  useEffect(() => {
    getK4nBanks()
    setSearchedFavorites(favorites.contacts)
    console.log(`FAV ->>>>`, favorites)
  }, [])

  useEffect(() => {
    if (favoriteSearchInput !== `` && favorites.contacts.length) {
      setSearchedFavorites(
        favorites.contacts.filter((f) =>
          f.nome?.toUpperCase().match(favoriteSearchInput.toUpperCase()),
        ),
      )
    } else if (favorites.contacts.length) {
      setSearchedFavorites(favorites.contacts)
    }
  }, [favoriteSearchInput])

  const getNameInitials = (name) => {
    const names = name.split(` `)
    let initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
  }

  const getFormattedPixKey = (key) => {
    if (validateCpf(key)) {
      return formatCpf(key)
    } else if (validateCNPJ(key)) {
      return formatCnpj(key)
    } else if (key.length === 11) {
      return formatPhone(key)
    }
    return key
  }

  const onFavoriteClick = (
    favorite: Contact,
    instituicao?: string,
    key?: number,
  ) => {
    if (screen === FavoriteScreenEnum.TRANSFER) {
      addData({ ...favorite, instituicao })
      changeStep(3)
      setSearchingOnMobile(false)
    } else if (screen === FavoriteScreenEnum.PIX) {
      addData({ ...favorite })
      setFavoriteLoading(key!)
      setSearchingOnMobile(false)
    }
  }

  const onClickDelete = async (id: number, cpf: string) => {
    await services.bankingService.removeFavorite(id, cpf)
    removeFromFavorites(cpf)
  }

  const removeFromFavorites = (cpf: string) => {
    setSearchedFavorites((current) =>
      current.filter((user) => user.cpf !== cpf),
    )
  }

  function getBankNameByNumber(numero_banco?: string) {
    return banks.banks[banks.banks?.findIndex((b) => b.number === numero_banco)]
      ?.name
  }

  const favoriteContainerClass = () => {
    if (!!searchingOnMobile) return `favorites-container-active-mobile`
    else return `favorites-container-inactive-mobile`
  }
  return (
    <div
      className={`favorites-container 
      ${favoriteContainerClass()}`}
    >
      {/* {!!isMobile && !!searchingOnMobile && (
        <button
          className="favorites-mobile-close-btn"
          onClick={() => {
            setSearchingOnMobile(false)
          }}
        ></button>
      )} */}
      <div
        className={`favorite-div 
        ${!!isMobile ? `favorites-flex-direction-column` : ``}`}
      >
        <h1 className="favorite-title">{title}</h1>
        <input
          onChange={(e) => setFavoriteSearchInput(e.target.value)}
          onFocus={(e) => {
            //full screen search on mobile!
            if (!!isMobile) setSearchingOnMobile(true)
          }}
          type="text"
          placeholder="Buscar favorito da sua lista"
          className="favorite-input"
        />
      </div>
      {searchedFavorites.length > 0 && !loadingBanks && (
        <div className="favorite-content">
          {searchedFavorites.map((f, i) => (
            <div key={i} className="favorite-row">
              <div
                className="favorite-row-click"
                onClick={() => {
                  screen === FavoriteScreenEnum.TRANSFER
                    ? onFavoriteClick(f, getBankNameByNumber(f?.numero_banco))
                    : onFavoriteClick(f, undefined, i)
                }}
              >
                <div className="favorite-avatar-row">
                  <span className="favorite-avatar">
                    <h1 className="favorite-name-avatar">
                      {getNameInitials(f.nome)}
                    </h1>
                  </span>
                  <span className="favorite-content-txt">{f?.nome}</span>
                  <span className="favorite-table-txt">
                    {screen === FavoriteScreenEnum.TRANSFER &&
                      `Instituição: ${getBankNameByNumber(f?.numero_banco)}`}
                    {screen === FavoriteScreenEnum.PIX &&
                      `Chave pix: ${getFormattedPixKey(f?.chave_pix)}`}
                  </span>
                </div>
                {favoriteLoading === i ? (
                  <div
                    className="spinner-border spinner-favorites"
                    role="status"
                  ></div>
                ) : (
                  <div className="favorites-img-container">
                    <span>
                      <img
                        className="pix-content-img"
                        src={arrow_right}
                        alt="/"
                        onLoad={(e) => SVGInject(e.target)}
                      />
                    </span>
                  </div>
                )}
              </div>
              <span
                className="trash-container"
                onClick={() =>
                  onClickDelete(user.user?.id as number, f?.cpf as string)
                }
              >
                <img
                  className="trash-img"
                  src={trash}
                  alt="/"
                  onLoad={(e) => SVGInject(e.target)}
                />
              </span>
            </div>
          ))}
        </div>
      )}
      {favorites.contacts.length === 0 && (
        <div className="no-favorites">
          <img
            className="no-favorites-img"
            src={noFavoritesImg}
            alt="/"
            onLoad={(e) => SVGInject(e.target)}
          />
          <h6 className="no-favorites-message">
            Você ainda não possui favoritos
          </h6>
        </div>
      )}
    </div>
  )
}

export default Favorites
