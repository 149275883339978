import { useContext, useEffect, useState } from "react"
import { SecondaryButton, PrimaryButton, CurrencyInput } from "k4n-components"
import PixNav from "../../nav-pix"
import "./styles.scss"
import DatePicker from "react-datepicker"
import InputMask from "react-input-mask"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { Controller, useForm } from "react-hook-form"
import { price_to_number } from "../../../../../../utils/validation"
import { formattedCurrency } from "../../../../../../services/banking"
import { PixStorageContext } from "../../../../../../context/PixStorageProvider"
import { Step2PixPayment } from "../../../../../../typings/step2-pix-payment.dto"
import dayjs from "dayjs"
import { AuthContext } from "../../../../../../context/AuthProvider"
import { useResposive } from "../../../../../../hooks/useResponsive"
import PixPaymentStep2Mobile from "./Mobile"
import { BalanceContext } from "../../../../../../context/BalanceProvider"
import services from "../../../../../../services/svcmesh"
import { ConsultPixKey, TipoPagamentoPix } from "k4n-svcmesh-sdk"

const PixPaymentStep2 = () => {
  const [showPixDate, setShowPixDate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingPayment, setLoadingPayment] = useState(true)
  const [startDate, setStartDate] = useState(null)
  const [paymentValue, setPaymentValue] = useState<number>(0)
  const [paymentStep2, setpaymentStep2] = useState(Object)
  const [saveFavorite, setSaveFavorite] = useState(false)
  const { changePixEnvironment, changePixPayment } = useContext(
    BankingNavigationContext,
  )
  const {
    addPixPaymentStep2,
    pixCopyPasteStep1,
    pixPaymentStep1,
    pixPaymentType,
  } = useContext(PixStorageContext)
  const { current_account } = useContext(AuthContext)
  const { balance, loadingBalance, refreshBalance } = useContext(BalanceContext)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({})
  const { isTabletOrMobile } = useResposive()

  const onSubmit = async (data: any) => {
    if (pixPaymentType === TipoPagamentoPix.PIX_COPYPASTE) {
      paymentStep2.message = data.message
      paymentStep2.save_favorite = saveFavorite
      addPixPaymentStep2(paymentStep2)
    } else {
      const payment = pixPaymentStep1 as Step2PixPayment
      payment.agendamento = showPixDate
      const date = dayjs(startDate).format(`YYYY-MM-DD`)
      payment.data_agendamento = date
      payment.message = data.message
      payment.valor = paymentValue
      payment.save_favorite = saveFavorite
      addPixPaymentStep2(payment)
    }
    if (!isTabletOrMobile) changePixPayment(3)
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    const fixedNumber = price_to_number(newValue)
    setPaymentValue(fixedNumber)
  }

  const consultPixQRCode = async () => {
    setLoadingPayment(false)
    try {
      let accountLength = 0
      let contaPgt: string | undefined
      if (current_account?.conta && current_account?.contaDigito) {
        accountLength = (current_account?.conta + current_account?.contaDigito)
          .length
      }
      if (accountLength == 5)
        contaPgt = `00` + current_account?.conta + current_account?.contaDigito
      if (accountLength == 6)
        contaPgt = `0` + current_account?.conta + current_account?.contaDigito
      if (accountLength == 7)
        contaPgt = `` + current_account?.conta + current_account?.contaDigito

      const sendObj = {
        Chave: pixCopyPasteStep1.chave,
        ContaDtoAgencia: current_account?.agencia,
        ContaDtoAgenciaDigito: current_account?.agenciaDigito,
        ContaDtoConta: current_account?.conta,
        ContaDtoContaDigito: current_account?.contaDigito,
        ContaDtoContaPgto: contaPgt,
        ContaDtoTipoConta: current_account?.tipoConta,
      } as ConsultPixKey
      const res = await services.bankingService.consultPixKey(sendObj)
      if (res.data.sucesso === true) {
        const date = dayjs(startDate).format(`YYYY-MM-DD`)
        const saveObj = {
          nome: res.data.nomeCorrentista,
          chave: res.data.chave,
          tipoChave: res.data.tipoChave,
          cpf: res.data.documentoFederal,
          numeroBanco: res.data.banco.descricao,
          agencia: res.data.conta.agencia,
          conta: res.data.conta.conta,
          valor: pixCopyPasteStep1.valor,
          message: ``,
          ticket: res.data.ticket,
          agendamento: showPixDate,
          data_agendamento: date,
        } as Step2PixPayment
        setpaymentStep2(saveObj)
        setLoadingPayment(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const validatePaymentCurrency = (value: string) => {
    const fixedValue = price_to_number(value)
    if (fixedValue > 0 && fixedValue <= 1000) return true
    else return false
  }

  const handlePixEnvironmentChange = (newPage: number) => {
    changePixEnvironment(newPage)
    changePixPayment(1)
  }

  useEffect(() => {
    refreshBalance()
    setShowPixDate(false)
    if (pixPaymentType === TipoPagamentoPix.PIX_PAYMENT) setLoadingPayment(true)
    if (pixPaymentType === TipoPagamentoPix.PIX_COPYPASTE) consultPixQRCode()
    if (loadingPayment === true) setLoading(true)
  }, [])

  return (
    <>
      {isTabletOrMobile !== true ? (
        <div className="pix-payment-step2-container">
          <PixNav step={2} title={`Pagamento`} />
          <div className="pix-payment-step2-content">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="pix-payment-step2-content-title">
                <h1 className="pix-payment-step2-title">Pagar para</h1>
              </div>
              {pixPaymentType === 1 ? (
                <>
                  <div className="table-step2-copy-paste">
                    <div className="inline-flex">
                      <div className="flex-direction-column">
                        <span className="content-table-step2-title">
                          Valor a pagar
                        </span>
                        <span className="content-table-step2-name-txt">
                          {formattedCurrency(pixCopyPasteStep1.valor)}
                        </span>
                      </div>
                      <div className="flex-direction-column">
                        <span className="content-table-step2-title">
                          Saldo Kikkin:
                        </span>
                        {!loadingBalance ? (
                          <span className="content-table-step2-pix-txt">
                            {formattedCurrency(balance)}
                          </span>
                        ) : (
                          <div className="emptySpace-pix gradient"></div>
                        )}
                      </div>
                    </div>
                    <span className="content-table-step2-title">
                      Forma de pagamento
                    </span>
                    <span className="content-table-step2-pix-txt">
                      Ag: {paymentStep2.agencia} - Cc: {paymentStep2.conta}
                    </span>
                  </div>
                  <div className="row align-items-start content-table-step2-copy-paste">
                    {loading ? (
                      <>
                        <div className="col direction-column">
                          <span className="content-table-step2-title">
                            Para
                          </span>
                          <span className="content-table-step2-pix-txt">
                            {paymentStep2.nome}
                          </span>
                        </div>
                        <div className="col direction-column">
                          <span className="content-table-step2-title">
                            Chave
                          </span>
                          <span className="content-table-step2-pix-txt">
                            {paymentStep2.chave}
                          </span>
                        </div>
                        <div className="col direction-column">
                          <span className="content-table-step2-title">CPF</span>
                          <span className="content-table-step2-pix-txt">
                            {paymentStep2.cpf}
                          </span>
                        </div>
                        <span className="content-table-step2-title">
                          Instituição
                        </span>
                        <span className="content-table-step2-pix-txt">
                          {paymentStep2.numeroBanco}
                        </span>
                      </>
                    ) : (
                      <>
                        <div className="emptySpace-pix gradient mg-top-24"></div>
                        <div className="emptySpace-pix gradient mg-top-24"></div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="content-table-step2">
                    <span className="content-table-step2-title">
                      Você vai pagar para
                    </span>
                    {loading ? (
                      <>
                        <span className="content-table-step2-name-txt">
                          {pixPaymentStep1.nome}
                        </span>
                        <span className="content-table-step2-pix-txt">
                          {pixPaymentStep1.numeroBanco} - Chave PIX{` `}
                          {pixPaymentStep1.chave}
                        </span>
                      </>
                    ) : (
                      <>
                        <div className="emptySpace-pix gradient"></div>
                        <div className="emptySpace-pix gradient"></div>
                      </>
                    )}
                  </div>
                  <div className="form-check-step2">
                    <input
                      className="form-check-input pix-payment-step2-checkbox"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onClick={() => setSaveFavorite(!saveFavorite)}
                      onKeyPress={(event) => {
                        if (event.key === `Enter`) {
                          event.preventDefault()
                        }
                      }}
                    />
                    <span className="pix-payment-step2-checkbox-txt">
                      Salvar como favorito
                    </span>
                  </div>
                  <div className="content-table-step2">
                    <span className="pix-payment-step2-title mg-bottom-24">
                      Valor a transferir
                    </span>
                  </div>
                  <div className="content-table-step2 mg-bottom-24">
                    <div className="pix-payment-step2-value">
                      <div>
                        <Controller
                          name="receiveValue"
                          control={control}
                          defaultValue="0,00"
                          rules={{
                            required: true,
                            validate: validatePaymentCurrency,
                            onChange: onValueChange,
                          }}
                          render={({ field }) => (
                            <CurrencyInput
                              value={field.value}
                              options={{
                                style: `decimal`,
                                allowNegative: false,
                              }}
                              onChangeEvent={(_, maskedValue) => {
                                field.onChange(maskedValue)
                              }}
                              required={true}
                              className="pix-payment-step2-value-input mg-bottom-11"
                              onKeyPress={(event) => {
                                if (event.key === `Enter`) {
                                  event.preventDefault()
                                }
                              }}
                            />
                          )}
                        />
                        {errors.receiveValue && (
                          <p className="pix-error-msg ">
                            Por favor, verifique o valor.
                          </p>
                        )}
                      </div>
                      <div className="pix-payment-step2-balance">
                        <span className="content-table-step2-title">
                          Saldo Kikkin:
                        </span>
                        {!loadingBalance ? (
                          <span className="content-table-step2-pix-txt">
                            {formattedCurrency(balance)}
                          </span>
                        ) : (
                          <div className="emptySpace-pix gradient"></div>
                        )}
                      </div>
                    </div>
                    <span className="content-table-step2-txt-red">
                      Limite do PIX de R$ 1.000,00
                    </span>
                  </div>
                </>
              )}
              <div className="content-table-step2">
                <input
                  id="message"
                  type="text"
                  className="pix-payment-step2-message-input"
                  placeholder="Enviar uma mensagem"
                  {...register(`message`, {
                    required: false,
                  })}
                  onKeyPress={(event) => {
                    if (event.key === `Enter`) {
                      event.preventDefault()
                    }
                  }}
                />
                <span className="content-table-step2-pix-txt">
                  Use este espaço para mandar um recado
                </span>
                <div className="pix-payment-step2-date">
                  <span className="content-table-step2-title-18">
                    Data do PIX
                  </span>
                  <div className="form-check form-switch">
                    <input
                      className="pix-payment-step2-date-input form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      onClick={() => setShowPixDate(!showPixDate)}
                      onKeyPress={(event) => {
                        if (event.key === `Enter`) {
                          event.preventDefault()
                        }
                      }}
                    />
                  </div>
                </div>
                <span className="content-table-step2-pix-txt">
                  Agende o pagamento
                </span>
                {showPixDate === true && (
                  <DatePicker
                    selected={startDate}
                    onChange={(date: any) => {
                      setStartDate(date)
                    }}
                    locale="pt-BR"
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    required={true}
                    placeholderText="Quando deseja realizar o Pix?"
                    customInput={
                      <InputMask
                        mask="99/99/9999"
                        maskPlaceholder={null}
                        className="pix-payment-step2-message-input pix-payment-step2-input-calender"
                      />
                    }
                  />
                )}
              </div>
              <div className="pix-payment-step2-btn">
                <SecondaryButton
                  width={`170px`}
                  height={`60px`}
                  text={`Cancelar`}
                  margin_right={`50px`}
                  OnClick={() => handlePixEnvironmentChange(1)}
                />
                <PrimaryButton
                  width={`264px`}
                  height={`60px`}
                  onClick={() => true}
                >
                  {showPixDate === true ? `Agendar pagamento` : `Próximo`}
                </PrimaryButton>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <PixPaymentStep2Mobile
          validatePaymentCurrency={validatePaymentCurrency}
          onValueChange={onValueChange}
          control={control}
          errors={errors}
          paymentValue={paymentValue}
          handleSubmit={handleSubmit}
          startDate={startDate}
          setStartDate={setStartDate}
          register={register}
        />
      )}
    </>
  )
}
export default PixPaymentStep2
