/**
 * Shared module between mobile and web app
 */
import entradaDinheiro from "../../../../assets/images/entrada_dinheiro.svg"
import pix from "../../../../assets/images/navbar-banking/pix_yellow.svg"
import saidaDinheiro from "../../../../assets/images/saida_dinheiro.svg"
import purchase from "../../../../assets/images/purchase.svg"
import others from "../../../../assets/images/others.svg"

export const DEFAULT_PERIOD_FILTER = 7

export const TRANSACTIONS_ICONS_TITLESa: {
  [key: string]: { icon: string; title: string }
} = {
  CUSTO_RECEBIMENTO_TRANSFERENCIA: {
    icon: saidaDinheiro,
    title: `Taxa de transferência`,
  },
  TRANSFERENCIA_ENTRE_CONTAS_DEBITO: {
    icon: saidaDinheiro,
    title: `Transferência realizada`,
  },
  TRANSFERENCIA_ENTRE_CONTAS_CREDITO: {
    icon: entradaDinheiro,
    title: `Transferência recebida`,
  },
  TED___PAG0108: { icon: saidaDinheiro, title: `TED realizado` },
  TED___STR0008: { icon: saidaDinheiro, title: `TED realizado` },
  TED_DEVOLUCAO: { icon: entradaDinheiro, title: `Devolução TED` },
  IMPLANTACAO_DE_SALDO: {
    icon: entradaDinheiro,
    title: `Implementação de saldo`,
  },
  RECEBIMENTO_PIX: {
    icon: pix,
    title: `PIX recebido`,
  },
  ENVIO_PIX: {
    icon: pix,
    title: `PIX enviado`,
  },
  RECEBIMENTO_BOLETO: {
    icon: entradaDinheiro,
    title: `Pagamento recebido`,
  },
  PAGAMENTO_BOLETO: {
    icon: saidaDinheiro,
    title: `Pagamento realizado`,
  },
  PIX_RECEBIMENTO_VIA_BOLETO: {
    icon: entradaDinheiro,
    title: `Pagamento recebido`,
  },
  DEVOLUCAO_PAGAMENTO_BOLETO: {
    icon: entradaDinheiro,
    title: `Devolução boleto`,
  },
}

export const SCHEDULES_ICONS_TITLES: {
  [key: string]: { icon: string; title: string }
} = {
  transfer: {
    icon: saidaDinheiro,
    title: `Transferência agendada`,
  },
  ted: {
    icon: saidaDinheiro,
    title: `Transferência agendada`,
  },
  pix: {
    icon: pix,
    title: `PIX agendado`,
  },
  boleto: {
    icon: saidaDinheiro,
    title: `Pagamento agendado`,
  },
}

export interface csvData {
  Data: string
  Nome: string
  Descricao: string
  Valor: string
}
export interface RequestData {
  initial_date: string
  relative_final_date: string
}

export interface SchedulesRequestData {
  customer_pf_id: number
}

export const TRANSACTIONS_ICONS_TITLES: {
  [key: number]: { icon: string; title: string }
} = {
  0: {
    icon: saidaDinheiro,
    title: `Taxa de transferência`,
  },
  1: {
    icon: saidaDinheiro,
    title: `Transferência realizada`,
  },
  2: { icon: saidaDinheiro, title: `TED realizado` },
  3: {
    icon: saidaDinheiro,
    title: `Pagamento realizado`,
  },
  4: { icon: saidaDinheiro, title: `TED realizado` },
  5: {
    icon: pix,
    title: `PIX enviado`,
  },
  6: {
    icon: saidaDinheiro,
    title: `Pagamento concessionária`,
  },
  7: {
    icon: purchase,
    title: `Compra cartão`,
  },
  8: {
    icon: saidaDinheiro,
    title: `Saque`,
  },
  9: {
    icon: saidaDinheiro,
    title: `Pagamento cartão`,
  },
  10: { icon: saidaDinheiro, title: `Cobrança` },
  501: {
    icon: entradaDinheiro,
    title: `Transferência recebida`,
  },
  502: {
    icon: entradaDinheiro,
    title: `TED recebida`,
  },
  503: {
    icon: entradaDinheiro,
    title: `Pagamento recebido`,
  },
  504: {
    icon: entradaDinheiro,
    title: `TED recebida`,
  },
  505: {
    icon: entradaDinheiro,
    title: `PIX recebido`,
  },
  506: {
    icon: entradaDinheiro,
    title: `SLC recebido`,
  },
  1001: {
    icon: entradaDinheiro,
    title: `Devolução Transferência`,
  },
  1002: {
    icon: entradaDinheiro,
    title: `Devolução TED`,
  },
  1003: {
    icon: entradaDinheiro,
    title: `Devolução título`,
  },
  1004: {
    icon: entradaDinheiro,
    title: `Devolução TED`,
  },
  1005: {
    icon: others,
    title: `Estorno PIX`,
  },
  1006: {
    icon: entradaDinheiro,
    title: `Devolução PIX`,
  },
  1007: {
    icon: entradaDinheiro,
    title: `Devolução pagamento título concessionária`,
  },
  1008: {
    icon: purchase,
    title: `Devolução compra cartão`,
  },
  1009: {
    icon: entradaDinheiro,
    title: `Devolução saque cartão`,
  },
  1501: {
    icon: others,
    title: `Registro boleto`,
  },
  1502: {
    icon: others,
    title: `Alteração boleto`,
  },
  1503: {
    icon: others,
    title: `Cancelamento boleto`,
  },
  2001: {
    icon: others,
    title: `CNAB Registrado`,
  },
  2002: {
    icon: others,
    title: `Pagamento CNAB`,
  },
  2003: {
    icon: others,
    title: `Alteração CNAB`,
  },
  2004: {
    icon: others,
    title: `Cancelamento CNAB`,
  },
  2501: {
    icon: others,
    title: `Split`,
  },
  3001: {
    icon: others,
    title: `Serviço`,
  },
  4001: {
    icon: others,
    title: `Safe`,
  },
  4501: {
    icon: others,
    title: `Prévia - Crédito boleto`,
  },
  5001: {
    icon: others,
    title: `Rejeição envio PIX`,
  },
  5002: {
    icon: others,
    title: `Rejeição recebimento PIX`,
  },
  5003: {
    icon: others,
    title: `Rejeição estorno PIX`,
  },
  5004: {
    icon: others,
    title: `Rejeição devolução PIX`,
  },
}
