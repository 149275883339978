import { useContext } from "react"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import { useResposive } from "../../../../../hooks/useResponsive"
import PixReceipt from "./PixReceipt"
import PixPaymentStep1 from "./Step1"
import PixPaymentStep2 from "./Step2"
import PixPaymentStep3 from "./Step3"
import "./styles.scss"

const Pix = () => {
  const { pixPayment } = useContext(BankingNavigationContext)
  const { isTabletOrMobile } = useResposive()

  return (
    <div
      className={isTabletOrMobile === true ? `pix-payment-wrapper-mobile` : ``}
    >
      {pixPayment === 1 && <PixPaymentStep1 />}
      {pixPayment === 2 && <PixPaymentStep2 />}
      {pixPayment === 3 && <PixPaymentStep3 />}
      {pixPayment === 4 && <PixReceipt />}
    </div>
  )
}
export default Pix
