import "./styles.scss"
import Card from "k4n-cards-mfe"
import { AuthContext } from "../../../../context/AuthProvider"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import * as webUtils from "../../../../utils"

const Cards = () => {
  const { user, hasDigitalSignature } = useContext(AuthContext)
  const navigate = useNavigate()

  const navigateTo = (route: string) => {
    navigate(route)
  }

  const context = {
    userContextData: user,
    hasDigitalSignature: hasDigitalSignature,
    webUtils: {
      ...webUtils,
      navigateTo,
    },
  }

  return (
    <>
      <Card context={context} />
    </>
  )
}

export default Cards
