import {
  createContext,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react"

type BankingNavigationContextType = {
  page: number
  changePage: (newPage: number) => void
  boletoCreationStep: number
  changeBoletoStep: (newStep: number) => void
  insuranceStep: number
  changeInsuranceStep: (newStep: number) => void
  pixEnvironment: number
  changePixEnvironment: (newStep: number) => void
  pixPayment: number
  changePixPayment: (newStep: number) => void
  transferStep: number
  changeTransferStep: (newStep: number) => void
  newContactTransferStep: number
  changeNewContactTransferStep: (newStep: number) => void
  isScheduling: boolean
  changeIsScheduling: (newValue: boolean) => void
  insurancePage: number
  changeInsurancePage: (page: number) => void
  withdrawPage: number
  changeWithdrawPage: (page: number) => void
}

const contextDefaultValues: BankingNavigationContextType = {
  page: 1,
  boletoCreationStep: 1,
  insuranceStep: 1,
  pixEnvironment: 1,
  pixPayment: 1,
  transferStep: 1,
  newContactTransferStep: 0,
  isScheduling: false,
  insurancePage: 1,
  withdrawPage: 1,
  changeBoletoStep: () => {},
  changeInsuranceStep: () => {},
  changePage: () => {},
  changePixEnvironment: () => {},
  changePixPayment: () => {},
  changeTransferStep: () => {},
  changeNewContactTransferStep: () => {},
  changeIsScheduling: () => {},
  changeInsurancePage: () => {},
  changeWithdrawPage: () => {},
}

export const BankingNavigationContext =
  createContext<BankingNavigationContextType>(contextDefaultValues)

const BankingNavigationProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const [page, setPage] = useState<number>(contextDefaultValues.page)
  const [boletoCreationStep, setCreateBoletoStep] = useState<number>(
    contextDefaultValues.boletoCreationStep,
  )

  const [insuranceStep, setInsuranceStep] = useState<number>(
    contextDefaultValues.insuranceStep,
  )
  const [pixEnvironment, setPixEnvironment] = useState<number>(
    contextDefaultValues.pixEnvironment,
  )
  const [pixPayment, setPixPayment] = useState<number>(
    contextDefaultValues.pixPayment,
  )

  const [transferStep, setTransferStep] = useState<number>(
    contextDefaultValues.transferStep,
  )

  const [newContactTransferStep, setNewContactTransferStep] = useState<number>(
    contextDefaultValues.newContactTransferStep,
  )

  const [isScheduling, setIsScheduling] = useState<boolean>(
    contextDefaultValues.isScheduling,
  )

  const [insurancePage, setInsurancePage] = useState<number>(
    contextDefaultValues.insurancePage,
  )

  const [withdrawPage, setWithdrawPage] = useState<number>(
    contextDefaultValues.withdrawPage,
  )

  const changePage = (newPage: number) => setPage(newPage)

  const changeBoletoStep = (newStep: number) => setCreateBoletoStep(newStep)

  const changeInsuranceStep = (newStep: number) => setInsuranceStep(newStep)

  const changePixEnvironment = (newStep: number) => setPixEnvironment(newStep)

  const changePixPayment = (newStep: number) => setPixPayment(newStep)

  const changeTransferStep = (newStep: number) => setTransferStep(newStep)

  const changeNewContactTransferStep = (newStep: number) =>
    setNewContactTransferStep(newStep)

  useEffect(
    function () {
      // console.log(`page >`, page)
      // console.log(`page (pixEnvironment) >`, pixEnvironment)
      // console.log(`page (pixPayment) >`, pixPayment)
    },
    [page, boletoCreationStep, pixEnvironment, pixPayment, isScheduling],
  )

  const changeIsScheduling = (newValue: boolean) => setIsScheduling(newValue)

  const changeInsurancePage = (newPage: number) => setInsurancePage(newPage)

  const changeWithdrawPage = (newPage: number) => setWithdrawPage(newPage)

  return (
    <BankingNavigationContext.Provider
      value={{
        page,
        changePage,
        boletoCreationStep,
        changeBoletoStep,
        insuranceStep,
        changeInsuranceStep,
        pixEnvironment,
        changePixEnvironment,
        pixPayment,
        changePixPayment,
        transferStep,
        changeTransferStep,
        newContactTransferStep,
        changeNewContactTransferStep,
        isScheduling,
        changeIsScheduling,
        insurancePage,
        changeInsurancePage,
        withdrawPage,
        changeWithdrawPage,
      }}
    >
      {children}
    </BankingNavigationContext.Provider>
  )
}

export default BankingNavigationProvider
