import "./styles.scss"

interface ButtonProps {
  step: number
  title: string
}

const PixNav = ({ step, title }: ButtonProps) => {
  return (
    <div className="pix-payment-nav">
      <h1 className="pix-payment-nav-title">{title}</h1>
      {step === 0 ? (
        <></>
      ) : (
        <div className="pix-payment-nav-btns">
          <button
            className={
              `pix-payment-nav-btn ` +
              (step >= 1 ? `toggle-pix-payment-nav-btn` : ``)
            }
          >
            1
          </button>
          <button
            className={
              `pix-payment-nav-btn ` +
              (step >= 2 ? `toggle-pix-payment-nav-btn` : ``)
            }
          >
            2
          </button>
          <button
            className={
              `pix-payment-nav-btn ` +
              (step >= 3 ? `toggle-pix-payment-nav-btn` : ``)
            }
          >
            3
          </button>
        </div>
      )}
    </div>
  )
}

export default PixNav
