import "./styles.scss"

interface NavProps {
  step: number
  stepTitle: string
}
const TransferNav = ({ step, stepTitle }: NavProps) => {
  return (
    <div className="payment-nav">
      <h1 className="payment-nav-title">{stepTitle}</h1>
      <div className="payment-nav-buttons">
        {step >= 1 ? (
          <button className="steps-btn-active">1</button>
        ) : (
          <button className="steps-btn">1</button>
        )}
        {step >= 2 ? (
          <button className="steps-btn-active">2</button>
        ) : (
          <button className="steps-btn">2</button>
        )}
        {step >= 3 ? (
          <button className="steps-btn-active">3</button>
        ) : (
          <button className="steps-btn">3</button>
        )}
        {step >= 4 ? (
          <button className="steps-btn-active">4</button>
        ) : (
          <button className="steps-btn">4</button>
        )}
      </div>
    </div>
  )
}

export default TransferNav
