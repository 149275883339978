import { useContext, useState } from "react"
import "./styles.scss"
import { BankingNavigationContext } from "../../../../../../../context/BankingNavigationContext"
import SVGInject from "@iconfu/svg-inject"
import close from "../../../../../../../assets/images/clear_x.svg"
import cell from "../../../../../../../assets/images/cell.svg"
import userImg from "../../../../../../../assets/images/user.svg"
import mail from "../../../../../../../assets/images/mail_yellow.svg"
import random_key from "../../../../../../../assets/images/random_key.svg"
import copy_yellow from "../../../../../../../assets/images/copy_yellow.svg"
import { PrimaryButton } from "k4n-components"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import { buildCpfCnpjMask } from "../../../../../../../utils/utils"
import Favorites, { FavoriteScreenEnum } from "../../../../../Favorites"
import { useResposive } from "../../../../../../../hooks/useResponsive"

const PixPaymentStep1Mobile = ({
  onSubmit,
  pixKeyType,
  setPixKeyType,
  btnLoading,
  errorPixKey,
  favorites,
  loadingFavorites,
  onSubmitFavorite,
}) => {
  const { changePixEnvironment, changePixPayment } = useContext(
    BankingNavigationContext,
  )
  const [cpfCnpjMask, setCpfCnpjMask] = useState(``)
  const { isTabletOrMobile } = useResposive()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({})
  const handlePageChange = (newPage: number) => {
    changePixEnvironment(newPage)
    changePixPayment(1)
  }

  function handleCpfCnpjChange(e: any) {
    let value = e.currentTarget.value
    value = buildCpfCnpjMask(value)
    setCpfCnpjMask(value)
  }

  return (
    <div className="pix-payment-step1-mobile">
      <span
        className="pix-receipt-img-close"
        onClick={() => handlePageChange(1)}
      >
        <img
          src={close}
          width="24"
          height="24"
          alt="/"
          onLoad={(e) => SVGInject(e.target)}
        />
      </span>
      <h1 className="pix-payment-step1-h1-mobile">Escolha a chave</h1>
      <div className="pix-payment-step1-keys-mobile">
        <button
          className={
            `pix-payment-step1-key-mobile ` +
            (pixKeyType === 2 ? `pix-key-active-mobile` : ``)
          }
          onClick={() => setPixKeyType(2)}
        >
          <img
            className="pix-payment-step1-key-img-mobile"
            src={cell}
            alt="/"
            onLoad={(e) => SVGInject(e.target)}
          />
          Celular
        </button>
        <button
          className={
            `pix-payment-step1-key-mobile ` +
            (pixKeyType === 0 ? `pix-key-active-mobile` : ``)
          }
          onClick={() => setPixKeyType(0)}
        >
          <img
            className="pix-payment-step1-key-img-mobile"
            src={userImg}
            alt="/"
            onLoad={(e) => SVGInject(e.target)}
          />
          CPF/CNPJ
        </button>
        <button
          className={
            `pix-payment-step1-key-mobile ` +
            (pixKeyType === 3 ? `pix-key-active-mobile` : ``)
          }
          onClick={() => setPixKeyType(3)}
        >
          <img
            className="pix-payment-step1-key-img-mobile"
            src={mail}
            alt="/"
            onLoad={(e) => SVGInject(e.target)}
          />
          E-mail
        </button>
        <button
          className={
            `pix-payment-step1-key-mobile ` +
            (pixKeyType === 5 ? `pix-key-active-mobile` : ``)
          }
          onClick={() => setPixKeyType(5)}
        >
          <img
            className="pix-payment-step1-key-img-mobile"
            src={copy_yellow}
            alt="/"
            onLoad={(e) => SVGInject(e.target)}
          />
          Copia e Cola
        </button>
        <button
          className={
            `pix-payment-step1-key-mobile ` +
            (pixKeyType === 4 ? `pix-key-active-mobile` : ``)
          }
          onClick={() => setPixKeyType(4)}
        >
          <img
            className="pix-payment-step1-key-img-mobile"
            src={random_key}
            alt="/"
            onLoad={(e) => SVGInject(e.target)}
          />
          Chave aleatória
        </button>
      </div>
      <h1 className="pix-payment-step1-h1-mobile margin-0">Digite a chave</h1>
      <form
        className="pix-payment-step1-form-mobile"
        onSubmit={handleSubmit(onSubmit)}
      >
        {pixKeyType === 0 && (
          <>
            <input
              value={cpfCnpjMask}
              type="text"
              maxLength={18}
              className="pix-payment-step1-input-mobile"
              {...register(`cpf`, {
                onChange: handleCpfCnpjChange,
                required: true,
                pattern:
                  /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/,
              })}
            />
            {errors.cpf && (
              <p className="pix-error-msg-mobile">
                Por favor, verifique o CPF ou CNPJ.
              </p>
            )}
            {errorPixKey && !errors.cpf && (
              <p className="pix-error-msg-mobile">{errorPixKey}</p>
            )}
          </>
        )}
        {pixKeyType === 2 && (
          <>
            <div className="pix-payment-input-phone-mobile">
              <select
                className="pix-payment-step1-input-mobile width-109-mobile"
                {...register(`ddd`, { required: true })}
              >
                <option value="+55">+55</option>
              </select>
              <InputMask
                mask="(99) 99999-9999"
                maskPlaceholder={null}
                type="text"
                className="pix-payment-step1-input-mobile width-187-mobile"
                {...register(`phone`, {
                  required: true,
                  pattern: /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/,
                })}
              />
            </div>
            {errors.phone && (
              <p className="pix-error-msg-mobile">
                Por favor, verifique o celular.
              </p>
            )}
            {errorPixKey && !errors.phone && (
              <p className="pix-error-msg-mobile">{errorPixKey}</p>
            )}
          </>
        )}

        {pixKeyType === 3 && (
          <>
            <input
              type="text"
              maxLength={300}
              className="pix-payment-step1-input-mobile"
              {...register(`email`, {
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            {errors.email && (
              <p className="pix-error-msg-mobile">
                Por favor, verifique o email.
              </p>
            )}
            {errorPixKey && !errors.email && (
              <p className="pix-error-msg-mobile">{errorPixKey}</p>
            )}
          </>
        )}
        {pixKeyType === 4 && (
          <>
            <input
              type="text"
              className="pix-payment-step1-input-mobile"
              {...register(`evp`, {
                required: true,
              })}
            />
            {errors.evp && (
              <p className="pix-error-msg-mobile">
                Por favor, verifique a chave.
              </p>
            )}
            {errorPixKey && !errors.evp && (
              <p className="pix-error-msg-mobile">{errorPixKey}</p>
            )}
          </>
        )}
        {pixKeyType === 5 && (
          <>
            <input
              type="text"
              className="pix-payment-step1-input-mobile"
              {...register(`copyPasteCode`, {
                required: true,
              })}
            />
            {errors.copyPasteCode && (
              <p className="pix-error-msg-mobile">
                Por favor, verifique o código.
              </p>
            )}
            {errorPixKey && !errors.copyPasteCode && (
              <p className="pix-error-msg-mobile">{errorPixKey}</p>
            )}
          </>
        )}
        <div className="pix-payment-step1-btn-mobile">
          <PrimaryButton width={`312px`} height={`56px`} onClick={() => true}>
            {!btnLoading ? (
              `Próximo`
            ) : (
              <div className="spinner-border spinner-pix" role="status"></div>
            )}
          </PrimaryButton>
        </div>
        {!loadingFavorites ? (
          <Favorites
            favorites={favorites}
            title={`Pagar para um contato`}
            screen={FavoriteScreenEnum.PIX}
            changeStep={() => true}
            addData={onSubmitFavorite}
            isMobile={isTabletOrMobile}
          />
        ) : (
          <h6
            style={{
              color: `black`,
              marginTop: `50px`,
              textAlign: `center`,
            }}
          >
            Carregando favoritos...
          </h6>
        )}
      </form>
    </div>
  )
}
export default PixPaymentStep1Mobile
