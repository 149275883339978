import { useContext, useEffect, useRef, useState } from "react"
import { useResposive } from "../../../../../../hooks/useResponsive"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { InsuranceContext } from "../../../../../../context/InsuranceContext"
import { SecondaryButton } from "k4n-components"
import InsuranceBoletoPayment from "./boleto"
import InsuranceCardPayment from "./card"
import "./styles.scss"

export const InsuranceStep4 = () => {
  const [bestPaymentDate, setBestPaymentDate] = useState(15)
  const [payment, setPayment] = useState(0)
  const { changeInsuranceStep } = useContext(BankingNavigationContext)
  const {
    addPaymentDay,
    addFormOfPayment,
    formOfPayment,
    paymentDay,
    hasBeneficiary,
  } = useContext(InsuranceContext)
  const { isTabletOrMobile } = useResposive()
  useEffect(
    function () {
      if (paymentDay !== 0) setBestPaymentDate(paymentDay)
      if (formOfPayment !== 0) setPayment(formOfPayment)
    },
    [paymentDay, formOfPayment],
  )
  const ref = useRef<HTMLInputElement | null>(null)

  const handlePaymentDateChange = (newDate: number) => {
    setBestPaymentDate(newDate)
    addPaymentDay(newDate)
  }

  const handleFormOfPaymentChange = (newForm: number) => {
    setPayment(newForm)
    addFormOfPayment(newForm)
  }

  const handleClickScroll = () => {
    ref.current?.scrollIntoView({ behavior: `smooth` })
  }

  useEffect(() => {
    handleClickScroll()
  }, [])

  return (
    <div className="insurance-step-3">
      <div className="row insurance-content-step-3">
        <div className="insurance-payment">
          <div
            className={
              isTabletOrMobile
                ? `col insurance-payment`
                : `row insurance-payment`
            }
          >
            <div className="col-12" style={{ padding: `0` }}>
              <div className={isTabletOrMobile ? `col` : `row`}>
                <div
                  className={isTabletOrMobile ? `col` : `col-7`}
                  style={{ paddingRight: `11px`, paddingLeft: `0` }}
                >
                  <div className="insurance-form-of-payment">
                    <h1 className="insurance-form-of-payment-h1">
                      Escolha a melhor forma de pagamento
                    </h1>
                    <div className="row">
                      <div className="d-grid gap-2 d-md-block">
                        <button
                          onClick={() => handleFormOfPaymentChange(0)}
                          className={
                            `Mg-top-24 Forma-Pagamento-Btn cartao ` +
                            (payment === 0 ? `insurance-step-3-tgl-active` : ``)
                          }
                        >
                          Cartão de crédito
                        </button>
                        {/* <button onClick={() => handleFormOfPaymentChange(1)} className={"Mg-top-24 Forma-Pagamento-Btn " + (payment === 1 ? 'insurance-step-4-tgl-active' : '')}
                                                                >Pix</button> */}
                      </div>
                    </div>
                    <div>
                      <div className="d-grid gap-2 d-md-block">
                        <button
                          onClick={() => handleFormOfPaymentChange(2)}
                          className={
                            `Mg-top-16 Forma-Pagamento-Btn boleto ` +
                            (payment === 2 ? `insurance-step-3-tgl-active` : ``)
                          }
                        >
                          Boleto
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={isTabletOrMobile ? `col` : `col-5`}
                  style={{ paddingRight: `0`, paddingLeft: `11px` }}
                >
                  <div className="insurance-payment-day">
                    <h1 className="insurance-payment-day-h1">
                      Qual a melhor data para pagamento?
                    </h1>
                    <div className="row">
                      <div className="center-div d-grid gap-2 d-md-block">
                        <button
                          onClick={() => handlePaymentDateChange(10)}
                          className={
                            `insurance-payment-day-btn ` +
                            (bestPaymentDate === 10
                              ? `insurance-step-3-tgl-active`
                              : ``)
                          }
                        >
                          10
                        </button>
                        <button
                          onClick={() => handlePaymentDateChange(15)}
                          className={
                            `insurance-payment-day-btn ` +
                            (bestPaymentDate === 15
                              ? `insurance-step-3-tgl-active`
                              : ``)
                          }
                        >
                          15
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="center-div d-grid gap-2 d-md-block">
                        <button
                          onClick={() => handlePaymentDateChange(20)}
                          className={
                            `insurance-payment-day-btn ` +
                            (bestPaymentDate === 20
                              ? `insurance-step-3-tgl-active`
                              : ``)
                          }
                        >
                          20
                        </button>
                        <button
                          onClick={() => handlePaymentDateChange(25)}
                          className={
                            `insurance-payment-day-btn ` +
                            (bestPaymentDate === 25
                              ? `insurance-step-3-tgl-active`
                              : ``)
                          }
                        >
                          25
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row insurance-payment-row">
            {payment === 0 && <InsuranceCardPayment />}
            {payment === 2 && <InsuranceBoletoPayment />}
          </div>
        </div>
        <div className="insurance-back">
          <SecondaryButton
            width={isTabletOrMobile ? `100px` : `170px`}
            height={`60px`}
            text={`Cancelar`}
            OnClick={() => changeInsuranceStep(1)}
          />
        </div>
      </div>
    </div>
  )
}
