import dayjs from "dayjs"
import { useContext } from "react"
import { Controller, useForm } from "react-hook-form"
import { BankingNavigationContext } from "../../../../../../../context/BankingNavigationContext"
import { CurrencyInput, PrimaryButton, SecondaryButton } from "k4n-components"
import "./styles.scss"
import InputMask from "react-input-mask"
import DatePicker from "react-datepicker"

const BoletoCreationStep2Mobile = ({
  onSubmit,
  validateCurrency,
  onValueChange,
  validateReduction,
  validateDueDate,
  onDueDateChange,
  BiggerThanDueDate,
  onDeadlineChange,
  handleFocus,
  handleSelectFocusEnd,
  onSelectChange,
  fee,
  validateFeeValue,
  onValuePctChange,
  fine,
  validateFineValue,
  discount,
  validateDiscountValue,
  validateDiscountDate,
  handleCancel,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bc-step-mobile-content"
      >
        <div className="bc-step-mobile">
          <button
            className="payment-mobile-close-btn"
            onClick={handleCancel}
          ></button>
          <div className="bc-title-div">
            <h1 className="bc-header-h1-mobile">
              Dados da cobrança e identificação
            </h1>
          </div>
          <div className="boleto-box-mobile">
            <label
              className={`boleto-creation-txt-mobile show-input`}
              htmlFor="nominalValue"
            >
              Valor nominal
            </label>
            <div className="bc-value-mobile">
              <label
                className={`boleto-creation-input-mobile show-input`}
                htmlFor="nominalValue"
              >
                R$
              </label>
              <Controller
                name="nominalValue"
                control={control}
                defaultValue="0,00"
                rules={{
                  required: true,
                  validate: validateCurrency,
                  onChange: onValueChange,
                }}
                render={({ field }) => (
                  <CurrencyInput
                    value={field.value}
                    options={{ style: `decimal`, allowNegative: false }}
                    onChangeEvent={(_, maskedValue) => {
                      field.onChange(maskedValue)
                    }}
                    required={true}
                    className="boleto-creation-input-mobile"
                  />
                )}
              />
            </div>

            {errors.nominalValue && (
              <span className="bc-error-msg-mobile">
                Por favor, verifique o valor.
              </span>
            )}
          </div>
          <div className="boleto-box-mobile">
            <label
              className={`boleto-creation-txt-mobile show-input`}
              htmlFor="reduction"
            >
              Abatimento
            </label>
            <div className="-value-mobile"></div>
            <div className="bc-value-mobile">
              <label
                className={`boleto-creation-input-mobile show-input`}
                htmlFor="reduction"
              >
                R$
              </label>
              <Controller
                name="reduction"
                control={control}
                defaultValue="0,00"
                rules={{
                  required: false,
                  validate: validateReduction,
                  onChange: onValueChange,
                }}
                render={({ field }) => (
                  <CurrencyInput
                    value={field.value}
                    options={{ style: `decimal`, allowNegative: false }}
                    onChangeEvent={(_, maskedValue) => {
                      field.onChange(maskedValue)
                    }}
                    className="boleto-creation-input-mobile"
                  />
                )}
              />
            </div>

            {errors.reduction && (
              <span className="bc-error-msg-mobile">
                Por favor, verifique o valor.
              </span>
            )}
          </div>
          <div className="boleto-box-mobile">
            <label
              className={`boleto-creation-txt-mobile show-input`}
              htmlFor="reduction"
            >
              Nº Documento
            </label>

            <input
              type="text"
              placeholder="Nº Documento"
              maxLength={10}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              className="boleto-creation-input-mobile"
              {...register(`numDoc`, {
                required: true,
                pattern: /^[0-9]+$/,
              })}
            />

            {errors.numDoc && (
              <span className="bc-error-msg-mobile">
                Por favor, preencha com números.
              </span>
            )}
          </div>
          <div className="bc-title-div">
            <h1 className="bc-header-h1-mobile">Datas</h1>
          </div>
          <div className="boleto-box-mobile">
            <span className="bc-step-2-issue-date-txt">Data de emissão</span>
            <span className="bc-input-value-txt">
              {dayjs().format(`DD/MM/YYYY`)}
            </span>
          </div>
          <div className="boleto-box-mobile">
            <label
              className={`boleto-creation-txt-mobile show-input`}
              htmlFor="dueDate"
            >
              Data do vencimento
            </label>
            <Controller
              control={control}
              name="dueDate"
              rules={{
                required: true,
                validate: validateDueDate,
                onChange: onDueDateChange,
              }}
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data de vencimento"
                  className="boleto-creation-input-mobile bc-step-1-select-mobile-due-date"
                  id="dueDate"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={
                    <InputMask mask="99/99/9999" maskPlaceholder={null} />
                  }
                />
              )}
            />
            {errors.dueDate && errors.dueDate?.type === `required` && (
              <span className="bc-error-msg-mobile">
                Por favor, verifique a data informada.
              </span>
            )}

            {errors.dueDate && (
              <>
                {errors.dueDate?.type !== `required` && (
                  <span className="bc-error-msg-mobile">
                    Deve ser maior que a data de hoje
                  </span>
                )}
              </>
            )}
          </div>
          <div className="boleto-box-mobile">
            <label
              className={`boleto-creation-txt-mobile show-input`}
              htmlFor="deadline"
            >
              Data limite para pagamento
            </label>
            <Controller
              control={control}
              name="deadline"
              rules={{
                required: true,
                validate: BiggerThanDueDate,
                onChange: onDeadlineChange,
              }}
              render={({ field }) => (
                <DatePicker
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data limite para pagamento"
                  className="boleto-creation-input-mobile bc-step-1-select-mobile-deadline"
                  id="deadline"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={
                    <InputMask mask="99/99/9999" maskPlaceholder={null} />
                  }
                />
              )}
            />
            {errors.deadline && errors.deadline?.type === `required` && (
              <span className="bc-error-msg-mobile">
                Por favor, verifique a data informada.
              </span>
            )}

            {errors.deadline && (
              <>
                {errors.deadline?.type !== `required` && (
                  <span className="bc-error-msg-mobile">
                    Deve ser maior que a data de vencimento
                  </span>
                )}
              </>
            )}
          </div>
          <div className="bc-title-div">
            <h1 className="bc-header-h1-mobile">Juros e multa</h1>
          </div>
          <div className="boleto-box-mobile">
            <label
              className={`boleto-creation-txt-mobile show-input`}
              htmlFor="fee"
            >
              Juros
            </label>
            <select
              className="bc-step-1-select-mobile bc-input-value-txt"
              id="fee"
              onFocus={handleFocus}
              {...register(`fee`, {
                required: true,
                onBlur: handleSelectFocusEnd,
                onChange: onSelectChange,
              })}
            >
              <option value="5" selected>
                Isento
              </option>
              <option value="1">Valor (dias corridos)</option>
              <option value="3">Percentual ao mês (dias corridos)</option>
              <option value="6">Valor (dias úteis)</option>
              <option value="8">Percentual ao mês (dias úteis)</option>
            </select>
            {fee !== `5` && (
              <>
                {fee !== `3` && fee !== `8` ? (
                  <>
                    <label
                      className={`boleto-creation-txt-mobile show-input`}
                      htmlFor="feeValue"
                    >
                      Valor
                    </label>
                    <div className="bc-value-mobile">
                      <label
                        className={`boleto-creation-input-mobile show-input`}
                        htmlFor="feeValue"
                      >
                        R$
                      </label>
                      <Controller
                        name="feeValue"
                        control={control}
                        defaultValue="0,00"
                        rules={{
                          required: true,
                          validate: validateFeeValue,
                          onChange: onValueChange,
                        }}
                        render={({ field }) => (
                          <CurrencyInput
                            value={field.value}
                            options={{
                              style: `decimal`,
                              allowNegative: false,
                            }}
                            onChangeEvent={(_, maskedValue) => {
                              field.onChange(maskedValue)
                            }}
                            required={true}
                            className={
                              errors.feeValue
                                ? `boleto-creation-input-mobile bc-step-2-fee-value`
                                : `boleto-creation-input-mobile bc-step-2-fee-value`
                            }
                          />
                        )}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <label
                      className={`boleto-creation-txt-mobile show-input`}
                      htmlFor="feeValue"
                    >
                      Valor percentual %
                    </label>
                    <input
                      type="number"
                      step="0.01"
                      maxLength={3}
                      placeholder="0.00"
                      className={
                        errors.feeValue
                          ? `boleto-creation-input-mobile`
                          : `boleto-creation-input-mobile`
                      }
                      onInput={(event) => {
                        if (parseFloat(event.currentTarget.value) > 100)
                          event.currentTarget.value =
                            event.currentTarget.value.slice(0, 2)
                        else if (event.currentTarget.value.length > 5)
                          event.currentTarget.value =
                            event.currentTarget.value.slice(0, 5)
                      }}
                      {...register(`feeValue` as const, {
                        required: true,
                        min: 0,
                        max: 100,
                        maxLength: 5,
                        onChange: onValuePctChange,
                        validate: validateFeeValue,
                      })}
                    />
                  </>
                )}

                {errors.feeValue && (
                  <span className="bc-error-msg-mobile">
                    Por favor, verifique o valor.
                  </span>
                )}
              </>
            )}
          </div>
          <div className="boleto-box-mobile">
            <label
              className={`boleto-creation-txt-mobile show-input`}
              htmlFor="fine"
            >
              Multa
            </label>
            <select
              className="bc-step-1-select-mobile bc-input-value-txt"
              id="fine"
              onFocus={handleFocus}
              {...register(`fine`, {
                required: true,
                onBlur: handleSelectFocusEnd,
                onChange: onSelectChange,
              })}
            >
              <option value="3" selected>
                Isento
              </option>
              <option value="2">Percentual</option>
              <option value="1">Valor fixo</option>
            </select>

            {fine !== `3` && (
              <>
                {fine === `1` ? (
                  <>
                    <label
                      className={`boleto-creation-txt-mobile show-input`}
                      htmlFor="fineValue"
                    >
                      Valor
                    </label>
                    <div className="bc-value-mobile">
                      <label
                        className={`boleto-creation-input-mobile show-input`}
                        htmlFor="fineValue"
                      >
                        R$
                      </label>
                      <Controller
                        name="fineValue"
                        control={control}
                        defaultValue="0,00"
                        rules={{
                          required: true,
                          validate: validateFineValue,
                          onChange: onValueChange,
                        }}
                        render={({ field }) => (
                          <CurrencyInput
                            value={field.value}
                            options={{
                              style: `decimal`,
                              allowNegative: false,
                            }}
                            onChangeEvent={(_, maskedValue) => {
                              field.onChange(maskedValue)
                            }}
                            required={true}
                            className={
                              errors.fineValue
                                ? `boleto-creation-input-mobile bc-step-2-fee-value`
                                : `boleto-creation-input-mobile bc-step-2-fee-value`
                            }
                          />
                        )}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <label
                      className={`boleto-creation-txt-mobile show-input`}
                      htmlFor="fineValue"
                    >
                      Valor percentual %
                    </label>

                    <input
                      type="number"
                      step="0.01"
                      maxLength={3}
                      placeholder="0.00"
                      className={
                        errors.feeValue
                          ? `boleto-creation-input-mobile`
                          : `boleto-creation-input-mobile`
                      }
                      onInput={(event) => {
                        if (parseFloat(event.currentTarget.value) > 100)
                          event.currentTarget.value =
                            event.currentTarget.value.slice(0, 2)
                        else if (event.currentTarget.value.length > 5)
                          event.currentTarget.value =
                            event.currentTarget.value.slice(0, 5)
                      }}
                      {...register(`fineValue` as const, {
                        required: true,
                        min: 0,
                        max: 100,
                        maxLength: 5,
                        onChange: onValuePctChange,
                        validate: validateFineValue,
                      })}
                    />
                  </>
                )}

                {errors.fineValue && (
                  <span className="bc-error-msg-mobile">
                    Por favor, verifique o valor.
                  </span>
                )}
              </>
            )}
          </div>
          <div className="bc-title-div">
            <h1 className="bc-header-h1-mobile">Desconto</h1>
          </div>
          <div className="boleto-box-mobile">
            <label
              className={`boleto-creation-txt-mobile show-input`}
              htmlFor="discount"
            >
              Desconto
            </label>
            <select
              className="bc-step-1-select-mobile bc-step-1-select-mobile-discount bc-input-value-txt"
              id="discount"
              onFocus={handleFocus}
              {...register(`discount`, {
                required: true,
                onBlur: handleSelectFocusEnd,
                onChange: onSelectChange,
              })}
            >
              <option value="0" selected>
                Isento
              </option>
              <option value="1">Valor fixo até a data informada</option>
              <option value="2">Percentual até a data informada</option>
              <option value="3">Valor por antecipação dia corrido</option>
              <option value="5">Percentual por antecipação dia corrido</option>
            </select>
            {discount !== `0` && (
              <>
                {discount === `1` || discount === `3` ? (
                  <>
                    <label
                      className={`boleto-creation-txt-mobile show-input`}
                      htmlFor="discountValue"
                    >
                      Valor
                    </label>
                    <div className="bc-value-mobile">
                      <label
                        className={`boleto-creation-input-mobile show-input`}
                        htmlFor="discountValue"
                      >
                        R$
                      </label>
                      <Controller
                        name="discountValue"
                        control={control}
                        defaultValue="0,00"
                        rules={{
                          required: true,
                          validate: validateDiscountValue,
                        }}
                        render={({ field }) => (
                          <CurrencyInput
                            value={field.value}
                            options={{
                              style: `decimal`,
                              allowNegative: false,
                            }}
                            onChangeEvent={(_, maskedValue) => {
                              field.onChange(maskedValue)
                            }}
                            required={true}
                            className={
                              errors.discountValue
                                ? `boleto-creation-input-mobile bc-step-2-discount-value`
                                : `boleto-creation-input-mobile bc-step-2-discount-value`
                            }
                          />
                        )}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <label
                      className={`boleto-creation-txt-mobile show-input`}
                      htmlFor="discountValue"
                    >
                      Valor percentual %
                    </label>
                    <input
                      type="number"
                      step="0.01"
                      maxLength={3}
                      placeholder="0.00"
                      className={
                        errors.discountValue
                          ? `boleto-creation-input-mobile pl-24 bc-step-2-discount-value`
                          : `boleto-creation-input-mobile pl-24 bc-step-2-discount-value`
                      }
                      onInput={(event) => {
                        if (parseFloat(event.currentTarget.value) > 100)
                          event.currentTarget.value =
                            event.currentTarget.value.slice(0, 2)
                        else if (event.currentTarget.value.length > 5)
                          event.currentTarget.value =
                            event.currentTarget.value.slice(0, 5)
                      }}
                      {...register(`discountValue` as const, {
                        required: true,
                        min: 0,
                        max: 100,
                        maxLength: 5,
                        validate: validateDiscountValue,
                      })}
                    />
                  </>
                )}

                {errors.discountValue && (
                  <span className="bc-error-msg-mobile">
                    Por favor, verifique o valor.
                  </span>
                )}

                <label
                  className={`boleto-creation-txt-mobile pt-10show-input`}
                  htmlFor="discountDate"
                >
                  Data final do desconto
                </label>
                <Controller
                  control={control}
                  name="discountDate"
                  rules={{ required: true, validate: validateDiscountDate }}
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      locale="pt-BR"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Data"
                      className="boleto-creation-input-mobile bc-step-2-discount-date"
                      id="discountDate"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={
                        <InputMask mask="99/99/9999" maskPlaceholder={null} />
                      }
                    />
                  )}
                />

                {errors.discountDate && (
                  <>
                    {errors.discountDate?.type === `required` && (
                      <>
                        <span className="bc-error-msg-mobile">
                          Por favor, verifique a data informada.
                        </span>
                      </>
                    )}
                  </>
                )}

                {errors.discountDate && (
                  <>
                    {errors.discountDate?.type !== `required` && (
                      <span className="bc-error-msg-mobile">
                        Deve ser menor que a data de vencimento e maior que a
                        data de hoje.
                      </span>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="boleto-btn-div">
            <SecondaryButton
              width={`30%`}
              height={`50px`}
              OnClick={handleCancel}
              text="Cancelar"
            />

            <PrimaryButton width={`50%`} height={`50px`}>
              Próximo
            </PrimaryButton>
          </div>
        </div>
      </form>
    </>
  )
}

export default BoletoCreationStep2Mobile
