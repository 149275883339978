import { useContext, useState } from "react"
import "./styles.scss"
import { AxiosError } from "axios"
import { AuthContext } from "../../../../../context/AuthProvider"
import { PixKeyType } from "../../../../../context/PixStorageProvider"
import { useResposive } from "../../../../../hooks/useResponsive"
import PixRegisterMobile from "./Mobile"
import services from "../../../../../services/svcmesh"
import { Account, ConsultPixKey, CreatePixKey } from "k4n-svcmesh-sdk"

const PixRegister = () => {
  const [pixKeyType, setPixKeyType] = useState(5)
  const [showRegister, setShowRegister] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [errorRegister, setErrorRegister] = useState(String)
  const [btnLoading, setBtnLoading] = useState(false)
  const [showModalError, setShowModalError] = useState(false)
  const { user, current_account } = useContext(AuthContext)
  const { isTabletOrMobile } = useResposive()

  const defaultValuesPix = {
    phone: `+55` + user?.phones[0].phone,
    email: user?.emails[0].email,
    cpf: user?.cpf,
    cnpj: ``,
    random_key: ``,
  }

  const consultPix = async (chave: any) => {
    if (pixKeyType !== 4) {
      try {
        let accountLength = 0
        let contaPgt: string | undefined
        if (current_account?.conta && current_account?.contaDigito) {
          accountLength = (
            current_account?.conta + current_account?.contaDigito
          ).length
        }
        if (accountLength == 5)
          contaPgt =
            `00` + current_account?.conta + current_account?.contaDigito
        if (accountLength == 6)
          contaPgt = `0` + current_account?.conta + current_account?.contaDigito
        if (accountLength == 7)
          contaPgt = `` + current_account?.conta + current_account?.contaDigito

        const sendObj = {
          Chave: chave,
          ContaDtoAgencia: current_account?.agencia,
          ContaDtoAgenciaDigito: current_account?.agenciaDigito,
          ContaDtoConta: current_account?.conta,
          ContaDtoContaDigito: current_account?.contaDigito,
          ContaDtoContaPgto: contaPgt,
          ContaDtoTipoConta: current_account?.tipoConta,
        } as ConsultPixKey
        const res = await services.bankingService.consultPixKey(sendObj)
        console.log(res.data)
        if (res.data.sucesso === true) {
          return `Cadastrada`
        }
      } catch (error: any) {
        const err = error as AxiosError<any>
        if (err.response?.data.message === `A chave PIX não foi encontrada.`) {
          return `Nao cadastrada`
        } else {
          setBtnLoading(false)
          setErrorRegister(err.response?.data.message)
          setShowModalError(true)
        }
      }
    } else return `Nao cadastrada`
  }

  const handleCreatePixKey = async (key: string) => {
    setBtnLoading(true)
    try {
      const resConsult = await consultPix(key)
      console.log(resConsult)
      if (resConsult === `Nao cadastrada`) {
        const account = {
          agencia: current_account?.agencia,
          agenciaDigito: current_account?.agencia,
          conta: current_account?.conta,
          contaDigito: current_account?.contaDigito,
          contaPgto: current_account?.contaPgto,
          tipoConta: current_account?.tipoConta,
        } as Account
        const sendObj = {
          contaDto: account,
          tipoChave: null,
          chave: key,
        } as unknown as CreatePixKey
        if (pixKeyType === PixKeyType.CPF) {
          sendObj.tipoChave = 0
          sendObj.chave = ``
        }
        if (pixKeyType === PixKeyType.CELULAR) sendObj.tipoChave = 2
        if (pixKeyType === PixKeyType.EMAIL) sendObj.tipoChave = 3
        if (pixKeyType === PixKeyType.EVP) sendObj.tipoChave = 4
        console.log(sendObj)
        const res = await services.bankingService.createPixKey(sendObj)
        console.log(res)
        if (res.data.sucesso === true) {
          setBtnLoading(false)
          setShowModal(true)
        }
      }
      if (resConsult === `Cadastrada`) {
        setErrorRegister(`Essa chave PIX já foi cadastrada!`)
        setBtnLoading(false)
        setShowModalError(true)
      }
    } catch (error: any) {
      const err = error as AxiosError<any>
      setBtnLoading(false)
      setErrorRegister(err.response?.data.message)
      setShowModalError(true)
    }
  }

  return (
    <>
      {!isTabletOrMobile ? (
        <></>
      ) : (
        <PixRegisterMobile
          showRegister={showRegister}
          setShowRegister={setShowRegister}
          pixKeyType={pixKeyType}
          setPixKeyType={setPixKeyType}
          defaultValuesPix={defaultValuesPix}
          handleCreatePixKey={handleCreatePixKey}
          btnLoading={btnLoading}
          showModal={showModal}
          showModalError={showModalError}
          errorRegister={errorRegister}
        />
      )}
    </>
  )
}
export default PixRegister
