import { AxiosError } from "axios"
import { ConsultPixKey, ReadQRCode, TipoPagamentoPix } from "k4n-svcmesh-sdk"
import { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { AuthContext } from "../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import { PixStorageContext } from "../../../../../context/PixStorageProvider"
import { useResposive } from "../../../../../hooks/useResponsive"
import services from "../../../../../services/svcmesh"
import { Step1PixCodePaste } from "../../../../../typings/step1-pix-copy-paste.dto"
import { Step1PixPayment } from "../../../../../typings/step1-pix-payment.dto"
import { SecondaryButton, PrimaryButton } from "k4n-components"
import PixNav from "../nav-pix"
import PixCopyPasteMobile from "./Mobile"
import "./styles.scss"

const PixCopyPaste = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({})
  const { changePixPayment, changePixEnvironment } = useContext(
    BankingNavigationContext,
  )
  const {
    addPixCopyPasteStep1,
    addPixPaymentType,
    addPixPaymentStep1,
    addPixPaymentStep2,
  } = useContext(PixStorageContext)
  const [errorMessage, setErrorMessage] = useState(String)

  const handlePixEnvironmentChange = (newPage: number) => {
    changePixEnvironment(newPage)
  }
  const { current_account } = useContext(AuthContext)
  const [btnLoading, setBtnLoading] = useState(false)
  const { isTabletOrMobile } = useResposive()

  const getReadQRCode = async (data: any) => {
    setBtnLoading(true)
    let accountLength = 0
    let contaPgt: string | undefined
    if (current_account?.conta && current_account?.contaDigito) {
      accountLength = (current_account?.conta + current_account?.contaDigito)
        .length
    }
    if (accountLength == 5)
      contaPgt = `00` + current_account?.conta + current_account?.contaDigito
    if (accountLength == 6)
      contaPgt = `0` + current_account?.conta + current_account?.contaDigito
    if (accountLength == 7)
      contaPgt = `` + current_account?.conta + current_account?.contaDigito

    const sendObj = {
      ContaDtoAgencia: current_account?.agencia,
      ContaDtoAgenciaDigito: current_account?.agenciaDigito,
      ContaDtoConta: current_account?.conta,
      ContaDtoContaDigito: current_account?.contaDigito,
      ContaDtoContaPgto: contaPgt,
      ContaDtoTipoConta: current_account?.tipoConta,
      EMV: data.pixCodeCopyPaste,
    } as ReadQRCode
    try {
      const res = await services.bankingService.readQRCode(sendObj)
      if (res.data.sucesso === true) {
        const saveObj = {
          chave: res.data.chave,
          valor: res.data.valor,
          idConciliacaoRecebedor: res.data.idConciliacaoRecebedor,
          tpQRCode: res.data.tpQRCode,
          codigoLeituraQRCode: res.data.codigoLeituraQRCode,
        } as Step1PixCodePaste
        consultPix(saveObj.chave)
        console.log(saveObj)
        setBtnLoading(false)
        addPixCopyPasteStep1(saveObj)
        addPixPaymentType(TipoPagamentoPix.PIX_COPYPASTE)
        changePixEnvironment(2)
        changePixPayment(2)
      }
    } catch (error: any) {
      const err = error as AxiosError<any>
      setErrorMessage(err.response?.data.message)
      setBtnLoading(false)
    }
  }

  const consultPix = async (chave: any) => {
    setBtnLoading(true)
    try {
      let accountLength = 0
      let contaPgt: string | undefined
      if (current_account?.conta && current_account?.contaDigito) {
        accountLength = (current_account?.conta + current_account?.contaDigito)
          .length
      }
      if (accountLength == 5)
        contaPgt = `00` + current_account?.conta + current_account?.contaDigito
      if (accountLength == 6)
        contaPgt = `0` + current_account?.conta + current_account?.contaDigito
      if (accountLength == 7)
        contaPgt = `` + current_account?.conta + current_account?.contaDigito

      const sendObj = {
        Chave: chave,
        ContaDtoAgencia: current_account?.agencia,
        ContaDtoAgenciaDigito: current_account?.agenciaDigito,
        ContaDtoConta: current_account?.conta,
        ContaDtoContaDigito: current_account?.contaDigito,
        ContaDtoContaPgto: contaPgt,
        ContaDtoTipoConta: current_account?.tipoConta,
      } as ConsultPixKey
      const res = await services.bankingService.consultPixKey(sendObj)
      if (res.data.sucesso === true) {
        const saveObj = {
          nome: res.data.nomeCorrentista,
          chave: res.data.chave,
          tipoChave: res.data.tipoChave,
          cpf: res.data.documentoFederal,
          numeroBanco: res.data.banco.descricao,
          agencia: res.data.conta.agencia,
          conta: res.data.conta.conta,
          valor: 0,
          message: ``,
          ticket: res.data.ticket,
        } as Step1PixPayment
        addPixPaymentStep1(saveObj)
        addPixPaymentStep2(saveObj)
        changePixPayment(2)
      }
    } catch (error: any) {
      const err = error as AxiosError<any>
      setBtnLoading(false)
    }
  }

  return (
    <>
      {!isTabletOrMobile ? (
        <div className="pix-copy-paste-container">
          <PixNav step={1} title={`Pix Copia e Cola`} />
          <div className="pix-copy-paste-content">
            <div className="pix-copy-paste-title">
              <h1 className="pix-copy-paste-h1">Insira o código a ser pago</h1>
              <h1 className="pix-copy-paste-txt">
                Pague digitando ou colando o código de quem vai receber.
              </h1>
            </div>
            <form onSubmit={handleSubmit(getReadQRCode)}>
              <input
                type="text"
                className="pix-copy-paste-input"
                {...register(`pixCodeCopyPaste`, {
                  required: true,
                })}
                onKeyPress={(event) => {
                  if (event.key === `Enter`) {
                    event.preventDefault()
                  }
                }}
              />
              {errors.pixCodeCopyPaste && (
                <p className="pix-error-msg">Por favor, verifique o código.</p>
              )}
              {errorMessage && !errors.pixCodeCopyPaste && (
                <p className="pix-error-msg">{errorMessage}</p>
              )}
              <div className="pix-align-btn pix-border-top">
                <SecondaryButton
                  width={`141px`}
                  height={`60px`}
                  text={`Voltar`}
                  margin_right={`50px`}
                  OnClick={() => handlePixEnvironmentChange(1)}
                />
                <PrimaryButton width={`264px`} height={`60px`}>
                  {!btnLoading ? (
                    `Próximo`
                  ) : (
                    <div
                      className="spinner-border spinner-pix"
                      role="status"
                    ></div>
                  )}
                </PrimaryButton>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <PixCopyPasteMobile
          handleSubmit={handleSubmit}
          getReadQRCode={getReadQRCode}
          register={register}
          errors={errors}
          errorMessage={errorMessage}
          btnLoading={btnLoading}
        />
      )}
    </>
  )
}
export default PixCopyPaste
