import SVGInject from "@iconfu/svg-inject"
import { BeneficiaryInfo, KinshipEnum } from "k4n-svcmesh-sdk"
import { useContext, useEffect, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import Arrow from "../../../../../../assets/images/arrow.svg"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { InsuranceContext } from "../../../../../../context/InsuranceContext"
import { SecondaryButton } from "k4n-components"
import "./styles.scss"

const InsuranceStep2 = () => {
  const [device] = useState(`desktop`)
  const { addBeneficiaries, beneficiaries } = useContext(InsuranceContext)
  const { changeInsuranceStep } = useContext(BankingNavigationContext)
  const [sumError, setSumError] = useState(false)

  type Name = {
    name: string
    pct: number
    kinship: string
  }

  type FormValues = {
    names: Array<Name>
  }

  const {
    getValues,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    mode: `all`,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: `names`,
  })

  useEffect(function () {
    if (fields.length === 0) {
      if (beneficiaries.length > 0) {
        beneficiaries.forEach((element: any) => {
          append({
            name: element.name,
            pct: element.pct,
            kinship: element.kinship,
          })
        })
      } else handleAppend()
    }
  }, [])

  const checkPctSum = () => {
    let sum = 0
    const data = getValues()
    console.log(data)
    data.names?.forEach((element: any) => {
      sum = sum + parseFloat(element.pct)
    })
    console.log(sum)
    if (sum !== 100) {
      setSumError(true)
    } else {
      setSumError(false)
    }
  }

  const onSubmit = (data: any) => {
    if (sumError === false) {
      const beneficiaryList: BeneficiaryInfo[] = []
      data.names?.forEach((element: any) => {
        const obj = {
          name: element.name,
          pct: parseFloat(element.pct),
          kinship: element.kinship,
        } as BeneficiaryInfo
        beneficiaryList.push(obj)
      })
      addBeneficiaries(beneficiaryList)
      handleClickScroll()
      changeInsuranceStep(3)
    }
  }

  const handleClickScroll = () => {
    const scroll = document.getElementById(`header`)
    if (scroll) {
      scroll.scrollTo(0, 0)
    }
  }

  const handleAppend = () => {
    if (fields.length < 5) {
      append({ name: ``, pct: 0, kinship: `` })
    }
  }

  const backOneStep = () => {
    changeInsuranceStep(1)
  }

  return (
    <>
      <div className="insurance-step-2">
        <div className="row insurance-content-step-2">
          <>
            {device && device !== `mobile` ? (
              <>
                <div>
                  <form
                    className="insurance-step-2-bg"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {fields.map((item, index) => (
                      <div key={index} className="col-12 no-pd">
                        <div
                          className={
                            index === 0
                              ? `insurance-step-2-header`
                              : `insurance-step-2-header mt-32`
                          }
                        >
                          <h1 className={`insurance-step-2-h1`}>
                            Beneficiário {index + 1}
                          </h1>
                          {index > 0 && (
                            <button
                              type="button"
                              className="insurance-step-2-trash"
                              onClick={() => remove(index)}
                            />
                          )}
                        </div>
                        <div className="insurance-step-2-separator">
                          <div>
                            <label className="insurance-step-2-label">
                              Nome completo do beneficiário
                            </label>
                          </div>
                          <input
                            key={item.id}
                            {...register(`names.${index}.name` as const, {
                              required: true,
                              pattern: /^(([A-zÀ-ÿ]+)(\ )?){0,}$/,
                            })}
                            type="text"
                            className="insurance-step-2-name insurance-input"
                            defaultValue={item.name}
                          />
                          {errors.names?.[index]?.name && (
                            <p className="insurance-step-2-error-msg">
                              Por favor, verifique o nome informado.
                            </p>
                          )}
                        </div>

                        <div className="insurance-step-2-separator">
                          <div>
                            <label className="insurance-step-2-label">
                              Participação
                            </label>
                          </div>
                          <input
                            key={`pct` + index}
                            type="number"
                            step="0.01"
                            maxLength={3}
                            placeholder="0.00"
                            className="insurance-step-2-input-pct insurance-input no-spin"
                            onInput={(event) => {
                              if (parseFloat(event.currentTarget.value) > 100)
                                event.currentTarget.value =
                                  event.currentTarget.value.slice(0, 2)
                              else if (event.currentTarget.value.length > 5)
                                event.currentTarget.value =
                                  event.currentTarget.value.slice(0, 5)
                            }}
                            {...register(`names.${index}.pct` as const, {
                              required: true,
                              // pattern: /(^(100(?:\.0{1,2})?))|(?!^0*$)(?!^0*\.0*$)^\d{1,2}(\.\d{1,2})?$/,
                              min: 0,
                              max: 100,
                              maxLength: 5,
                            })}
                            onBlur={() => checkPctSum()}
                          />
                          <span className="insurance-step-2-span-pct">%</span>
                          {errors.names?.[index]?.pct && (
                            <p className="insurance-step-2-error-msg">
                              Por favor, verifique a porcentagem informada.
                            </p>
                          )}
                        </div>
                        <div className="insurance-step-2-separator">
                          <div>
                            <label className="insurance-step-2-label">
                              Grau de Parentesco
                            </label>
                          </div>
                          <select
                            className="form-select-marital margin-0"
                            style={{ marginTop: 24 }}
                            {...register(`names.${index}.kinship` as const, {
                              required: true,
                              min: 1,
                            })}
                          >
                            <option value={KinshipEnum.CONJUGE}>Cônjuge</option>
                            <option value={KinshipEnum.FILHO}>Filho</option>
                            <option value={KinshipEnum.MAE}>Mãe</option>
                            <option value={KinshipEnum.PAI}>Pai</option>
                            <option value={KinshipEnum.OUTROS}>Outros</option>
                            <option value={KinshipEnum.IRMAO}>Irmão</option>
                            <option value={KinshipEnum.AVO}>Avô</option>
                            <option value={KinshipEnum.GENRO_NORA}>
                              Genro (Nora)
                            </option>
                            <option value={KinshipEnum.SOGRO}>Sogro</option>
                            <option value={KinshipEnum.TIO}>Tio</option>
                          </select>
                          {errors.names?.[index]?.kinship && (
                            <p className="insurance-step-2-error-msg">
                              Por favor, verifique o grau de parentesco.
                            </p>
                          )}
                        </div>
                      </div>
                    ))}

                    {sumError && (
                      <p className="insurance-step-2-error-msg">
                        Erro, a soma das porcentagens devem totalizar 100%.
                      </p>
                    )}
                    {/* <input type="submit" /> */}
                  </form>
                  <div className="insurance-step-2-add">
                    <button
                      className="insurance-step-2-add-btn"
                      onClick={() => handleAppend()}
                    >
                      <span className="insurance-step-2-add-span">+</span>
                    </button>
                  </div>
                </div>
                <div className="insurance-next">
                  <SecondaryButton
                    width={`170px`}
                    height={`60px`}
                    text={`Voltar`}
                    margin_right={`50px`}
                    OnClick={() => backOneStep()}
                  />
                  <button
                    type="submit"
                    onClick={() => handleSubmit(onSubmit)()}
                    className="insurance-next-btn"
                  >
                    <img
                      src={Arrow}
                      className="insurance-next-arrow"
                      alt=">"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="insurance-step-2-mobile container">
                  <form
                    className="insurance-step-2-bg"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {fields.map((item, index) => (
                      <div key={index} className="col-12 no-pd">
                        <div
                          className={
                            index === 0
                              ? `insurance-step-2-header`
                              : `insurance-step-2-header mt-32`
                          }
                        >
                          <h1
                            className={
                              index === 0
                                ? `insurance-step-2-h1`
                                : `insurance-step-2-h1`
                            }
                          >
                            Beneficiário {index + 1}
                          </h1>
                          {index > 0 && (
                            <button
                              type="button"
                              className="insurance-step-2-trash"
                              onClick={() => remove(index)}
                            />
                          )}
                        </div>

                        <label className="k4n-input-label">
                          Nome completo do beneficiário
                        </label>
                        <input
                          key={item.id}
                          {...register(`names.${index}.name` as const, {
                            required: true,
                            pattern: /^(([a-zA-Zà-ùÀ-Ù]+)(\ )?){0,}$/,
                          })}
                          type="text"
                          className="insurance-step-2-name is-step-2-input"
                          defaultValue={item.name}
                        />
                        {errors.names?.[index]?.name && (
                          <p className="insurance-step-2-error-msg">
                            Por favor, verifique o nome informado.
                          </p>
                        )}

                        <div className="col-auto no-spin">
                          <label className="k4n-input-label">
                            Participação
                          </label>
                          <input
                            key={`pct` + index}
                            type="number"
                            step="0.01"
                            maxLength={3}
                            placeholder="0.00"
                            className="insurance-step-2-input-pct is-step-2-input"
                            onInput={(event) => {
                              if (parseFloat(event.currentTarget.value) > 100)
                                event.currentTarget.value =
                                  event.currentTarget.value.slice(0, 2)
                              else if (event.currentTarget.value.length > 5)
                                event.currentTarget.value =
                                  event.currentTarget.value.slice(0, 5)
                            }}
                            {...register(`names.${index}.pct` as const, {
                              required: true,
                              // pattern: /(^(100(?:\.0{1,2})?))|(?!^0*$)(?!^0*\.0*$)^\d{1,2}(\.\d{1,2})?$/,
                              min: 0,
                              max: 100,
                              maxLength: 5,
                            })}
                            onBlur={() => checkPctSum()}
                          />
                          <span className="insurance-step-2-span-pct-mobile">
                            %
                          </span>
                          {errors.names?.[index]?.pct && (
                            <p className="insurance-step-2-error-msg">
                              Por favor, verifique a porcentagem informada.
                            </p>
                          )}
                        </div>

                        <label className="k4n-input-label">
                          Grau de Parentesco
                        </label>
                        <select
                          className="form-select-marital"
                          {...register(`names.${index}.kinship` as const, {
                            required: true,
                            min: 1,
                          })}
                        >
                          <option value={KinshipEnum.CONJUGE}>Cônjuge</option>
                          <option value={KinshipEnum.FILHO}>Filho</option>
                          <option value={KinshipEnum.MAE}>Mãe</option>
                          <option value={KinshipEnum.PAI}>Pai</option>
                          <option value={KinshipEnum.OUTROS}>Outros</option>
                          <option value={KinshipEnum.IRMAO}>Irmão</option>
                          <option value={KinshipEnum.AVO}>Avô</option>
                          <option value={KinshipEnum.GENRO_NORA}>
                            Genro (Nora)
                          </option>
                          <option value={KinshipEnum.SOGRO}>Sogro</option>
                          <option value={KinshipEnum.TIO}>Tio</option>
                        </select>
                        {errors.names?.[index]?.kinship && (
                          <p className="insurance-step-2-error-msg">
                            Por favor, verifique o grau de parentesco.
                          </p>
                        )}
                      </div>
                    ))}

                    {sumError && (
                      <p className="insurance-step-2-error-msg">
                        Erro, a soma das porcentagens devem totalizar 100%.
                      </p>
                    )}
                    {/* <input type="submit" /> */}
                  </form>

                  <button
                    className="insurance-step-2-add-btn-mobile"
                    onClick={() => handleAppend()}
                  >
                    <span className="insurance-step-2-add-span">+</span>
                  </button>

                  {/* <div className="insurance-step-2-next">
                    <button
                      type="submit"
                      onClick={() => handleSubmit(onSubmit)()}
                      className="insurance-step-2-next-btn-mobile"
                    >
                      <img
                        src={Arrow}
                        className="insurance-step-2-next-arrow"
                        alt=">"
                      />
                    </button>
                  </div> */}
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  )
}
export default InsuranceStep2
