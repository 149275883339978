import { useContext } from "react"
import { FieldValues, useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import { OnboardingContext } from "../../../../context/OnboardingContext"
import services from "../../../../services/svcmesh"
import "./address.scss"

const AddressForm = () => {
  const { goBack, confirmAddress, address, loading } =
    useContext(OnboardingContext)

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: address,
  })

  const handleCompleteAddress = async (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    clearErrors(`cep`)
    clearErrors(`numero`)
    clearErrors(`logradouro`)
    clearErrors(`bairro`)
    clearErrors(`complemento`)
    clearErrors(`cidade`)
    clearErrors(`uf`)

    const zipCode = event.currentTarget.value.replace(`-`, ``)
    const res = await services.zipCodeService.consultZipCode(zipCode)
    if (res.data.erro) {
      /* setIsEditing(true) */
      setError(`cep`, { message: `Por favor, verifique o CEP.` })
    }

    setValue(`logradouro`, res.data.logradouro)
    setValue(`uf`, res.data.uf)
    setValue(`cidade`, res.data.localidade)
    setValue(`bairro`, res.data.bairro)
  }

  const handleConfirmAddress = async (data: FieldValues) => {
    await confirmAddress(data)
  }

  return (
    <form
      className="onboarding-form-container"
      style={{ maxWidth: `1000px` }}
      onSubmit={handleSubmit(
        handleConfirmAddress /* , () => setIsEditing(true) */,
      )}
    >
      <div>
        <button className="onboarding-go-back" type="button" onClick={goBack} />
      </div>
      <div className="onboarding-question-primary mt-5">
        Preencha os dados o seu endereço
      </div>

      <div className="row">
        <div className="col-7">
          <label className="address-input-label">CEP</label>
          <InputMask
            mask="99999-999"
            {...register(`cep`, {
              pattern: /^\d{5}-\d{3}$/,
              required: true,
              minLength: 4,
              maxLength: 250,
              onChange: (e) => {
                handleCompleteAddress(e)
              },
            })}
            type="text"
            className={
              errors.cep ? `address-input input-invalid` : `address-input`
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-10">
          <label className="address-input-label">Endereço</label>
          <input
            {...register(`logradouro`, {
              required: true,
              minLength: 4,
              maxLength: 250,
            })}
            disabled={true}
            type="text"
            className={
              errors.logradouro
                ? `address-input input-invalid`
                : `address-input address-input-disabled-bg`
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-5">
          <label className="address-input-label">Número</label>
          <input
            {...register(`numero`, { required: true, maxLength: 250 })}
            type="text"
            className={
              errors.numero ? `address-input input-invalid` : `address-input`
            }
          />
        </div>
        <div className="col-5">
          <label className="address-input-label">Complemento</label>
          <input
            {...register(`complemento`, { maxLength: 250 })}
            type="text"
            className={
              errors.complemento
                ? `address-input input-invalid`
                : `address-input`
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <label className="address-input-label">Bairro</label>
          <input
            {...register(`bairro`, {
              required: true,
              minLength: 4,
              maxLength: 250,
            })}
            disabled={true}
            type="text"
            className={
              errors.bairro
                ? `address-input input-invalid`
                : `address-input address-input-disabled-bg`
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <label className="address-input-label">Cidade</label>
          <input
            {...register(`cidade`, {
              required: true,
              minLength: 4,
              maxLength: 250,
            })}
            disabled={true}
            type="text"
            className={
              errors.cidade
                ? `address-input input-invalid`
                : `address-input address-input-disabled-bg`
            }
          />
        </div>
        <div className="col-4">
          <label className="address-input-label">UF</label>
          <input
            {...register(`uf`, {
              required: true,
              minLength: 2,
              maxLength: 2,
            })}
            disabled={true}
            type="text"
            className={
              errors.uf
                ? `address-input input-invalid`
                : `address-input address-input-disabled-bg`
            }
          />
        </div>
      </div>

      <div className="flex-end">
        <button
          className={
            `onboarding-confirm onboarding-btn-next` +
            (loading ? ` loading` : ``)
          }
          disabled={loading}
          type="submit"
        />
      </div>
    </form>
  )
}

export default AddressForm
