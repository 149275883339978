import BoletoManager from "./manage"
import "./styles.scss"
import { useResposive } from "../../../../hooks/useResponsive"
import BoletoManagerMobile from "./mobile/panel"

const Boleto = () => {
  const { isTabletOrMobile } = useResposive()

  return (
    <div
      className={isTabletOrMobile ? `boleto-wrapper-mobile` : `boleto-wrapper`}
    >
      {isTabletOrMobile ? <BoletoManagerMobile /> : <BoletoManager />}
    </div>
  )
}
export default Boleto
