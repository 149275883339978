import { useContext, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import {
  PixKeyType,
  PixStorageContext,
} from "../../../../../context/PixStorageProvider"
import { price_to_number } from "../../../../../utils/validation"
import {
  CurrencyInput,
  SuccessModal,
  SecondaryButton,
  PrimaryButton,
  FailureModal,
} from "k4n-components"
import PixNav from "../nav-pix"
import copy from "../../../../../assets/images/copy_green.svg"
import copy_sucess from "../../../../../assets/images/copy_sucess.svg"
import "./styles.scss"
import { AxiosError } from "axios"
import SVGInject from "@iconfu/svg-inject"
import { useResposive } from "../../../../../hooks/useResponsive"
import PixReceiveMobile from "./Mobile"
import services from "../../../../../services/svcmesh"
import { BmpConta, CreateQRCode } from "k4n-svcmesh-sdk"
import { AuthContext } from "../../../../../context/AuthProvider"

const PixReceive = () => {
  const [showModal, setShowModal] = useState(false)
  const [showModalError, setShowModalError] = useState(false)
  const [errorReceive, setErrorReceive] = useState(false)
  const [pixKeyType, setPixKeyType] = useState(0)
  const [qrCode, setQRCode] = useState(Object)
  const [receiveValue, setReceiveValue] = useState<number>(0)
  const { changePixEnvironment } = useContext(BankingNavigationContext)
  const { current_account } = useContext(AuthContext)
  const { userPixKeys } = useContext(PixStorageContext)
  const [btnLoading, setBtnLoading] = useState(false)
  const [copyCode, setCopyCode] = useState(false)
  const { isTabletOrMobile } = useResposive()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const handlePixEnvironmentChange = (newPage: number) => {
    changePixEnvironment(newPage)
  }

  const onSubmit = async (data: any) => {
    setBtnLoading(true)
    let accountLength = 0
    let contaPgt: string | undefined
    if (current_account?.conta && current_account?.contaDigito) {
      accountLength = (current_account?.conta + current_account?.contaDigito)
        .length
    }
    if (accountLength == 5)
      contaPgt = `00` + current_account?.conta + current_account?.contaDigito
    if (accountLength == 6)
      contaPgt = `0` + current_account?.conta + current_account?.contaDigito
    if (accountLength == 7)
      contaPgt = `` + current_account?.conta + current_account?.contaDigito

    const conta = {
      agencia: current_account?.agencia,
      agenciaDigito: current_account?.agenciaDigito,
      conta: current_account?.conta,
      contaDigito: current_account?.contaDigito,
      contaPgto: contaPgt,
      tipoConta: current_account?.tipoConta,
    } as BmpConta
    const sendObj = {
      chave: ``,
      tipoChave: pixKeyType,
      valor: receiveValue,
      informacoesAdicionais: data.message,
      idConciliacaoRecebedor: ``,
      conta: conta,
    } as CreateQRCode
    if (pixKeyType === PixKeyType.CPF) sendObj.chave = userPixKeys.cpf!
    if (pixKeyType === PixKeyType.CNPJ) sendObj.chave = userPixKeys.cnpj!
    if (pixKeyType === PixKeyType.CELULAR) sendObj.chave = userPixKeys.celular!
    if (pixKeyType === PixKeyType.EMAIL) sendObj.chave = userPixKeys.email!
    if (pixKeyType === PixKeyType.EVP) sendObj.chave = userPixKeys.evp!
    try {
      const res = await services.bankingService.createQRCodeStatic(sendObj)
      if (res.data.sucesso === true) {
        const qrcode = {
          emv: res.data.emv,
          imagem: res.data.imagem,
        }
        console.log(qrcode)
        setQRCode(qrcode)
        setShowModal(true)
        setBtnLoading(false)
      }
    } catch (error: any) {
      const err = error as AxiosError<any>
      setErrorReceive(err.response?.data.message)
      setBtnLoading(false)
      setShowModalError(true)
    }
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    const fixedNumber = price_to_number(newValue)
    setReceiveValue(fixedNumber)
  }

  const copyQRCode = () => {
    navigator.clipboard.writeText(qrCode.emv)
    setCopyCode(true)
    setTimeout(function () {
      setCopyCode(false)
    }, 3000)
  }

  return (
    <>
      {!isTabletOrMobile ? (
        <div className="pix-receive-container">
          <PixNav step={0} title={`Receber Pix`} />
          <div className="pix-receive-content">
            <div className="pix-receive-content-table">
              <h1 className="pix-receive-title">
                Qual o valor você quer receber?
              </h1>
              <h1 className="pix-receive-txt">
                Caso você não especifique o valor, o preenchimento do campo será
                feito por quem for pagar.
              </h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="pix-receive-content-table">
                <Controller
                  name="receiveValue"
                  control={control}
                  defaultValue="0,00"
                  rules={{
                    required: false,
                    // validate: validateCurrency,
                    onChange: onValueChange,
                  }}
                  render={({ field }) => (
                    <CurrencyInput
                      value={field.value}
                      options={{ style: `decimal`, allowNegative: false }}
                      onChangeEvent={(_, maskedValue) => {
                        field.onChange(maskedValue)
                      }}
                      required={false}
                      className="pix-payment-step2-value-input"
                      onKeyPress={(event) => {
                        if (event.key === `Enter`) {
                          event.preventDefault()
                        }
                      }}
                    />
                  )}
                />
              </div>
              <div className="pix-receive-content-table">
                <input
                  id="message"
                  type="text"
                  className="pix-payment-step2-message-input"
                  placeholder="Enviar uma mensagem"
                  {...register(`message`, {
                    required: false,
                  })}
                  onKeyPress={(event) => {
                    if (event.key === `Enter`) {
                      event.preventDefault()
                    }
                  }}
                />
                <span className="content-table-step2-pix-txt margin-0">
                  Use este espaço para mandar um recado
                </span>
              </div>
              <div className="pix-receive-content-table">
                <h1 className="pix-receive-txt">Escolha sua chave</h1>
              </div>
              <div className="pix-receive-content-keys">
                {userPixKeys.cpf && (
                  <div
                    className={`pix-receive-keys`}
                    onClick={() => {
                      setPixKeyType(0)
                    }}
                  >
                    <span
                      className={
                        `pix-receive-key-btn ` +
                        (pixKeyType === 0 ? `pix-receive-key-btn-selected` : ``)
                      }
                    ></span>
                    <div>
                      <h1 className="pix-receive-keys-h1">CPF</h1>
                      <h1 className="pix-receive-keys-txt">
                        {userPixKeys.cpf}
                      </h1>
                    </div>
                  </div>
                )}
                {userPixKeys.celular && (
                  <div
                    className={`pix-receive-keys`}
                    onClick={() => {
                      setPixKeyType(2)
                    }}
                  >
                    <span
                      className={
                        `pix-receive-key-btn ` +
                        (pixKeyType === 2 ? `pix-receive-key-btn-selected` : ``)
                      }
                    ></span>
                    <div>
                      <h1 className="pix-receive-keys-h1">Chave de celular</h1>
                      <h1 className="pix-receive-keys-txt">
                        {userPixKeys.celular}
                      </h1>
                    </div>
                  </div>
                )}
                {userPixKeys.email && (
                  <div
                    className={`pix-receive-keys`}
                    onClick={() => {
                      setPixKeyType(3)
                    }}
                  >
                    <span
                      className={
                        `pix-receive-key-btn ` +
                        (pixKeyType === 3 ? `pix-receive-key-btn-selected` : ``)
                      }
                    ></span>
                    <div>
                      <h1 className="pix-receive-keys-h1">Chave de e-mail</h1>
                      <h1 className="pix-receive-keys-txt">
                        {userPixKeys.email}
                      </h1>
                    </div>
                  </div>
                )}
                {userPixKeys.evp && (
                  <div
                    className={`pix-receive-keys`}
                    onClick={() => {
                      setPixKeyType(4)
                    }}
                  >
                    <span
                      className={
                        `pix-receive-key-btn ` +
                        (pixKeyType === 4 ? `pix-receive-key-btn-selected` : ``)
                      }
                    ></span>
                    <div className="pix-receive-txts">
                      <h1 className="pix-receive-keys-h1">Chave aleatória</h1>
                      <h1 className="pix-receive-keys-txt">
                        {userPixKeys.evp}
                      </h1>
                    </div>
                  </div>
                )}
              </div>
              <div className="pix-align-btn">
                <SecondaryButton
                  width={`170px`}
                  height={`60px`}
                  text={`Voltar`}
                  margin_right={`50px`}
                  OnClick={() => handlePixEnvironmentChange(1)}
                />
                <PrimaryButton width={`264px`} height={`60px`}>
                  {!btnLoading ? (
                    `Gerar QRCode`
                  ) : (
                    <div
                      className="spinner-border spinner-pix"
                      role="status"
                    ></div>
                  )}
                </PrimaryButton>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <PixReceiveMobile
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          control={control}
          onValueChange={onValueChange}
          errors={errors}
          setPixKeyType={setPixKeyType}
          pixKeyType={pixKeyType}
          btnLoading={btnLoading}
          register={register}
        />
      )}
      <SuccessModal
        isOpen={showModal}
        width={!isTabletOrMobile ? `937px` : `100vw`}
        height={!isTabletOrMobile ? `676px` : `100%`}
        onClose={() => {
          setShowModal(false)
          handlePixEnvironmentChange(1)
        }}
      >
        <h1 className="pix-receive-modal-sucess-h1">QR Code gerado!</h1>
        <h1 className="pix-receive-modal-sucess-txt">
          Mostre o QR Code ou compartilhe <br /> o link para quem for pagar.
        </h1>
        <div className="pix-receive-align-center">
          <img
            src={`data:image/png;base64,` + qrCode.imagem}
            width={`100%`}
            height={`100%`}
            alt="QR Code"
            className="pix-receive-modal-img"
          />
        </div>
        <div className="pix-align-center">
          <PrimaryButton width={`312px`} height={`60px`} onClick={copyQRCode}>
            {copyCode ? (
              <div className="btn-align-center">
                <img
                  src={copy_sucess}
                  className="pix-receive-modal-btn-img"
                  alt=""
                  onLoad={(e) => SVGInject(e.target)}
                />
                QRCode copiado!
              </div>
            ) : (
              <div className="btn-align-center">
                <img
                  src={copy}
                  className="pix-receive-modal-btn-img"
                  alt=""
                  onLoad={(e) => SVGInject(e.target)}
                />
                Copiar QRCode
              </div>
            )}
          </PrimaryButton>
        </div>
      </SuccessModal>
      <FailureModal
        isOpen={showModalError}
        width={!isTabletOrMobile ? `937px` : `100vw`}
        height={!isTabletOrMobile ? `576px` : `100%`}
        onClose={() => setShowModalError(false)}
      >
        <h1 className="pix-modal-sucess-h1">Ocorreu um Erro</h1>
        <h1 className="pix-modal-sucess-3p">• • •</h1>
        <h1 className="pix-modal-sucess-txt">{errorReceive}</h1>
        <div className="pix-align-center">
          <PrimaryButton
            width={`312px`}
            height={`60px`}
            onClick={() => setShowModalError(false)}
          >
            OK!
          </PrimaryButton>
        </div>
      </FailureModal>
    </>
  )
}
export default PixReceive
