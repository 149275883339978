import "./styles.scss"
import WaitImg from "../../../assets/images/wait.svg"
import Navbar from "../../features/Navbar"
import X from "../../../assets/images/exclamation_modal.png"
const AccountOpeningError = () => {
  return (
    <>
      <Navbar isLogin={true} />
      <div className="warning-account-container">
        <div className="warning-account-title">
          <div className="warning-account-flex-center">
            <div className="warning-error-icon-space">
              <div className="warning-error-exclamation-div">
                <img src={X} width="50" height="50" />
              </div>
            </div>
          </div>
          <h3>Prezado cliente,</h3>
          <h5 className="mx-1">
            Houve um problema na abertura da sua conta, por favor, entre em
            contato com o nosso suporte através do e-mail contato@kikkin.com.br
          </h5>
        </div>
      </div>
    </>
  )
}
export default AccountOpeningError
