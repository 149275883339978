import PaymentStep1 from "./Step1"
import { useResposive } from "../../../../hooks/useResponsive"
import "./styles.scss"
import PaymentMobile from "./PaymentMobile"

const Payment = () => {
  const { isTabletOrMobile } = useResposive()
  return (
    <>
      {isTabletOrMobile ? (
        <>
          <div className="payment-wrapper-mobile">
            <div className="payment-mobile-header">
              <PaymentMobile />
            </div>
          </div>
        </>
      ) : (
        <div className="payment-wrapper">
          <PaymentStep1 />
        </div>
      )}
    </>
  )
}
export default Payment
