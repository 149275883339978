import dayjs from "dayjs"
import dayjsBusinessTime from "dayjs-business-time"
import { useContext, useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import InputMask from "react-input-mask"
import { BMP_BANK_NUMBER } from "../../../../../assets/constants"
import { AuthContext } from "../../../../../context/AuthProvider"
import { BalanceContext } from "../../../../../context/BalanceProvider"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import { TransferContext } from "../../../../../context/TransferContext"
import { useResposive } from "../../../../../hooks/useResponsive"
import { formattedCurrency } from "../../../../../services/banking"
import services from "../../../../../services/svcmesh"
import { censorCpf } from "../../../../../utils/validation"
import {
  CustomModal,
  FailureModal,
  SecondaryButton,
  PrimaryButton,
} from "k4n-components"
import TransferNav from "../transfer-nav"
import TransferStep3Mobile from "./mobile"
import "./styles.scss"
dayjs.extend(dayjsBusinessTime)

const TransferStep3 = () => {
  const {
    transferStep,
    changeTransferStep,
    newContactTransferStep,
    isScheduling,
    changeIsScheduling,
  } = useContext(BankingNavigationContext)
  const [showPaymentDate, setShowPaymentDate] = useState(false)
  const [message, setMessage] = useState(``)

  const { balance, loadingBalance, refreshBalance } = useContext(BalanceContext)

  const { favoriteData, transferValue, addTransferStep3Data } =
    useContext(TransferContext)
  const { current_account, user } = useContext(AuthContext)
  const [defaultDate, setDefaultDate] = useState<Date>(dayjs().toDate())
  const [defaultMinScheduleDate, setDefaultMinScheduleDate] = useState<Date>(
    dayjs().toDate(),
  )
  const [scheduledDate, setScheduledDate] = useState<Date>(dayjs().toDate())
  const [failureModalAlert, setFailureModalAlert] = useState(``)
  const [customModalAlert, setCustomModalAlert] = useState(``)
  const [saveFavorite, setSaveFavorite] = useState<boolean>(false)
  const [holidays, setHolidays] = useState<Date[]>([])
  const { isTabletOrMobile } = useResposive()

  const messageInput = (e: any) => {
    setMessage(e.target.value)
  }

  const nextNotHolidayDay = (holidays: string[]) => {
    let auxDate = dayjs().add(1, `d`).toDate()
    while (holidays.some((h) => h === dayjs(auxDate).format(`YYYY-MM-DD`))) {
      auxDate = dayjs(auxDate).add(1, `d`).toDate()
    }
    return auxDate
  }

  const getHolidays = async () => {
    try {
      const holidays = (
        await services.holidaysService.getPublicHolidays(dayjs().get(`y`), `BR`)
      )
        .filter((h) => h.global)
        .map((h) => h.date)
      setHolidays(holidays.map((h) => dayjs(h).toDate()))
      dayjs.setHolidays(holidays)
      if (
        favoriteData.numero_banco !== BMP_BANK_NUMBER &&
        !dayjs().isBusinessDay()
      ) {
        const auxDate = dayjs().nextBusinessDay().toDate()
        setDefaultDate(auxDate)
        setScheduledDate(auxDate)
      } else if (dayjs().isHoliday()) {
        const auxDate = nextNotHolidayDay(holidays)
        setDefaultDate(auxDate)
        setScheduledDate(auxDate)
      }
      setDefaultMinScheduleDate(
        favoriteData.numero_banco !== BMP_BANK_NUMBER
          ? dayjs().nextBusinessDay().toDate()
          : nextNotHolidayDay(holidays),
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getHolidays()
    refreshBalance()
  }, [])

  const changeContext = (date: Date) => {
    addTransferStep3Data({
      reason: message,
      date,
    })
    changeTransferStep(4)
  }

  const saveFavoriteAndChangeContext = async (date: Date) => {
    if (saveFavorite) {
      try {
        const res = await services.bankingService.createCustomerFavorite({
          clientePf: { id: user?.id },
          nome: favoriteData.nome,
          cpf: favoriteData.cpf,
          numero_banco: favoriteData.numero_banco,
          agencia: favoriteData.agencia,
          agencia_digito: favoriteData.agencia_digito,
          conta: favoriteData.conta,
          conta_digito: favoriteData.conta_digito,
          tipo_conta: Number(favoriteData.tipo_conta),
        })
        if (res?.sucesso) {
          changeContext(date)
        }
      } catch (error: any) {
        setFailureModalAlert(
          error?.response?.data?.message || error?.message || error,
        )
      }
    } else {
      changeContext(date)
    }
  }

  const handleNext = async () => {
    const isTedNotInTheFutureButPast430PM =
      favoriteData.numero_banco !== BMP_BANK_NUMBER &&
      !dayjs(scheduledDate).isAfter(dayjs(), `d`) &&
      dayjs().isAfter(
        dayjs().hour(16).minute(30).second(0).millisecond(0),
        `ms`,
      )
    const isDefaultDateInTheFutureButNotScheduled =
      dayjs(defaultDate).isAfter(dayjs(), `d`) &&
      dayjs(defaultDate).isSame(scheduledDate, `d`) &&
      !showPaymentDate
    if (
      isTedNotInTheFutureButPast430PM ||
      isDefaultDateInTheFutureButNotScheduled
    ) {
      setCustomModalAlert(
        `Dado o dia e/ou horário, sua transferência será agendada para o dia ${
          isTedNotInTheFutureButPast430PM
            ? dayjs().nextBusinessDay().format(`DD/MM/YYYY`)
            : dayjs(scheduledDate).format(`DD/MM/YYYY`)
        } dentro do intervalo de 07:00 às 16:30. Deseja continuar?`,
      )
      return
    }
    await saveFavoriteAndChangeContext(scheduledDate)
  }

  const isWeekday = (date: Date) => {
    const day = dayjs(date).get(`d`)
    return day !== 0 && day !== 6
  }

  return (
    <>
      {!isTabletOrMobile ? (
        <div className="transfer-container">
          <TransferNav step={transferStep} stepTitle="Nova transferência" />
          <div className="panel-content">
            <div className="panel-title">
              <span className="title-blue">Transferir para</span>
            </div>
            <div className="step-3-info-div">
              <div className="step-3-info-div-flex">
                <div className="steps-value-info">
                  <h1 className="info-span-bold-16">Valor a pagar</h1>
                  <span className="info-span-grey-24">
                    {formattedCurrency(transferValue)}
                  </span>
                </div>
                <div className="info-div">
                  <h1 className="info-span-bold-16">Saldo Kikkin:</h1>
                  <h1 className="info-span-grey-16">
                    {loadingBalance ? (
                      <div className="emptySpace-pix gradient"></div>
                    ) : (
                      <span className="content-table-step2-pix-txt">
                        {formattedCurrency(balance)}
                      </span>
                    )}
                  </h1>
                </div>
              </div>
              <div className="margin-top-20">
                <div className="info-div">
                  <h1 className="info-span-bold-16">Forma de pagamento:</h1>
                  <h1 className="info-span-grey-16">
                    {`Agência ${current_account?.agencia}-${
                      current_account?.agenciaDigito || `0`
                    } - Conta ${current_account?.conta}-${
                      current_account?.contaDigito
                    }`}
                  </h1>
                </div>
              </div>
              {newContactTransferStep != 0 && (
                <div className="transfer-step-3-checkbox-div">
                  <input
                    className="form-check-input checkbox-transfer-step-3"
                    type="checkbox"
                    onClick={() => setSaveFavorite(!saveFavorite)}
                  />
                  <span className="transfer-step-3-checkbox-span">
                    Salvar como favorito
                  </span>
                </div>
              )}
            </div>
            <div className="transfer-contact-div">
              <div className="steps-value-info">
                <h1 className="info-span-bold-16">Para</h1>
                <span className="info-span-grey-16">{favoriteData.nome}</span>
              </div>
              <div className="info-div">
                <h1 className="info-span-bold-16">CPF:</h1>
                <h1 className="info-span-grey-16">
                  {censorCpf(favoriteData.cpf || ``)}
                </h1>
              </div>
              <div className="info-div">
                <h1 className="info-span-bold-16">Agencia:</h1>
                <h1 className="info-span-grey-16">{`${favoriteData.agencia}-${
                  favoriteData.agencia_digito || `0`
                }`}</h1>
              </div>
              <div className="info-div">
                <h1 className="info-span-bold-16">Conta:</h1>
                <h1 className="info-span-grey-16">{`${favoriteData.conta}-${favoriteData.conta_digito}`}</h1>
              </div>
              <div className="info-div">
                <h1 className="info-span-bold-16">Instituição:</h1>
                <h1 className="info-span-grey-16">
                  {favoriteData.instituicao}
                </h1>
              </div>
            </div>
            <div className="form-step-3-div">
              <div className="send-message-content">
                <div className="send-message-box">
                  <input
                    type="text"
                    className="send-message-input"
                    placeholder="Motivo"
                    maxLength={140}
                    onChange={messageInput}
                  />
                </div>
                <div className="float-r">
                  <span className="send-message-limit-char">
                    {message.length.toString() + `/140`}
                  </span>
                </div>
              </div>
              <span className="info-span-grey-14">
                Escreva o motivo da transferência (opcional)
              </span>
            </div>
            <div className="form-step-3-div">
              <div className="transfer-step3-date">
                <span className="content-table-step2-title-18">
                  Agendar pagamento
                </span>
                <div className="form-check form-switch">
                  <input
                    className="transfer-step3-date-input form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    onClick={() => {
                      changeIsScheduling(!isScheduling)
                      setShowPaymentDate(!showPaymentDate)
                      if (showPaymentDate) {
                        setScheduledDate(defaultDate)
                      } else {
                        setScheduledDate(defaultMinScheduleDate)
                      }
                    }}
                  />
                </div>
              </div>
              <span className="info-span-grey-14">Agende o pagamento</span>
              {showPaymentDate && (
                <div className="padding-top-30">
                  {/* excludeDates={holidays} assuming you cannot transfer between the same bank or between different banks on holidays */}
                  <DatePicker
                    selected={scheduledDate}
                    onChange={(date: any) => setScheduledDate(date)}
                    locale="pt-BR"
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    required={true}
                    minDate={defaultMinScheduleDate}
                    placeholderText="Quando você quer pagar?"
                    customInput={
                      <InputMask
                        mask="99/99/9999"
                        maskPlaceholder={null}
                        className="payment-step2-message-input payment-step2-input-calender"
                      />
                    }
                    filterDate={
                      favoriteData.numero_banco !== BMP_BANK_NUMBER
                        ? isWeekday
                        : undefined
                    }
                    excludeDates={holidays}
                  />
                </div>
              )}
            </div>
            <div className="transfer-step-3-btns-div">
              <PrimaryButton
                width={`245px`}
                height={`60px`}
                onClick={handleNext}
              >
                Próximo
              </PrimaryButton>
              <button
                className="cancel-btn"
                onClick={() => changeTransferStep(2)}
              >
                <span className="cancel-span">Voltar</span>
              </button>
            </div>
          </div>
          <FailureModal
            isOpen={!!failureModalAlert}
            width={`937px`}
            height={`350px`}
            onClose={() => {
              setFailureModalAlert(``)
            }}
          >
            <h1 className="pix-modal-sucess-h1">{failureModalAlert}</h1>
          </FailureModal>
          <CustomModal
            isOpen={!!customModalAlert}
            onClose={() => setCustomModalAlert(``)}
          >
            <div className="modal-message transfer-custom-modal">
              {customModalAlert}
            </div>
            <div className="transfer-schedule-alert">
              <SecondaryButton
                text="Não"
                width="200px"
                height="40px"
                OnClick={() => setCustomModalAlert(``)}
              />
              <PrimaryButton
                width="200px"
                height="40px"
                onClick={() => {
                  const isTedNotInTheFutureButPast430PM =
                    favoriteData.numero_banco !== BMP_BANK_NUMBER &&
                    !dayjs(scheduledDate).isAfter(dayjs(), `d`) &&
                    dayjs().isAfter(
                      dayjs().hour(16).minute(30).second(0).millisecond(0),
                      `ms`,
                    )
                  saveFavoriteAndChangeContext(
                    isTedNotInTheFutureButPast430PM
                      ? dayjs().nextBusinessDay().toDate()
                      : scheduledDate,
                  )
                }}
              >
                Sim
              </PrimaryButton>
            </div>
          </CustomModal>
        </div>
      ) : (
        <div className="transfer-mobile-wrapper">
          <TransferStep3Mobile
            showPaymentDate={showPaymentDate}
            setShowPaymentDate={setShowPaymentDate}
            handleNext={handleNext}
            getHolidays={getHolidays}
            saveFavoriteAndChangeContext={saveFavoriteAndChangeContext}
            customModalAlert={customModalAlert}
            setCustomModalAlert={setCustomModalAlert}
            setSaveFavorite={setSaveFavorite}
            message={message}
            setMessage={setMessage}
            failureModalAlert={failureModalAlert}
            scheduledDate={scheduledDate}
            setScheduledDate={setScheduledDate}
            saveFavorite={saveFavorite}
            setFailureModalAlert={setFailureModalAlert}
          />
        </div>
      )}
    </>
  )
}

export default TransferStep3
