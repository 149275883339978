import SVGInject from "@iconfu/svg-inject"
import { AxiosError } from "axios"
import dayjs from "dayjs"
import { useContext, useEffect, useState } from "react"
import Arrow from "../../../../../../assets/images/arrow.svg"
import { AuthContext } from "../../../../../../context/AuthProvider"
import { BoletoContext } from "../../../../../../context/BoletoContext"
import { useResposive } from "../../../../../../hooks/useResponsive"
import { formattedCurrency } from "../../../../../../services/banking"
import services from "../../../../../../services/svcmesh"
import {
  Address,
  BmpConta,
  BmpDadosBoleto,
  BmpDesconto,
  BmpJuros,
  BmpMulta,
  BmpPagador,
  BmpRegistroBoletoDto,
  CustomerEmail,
  CustomerPhone,
} from "k4n-svcmesh-sdk"
import { ErrorModal } from "k4n-components"
import BoletoCreationStep3Mobile from "./mobile"
import "./styles.scss"

export interface Props {
  handleCancel: () => void
  handleSuccess: (barCode: string) => void
}

const BoletoCreationStep3: React.FC<Props> = ({
  handleCancel,
  handleSuccess,
}) => {
  const { boletoCreationStep1, boletoCreationStep2 } = useContext(BoletoContext)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [modalErrorMessage, setModalErrorMessage] = useState<string>(``)
  const [loading, setLoading] = useState(false)
  const { user } = useContext(AuthContext)
  const { isTabletOrMobile } = useResposive()

  const createBoleto = async () => {
    setLoading(true)
    const countCpfCnpj = boletoCreationStep1.cpfCnpj
      ?.replaceAll(`.`, ``)
      .replaceAll(`-`, ``)
      .replaceAll(`/`, ``).length

    console.log(
      `🚀 ~ file: index.tsx ~ line 55 ~ createBoleto ~ countCpfCnpj`,
      countCpfCnpj,
    )
    let complement = ``
    if (boletoCreationStep1.complemento)
      complement = boletoCreationStep1.complemento
        .toLowerCase()
        .replace(`apartamento`, `ap`)
        .replace(`apart`, `ap`)

    const pagador: BmpPagador = {
      tipoPessoa: countCpfCnpj === 11 ? 1 : 2,
      documentoFederal: boletoCreationStep1.cpfCnpj,
      nomeRazao: boletoCreationStep1.recipient,
      logradouro: `${boletoCreationStep1.logradouro
        ?.slice(0, 15)
        .replace(`Avenida`, `Av`)
        .replace(`Rua`, `R`)},${boletoCreationStep1.numero}${
        boletoCreationStep1.complemento ? `,${complement}` : ``
      } `,
      cidade: boletoCreationStep1.cidade,
      uf: boletoCreationStep1.uf,
      cep: boletoCreationStep1.cep,
    }

    const beneficiario: BmpConta = {
      tipoConta: 3,
      agencia:
        user && user.accounts.length > 0 ? user?.accounts[0].agencia : ``,
      conta: user && user.accounts.length > 0 ? user?.accounts[0].conta : ``,
      contaDigito:
        user && user.accounts.length > 0 ? user?.accounts[0].contaDigito : ``,
      contaPgto:
        user && user.accounts.length > 0 ? user?.accounts[0].contaPgto : ``,
    }

    const dadosBoleto: BmpDadosBoleto = {
      dtVencimento: boletoCreationStep2.dueDate,
      dtLimPgto: boletoCreationStep2.deadline,
      vlrTitulo: boletoCreationStep2.nominalValue,
      numDocTit: `0`,
      identdNossoNum: `1`,
      codEspTit: 2,
      dtEmissao: boletoCreationStep2.issueDate,
      vlrAbatimento: boletoCreationStep2.reduction,
      numeroDocumento: boletoCreationStep2.numDoc,
    }

    const juros: BmpJuros = {
      codigo: boletoCreationStep2.fee?.code,
      vlr: boletoCreationStep2.fee?.value ? boletoCreationStep2.fee?.value : 0,
    }
    if (juros.vlr && juros.vlr > 0) juros.data = boletoCreationStep2.fee?.date

    const multa: BmpMulta = {
      codigo: boletoCreationStep2.fine?.code,
      vlr: boletoCreationStep2.fine?.value
        ? boletoCreationStep2.fine?.value
        : 0,
    }

    if (multa.vlr && multa.vlr > 0) multa.data = boletoCreationStep2.fine?.date

    const desconto: BmpDesconto = {
      codigo: boletoCreationStep2.discount?.code,
      vlr: boletoCreationStep2.discount?.value
        ? boletoCreationStep2.discount?.value
        : 0,
    }

    if (desconto.vlr && desconto.vlr > 0)
      desconto.data = boletoCreationStep2.discount?.date

    const boleto: BmpRegistroBoletoDto = {
      beneficiario: beneficiario,
      dadosBoleto: dadosBoleto,
      pagador: pagador,
      juros: juros,
      multa: multa,
      desconto: desconto,
      numeroCarteira: 1,
      tipoRegistro: 1,
    }
    try {
      const res = await services.bankingService.registerBoleto(boleto)
      if (res && res.data.sucesso) {
        if (boletoCreationStep1.favorite) {
          await saveFavorite()
        }
        setLoading(false)
        handleSuccess(res.data.numCodBarras)
      }
    } catch (error: any) {
      setLoading(false)
      const err = error as AxiosError<any>
      if (err.code === `ERR_BAD_REQUEST`)
        setModalErrorMessage(
          error?.response?.data?.message || error?.message || error,
        )
      else setModalErrorMessage(``)
      setShowErrorModal(true)
    }
  }

  const saveFavorite = async () => {
    const addresses = [
      {
        cep: boletoCreationStep1.cep,
        logradouro: boletoCreationStep1.logradouro,
        numero: boletoCreationStep1.numero?.toString(),
        complemento: boletoCreationStep1.complemento,
        bairro: boletoCreationStep1.bairro,
        cidade: boletoCreationStep1.cidade,
        uf: boletoCreationStep1.uf,
      },
    ] as Address[]

    const phones = [
      {
        phone: boletoCreationStep1.phone,
      },
    ] as CustomerPhone[]

    const emails = [
      {
        email: boletoCreationStep1.email,
      },
    ] as CustomerEmail[]
    try {
      await services.bankingService.createCustomerFavorite({
        clientePf: { id: user?.id },
        nome: boletoCreationStep1.recipient,
        cpf: boletoCreationStep1.cpfCnpj,
        addresses: addresses,
        emails: emails,
        phones: phones,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(function () {
    console.log(
      `🚀 ~ file: index.tsx ~ line 9 ~ BoletoCreationStep3 ~ boletoCreationStep2`,
      boletoCreationStep2,
    )
    console.log(
      `🚀 ~ file: index.tsx ~ line 9 ~ BoletoCreationStep3 ~ boletoCreationStep1`,
      boletoCreationStep1,
    )
  }, [])

  return (
    <>
      {!isTabletOrMobile ? (
        <>
          <div className="bc-step-3">
            <div className="bc-step-3-info">
              <div className="row bc-step-3-row row-mx-wd-step-3 bc-step-3-border-bottom">
                <div className="col bc-step-3-grid-col mx-wd-289">
                  <span className="bc-step-3-title">Dados do pagador</span>

                  <div className="bc-step-3-item-div">
                    <span className="bc-step-3-label">
                      Nome do destinatário
                    </span>
                    {boletoCreationStep1 && boletoCreationStep1.recipient && (
                      <span className="bc-step-3-value">
                        {boletoCreationStep1.recipient}
                      </span>
                    )}
                  </div>

                  <div className="bc-step-3-item-div mt-8">
                    <span className="bc-step-3-label">E-mail</span>
                    {boletoCreationStep1 && boletoCreationStep1.email && (
                      <span className="bc-step-3-value">
                        {boletoCreationStep1.email}
                      </span>
                    )}
                  </div>

                  <div className="bc-step-3-item-div mt-8">
                    <span className="bc-step-3-label">CPF/CNPJ</span>
                    {boletoCreationStep1 && boletoCreationStep1.cpfCnpj && (
                      <span className="bc-step-3-value">
                        {boletoCreationStep1.cpfCnpj}
                      </span>
                    )}
                  </div>

                  <div className="bc-step-3-item-div mt-8">
                    <span className="bc-step-3-label">Telefone</span>
                    {boletoCreationStep1 && boletoCreationStep1.phone && (
                      <span className="bc-step-3-value">
                        {boletoCreationStep1.phone}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col bc-step-3-grid-col mx-wd-202">
                  <span className="bc-step-3-title">Endereço</span>

                  <div className="bc-step-3-item-div">
                    <span className="bc-step-3-label">CEP</span>

                    {boletoCreationStep1 && boletoCreationStep1.cep && (
                      <span className="bc-step-3-value">
                        {boletoCreationStep1.cep}
                      </span>
                    )}
                  </div>

                  <div className="bc-step-3-item-div mt-8">
                    <span className="bc-step-3-label">Endereço</span>
                    {boletoCreationStep1 && boletoCreationStep1.logradouro && (
                      <span className="bc-step-3-value">
                        {boletoCreationStep1.logradouro}
                      </span>
                    )}
                  </div>

                  <div className="bc-step-3-item-div mt-8">
                    {boletoCreationStep1 && boletoCreationStep1.complemento && (
                      <>
                        <span className="bc-step-3-label">Complemento</span>
                        <span className="bc-step-3-value">
                          {boletoCreationStep1.complemento}
                        </span>
                      </>
                    )}
                  </div>

                  <div className="bc-step-3-item-div mt-8">
                    <span className="bc-step-3-label">Cidade</span>
                    {boletoCreationStep1 && boletoCreationStep1.cidade && (
                      <span className="bc-step-3-value">
                        {boletoCreationStep1.cidade}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col bc-step-3-grid-col">
                  <div className="bc-step-3-item-div mt-81">
                    <span className="bc-step-3-label">Número</span>
                    {boletoCreationStep1 && boletoCreationStep1.numero && (
                      <span className="bc-step-3-value">
                        {boletoCreationStep1.numero}
                      </span>
                    )}
                  </div>

                  <div className="bc-step-3-item-div mt-8">
                    <span className="bc-step-3-label">Bairro</span>
                    {boletoCreationStep1 && boletoCreationStep1.bairro && (
                      <span className="bc-step-3-value">
                        {boletoCreationStep1.bairro}
                      </span>
                    )}
                  </div>

                  <div className="bc-step-3-item-div mt-8">
                    <span className="bc-step-3-label">Estado</span>
                    {boletoCreationStep1 && boletoCreationStep1.uf && (
                      <span className="bc-step-3-value">
                        {boletoCreationStep1.uf}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row bc-step-3-row row-mx-wd-step-3 pb-0">
                <div className="col mn-wd-275 mx-wd-275">
                  <span className="bc-step-3-title">Dados da cobrança</span>

                  <div className="bc-step-3-item-div mx-wd-171 mt-11">
                    <span className="bc-step-3-label">
                      Data e hora da cobrança
                    </span>
                    <span className="bc-step-3-value">
                      {dayjs().format(`DD/MM/YYYY`)}, às
                      {dayjs().format(`HH:mm`)}
                    </span>
                  </div>

                  <div className="bc-step-3-item-div mx-wd-171">
                    <span className="bc-step-3-label">Data de vencimento</span>

                    {boletoCreationStep2 && boletoCreationStep2.dueDate && (
                      <span className="bc-step-3-value">
                        {dayjs(boletoCreationStep2.dueDate).format(
                          `DD/MM/YYYY`,
                        )}
                      </span>
                    )}
                  </div>

                  <div className="bc-step-3-item-div mx-wd-171">
                    <span className="bc-step-3-label">Data limite</span>
                    {boletoCreationStep2 && boletoCreationStep2.deadline && (
                      <span className="bc-step-3-value">
                        {dayjs(boletoCreationStep2.deadline).format(
                          `DD/MM/YYYY`,
                        )}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col mn-wd-275 mx-wd-275">
                  <span className="bc-step-3-title">Dados do pagamento</span>
                  <div className="bc-step-3-item-div mx-wd-171 mt-11">
                    <span className="bc-step-3-label">Valor original</span>
                    {boletoCreationStep2 ? (
                      boletoCreationStep2.nominalValue && (
                        <span className="bc-step-3-value">
                          {formattedCurrency(boletoCreationStep2.nominalValue)}
                        </span>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="bc-step-3-item-div">
                    {boletoCreationStep2 && boletoCreationStep2.reduction && (
                      <>
                        <span className="bc-step-3-label">Abatimento</span>
                        <span className="bc-step-3-value">
                          {formattedCurrency(boletoCreationStep2.reduction)}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                <div className="col">
                  <span className="bc-step-3-title">Juros</span>

                  <div
                    className={
                      boletoCreationStep2.fee?.code === `5`
                        ? `bc-step-3-item-div mt-11`
                        : `bc-step-3-item-div mn-hg-76 mt-11`
                    }
                  >
                    <span className="bc-step-3-label">Tipo</span>
                    {boletoCreationStep2.fee?.code === `1` && (
                      <span className="bc-step-3-value">
                        Valor (dias corridos)
                      </span>
                    )}
                    {boletoCreationStep2.fee?.code === `3` && (
                      <span className="bc-step-3-value">
                        Percentual ao mês (dias corridos)
                      </span>
                    )}
                    {boletoCreationStep2.fee?.code === `6` && (
                      <span className="bc-step-3-value">
                        Valor (dias úteis)
                      </span>
                    )}
                    {boletoCreationStep2.fee?.code === `8` && (
                      <span className="bc-step-3-value">
                        Percentual ao mês (dias úteis)
                      </span>
                    )}
                    {boletoCreationStep2.fee?.code === `5` && (
                      <span className="bc-step-3-value">Isento</span>
                    )}
                  </div>

                  {boletoCreationStep2.fee?.code !== `5` && (
                    <>
                      <div className="bc-step-3-item-div mt-8">
                        <span className="bc-step-3-label">Valor</span>
                        {(boletoCreationStep2.fee?.code === `1` ||
                          boletoCreationStep2.fee?.code === `6`) && (
                          <span className="bc-step-3-value">
                            {formattedCurrency(boletoCreationStep2.fee?.value)}
                          </span>
                        )}
                        {(boletoCreationStep2.fee?.code === `3` ||
                          boletoCreationStep2.fee?.code === `8`) && (
                          <span className="bc-step-3-value">
                            {boletoCreationStep2.fee?.value}%
                          </span>
                        )}
                      </div>

                      <div className="bc-step-3-item-div mt-8">
                        <span className="bc-step-3-label">Data inicial</span>
                        <span className="bc-step-3-value">
                          {dayjs(boletoCreationStep2.fee?.date).format(
                            `DD/MM/YYYY`,
                          )}
                        </span>
                      </div>
                    </>
                  )}
                </div>

                <div className="col">
                  <span className="bc-step-3-title">Multa</span>

                  <div
                    className={
                      boletoCreationStep2.fine?.code === `3`
                        ? `bc-step-3-item-div mt-11`
                        : `bc-step-3-item-div mn-hg-76 mt-11`
                    }
                  >
                    <span className="bc-step-3-label">Tipo</span>

                    {boletoCreationStep2.fine?.code === `1` && (
                      <span className="bc-step-3-value">Valor fixo</span>
                    )}
                    {boletoCreationStep2.fine?.code === `2` && (
                      <span className="bc-step-3-value">Percentual</span>
                    )}
                    {boletoCreationStep2.fine?.code === `3` && (
                      <span className="bc-step-3-value">Isento</span>
                    )}
                  </div>

                  {boletoCreationStep2.fine?.code !== `3` && (
                    <>
                      <div className="bc-step-3-item-div mt-8">
                        <span className="bc-step-3-label">Valor</span>
                        {boletoCreationStep2.fine?.code === `1` && (
                          <span className="bc-step-3-value">
                            {formattedCurrency(boletoCreationStep2.fine?.value)}
                          </span>
                        )}

                        {boletoCreationStep2.fine?.code === `2` && (
                          <span className="bc-step-3-value">
                            {boletoCreationStep2.fine?.value}%
                          </span>
                        )}
                      </div>

                      <div className="bc-step-3-item-div mt-8">
                        <span className="bc-step-3-label">Data inicial</span>
                        <span className="bc-step-3-value">
                          {dayjs(boletoCreationStep2.fine?.date).format(
                            `DD/MM/YYYY`,
                          )}
                        </span>
                      </div>
                    </>
                  )}
                </div>

                <div className="col">
                  <span className="bc-step-3-title">Desconto</span>
                  <div
                    className={
                      boletoCreationStep2.discount?.code === `0`
                        ? `bc-step-3-item-div mt-11`
                        : `bc-step-3-item-div mn-hg-76 mt-11`
                    }
                  >
                    <span className="bc-step-3-label">Tipo</span>
                    {boletoCreationStep2.discount?.code === `0` && (
                      <span className="bc-step-3-value">Isento</span>
                    )}
                    {boletoCreationStep2.discount?.code === `1` && (
                      <span className="bc-step-3-value">
                        Valor fixo até a data informada
                      </span>
                    )}
                    {boletoCreationStep2.discount?.code === `2` && (
                      <span className="bc-step-3-value">
                        Percentual até a data informada
                      </span>
                    )}
                    {boletoCreationStep2.discount?.code === `3` && (
                      <span className="bc-step-3-value">
                        Valor por antecipação dia corrido
                      </span>
                    )}
                    {boletoCreationStep2.discount?.code === `5` && (
                      <span className="bc-step-3-value">
                        Percentual por antecipação dia corrido
                      </span>
                    )}
                  </div>
                  {boletoCreationStep2.discount?.code !== `0` && (
                    <>
                      <div className="bc-step-3-item-div mt-8">
                        <span className="bc-step-3-label">Valor</span>
                        {(boletoCreationStep2.discount?.code === `1` ||
                          boletoCreationStep2.discount?.code === `3`) && (
                          <span className="bc-step-3-value">
                            {formattedCurrency(
                              boletoCreationStep2.discount?.value,
                            )}
                          </span>
                        )}

                        {(boletoCreationStep2.discount?.code === `2` ||
                          boletoCreationStep2.discount?.code === `5`) && (
                          <span className="bc-step-3-value">
                            {boletoCreationStep2.discount?.value}%
                          </span>
                        )}
                      </div>
                      <div className="bc-step-3-item-div mt-8">
                        <span className="bc-step-3-label">Data final</span>
                        <span className="bc-step-3-value">
                          {dayjs(boletoCreationStep2.discount?.date).format(
                            `DD/MM/YYYY`,
                          )}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="bc-row-btns">
              <button
                className="bc-btn-transparent v-middle"
                onClick={handleCancel}
              >
                Cancelar
              </button>

              <button
                type="button"
                onClick={createBoleto}
                disabled={loading}
                className={
                  loading
                    ? `bc-btn-yellow bc-step-3-issue-btn v-middle no-pl`
                    : `bc-btn-yellow bc-step-3-issue-btn v-middle`
                }
              >
                {loading ? (
                  <div className="bc-spinner-btn" role="status"></div>
                ) : (
                  <>
                    <span>
                      Emitir Boleto
                      <img
                        src={Arrow}
                        className="bc-step-3-issue-btn-arrow"
                        onLoad={(e) => SVGInject(e.target)}
                      />
                    </span>
                  </>
                )}
              </button>
            </div>
          </div>
          <ErrorModal
            isOpen={showErrorModal}
            width={`500px`}
            height={`auto`}
            onClose={() => setShowErrorModal(false)}
          >
            <h1 className="pix-modal-sucess-h1">
              {modalErrorMessage !== ``
                ? modalErrorMessage
                : `Houve um erro ao realizar essa ação, tente novamente mais tarde.`}
            </h1>
          </ErrorModal>
        </>
      ) : (
        <>
          <BoletoCreationStep3Mobile
            createBoleto={createBoleto}
            loading={loading}
            handleCancel={handleCancel}
          />
          <ErrorModal
            isOpen={showErrorModal}
            width={`100%`}
            height={`auto`}
            onClose={() => setShowErrorModal(false)}
          >
            {modalErrorMessage !== ``
              ? modalErrorMessage
              : `Houve um erro ao realizar essa ação, tente novamente mais tarde.`}
          </ErrorModal>
        </>
      )}
    </>
  )
}

export default BoletoCreationStep3
