import { useContext, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link, useParams } from "react-router-dom"
import { AuthContext } from "../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import { TransferContext } from "../../../../../context/TransferContext"
import { formattedCurrency } from "../../../../../services/banking"
import { price_to_number } from "../../../../../utils/validation"
import { CurrencyInput, CustomModal } from "k4n-components"
import SVGInject from "@iconfu/svg-inject"
import key from "../../../../../assets/images/key.svg"
import TransferNav from "../transfer-nav"
import "./styles.scss"
import { BalanceContext } from "../../../../../context/BalanceProvider"
import { useResposive } from "../../../../../hooks/useResponsive"
import TransferStep1Mobile from "./mobile"

const TransferStep1 = () => {
  const { transferStep, changeTransferStep } = useContext(
    BankingNavigationContext,
  )
  const { hasDigitalSignature } = useContext(AuthContext)
  const [digitalSignatureModal, setDigitalSignatureModal] = useState(false)
  const { addTransferValue } = useContext(TransferContext)
  const { balance, loadingBalance, refreshBalance } = useContext(BalanceContext)
  const [transferValue, setTransferValue] = useState<number>(0)
  const queryParameters = useParams()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const { isTabletOrMobile } = useResposive()
  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTransferValue(price_to_number(e.target.value))
  }

  const onSubmit = () => {
    if (hasDigitalSignature) {
      addTransferValue(transferValue)
      changeTransferStep(2)
    } else setDigitalSignatureModal(true)
  }

  const getValueToTransfer = () => {
    return queryParameters.transferAmount || `R$00,00`
  }

  useEffect(() => {
    refreshBalance()
  }, [])

  return (
    <>
      {!isTabletOrMobile ? (
        <>
          <div className="transfer-container">
            <CustomModal
              isOpen={digitalSignatureModal}
              onClose={() => {
                setDigitalSignatureModal(false)
              }}
              isBanking={true}
            >
              <div style={{ width: `120px`, margin: `0 auto` }}>
                <img
                  src={key}
                  alt="/"
                  width="120"
                  height="120"
                  className="key-vector"
                  onLoad={(e) => SVGInject(e.target)}
                />
              </div>
              <h1 className="digital-signature-h1">
                Crie agora sua assinatura digital <br /> e aproveite todos os
                recursos de sua conta!
              </h1>
              <div className="flex-center mt-3">
                <Link
                  style={{ textDecoration: `none` }}
                  to="/digital-signature"
                >
                  <button className="home-bold-btn">Criar</button>
                </Link>
              </div>
            </CustomModal>
            <TransferNav step={transferStep} stepTitle="Nova transferência" />
            <div className="panel-content">
              <div className="panel-title">
                <span className="title-blue">Qual valor da transferência?</span>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="transfer-value-div">
                  <div>
                    <div className="transfer-value-box">
                      <span className="transfer-value-label">R$</span>
                      <Controller
                        name="transferValue"
                        control={control}
                        defaultValue={getValueToTransfer}
                        rules={{
                          required: true,
                          validate: () => {
                            return balance >= transferValue && transferValue > 0
                          },
                          onChange: onValueChange,
                        }}
                        render={({ field }) => (
                          <CurrencyInput
                            value={field.value}
                            options={{ style: `decimal`, allowNegative: false }}
                            onChangeEvent={(_, maskedValue) => {
                              field.onChange(maskedValue)
                            }}
                            required={true}
                            className="transfer-value-input"
                          />
                        )}
                      />
                    </div>
                    {errors.transferValue && (
                      <p className="transfer-step1-error">
                        Por favor, verifique o valor.
                      </p>
                    )}
                  </div>
                  <div className="info-div">
                    <h1 className="info-span-bold-16">Saldo Kikkin:</h1>
                    <h1 className="info-span-grey-16">
                      {loadingBalance ? (
                        <div className="emptySpace-pix gradient"></div>
                      ) : (
                        <span className="content-table-step2-pix-txt">
                          {formattedCurrency(balance)}
                        </span>
                      )}
                    </h1>
                  </div>
                </div>
                <div className="transfer-step-1-btn">
                  <button
                    type="submit"
                    className="transfer-submit-btn transfer-submit-btn-txt"
                  >
                    Próximo
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <div className="transfer-mobile-wrapper">
          <TransferStep1Mobile
            onSubmit={onSubmit}
            setTransferValue={setTransferValue}
            transferValue={transferValue}
          />
        </div>
      )}
    </>
  )
}

export default TransferStep1
