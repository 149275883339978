import "./styles.scss"
import BoletoCreationNavigation from "./navigation"
import BoletoCreationStep1 from "./step1"
import { useContext } from "react"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import BoletoCreationStep2 from "./step2"
import BoletoCreationStep3 from "./step3"
import { useResposive } from "../../../../../hooks/useResponsive"
import BoletoCreationStep1Mobile from "./step1/mobile"
import BoletoCreationStep2Mobile from "./step2/mobile"

export interface Props {
  handleCancel: () => void
  handleSuccess: (barCode: string) => void
}

const BoletoCreation: React.FC<Props> = ({ handleCancel, handleSuccess }) => {
  const { boletoCreationStep } = useContext(BankingNavigationContext)
  const { isTabletOrMobile } = useResposive()

  return (
    <>
      {!isTabletOrMobile && (
        <div className="boleto-creation-container">
          <div className="boleto-creation">
            <h1 className="boleto-creation-title vertical-center">
              Novo Boleto
            </h1>
            <BoletoCreationNavigation />
          </div>
        </div>
      )}
      {boletoCreationStep === 1 && (
        <BoletoCreationStep1 handleCancel={handleCancel} />
      )}
      {boletoCreationStep === 2 && (
        <BoletoCreationStep2 handleCancel={handleCancel} />
      )}
      {boletoCreationStep === 3 && (
        <BoletoCreationStep3
          handleSuccess={handleSuccess}
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}
export default BoletoCreation
