import ptBR from "date-fns/locale/pt-BR"
import dayjs from "dayjs"
import { FocusEvent, useContext, useEffect, useState } from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import { Controller, useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import Arrow from "../../../../../../assets/images/arrow.svg"
import Spinner from "../../../../../../assets/images/spinner-white.svg"
import X from "../../../../../../assets/images/x.svg"
import { AuthContext } from "../../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { InsuranceContext } from "../../../../../../context/InsuranceContext"
import { Step1Data } from "../../../../../../typings/insurance.dto"
import { CustomModal } from "k4n-components"
import InsurancePlan from "../../plano"
import SVGInject from "@iconfu/svg-inject"
import "./styles.scss"
import { useResposive } from "../../../../../../hooks/useResponsive"
import services from "../../../../../../services/svcmesh"

registerLocale(`pt-BR`, ptBR)

interface InputFocus {
  cpf?: boolean
  fullName?: boolean
  dateInput?: boolean
  email?: boolean
  phone?: boolean
  marital?: boolean
}

interface Latlong {
  lat?: number
  long?: number
}

const InsuranceStep1 = () => {
  const defaultFocus = {
    cpf: true,
    fullName: true,
    dateInput: true,
    email: true,
    phone: true,
    marital: false,
  } as InputFocus

  const {
    step1Data,
    hasBeneficiary,
    addStep1,
    addStep1Valid,
    addHasBeneficiary,
  } = useContext(InsuranceContext)

  const [gender, setGender] = useState(0)
  const [representativeModal, setRepresentativeModal] = useState(false)
  const [inputCode, setInputCode] = useState(``)
  const [locationError, setLocationError] = useState(false)
  const [modalLocation, setModalLocation] = useState(false)
  const [latlong, setLatLong] = useState<Latlong>({})
  const [checkedBeneficiary, setCheckedBeneficiary] = useState(
    hasBeneficiary ? hasBeneficiary : false,
  )
  const [focusList, setFocusList] = useState<InputFocus>(defaultFocus)
  const { changeInsuranceStep } = useContext(BankingNavigationContext)
  const { user } = useContext(AuthContext)
  const [filledRepresentative, setFilledRepresentative] = useState(false)
  const [affiliateName, setAffiliateName] = useState(``)
  const [affiliateNotFound, setAffiliateNotFound] = useState(false)
  const [getAffiliateLoading, setGetAffiliateLoading] = useState(false)
  const { isTabletOrMobile } = useResposive()

  useEffect(function () {
    // setGender(user?.sexo === `M` ? 1 : 0)

    if (!filledRepresentative && step1Data.representativeCode) {
      setInputCode(step1Data.representativeCode.toString())
      setFilledRepresentative(true)
    }
    if (`geolocation` in navigator) {
      fetchGeolocaton()
    } else {
      setLocationError(true)
    }
  })

  const fetchGeolocaton = async () => {
    navigator.geolocation.watchPosition(
      function (position) {
        const location = {
          lat: position.coords.latitude,
          long: position.coords.longitude,
        } as Latlong
        setLatLong(location)
        setLocationError(false)
      },
      function (error) {
        if (error.code === error.PERMISSION_DENIED) setLocationError(true)
      },
    )
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cpf: user?.cpf,
      fullName: user?.nome,
      dateInput: dayjs(user?.dtNasc).toDate(),
      email: user?.emails[0]?.email,
      phone: user?.phones[0]?.phone,
      marital: user?.estadoCivil || 0,
    },
  })

  const handleFocus = (
    event:
      | FocusEvent<HTMLInputElement>
      | FocusEvent<HTMLSelectElement, Element>,
  ) => {
    switch (event.target.id) {
      case `cpf`:
        setFocusList({ cpf: true })
        break
      case `fullName`:
        setFocusList({ fullName: true })
        break
      case `dateInput`:
        setFocusList({ dateInput: true })
        break
      case `email`:
        setFocusList({ email: true })
        break
      case `phone`:
        setFocusList({ phone: true })
        break
      case `marital`:
        setFocusList({ marital: true })
        break
    }
  }

  const handleFocusEnd = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target.value === ``) {
      setFocusList(defaultFocus)
    }
  }

  /* const handleSelectFocusEnd = (event: FocusEvent<HTMLSelectElement>) => {
    if (parseInt(event.target.value) === 0) {
      setFocusList(defaultFocus)
    }
  } */

  const Agreed = () => {
    changeInsuranceStep(2)
  }

  const checked = () => {
    setCheckedBeneficiary(!checkedBeneficiary)
    addHasBeneficiary(!checkedBeneficiary)
  }

  const onSubmit = (data: any) => {
    const sendObj = {
      cpf: data?.cpf.replaceAll(`.`, ``).replace(`-`, ``),
      fullName: data?.fullName,
      birthDate: data?.dateInput,
      email: data?.email,
      gender: gender,
      maritalStatus: data?.marital,
      phone: data?.phone,
      lat: latlong.lat,
      long: latlong.long,
    } as Step1Data

    if (inputCode !== ``) sendObj.representativeCode = parseInt(inputCode)
    addHasBeneficiary(checkedBeneficiary)
    console.log(
      `🚀 ~ file: index.tsx ~ line 175 ~ onSubmit ~ checkedBeneficiary`,
      checkedBeneficiary,
    )
    addStep1(sendObj)
    addStep1Valid(true)
    setModalLocation(true)
  }

  const validateAge = (date: Date) => {
    const timeDiff = Math.abs(Date.now() - date.getTime())
    const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25)
    if (age >= 18 && age <= 69) return true
    else return false
  }

  const handleOkAffiliateClick = async () => {
    try {
      setGetAffiliateLoading(true)
      const { data } = await services.bankingService.getAffiliateById(
        parseInt(inputCode),
        user?.cpf ? user.cpf : ``,
      )
      setAffiliateName(data?.name)
      setRepresentativeModal(false)
      setGetAffiliateLoading(false)
      setAffiliateNotFound(false)
    } catch (e) {
      setGetAffiliateLoading(false)
      setAffiliateNotFound(true)
      setAffiliateName(``)
    }
  }

  const onCloseAffiliateModal = () => {
    if (!affiliateName) {
      setInputCode(``)
    }
    setRepresentativeModal(false)
    setAffiliateNotFound(false)
  }

  return (
    <>
      {locationError ? (
        <div className="location-error row">
          <div className="col-12 no-padding-flx">
            <div className="col-1 dp-table">
              <div className="location-error-red-bar"></div>
              <div className="dp-table-cell pl-15">
                <div className="location-error-red-circle">
                  <div className="location-error-x">
                    <img
                      src={X}
                      className="location-error-x-img"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-11 dp-table location-error-msg-div pl-15">
              <span className="location-error-txt">
                Por favor, ative a localização para continuar a contratação do
                seguro.
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={
            isTabletOrMobile ? `insurance-step-1-mobile` : `insurance-step-1`
          }
        >
          <div className="insurance-content-step1">
            <div className="insurance-content-step-1">
              <div className="insurance-step-1-details">
                <InsurancePlan />
              </div>
              <div className="insurance-step-1-input">
                <form onSubmit={handleSubmit(onSubmit)} className="Pv-kikkin">
                  <div className="insurance-input-div">
                    <label
                      className={
                        focusList.cpf
                          ? `insurance-input-label show-input`
                          : `insurance-input-label`
                      }
                      htmlFor="cpf"
                    >
                      CPF
                    </label>
                    <InputMask
                      mask="999.999.999-99"
                      maskPlaceholder={null}
                      type="text"
                      id="cpf"
                      placeholder="Qual seu CPF?"
                      className="insurance-input insurance-disabled-bg"
                      onFocus={handleFocus}
                      disabled={true}
                      {...register(`cpf`, {
                        onBlur: handleFocusEnd,
                      })}
                    />
                    {errors.cpf && (
                      <p className="insurance-error-msg">
                        Por favor, verifique o cpf informado.
                      </p>
                    )}
                  </div>

                  <div className="insurance-input-div mt-16">
                    <label
                      className={
                        focusList.fullName
                          ? `insurance-input-label show-input`
                          : `insurance-input-label`
                      }
                      htmlFor="fullName"
                    >
                      Nome completo
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      maxLength={120}
                      placeholder="Qual seu nome completo?"
                      className="insurance-input insurance-disabled-bg"
                      onFocus={handleFocus}
                      disabled={true}
                      {...register(`fullName`, {
                        required: true,
                        pattern: /^(([A-zÀ-ÿ]+)(\ )?){0,}$/,
                        onBlur: handleFocusEnd,
                      })}
                    />
                    {errors.fullName && (
                      <p className="insurance-error-msg">
                        Por favor, verique o nome.
                      </p>
                    )}
                  </div>

                  <div className="insurance-input-div mt-16">
                    <label
                      className={`insurance-input-label show-input`}
                      htmlFor="dateInput"
                    >
                      Data de nascimento
                    </label>
                    <Controller
                      control={control}
                      name="dateInput"
                      rules={{
                        required: true,
                        validate: validateAge,
                      }}
                      render={({ field }) => (
                        <DatePicker
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          locale="pt-BR"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Qual sua data de nascimento?"
                          className="insurance-input insurance-disabled-bg"
                          id="dateInput"
                          disabled={true}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          required={true}
                          customInput={
                            <InputMask
                              mask="99/99/9999"
                              maskPlaceholder={null}
                            />
                          }
                        />
                      )}
                    />
                    {errors.dateInput && (
                      <p className="insurance-error-msg">
                        O contratante deve ter no mínimo 18 anos e no máximo 70
                        anos.
                      </p>
                    )}
                  </div>
                  <div className="toggle-kikkin mt-16 row nowrap">
                    <div className="col-2 toggle-kikkin-col">
                      <span className="toggle-kikkin-label">Sexo:</span>
                    </div>
                    <div className="col-5 insurance-step1-gender">
                      <button
                        type="button"
                        className={
                          `btn toggle-kikkin-btn ` +
                          (gender === 0 ? `toggle-active` : ``)
                        }
                        style={{ padding: 0 }}
                        onClick={() => setGender(0)}
                      >
                        <span className="toggle-kikkin-span">Feminino</span>
                      </button>
                      <button
                        type="button"
                        className={
                          `btn toggle-kikkin-btn ` +
                          (gender === 1 ? `toggle-active` : ``)
                        }
                        style={{ padding: 0 }}
                        onClick={() => setGender(1)}
                      >
                        <span className="toggle-kikkin-span">Masculino</span>
                      </button>
                    </div>
                  </div>
                  <div className="insurance-input-div mt-16">
                    <label
                      className={
                        focusList.email
                          ? `insurance-input-label show-input`
                          : `insurance-input-label`
                      }
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      maxLength={300}
                      placeholder="Qual seu melhor email?"
                      className="insurance-input insurance-disabled-bg"
                      onFocus={handleFocus}
                      disabled={true}
                      {...register(`email`, {
                        required: true,
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        onBlur: handleFocusEnd,
                      })}
                    />
                    {errors.email && (
                      <p className="insurance-error-msg">
                        Por favor, verique o email.
                      </p>
                    )}
                  </div>
                  <div className="insurance-input-div mt-16">
                    <label
                      className={
                        focusList.phone
                          ? `insurance-input-label show-input`
                          : `insurance-input-label`
                      }
                      htmlFor="phone"
                    >
                      Celular
                    </label>
                    <InputMask
                      mask="(99) 99999-9999"
                      maskPlaceholder={null}
                      type="text"
                      id="phone"
                      placeholder="E qual seu celular?"
                      className="insurance-input insurance-disabled-bg"
                      onFocus={handleFocus}
                      disabled={true}
                      defaultValue={step1Data?.phone}
                      {...register(`phone`, {
                        onBlur: handleFocusEnd,
                      })}
                    />
                    {errors.phone && (
                      <p className="insurance-error-msg">
                        Por favor, verifique o celular informado.
                      </p>
                    )}
                  </div>
                  <div className="insurance-select-div mt-16">
                    <select
                      className="form-select-marital"
                      {...register(`marital`, { required: true, min: 1 })}
                    >
                      <option value="0" disabled selected>
                        Qual seu estado civil?
                      </option>
                      <option value="1">Solteiro (a)</option>
                      <option value="2">Casado (a)</option>
                      <option value="3">Desquitado (a)</option>
                      <option value="4">Divorciado (a)</option>
                      <option value="5">Separado (a)</option>
                      <option value="6">Viuvo (a)</option>
                      <option value="7">Outros (as)</option>
                      <option value="8">Não informado</option>
                      <option value="9">Vinculo Conjugal</option>
                    </select>
                  </div>
                  {errors.marital && (
                    <p className="insurance-error-msg">
                      Por favor, verifique o estado civil.
                    </p>
                  )}
                  <div className="mt-16 representative">
                    {affiliateName !== `` ? (
                      <>
                        <span className="representative-label">
                          Representante: {affiliateName}
                        </span>
                        <a
                          className="representative-code"
                          onClick={() => setRepresentativeModal(true)}
                        >
                          Alterar
                        </a>
                      </>
                    ) : (
                      <>
                        <span className="representative-label">
                          Possui código do representante?
                        </span>
                        <a
                          className="representative-code"
                          onClick={() => setRepresentativeModal(true)}
                        >
                          Informe o código
                        </a>
                      </>
                    )}
                  </div>
                  <div className="insurance-row-check">
                    <input
                      onClick={() => checked()}
                      type="button"
                      className={
                        checkedBeneficiary
                          ? `insurance-checkbox insurance-checked`
                          : `insurance-checkbox`
                      }
                    />
                    <span className="insurance-recipient-txt">
                      Incluir beneficiários
                    </span>
                  </div>
                </form>
              </div>
            </div>

            <div className="insurance-btn-col">
              <button
                type="submit"
                onClick={handleSubmit(onSubmit)}
                className="col insurance-step-1-btn"
              >
                <span className=" insurance-step-1-btn-txt">Contratar</span>
                <img
                  src={Arrow}
                  width="30"
                  height="16"
                  onLoad={(e) => SVGInject(e.target)}
                />
              </button>
            </div>
          </div>

          <CustomModal
            isOpen={representativeModal}
            onClose={onCloseAffiliateModal}
            containerBg="#FFFFFF"
          >
            <div className="representative-modal-body">
              <div className="representative-modal-input">
                <span className="representative-modal-title">
                  Digite o código do representante.
                </span>
                <input
                  type="text"
                  className="insurance-input mt-16"
                  name="representante"
                  placeholder="Código"
                  maxLength={10}
                  value={inputCode}
                  pattern="[0-9]*"
                  onChange={(e) =>
                    setInputCode((v) =>
                      e.target.validity.valid ? e.target.value : v,
                    )
                  }
                />
                {affiliateNotFound && (
                  <p className="insurance-error-msg">
                    Representante não encontrado para o código informado.
                  </p>
                )}
                <button
                  type="button"
                  disabled={getAffiliateLoading}
                  onClick={handleOkAffiliateClick}
                  className="representative-modal-btn"
                >
                  <span className="representative-modal-btn-txt">OK</span>
                  {!getAffiliateLoading ? (
                    <img
                      src={Arrow}
                      className="arrow-next"
                      alt=">"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  ) : (
                    <img
                      style={{ width: 50 }}
                      src={Spinner}
                      alt="loading"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  )}
                </button>
              </div>
            </div>
          </CustomModal>

          <CustomModal
            isOpen={modalLocation}
            onClose={() => setModalLocation(false)}
            containerBg="#FFFFFF"
          >
            <div className="representative-modal-body">
              <div className="location-modal-content">
                <span className="location-modal-txt">
                  Ao prosseguir, você concorda em conceder sua localização para
                  a realização do cadastro.
                </span>
                <button
                  type="button"
                  onClick={() => Agreed()}
                  className="location-modal-btn"
                >
                  <span className="representative-modal-btn-txt">OK</span>
                  <img
                    src={Arrow}
                    className="arrow-next"
                    alt=">"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </button>
              </div>
            </div>
          </CustomModal>
        </div>
      )}
    </>
  )
}
export default InsuranceStep1
