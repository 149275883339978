import { useContext, useState } from "react"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import arrow_right from "../../../../../../assets/images/arrow-right-yellow.svg"
import { CustomModal, CurrencyInput, PrimaryButton } from "k4n-components"
import close from "../../../../../../assets/images/clear_x.svg"
import SVGInject from "@iconfu/svg-inject"
import "./styles.scss"
import { Controller } from "react-hook-form"
import { PixStorageContext } from "../../../../../../context/PixStorageProvider"

const PixReceiveMobile = ({
  handleSubmit,
  onSubmit,
  control,
  onValueChange,
  errors,
  setPixKeyType,
  pixKeyType,
  btnLoading,
  register,
}) => {
  const { userPixKeys } = useContext(PixStorageContext)
  const { changePixEnvironment } = useContext(BankingNavigationContext)
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState(``)

  return (
    <div className="pix-payment-wrapper-mobile">
      <div className="pix-payment-step1-mobile">
        <form onSubmit={handleSubmit(onSubmit)}>
          <span
            className="pix-receipt-img-close"
            onClick={() => changePixEnvironment(1)}
          >
            <img
              src={close}
              width="24"
              height="24"
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </span>
          <h1 className="pix-receive-title-mobile">
            Qual o valor você quer receber?
          </h1>
          <div className="pix-value-box pix-receive-input-mobile">
            <span className="pix-value-label">R$</span>
            <Controller
              name="receiveValue"
              control={control}
              defaultValue="0,00"
              rules={{
                required: false,
                onChange: onValueChange,
              }}
              render={({ field }) => (
                <CurrencyInput
                  value={field.value}
                  options={{ style: `decimal`, allowNegative: false }}
                  onChangeEvent={(_, maskedValue) => {
                    field.onChange(maskedValue)
                  }}
                  required={false}
                  className="pix-value-input-mobile"
                />
              )}
            />
          </div>
          {errors.receiveValue && (
            <p className="pix-error-msg ">Por favor, verifique o valor.</p>
          )}
          <div
            className="pix-receive-date-mobile"
            onClick={() => setShowMessage(true)}
          >
            <div className="pix-payment-step3-message-mobile">
              <h1 className="pix-payment-step3-h1-mobile">
                Enviar uma mensagem
              </h1>
              <h1
                className={
                  `pix-payment-step3-txt-mobile ` +
                  (message && `nowrap-ellipsis`)
                }
              >
                {message ? message : `Use este espaço para mandar um recado`}
              </h1>
            </div>
            <img
              className="pix-payment-img"
              src={arrow_right}
              alt="/"
              onLoad={(e) => SVGInject(e.target)}
            />
          </div>
          <div className="pix-payment-step3-message-mobile">
            <h1 className="pix-payment-step3-h1-mobile">Escolha sua chave</h1>
          </div>
          <div className="pix-payment-step1-keys-mobile">
            {userPixKeys.cpf && (
              <div
                className={`pix-receive-keys`}
                onClick={() => {
                  setPixKeyType(0)
                }}
              >
                <span
                  className={
                    `pix-receive-key-btn ` +
                    (pixKeyType === 0 ? `pix-receive-key-btn-selected` : ``)
                  }
                ></span>
                <div>
                  <h1 className="pix-receive-keys-h1">CPF</h1>
                  <h1 className="pix-receive-keys-txt">{userPixKeys.cpf}</h1>
                </div>
              </div>
            )}
            {userPixKeys.celular && (
              <div
                className={`pix-receive-keys`}
                onClick={() => {
                  setPixKeyType(2)
                }}
              >
                <span
                  className={
                    `pix-receive-key-btn ` +
                    (pixKeyType === 2 ? `pix-receive-key-btn-selected` : ``)
                  }
                ></span>
                <div>
                  <h1 className="pix-receive-keys-h1">Chave de celular</h1>
                  <h1 className="pix-receive-keys-txt">
                    {userPixKeys.celular}
                  </h1>
                </div>
              </div>
            )}
            {userPixKeys.email && (
              <div
                className={`pix-receive-keys`}
                onClick={() => {
                  setPixKeyType(3)
                }}
              >
                <span
                  className={
                    `pix-receive-key-btn ` +
                    (pixKeyType === 3 ? `pix-receive-key-btn-selected` : ``)
                  }
                ></span>
                <div className="pix-receive-txts">
                  <h1 className="pix-receive-keys-h1">Chave de e-mail</h1>
                  <h1 className="pix-receive-keys-txt">
                    ashashagshagshaghsgahgsags@gmail.com
                  </h1>
                </div>
              </div>
            )}
            {userPixKeys.evp && (
              <div
                className={`pix-receive-keys`}
                onClick={() => {
                  setPixKeyType(4)
                }}
              >
                <span
                  className={
                    `pix-receive-key-btn ` +
                    (pixKeyType === 4 ? `pix-receive-key-btn-selected` : ``)
                  }
                ></span>
                <div className="pix-receive-txts">
                  <h1 className="pix-receive-keys-h1">Chave aleatória</h1>
                  <h1 className="pix-receive-keys-txt">{userPixKeys.evp}</h1>
                </div>
              </div>
            )}
          </div>
          <div className="justify-center">
            <PrimaryButton width={`312px`} height={`56px`} margin_top={`28px`}>
              {!btnLoading ? (
                `Gerar QR Code`
              ) : (
                <div className="spinner-border spinner-pix" role="status"></div>
              )}
            </PrimaryButton>
          </div>
          <CustomModal
            isOpen={showMessage}
            onClose={() => setShowMessage(false)}
            containerBg={`#FFFFFF`}
          >
            <div className="pix-payment-modal-mobile">
              <h1 className="pix-payment-step3-h1-mobile size-24">
                Escreva sua mensagem
              </h1>
              <textarea
                id="pix-payment-message"
                maxLength={140}
                className="pix-textarea-message-modal-mobile"
                placeholder="Digite sua mensagem aqui..."
                {...register(`message`, {
                  required: false,
                  onBlur: (e) => {
                    setMessage(e.currentTarget.value)
                  },
                })}
              />
              <PrimaryButton
                width={`312px`}
                height={`56px`}
                margin_top={`50px`}
                onClick={() => setShowMessage(false)}
              >
                Confirmar
              </PrimaryButton>
            </div>
          </CustomModal>
        </form>
      </div>
    </div>
  )
}
export default PixReceiveMobile
