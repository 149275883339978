import "bootstrap/dist/css/bootstrap.min.css"
import "react-datepicker/dist/react-datepicker.css"
import ReactDOM from "react-dom/client"
import App from "./components/App"
import "./index.css"
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById(`root`) as HTMLElement)

root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
