import dayjs from "dayjs"
import { ChangeEvent, useContext, useState } from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import CheckFailed from "../../../../assets/images/check-failed.svg"
import Check from "../../../../assets/images/check-green.svg"
import { AuthContext } from "../../../../context/AuthProvider"
import "./styles.scss"
import key from "../../../../assets/images/key.svg"
import { CustomModal } from "k4n-components"
import SVGInject from "@iconfu/svg-inject"
import Header from "../../../features/Header"
import NumericPasswordInput from "../../../features/NumericPasswordInput"
import services from "../../../../services/svcmesh"
import { CreateSrpDigitalSignatureDto } from "k4n-svcmesh-sdk"

const CreateDigitalSignature = () => {
  const {
    user,
    addHasDigitalSignature,
    hasDigitalSignature,
    digitalSignature,
    addDigitalSignature,
  } = useContext(AuthContext)
  const [validLength, setValidLength] = useState(false)
  const [notNumericSequence, setNotNumericSequence] = useState(false)
  const [notBirthdate, setNotBirthdate] = useState(false)
  const [errorDescription, setErrorDescription] = useState(``)
  const [isLoading, setIsLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [isChanging, setIsChanging] = useState(false)
  const [showSmsConfirmation, setShowSmsConfirmation] = useState(false)
  const [authorizeModal, setAuthorizeModal] = useState(false)
  // const [signature, setSignature] = useState(``)
  const [loadingSms, setLoadingSms] = useState(false)
  const {
    register,
    formState: { errors: errorsCreate },
    handleSubmit: handleSubmitCreate,
    watch,
  } = useForm({ reValidateMode: `onChange` })

  const onSubmitCreate = async (data: any) => {
    setIsLoading(true)
    if (!user)
      throw new Error(`User not logged in, can't create digital signature.`)

    if (!hasDigitalSignature) {
      const sanitizeCpf = user[`cpf`].replaceAll(`.`, ``).replace(`-`, ``)
      const signature = data?.signature
      const { salt, verifier } =
        await services.digitalSignatureService.createSrpVerifier(
          sanitizeCpf,
          signature,
        )
      const sigObj = {
        cpf: sanitizeCpf,
        salt: salt.toString(16),
        verifier: verifier.toString(16),
      } as CreateSrpDigitalSignatureDto
      console.log(
        `🚀 ~ file: index.tsx ~ line 41 ~ onSubmitCreate ~ sendObj`,
        sigObj,
      )
      try {
        const res = await services.authService.createDigitalSignature(sigObj)
        if (res && res?.status === 201) {
          setIsLoading(false)
          setShowSuccess(true)
          addHasDigitalSignature(true)
        }
      } catch (error: any) {
        const err = error
        setErrorDescription(err?.response?.data?.message)
        setIsLoading(false)
      }
    } else {
      authorizeSignatureChange(data?.signature)
      // setIsLoading(false)
      // setSignature(data?.signature)
    }
  }

  const authorizeSignatureChange = async (signature: string) => {
    setAuthorizeModal(false)
    setShowSmsConfirmation(true)
    setLoadingSms(true)
    if (user && user.cpf) {
      const sanitizeCpf = user[`cpf`].replaceAll(`.`, ``).replace(`-`, ``)
      const signatureCode = signature
      const { salt, verifier } =
        await services.digitalSignatureService.createSrpVerifier(
          sanitizeCpf,
          signatureCode,
        )
      const sigObj = {
        cpf: sanitizeCpf,
        salt: salt.toString(16),
        verifier: verifier.toString(16),
      } as CreateSrpDigitalSignatureDto
      addDigitalSignature(sigObj)
      await services.authService.sendConfirmationSms({ userId: user.id })
      setLoadingSms(false)
    }
  }

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    setErrorDescription(``)
    const signature = event.currentTarget.value
    const hasNotNumericSequence_ = hasNotNumericSequence(signature)
    const hasNotDate_ = hasNotDate(signature)
    setValidLength(false)
    if (signature.length === 8) {
      setValidLength(true)
    }
    if (hasNotNumericSequence_) setNotNumericSequence(true)
    else setNotNumericSequence(false)

    if (hasNotDate_) setNotBirthdate(true)
    else setNotBirthdate(false)
  }

  const hasNotNumericSequence = (value: any) => {
    setNotNumericSequence(false)
    const length = value.length
    for (let i = 0; i < length / 2; i++) {
      let new_str = value.substring(0, i + 1)
      let num = parseInt(new_str)
      while (new_str.length < length) {
        num++
        new_str = new_str + num.toString()
      }
      if (new_str == value) {
        setNotNumericSequence(false)
        return false
      }
    }
    setNotNumericSequence(true)
    return true
  }

  const hasNotDate = (value: any) => {
    const date =
      value.substr(0, 2) + `/` + value.substr(2, 2) + `/` + value.substr(4, 7)
    const result = dayjs(date, `DD/MM/YYYY`, true).isValid()
    if (
      result === true &&
      value.substr(4, 7) >= 1800 &&
      value.substr(4, 7) <= new Date().getFullYear()
    ) {
      setNotBirthdate(false)
      return false
    } else {
      setNotBirthdate(true)
      return true
    }
  }

  const validatePin = (value: any) => {
    const hasNotNumericSequence_ = hasNotNumericSequence(value)
    const hasNotDate_ = hasNotDate(value)
    if (hasNotNumericSequence_ && hasNotDate_) return true
    else return false
  }

  const handleVerifyPhone = async () => {
    let code = ``
    for (let i = 0; i < 5; i++) {
      const d = (document.getElementById(`code-` + i) as HTMLInputElement)
        ?.value
      code += d
    }
    console.log(code)
    if (code.length !== 5) {
      console.log(
        `🚀 ~ file: index.tsx ~ line 154 ~ handleVerifyPhone ~ code.length`,
        code.length,
      )
    } else {
      setLoadingSms(true)
      try {
        const res = await services.authService.changeDigitalSignature({
          cpf: user?.cpf,
          smsCode: code,
          salt: digitalSignature.salt,
          verifier: digitalSignature.verifier,
        })
        console.log(
          `🚀 ~ file: index.tsx ~ line 161 ~ handleVerifyPhone ~ res`,
          res,
        )
        setIsChanging(true)
        setShowSuccess(true)
        setLoadingSms(false)
      } catch (error: any) {
        setLoadingSms(false)
        const err = error
        setErrorDescription(err?.response?.data?.message)
      }
    }
  }

  const nextInput = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const digit = e.target.value
    if (digit) {
      if (Number(digit) || Number(digit) === 0) {
        if (index < 4) {
          document.getElementById(`code-` + (index + 1))?.focus()
        } else {
          handleVerifyPhone()
        }
      } else {
        e.target.value = ``
      }
    }
  }

  return (
    <>
      <Header bgColor={`var(--k4n-secondary-color)`} />
      <CustomModal
        isOpen={authorizeModal}
        onClose={() => {
          setAuthorizeModal(false)
        }}
        isBanking={true}
      >
        <div style={{ width: `120px`, margin: `0 auto` }}>
          <img
            src={key}
            alt="/"
            width="120"
            height="120"
            className="key-vector"
            onLoad={(e) => SVGInject(e.target)}
          />
        </div>
        {/* <DigitalSignature handleSuccess={authorizeSignatureChange} /> */}
      </CustomModal>
      <div className="signature-transaction-container">
        {hasDigitalSignature && !showSuccess ? (
          <div className="signature-transaction-title">
            {showSmsConfirmation ? (
              <h1 className="signature-transaction-h1">
                Preencha o código de confirmação que enviamos via SMS
              </h1>
            ) : (
              <h1 className="signature-transaction-h1">
                Altere sua assinatura digital
              </h1>
            )}
          </div>
        ) : (
          <div className="signature-transaction-title">
            {showSuccess ? (
              <>
                {isChanging ? (
                  <h1 className="signature-transaction-h1">
                    Assinatura digital alterada com sucesso!
                  </h1>
                ) : (
                  <h1 className="signature-transaction-h1">
                    Assinatura digital criada com sucesso!
                  </h1>
                )}
              </>
            ) : (
              <h1 className="signature-transaction-h1">
                Crie uma assinatura digital para transações futuras
              </h1>
            )}
          </div>
        )}

        {showSuccess ? (
          <div className="signature-transaction-form">
            <Link style={{ textDecoration: `none` }} to="/banking">
              <button className="signature-transaction-back">
                Voltar para o banking
              </button>
            </Link>
          </div>
        ) : (
          <>
            {showSmsConfirmation ? (
              <div className="signature-sms-form">
                <form className="d-flex mt-3">
                  {loadingSms ? (
                    <div className="signature-spinner"></div>
                  ) : (
                    <>
                      <input
                        className="phone-verify-input-signature"
                        id="code-0"
                        onChange={(e) => nextInput(e, 0)}
                        type="text"
                        maxLength={1}
                        size={1}
                        min={0}
                        max={9}
                        pattern="[0-9]{1}"
                      />
                      <input
                        className="phone-verify-input-signature"
                        id="code-1"
                        onChange={(e) => nextInput(e, 1)}
                        type="text"
                        maxLength={1}
                        size={1}
                        min={0}
                        max={9}
                        pattern="[0-9]{1}"
                      />
                      <input
                        className="phone-verify-input-signature"
                        id="code-2"
                        onChange={(e) => nextInput(e, 2)}
                        type="text"
                        maxLength={1}
                        size={1}
                        min={0}
                        max={9}
                        pattern="[0-9]{1}"
                      />
                      <input
                        className="phone-verify-input-signature"
                        id="code-3"
                        onChange={(e) => nextInput(e, 3)}
                        type="text"
                        maxLength={1}
                        size={1}
                        min={0}
                        max={9}
                        pattern="[0-9]{1}"
                      />
                      <input
                        className="phone-verify-input-signature"
                        id="code-4"
                        onChange={(e) => nextInput(e, 4)}
                        type="text"
                        maxLength={1}
                        size={1}
                        min={0}
                        max={9}
                        pattern="[0-9]{1}"
                      />
                    </>
                  )}
                </form>
                <span className="signature-change-error">
                  {errorDescription !== `` && errorDescription}
                </span>
              </div>
            ) : (
              <form
                className="signature-transaction-form"
                onSubmit={handleSubmitCreate(onSubmitCreate)}
              >
                <div className="signature-transaction-form-error">
                  <div>
                    <img
                      src={validLength ? Check : CheckFailed}
                      className="signature-transaction-error-check"
                      alt=">"
                    />
                    <span
                      className={
                        validLength
                          ? `signature-transaction-form-check-txt`
                          : `signature-transaction-form-error-txt`
                      }
                    >
                      A assinatura deve conter 8 dígitos numéricos
                    </span>
                  </div>
                  <div>
                    <img
                      src={notNumericSequence ? Check : CheckFailed}
                      className="signature-transaction-error-check"
                      alt=">"
                    />
                    <span
                      className={
                        notNumericSequence
                          ? `signature-transaction-form-check-txt`
                          : `signature-transaction-form-error-txt`
                      }
                    >
                      Não pode ser uma sequência numérica
                    </span>
                  </div>
                  <div>
                    <img
                      src={notBirthdate ? Check : CheckFailed}
                      className="signature-transaction-error-check"
                      alt=">"
                    />
                    <span
                      className={
                        notBirthdate
                          ? `signature-transaction-form-check-txt`
                          : `signature-transaction-form-error-txt`
                      }
                    >
                      Não pode ser data de nascimento
                    </span>
                  </div>
                </div>
                <NumericPasswordInput
                  placeholder="Digite sua nova assinatura"
                  maxLength={8}
                  register={() =>
                    register(`signature`, {
                      onBlur: (e) => handleBlur(e),
                      required: true,
                      maxLength: {
                        value: 8,
                        message: ``,
                      },
                      validate: validatePin,
                    })
                  }
                />
                <NumericPasswordInput
                  placeholder="Confirme sua assinatura"
                  maxLength={8}
                  register={() =>
                    register(`signatureConfirmation`, {
                      required: `Preencha a confirmação da assinatura`,
                      validate: (val: string) => {
                        const s = watch(`signature`)
                        console.log(`Watching... ${s} == ${val}`)
                        if (s !== val) {
                          return `A assinatura e a confirmação devem ser iguais!`
                        }
                      },
                    })
                  }
                />
                {errorsCreate.signatureConfirmation && (
                  <div>
                    <img
                      src={CheckFailed}
                      className="signature-transaction-error-check"
                      alt=">"
                    />
                    <span className="signature-transaction-form-error-txt">
                      {errorsCreate.signatureConfirmation?.message as string}
                    </span>
                  </div>
                )}

                <span className="signature-transaction-span-error">
                  {errorDescription !== `` && errorDescription}
                </span>

                <button
                  disabled={isLoading}
                  className="signature-transaction-btn-next"
                  type="submit"
                >
                  {isLoading ? (
                    <div className="signature-spinner" role="status"></div>
                  ) : (
                    <>
                      {hasDigitalSignature ? (
                        <span>Alterar assinatura</span>
                      ) : (
                        <span>Criar assinatura</span>
                      )}
                    </>
                  )}
                </button>
              </form>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default CreateDigitalSignature
