import { PlanDescription } from "k4n-svcmesh-sdk"
import React, { useContext, useEffect, useState } from "react"
import { InsuranceContext } from "../../../../../context/InsuranceContext"
import "./styles.scss"

function InsurancePlan(props: any) {
  const { plans, selectedPlan, addSelectedPlan } = useContext(InsuranceContext)
  const [planId, setPlanId] = useState(1)

  useEffect(() => {
    if (!selectedPlan.id)
      addSelectedPlan(plans.filter((plan) => plan.id === planId)[0])
  }, [plans, selectedPlan])

  const handlePlanData = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newValue = parseInt(event.target.value)
    setPlanId(newValue)
    addSelectedPlan(plans.filter((plan) => plan.id === newValue)[0])
  }

  return (
    <>
      <div className="Pv-kikkin">
        <div className="Pv-1">
          <div>
            <select
              onChange={(e) => handlePlanData(e)}
              className="select-plan-step1 arrow-drop"
            >
              {plans &&
                plans.map((c: PlanDescription, index: number) => (
                  <option key={index} value={c.id}>
                    {c.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="Pv-1-subtitle-div">
            <span className="Pv-1-subtitle">{plans[planId]?.description}</span>
          </div>
        </div>
        <div className="Pv-2">
          <div className="row Container-title">
            <span className="Pv-2-title">Coberturas:</span>
          </div>
          {plans
            .filter((item) => item.id === planId)[0]
            ?.coverages?.map((c, index) => (
              <div
                className="row Container-item"
                key={index}
                style={{ marginTop: `36px` }}
              >
                <div className="col-7 Item-div">
                  <span className="Item">{c.description}</span>
                </div>
                <div className="col-5 Valor-div">
                  <span className="Valor-item">{c.coverage}</span>
                </div>
              </div>
            ))}
        </div>
        <div className="Pv-3">
          <div className="row Container-3" style={{ paddingTop: 13.75 }}>
            <div className="col-6 Col-mensalidade">
              <span className="Txt-mensalidade">Mensalidade</span>
            </div>
            <div className="col-6 Valor-mensalidade">
              <span className="Pv-3-symbol">R$</span>
              <span className="Pv-3-value">
                {plans.filter((item) => item.id === planId)[0]?.monthly_payment}
              </span>
            </div>
          </div>
          {/* <div
            className={
              props.isMobile
                ? `row Container-3-mg-14`
                : `row Container-valor-total`
            }
          >
            <div
              className={
                props.isMobile
                  ? `col-6 Col-mensalidade-mobile`
                  : `col-6 Col-mensalidade`
              }
            >
              <span
                className={
                  props.isMobile ? `Txt-valor-total-mobile` : `Txt-valor-total`
                }
              >
                Valor total anual
              </span>
            </div>
            <div
              className={
                props.isMobile
                  ? `col-6 Pv-3-col-vlr-total`
                  : `col-6 inline-flx Pd-right-19 Txt-align-right Display-blck`
              }
            >
              <span
                className={
                  props.isMobile
                    ? `Pv-3-symbol-mobile ft-sz-16`
                    : `Pv-3-symbol-small`
                }
              >
                R$
              </span>
              <span
                className={
                  props.isMobile
                    ? `Pv-3-value-mobile ft-sz-16 `
                    : `Pv-3-value-small`
                }
              >
                {(
                  Number(
                    plans.filter((item) => item.id === planId)[0]
                      ?.monthly_payment,
                  ) * 12
                ).toFixed(2)}
              </span>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default InsurancePlan
