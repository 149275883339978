import {
  createContext,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from "react"

type RegisterNavigationContextType = {
  page: number
  changePage: (newPage: number) => void
}

const contextDefaultValues: RegisterNavigationContextType = {
  page: 1,
  changePage: () => {},
}

export const RegisterNavigationContext =
  createContext<RegisterNavigationContextType>(contextDefaultValues)

const RegisterNavigationProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const [page, setPage] = useState<number>(contextDefaultValues.page)

  const changePage = (newPage: number) => setPage(newPage)

  useEffect(
    function () {
      console.log(`page >`, page)
    },
    [page],
  )

  return (
    <RegisterNavigationContext.Provider
      value={{
        page,
        changePage,
      }}
    >
      {children}
    </RegisterNavigationContext.Provider>
  )
}

export default RegisterNavigationProvider
