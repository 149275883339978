import "./styles.scss"
import arrowLeft from "../../../../../../assets/images/yellow-arrow-left.svg"
import { useContext } from "react"
import SVGInject from "@iconfu/svg-inject"
import filter from "../../../../../../assets/images/filter.svg"
import arrowRight from "../../../../../../assets/images/right_yellow_vector.svg"
import { useState } from "react"
import { BoletoContext } from "../../../../../../context/BoletoContext"
import { useEffect } from "react"
import dayjs from "dayjs"
import { formattedCurrency } from "../../../../../../services/banking"
import { Pagination, PrimaryButton } from "k4n-components"
import close from "../../../../../../assets/images/clear_x.svg"
import calendar from "../../../../../../assets/images/calendar.svg"
import copy from "../../../../../../assets/images/copy_yellow.svg"
import spinner from "../../../../../../assets/images/spinner.svg"
import "./styles.scss"
import DatePicker from "react-datepicker"
import InputMask from "react-input-mask"
import { DEFAULT_PERIOD_FILTER } from "../../../extrato/extrato.shared"
import { BoletoOptionalFields } from "../../../../../../typings/boleto-manager.dto"
import { codBarras2LinhaDigitavel } from "../../../../../../utils/utils"
import dots from "../../../../../../assets/images/dots.svg"
import services from "../../../../../../services/svcmesh"
import { useNavigate } from "react-router-dom"

const BoletoManagerMobile = () => {
  const {
    statusFilter,
    filterByStatus,
    applyFilter,
    clearFilter,
    filteringByStatus,
    applyDateFilter,
    loadingData,
    dataArray,
    totalPages,
    page,
    handlePages,
    dateFilterError,
    recipient,
    loadingDropdown,
    cancel,
    sendMail,
    loadingSendingMail,
    dropdownError,
  } = useContext(BoletoContext)

  const [oldStatusFilter, setOldStatusFilter] = useState(statusFilter)
  const [showDateFilterModal, setShowDateFilterModal] = useState(false)
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(DEFAULT_PERIOD_FILTER, `d`).toDate(),
    dayjs().toDate(),
  ])
  const [startDate, endDate] = dateRange
  const [dateFilterSelectedOption, setDateFilterSelectedOption] = useState(
    DEFAULT_PERIOD_FILTER,
  )
  const [selectedBoleto, setSelectedBoleto] = useState<BoletoOptionalFields>({})
  const [isCopying, setIsCopying] = useState(false)
  const [pdfLoading, setPdfLoading] = useState(false)
  const [base64Pdf, setBase64Pdf] = useState(``)
  const [showDropdown, setShowDropdown] = useState(false)
  const [sendEmailOption, setSendEmailOption] = useState(false)
  const navigate = useNavigate()

  useEffect(
    function () {
      console.log(
        `🚀 ~ file: index.tsx ~ line 18 ~ BoletoManagerMobile ~ statusFilter`,
        statusFilter,
      )
      if (statusFilter.values() !== oldStatusFilter.values()) applyFilter()
      if (statusFilter.length === 0 && filteringByStatus) clearFilter()
    },
    [statusFilter],
  )

  const handleStatusFilter = (type: string) => {
    filterByStatus(type)
    if (oldStatusFilter.includes(type))
      setOldStatusFilter(oldStatusFilter.filter((e) => e !== type))
    else setOldStatusFilter([...oldStatusFilter, type])
  }

  function dateSelection(days: number) {
    const selection = [dayjs().subtract(days, `d`).toDate(), dayjs().toDate()]
    setDateRange(selection)
  }

  const handleSelection = (boleto: BoletoOptionalFields) => {
    setSelectedBoleto(boleto)
    if (
      boleto.status !== `canceled` &&
      boleto.status !== `expired` &&
      boleto.status !== `pendingCancellation` &&
      boleto.status !== `paid`
    )
      downloadPDF(boleto.codigoBarras)
    setShowDateFilterModal(false)
    setShowDetailModal(true)
  }

  const downloadPDF = async (id?: string) => {
    if (id) {
      setPdfLoading(true)
      const pdf = await services.bankingService.getBoletoPdf(id, recipient)
      if (pdf && pdf.data.boletoPdf) {
        setBase64Pdf(`data:application/pdf;base64,` + pdf.data.boletoPdf)
        setPdfLoading(false)
      }
    }
  }

  function DownloadPdfButton() {
    if (
      selectedBoleto.status !== `canceled` &&
      selectedBoleto.status !== `expired` &&
      selectedBoleto.status !== `pendingCancellation` &&
      selectedBoleto.status !== `paid`
    ) {
      return (
        <a
          href={base64Pdf}
          download={`${selectedBoleto.codigoBarras}.pdf`}
          style={{
            pointerEvents: pdfLoading ? `none` : `all`,
          }}
          className="mb-pdf-download"
        >
          {pdfLoading ? (
            <div className="bc-spinner-btn mb-top-7" role="status"></div>
          ) : (
            <div className="mb-h-100">
              <div className="mb-vertical-center">Baixar PDF do boleto</div>
            </div>
          )}
        </a>
      )
    } else return <></>
  }

  function CancelOption() {
    if (
      selectedBoleto.status !== `canceled` &&
      selectedBoleto.status !== `expired` &&
      selectedBoleto.status !== `pendingCancellation` &&
      selectedBoleto.status !== `paid`
    ) {
      return (
        <li
          onClick={() => cancel(selectedBoleto.codigoBarras || ``)}
          className="list-group-item li-table-popup"
        >
          Cancelar
        </li>
      )
    } else return <></>
  }

  function DateFilterModal() {
    return (
      <>
        <div
          className="modal-overlay"
          onClick={() => {
            setShowDateFilterModal(false)
          }}
        ></div>
        <div className="modal-boleto-menu-mobile">
          <button
            className="modal-boleto-menu-close-mobile"
            onClick={() => {
              setShowDateFilterModal(false)
            }}
          >
            <img src={close} alt="" onLoad={(e) => SVGInject(e.target)} />
          </button>
          <h1 className="modal-boleto-title-h1-mobile">
            Qual período você quer filtrar?
          </h1>
          <span className="date-filter-mobile-text">Períodos</span>
          <div className="date-filter-menu-btns-div">
            {dateFilterSelectedOption === 0 ? (
              <button className="date-filter-option-mobile">Último dia</button>
            ) : (
              <button
                className="date-filter-option-mobile-disable"
                onClick={() => {
                  dateSelection(0)
                  setDateFilterSelectedOption(0)
                }}
              >
                Último dia
              </button>
            )}
            {dateFilterSelectedOption === 7 ? (
              <button className="date-filter-option-mobile">7 dias</button>
            ) : (
              <button
                className="date-filter-option-mobile-disable"
                onClick={() => {
                  dateSelection(7)
                  setDateFilterSelectedOption(7)
                }}
              >
                7 dias
              </button>
            )}
            {dateFilterSelectedOption === 30 ? (
              <button className="date-filter-option-mobile">30 dias</button>
            ) : (
              <button
                className="date-filter-option-mobile-disable"
                onClick={() => {
                  dateSelection(30)
                  setDateFilterSelectedOption(30)
                }}
              >
                30 dias
              </button>
            )}
          </div>
          <span className="date-filter-mobile-text">Período</span>
          <div
            className="date-filter-calendar-mobile"
            onClick={() => setDateFilterSelectedOption(1)}
          >
            <img
              src={calendar}
              alt=""
              className="date-manager-calendar-icon"
              onLoad={(e) => SVGInject(e.target)}
            />
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              locale="pt-BR"
              onChange={(update: any) => {
                setDateRange(update)
              }}
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              required={true}
              placeholderText="Filtre por data"
              customInput={
                <InputMask
                  mask="99/99/9999 - 99/99/9999"
                  maskPlaceholder={null}
                  className="manager-input-date"
                />
              }
              maxDate={dayjs().toDate()}
            />
          </div>
          {dateFilterError && (
            <span className="boleto-manager-filter-error">
              O período não pode ser maior que 31 dias.
            </span>
          )}
          <div className="date-filter-apply-mobile">
            <PrimaryButton
              width={`80%`}
              height={`56px`}
              onClick={() => applyDateFilter(dateRange)}
              disabled={loadingData}
            >
              {loadingData ? (
                <div className="bc-spinner-btn" role="status"></div>
              ) : (
                `Aplicar filtros`
              )}
            </PrimaryButton>
          </div>
        </div>
      </>
    )
  }

  const copyDigitableLine = () => {
    setIsCopying(true)
    if (selectedBoleto.codigoBarras)
      navigator.clipboard.writeText(
        codBarras2LinhaDigitavel(selectedBoleto.codigoBarras, false),
      )
    setTimeout(function () {
      setIsCopying(false)
    }, 3000)
  }

  function DetailModal() {
    return (
      <>
        <div
          className="modal-overlay"
          onClick={() => {
            setShowDetailModal(false)
            setSendEmailOption(false)
            setShowDropdown(false)
          }}
        ></div>
        <div className="modal-boleto-menu-mobile">
          <div className="mb-options-btns">
            <button
              className="modal-boleto-menu-close-mobile"
              onClick={() => {
                setShowDetailModal(false)
                setSendEmailOption(false)
                setShowDropdown(false)
              }}
            >
              <img
                src={close}
                alt=""
                width="22"
                height="22"
                onLoad={(e) => SVGInject(e.target)}
              />
            </button>
            {selectedBoleto.status !== `canceled` && (
              <>
                <div className="dropdown">
                  <div
                    onClick={() => setShowDropdown(!showDropdown)}
                    className="div-dots"
                  >
                    <img
                      onLoad={(e) => SVGInject(e.target)}
                      id={`dropdownMenuButton`}
                      className="img-dots dropdown-toggle"
                      src={dots}
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    />
                  </div>
                  <div
                    className={
                      showDropdown
                        ? `dropdown-menu show manager-table-popup`
                        : `dropdown-menu manager-table-popup`
                    }
                    aria-labelledby={`dropdownMenuButton`}
                  >
                    <div className="boleto-dots-drop">
                      {loadingDropdown ? (
                        <>
                          <div className="mb-dropdown-spinner-div">
                            <img
                              width={50}
                              height={50}
                              src={spinner}
                              alt="loading"
                              onLoad={(e) => SVGInject(e.target)}
                            />
                          </div>
                          {loadingSendingMail && (
                            <div className="email-txt-div">
                              <span>
                                Enviando para{` `}
                                {
                                  (
                                    document.getElementById(
                                      `recipientEmail`,
                                    ) as HTMLInputElement
                                  ).value
                                }
                              </span>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {dropdownError ? (
                            <div>
                              <p className="mb-dropdown-error-msg">
                                Houve um erro ao realizar essa ação, tente
                                novamente mais tarde.
                              </p>
                            </div>
                          ) : (
                            <>
                              {!sendEmailOption ? (
                                <ul className="list-group">
                                  <CancelOption />
                                  <li
                                    onClick={
                                      () => setSendEmailOption(!sendEmailOption)
                                      // sendMail(selectedBoleto.codigoBarras || ``)
                                    }
                                    className="list-group-item li-table-popup"
                                  >
                                    Enviar por email
                                  </li>
                                </ul>
                              ) : (
                                <>
                                  <img
                                    src={arrowLeft}
                                    alt=""
                                    onClick={() => {
                                      setSendEmailOption(false)
                                    }}
                                  />
                                  <div className="mail-dropdown-div">
                                    <input
                                      placeholder="Digite o email de destino"
                                      className="mail-dropdown-input"
                                      id={`recipientEmail`}
                                    ></input>
                                    <PrimaryButton
                                      width={`100%`}
                                      height={`40px`}
                                      onClick={() => {
                                        sendMail(
                                          (
                                            document.getElementById(
                                              `recipientEmail`,
                                            ) as HTMLInputElement
                                          ).value,
                                          selectedBoleto.codigoBarras || ``,
                                        )
                                      }}
                                    >
                                      Enviar
                                    </PrimaryButton>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            <h1 className="modal-boleto-title-h1-mobile">Detalhes do boleto</h1>
            <span className="modal-boleto-detail-subtitle">
              Gerado em{` `}
              <span className="modal-boleto-detail-subtitle-bold">
                {dayjs(selectedBoleto.dataRegistro).format(`DD/MM/YYYY`)}
              </span>
            </span>
            <div className="mt-16">
              {selectedBoleto.status === `paid` && (
                <div className="table-status-paid table-status">
                  <span className="table-status-span">Pago</span>
                </div>
              )}
              {selectedBoleto.status === `pending` && (
                <div className="table-status-pending table-status">
                  <span className="table-status-span">
                    Aguardando pagamento
                  </span>
                </div>
              )}
              {selectedBoleto.status === `pendingCancellation` && (
                <div className="table-status-pending-cancellation table-status">
                  <span className="table-status-span">Cancelando</span>
                </div>
              )}
              {selectedBoleto.status === `canceled` && (
                <div className="table-status-canceled table-status">
                  <span className="table-status-span">Cancelado</span>
                </div>
              )}
              {selectedBoleto.status === `overdue` && (
                <div className="table-status-overdue table-status">
                  <span className="table-status-span">Vencido</span>
                </div>
              )}
              {selectedBoleto.status === `expired` && (
                <div className="table-status-expired table-status">
                  <span className="table-status-span">Expirado</span>
                </div>
              )}
            </div>
          </div>
          <div className="row modal-boleto-detail-row">
            <div className="col-4">
              <span className="modal-boleto-detail-txt">
                Valor do <br /> boleto
              </span>
            </div>
            <div className="col-8">
              <span className="modal-boleto-detail-value mb-vertical-center">
                {formattedCurrency(selectedBoleto.vlrBoleto)}
              </span>
            </div>
          </div>
          <div className="row modal-boleto-detail-row">
            <div className="col-4">
              <span className="modal-boleto-detail-txt">Pagador</span>
            </div>
            <div className="col-8">
              <span className="modal-boleto-detail-small-value mb-vertical-center">
                {selectedBoleto.nom_RzSocPagdr}
              </span>
            </div>
          </div>
          <div className="row modal-boleto-detail-row">
            <div className="col-4">
              <span className="modal-boleto-detail-txt">Vencimento</span>
            </div>
            <div className="col-8">
              <span className="modal-boleto-detail-small-value mb-vertical-center">
                {dayjs(selectedBoleto.dtVencimento).format(`DD/MM/YYYY`)}
              </span>
            </div>
          </div>
          <div onClick={copyDigitableLine} className="mb-copy-txt">
            <span className="modal-boleto-detail-txt">
              Copiar linha digitável
            </span>
            <div className="mb-copy-div">
              <img
                src={copy}
                alt=""
                className="mb-copy-icon"
                onLoad={(e) => SVGInject(e.target)}
              />
              {isCopying ? (
                <span className="mb-copy-value">Linha digitável copiada!</span>
              ) : (
                <span className="mb-copy-value">
                  {codBarras2LinhaDigitavel(selectedBoleto.codigoBarras, false)}
                </span>
              )}
            </div>
          </div>
          <div className="date-filter-apply-mobile">
            <DownloadPdfButton />
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="boleto-manager-wrapper-mobile">
        <div className="boleto-container-mobile">
          {showDateFilterModal && <DateFilterModal />}
          {showDetailModal && <DetailModal />}
          <div className="boleto-header-mobile">
            <button
              className="boleto-header-mobile-btn f-l"
              onClick={() => navigate(`/banking`)}
            >
              <img src={arrowLeft} alt="" onLoad={(e) => SVGInject(e.target)} />
            </button>
            <button className="boleto-header-mobile-btn"></button>
            <button
              onClick={() => setShowDateFilterModal(!showDateFilterModal)}
              className="boleto-header-mobile-btn"
            >
              <img
                src={filter}
                alt=""
                className=""
                onLoad={(e) => SVGInject(e.target)}
              />
            </button>
            <h1 className="boleto-header-h1-mobile">Gerenciar boletos </h1>
          </div>
        </div>

        {/* FILTER BY STATUS MOBILE */}
        <div className="boleto-table-content-mobile">
          <div className="boleto-options-btns-mobile-div">
            <button
              className={
                statusFilter.includes(`paid`)
                  ? `boleto-option-btn-mobile`
                  : `boleto-option-btn-mobile-disable`
              }
              onClick={() => handleStatusFilter(`paid`)}
            >
              Pago
            </button>
            <button
              className={
                statusFilter.includes(`pending`)
                  ? `boleto-option-btn-mobile`
                  : `boleto-option-btn-mobile-disable`
              }
              onClick={() => handleStatusFilter(`pending`)}
            >
              Aguardando pagamento
            </button>
            <button
              className={
                statusFilter.includes(`overdue`)
                  ? `boleto-option-btn-mobile`
                  : `boleto-option-btn-mobile-disable`
              }
              onClick={() => handleStatusFilter(`overdue`)}
            >
              Vencido
            </button>
            <button
              className={
                statusFilter.includes(`canceled`)
                  ? `boleto-option-btn-mobile`
                  : `boleto-option-btn-mobile-disable`
              }
              onClick={() => handleStatusFilter(`canceled`)}
            >
              Cancelado
            </button>
            <button
              className={
                statusFilter.includes(`expired`)
                  ? `boleto-option-btn-mobile`
                  : `boleto-option-btn-mobile-disable`
              }
              onClick={() => handleStatusFilter(`expired`)}
            >
              Expirado
            </button>
          </div>
          {!loadingData ? (
            <>
              {dataArray && dataArray.length > 0 && (
                <>
                  {dataArray.map((row, i) => (
                    <div key={i} className="boleto-item">
                      <h3 className="boleto-item-h3">{row.nom_RzSocPagdr}</h3>
                      <div className="row">
                        <div className="col-4">
                          <span className="boleto-item-label">Pago em:</span>
                          <br />
                          <>
                            {row.dtPagamento !== `` && (
                              <span className="boleto-item-txt">
                                {dayjs(row.dtPagamento).format(`DD/MM/YYYY`)}
                              </span>
                            )}
                            {row.dtPagamento === `` && (
                              <span className="boleto-item-txt">
                                Aguardando pagamento
                              </span>
                            )}
                          </>
                        </div>
                        <div className="col-4">
                          <span className="boleto-item-label">Valor:</span>
                          <br />
                          <span className="boleto-item-txt">
                            {formattedCurrency(row.vlrBoleto)}
                          </span>
                        </div>
                        <div
                          onClick={() => handleSelection(row)}
                          className="col-4"
                        >
                          <img
                            src={arrowRight}
                            alt=""
                            className="boleto-item-arrow"
                            onLoad={(e) => SVGInject(e.target)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              <div className="boleto-loading-mobile loading-gradient"></div>
              <div className="boleto-loading-mobile loading-gradient mt-25"></div>
              <div className="boleto-loading-mobile loading-gradient mt-25"></div>
            </>
          )}
          {dataArray.length === 0 && !loadingData && (
            <div className="mb-not-found-mobile">
              <span className="mb-not-found-txt">
                Não foram encontrados registros de boletos no período
              </span>
            </div>
          )}
          {!loadingData && totalPages > 1 && (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default BoletoManagerMobile
