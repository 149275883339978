import { useContext, useEffect, useState } from "react"
import next from "../../../../../assets/images/next.svg"
import { AuthContext } from "../../../../../context/AuthProvider"
import { BankingNavigationContext } from "../../../../../context/BankingNavigationContext"
import { TransferContext } from "../../../../../context/TransferContext"
import { formattedCurrency } from "../../../../../services/banking"
import { SecondaryButton, PrimaryButton } from "k4n-components"
import Favorites, { FavoriteScreenEnum } from "../../../Favorites"
import TransferNav from "../transfer-nav"
import "./styles.scss"
import SVGInject from "@iconfu/svg-inject"
import { BalanceContext } from "../../../../../context/BalanceProvider"
import { useResposive } from "../../../../../hooks/useResponsive"
import TransferStep2Mobile from "./mobile"
import services from "../../../../../services/svcmesh"
import { GetCustomerFavoritesResponse } from "k4n-svcmesh-sdk"

const TransferStep2 = () => {
  const { transferStep, changeTransferStep, changeNewContactTransferStep } =
    useContext(BankingNavigationContext)
  const { transferValue, addFavoriteData } = useContext(TransferContext)
  const { balance, loadingBalance, refreshBalance } = useContext(BalanceContext)

  const { user } = useContext(AuthContext)

  const [favorites, setFavorites] = useState<GetCustomerFavoritesResponse>({
    contacts: [],
  })
  const [loadingFavorites, setLoadingFavorites] = useState<boolean>(true)

  const { isTabletOrMobile } = useResposive()
  const getUserFavorites = async () => {
    try {
      setFavorites(
        await services.bankingService.getCustomerFavorites({
          clientePfId: user?.id,
          type: `transferencia`,
        }),
      )
      setLoadingFavorites(false)
    } catch (error) {
      console.log(error)
      setLoadingFavorites(false)
    }
  }

  useEffect(() => {
    getUserFavorites()
    refreshBalance()
    console.log(favorites)
  }, [])

  return (
    <>
      {!isTabletOrMobile ? (
        <div className="transfer-container">
          <TransferNav step={transferStep} stepTitle="Nova transferência" />
          <div className="panel-content">
            <div className="panel-title-step-2">
              <h1 className="title-blue">Para quem transferir</h1>
              <span className="info-span-bold-16">
                Selecione um contato da sua lista ou busque por uma nova chave.
              </span>
            </div>
            <div className="transfer-value-div">
              <div className="steps-value-info">
                <h1 className="info-span-bold-16">Valor a pagar</h1>
                <span className="info-span-grey-24">
                  {formattedCurrency(transferValue)}
                </span>
              </div>
              <div className="info-div">
                <h1 className="info-span-bold-16">Saldo Kikkin:</h1>
                <h1 className="info-span-grey-16">
                  {loadingBalance ? (
                    <div className="emptySpace-pix gradient"></div>
                  ) : (
                    <span className="content-table-step2-pix-txt">
                      {formattedCurrency(balance)}
                    </span>
                  )}
                </h1>
              </div>
            </div>
            <div className="new-contact-btn-div">
              <PrimaryButton
                width={`312px`}
                height={`56px`}
                onClick={() => {
                  changeTransferStep(0)
                  changeNewContactTransferStep(1)
                }}
              >
                <div className="flex-space-evenly">
                  <span className="p-16">Transferir para novo contato</span>
                  <img
                    className="transfer-arrow-margin"
                    src={next}
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </div>
              </PrimaryButton>
            </div>
            {!loadingFavorites ? (
              <Favorites
                favorites={favorites}
                title={`Transferir para um favorito`}
                screen={FavoriteScreenEnum.TRANSFER}
                changeStep={changeTransferStep}
                addData={addFavoriteData}
              />
            ) : (
              <h6 style={{ color: `black` }}>Carregando favoritos</h6>
            )}
            <div className="transfer-step-2-btns-div">
              <SecondaryButton
                width={`180px`}
                height={`56px`}
                text={`Voltar`}
                OnClick={() => changeTransferStep(1)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="transfer-mobile-wrapper">
          <TransferStep2Mobile
            favorites={favorites}
            getUserFavorites={getUserFavorites}
            loadingFavorites={loadingFavorites}
          />
        </div>
      )}
    </>
  )
}

export default TransferStep2
