import { FC, useEffect, useState } from "react"
import { TOTAL_STEPS } from "../../../context/OnboardingContext"
import "./styles.scss"

interface SideMenuProps {
  step: number
  children?: React.ReactNode
}

const OnboardingSideMenu: FC<SideMenuProps> = ({ step, children }) => {
  const [progress, setProgress] = useState(0)
  const [progressGoal, setProgressGoal] = useState(0)
  const [ascending, setAscending] = useState(true)

  useEffect(() => {
    let timer: any
    if (ascending)
      timer =
        progress < progressGoal &&
        setInterval(() => setProgress(progress + 1), 50)
    else
      timer =
        progress > progressGoal &&
        setInterval(() => setProgress(progress - 1), 50)
    if (timer) return () => clearInterval(timer)
  }, [progressGoal, progress])

  useEffect(() => {
    if (((step - 1) * 100) / TOTAL_STEPS > progressGoal) {
      setAscending(true)
      setProgressGoal(((step - 1) * 100) / TOTAL_STEPS)
    } else if (((step - 1) * 100) / TOTAL_STEPS < progressGoal) {
      if (step > 0) {
        setAscending(false)
        setProgressGoal(((step - 1) * 100) / TOTAL_STEPS)
      }
    }
  }, [step])

  const getClassesForLabel = (labelNum: number) => {
    let classes = `step-label `
    if (step > labelNum) classes += `step-label-validated`
    else if (step === labelNum) classes += `step-label-active`
    return classes
  }

  return (
    <div className="onboarding-wrapper">
      <div className="onboarding-menu">
        <div className="progress-container">
          <div className="progress-bar-wrapper">
            <div
              className="progress-bar-filled"
              style={{ width: progressGoal + `%` }}
            />
          </div>
          <div style={{ width: `32px` }}>{progress + `%`}</div>
        </div>
        <div className={getClassesForLabel(1)}>Podemos te conhecer?</div>
        {/* <div className={getClassesForLabel(2)}>
          Talvez a gente saiba quem você é
        </div> 
        <div className={getClassesForLabel(3)}>Qual seu CPF?</div> */}
        {/* <div className={getClassesForLabel(2)}>Hora da foto!</div> */}
        <div className={getClassesForLabel(2)}>Que dia você nasceu?</div>
        <div className={getClassesForLabel(3)}>Email e Telefone</div>
        <div className={getClassesForLabel(4)}>
          Vamos validar a sua identidade?
        </div>
        <div className={getClassesForLabel(5)}>
          Agora, vamos validar o seu endereço?
        </div>
        {/* <div className={getClassesForLabel(10)}>
                    Conheça os benefícios da Kikkin
                </div> */}
      </div>
      <div className="onboarding-content">{children}</div>
    </div>
  )
}

export default OnboardingSideMenu
