import {
  MouseEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { INFORMACOES_BILHETE_BASE64 } from "../../../../../../assets/constants"
import Arrow from "../../../../../../assets/images/arrow.svg"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { InsuranceContext } from "../../../../../../context/InsuranceContext"
import { AuthContext } from "../../../../../../context/AuthProvider"
import { useResposive } from "../../../../../../hooks/useResponsive"
import { formattedCurrency } from "../../../../../../services/banking"
import { b64toBlob } from "../../../../../../utils/base64ToBlob"
import { SecondaryButton } from "k4n-components"
import services from "../../../../../../services/svcmesh"
import "./styles.scss"
import { CoveragePlan } from "k4n-svcmesh-sdk"
import dayjs from "dayjs"

export const InsuranceStep3 = (props: any) => {
  const { user } = useContext(AuthContext)
  const { selectedPlan, hasBeneficiary, paymentDay } =
    useContext(InsuranceContext)
  const { changeInsuranceStep } = useContext(BankingNavigationContext)
  const [checked, setChecked] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [errorMessage, setErrorMessage] = useState(``)
  const [termosPdf, setTermosPdf] = useState<Blob>()
  const { isTabletOrMobile } = useResposive()

  const onSubmit = () => {
    if (checked) {
      if (window.Cypress) {
        changeInsuranceStep(hasBeneficiary ? 4 : 3)
      } else {
        if (clicked) {
          changeInsuranceStep(hasBeneficiary ? 4 : 3)
        } else {
          setErrorMessage(`Por favor, leia os termos antes de aceitá-lo.`)
        }
      }
    } else {
      setErrorMessage(`Por favor, aceite os termos antes de prosseguir.`)
    }
  }

  const backOneStep = () => {
    changeInsuranceStep(hasBeneficiary ? 2 : 1)
  }

  const settingPdfBlob = async () => {
    const date = new Date()
    const cuerentDate = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    }

    const formatCpf = (cpf: string) => {
      return (
        cpf.substring(0, 3) +
        `.` +
        cpf.substring(3, 6) +
        `.` +
        cpf.substring(6, 9) +
        `-` +
        cpf.substring(9, 11)
      )
    }

    const getNameInitials = (name: string) => {
      const names = name.split(` `)
      let initials = names[0].substring(0, 1).toUpperCase()

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return initials
    }

    const converterdCoverage: any = selectedPlan.coverages
      ? selectedPlan.coverages
      : []

    converterdCoverage.forEach(function (obj) {
      obj.value = Number(obj.value)
    })

    const data = {
      proponenteNome: user!.nome,
      proponenteCpfCnpj: formatCpf(user!.cpf!),
      proponenteDtNasc: dayjs(user!.dtNasc).format(`DD/MM/YYYY`),
      proponenteEnderecoLogradouro: user!.addresses[0].logradouro!,
      proponenteEnderecoNumero: user!.addresses[0].numero!,
      proponenteEnderecoComplemento: user!.addresses[0].complemento!,
      proponenteEnderecoBairro: user!.addresses[0].bairro!,
      proponenteEnderecoCidade: user!.addresses[0].cidade!,
      proponenteEnderecoUf: user!.addresses[0].uf!,
      proponenteEnderecoCep: user!.addresses[0].cep!,
      proponenteCel: user!.phones[0].phone,
      proponenteEmail: user!.emails[0].email,
      dtIniVig: `${cuerentDate.day}/${cuerentDate.month}/${cuerentDate.year}`,
      dtFimVig: `${cuerentDate.day - 1}/${cuerentDate.month}/${
        cuerentDate.year + 1
      }`,
      proponenteIniciais: getNameInitials(user!.nome!),
      coverages: converterdCoverage,
      netValue: Number(selectedPlan.net_value!),
      iof: Number(selectedPlan.iof!),
      grossValue: Number(selectedPlan.monthly_payment!),
      ramo: selectedPlan.ramo!,
      kikkinCommission: Number(selectedPlan.product?.kikkin_commission),
      insuranceProductName: selectedPlan.product?.name!,
    }

    const pdf: any = await services.bankingService.getTermsOfInsuranceContract(
      data,
    )

    setTermosPdf(await b64toBlob(pdf.data, `application/pdf`))
  }

  useEffect(() => {
    settingPdfBlob()
  }, [])

  return (
    <div className="insurance-step-3">
      <div className="row insurance-content-step-3">
        <>
          <div
            className={
              isTabletOrMobile
                ? `Pv-kikkin-proposal-mobile`
                : `Pv-kikkin-proposal`
            }
          >
            <div
              className={
                isTabletOrMobile ? `Pv-1-proposal-mobile` : `Pv-1-proposal`
              }
            >
              <div>
                <span
                  className={
                    isTabletOrMobile
                      ? `Pv-1-title-proposal-mobile`
                      : `Pv-1-title-proposal`
                  }
                >
                  {selectedPlan.name}
                </span>
              </div>
              <div
                className={
                  isTabletOrMobile
                    ? `Pv-1-subtitle-div-proposal-mobile`
                    : `Pv-1-subtitle-div-proposal`
                }
              >
                <span
                  className={
                    isTabletOrMobile
                      ? `Pv-1-subtitle-proposal-mobile`
                      : `Pv-1-subtitle-proposal`
                  }
                >
                  {selectedPlan.description}
                </span>
              </div>
            </div>
            <div
              className={
                isTabletOrMobile ? `Pv-2-proposal-mobile` : `Pv-2-proposal`
              }
            >
              <div
                className={
                  isTabletOrMobile
                    ? `row Container-title-proposal-mobile`
                    : `row Container-title-proposal`
                }
              >
                <span
                  className={
                    isTabletOrMobile
                      ? `Pv-2-title-proposal-mobile`
                      : `Pv-2-title-proposal`
                  }
                >
                  Coberturas
                </span>
              </div>
              {selectedPlan?.coverages?.map((c, index) => (
                <div
                  key={index}
                  className={
                    isTabletOrMobile
                      ? `row Container-item-proposal-mobile`
                      : `row Container-item-proposal`
                  }
                  style={{ marginTop: `36px` }}
                >
                  <div
                    className={
                      isTabletOrMobile
                        ? `col-7 Item-div-proposal-mobile`
                        : `col-7 Item-div-proposal`
                    }
                  >
                    <span
                      className={
                        isTabletOrMobile
                          ? `Item-proposal-mobile`
                          : `Item-proposal`
                      }
                    >
                      {c.description}
                    </span>
                  </div>
                  <div
                    className={
                      isTabletOrMobile
                        ? `col-5 Valor-div-proposal-mobile`
                        : `col Valor-div-proposal`
                    }
                  >
                    <span
                      className={
                        isTabletOrMobile
                          ? `Valor-item-mobile-break-line`
                          : `Valor-item-proposal`
                      }
                    >
                      {c.coverage}
                    </span>
                  </div>
                </div>
              ))}

              <div
                className={
                  isTabletOrMobile
                    ? `row Container-title-2-proposal-mobile`
                    : `row Container-title-2-proposal`
                }
              >
                <span
                  className={
                    isTabletOrMobile
                      ? `Pv-2-title-proposal-mobile`
                      : `Pv-2-title-proposal`
                  }
                >
                  Bilhete anual
                </span>
              </div>

              <div
                className={
                  isTabletOrMobile
                    ? `row Container-item-proposal-mobile`
                    : `row Container-item-proposal`
                }
                style={{ marginTop: `36px` }}
              >
                <div
                  className={
                    isTabletOrMobile
                      ? `col-7 Item-div-proposal-mobile`
                      : `col-7 Item-div-proposal`
                  }
                >
                  <span
                    className={
                      isTabletOrMobile
                        ? `Item-proposal-mobile`
                        : `Item-proposal`
                    }
                  >
                    Valor mensal
                  </span>
                </div>
                <div
                  className={
                    isTabletOrMobile
                      ? `col Valor-div-proposal-mobile`
                      : `col Valor-div-proposal`
                  }
                >
                  <span
                    className={
                      isTabletOrMobile
                        ? `Valor-item-proposal-mobile`
                        : `Valor-item-proposal Ft-sz-14`
                    }
                  >
                    {formattedCurrency(Number(selectedPlan?.monthly_payment))}
                  </span>
                </div>
              </div>

              {/* <div
                className={
                  isTabletOrMobile
                    ? `row Container-item-proposal-mobile`
                    : `row Container-item-proposal`
                }
                style={{ marginTop: `36px` }}
              >
                <div
                  className={
                    isTabletOrMobile
                      ? `col-7 Item-div-proposal-mobile`
                      : `col-7 Item-div-proposal`
                  }
                >
                  <span
                    className={
                      isTabletOrMobile ? `Item-proposal-mobile` : `Item-proposal`
                    }
                  >
                    Vencimento
                  </span>
                </div>
                <div
                  className={
                    isTabletOrMobile
                      ? `col-5 Valor-div-proposal-mobile`
                      : `col-5 Valor-div-proposal`
                  }
                >
                  <span
                    className={
                      isTabletOrMobile
                        ? `Valor-item-proposal-mobile`
                        : `Valor-item-proposal`
                    }
                  >
                    Todo dia {paymentDay}
                  </span>
                </div>
              </div> */}

              <div
                className={
                  isTabletOrMobile
                    ? `row Container-title-2-proposal-mobile`
                    : `row Container-title-2-proposal`
                }
              >
                <div
                  className={
                    isTabletOrMobile
                      ? `Pv-2-title-3-div-proposal-mobile`
                      : `Pv-2-title-3-div-proposal`
                  }
                >
                  <div className="Mx-wd-title-3">
                    <span
                      className={
                        isTabletOrMobile
                          ? `Pv-2-title-proposal-mobile No-bottom-border`
                          : `Pv-2-title-3-proposal`
                      }
                    >
                      Prazos e carências
                    </span>
                  </div>
                </div>
              </div>

              <div
                className={
                  isTabletOrMobile
                    ? `row Container-item-proposal-mobile`
                    : `row Container-item-proposal`
                }
                style={{ marginTop: `36px` }}
              >
                <div
                  className={
                    isTabletOrMobile
                      ? `col-7 Item-div-proposal-mobile`
                      : `col-7 Item-div-proposal`
                  }
                >
                  <div className="Item-pv-2-3-proposal">
                    <span
                      className={
                        isTabletOrMobile
                          ? `Item-proposal-mobile`
                          : `Item-proposal Brk-spaces-proposal`
                      }
                    >
                      Carência para início das coberturas
                    </span>
                  </div>
                </div>
                <div
                  className={
                    isTabletOrMobile
                      ? `col-5 Valor-div-proposal-mobile`
                      : `col-5 Valor-div-proposal`
                  }
                >
                  <span
                    className={
                      isTabletOrMobile
                        ? `Valor-item-proposal-mobile`
                        : `Valor-item-proposal Ft-sz-14`
                    }
                  >
                    {selectedPlan.carencia} dias
                  </span>
                </div>
              </div>

              <div
                className={
                  isTabletOrMobile
                    ? `row Container-item-proposal-mobile`
                    : `row Container-item-proposal`
                }
                style={{ marginTop: `36px` }}
              >
                <div
                  className={
                    isTabletOrMobile
                      ? `col-4 Item-div-proposal-mobile`
                      : `col-7 Item-div-proposal`
                  }
                >
                  <span
                    className={
                      isTabletOrMobile
                        ? `Item-proposal-mobile`
                        : `Item-proposal`
                    }
                  >
                    Franquia
                  </span>
                </div>
                <div
                  className={
                    isTabletOrMobile
                      ? `col-8 Valor-div-proposal-mobile`
                      : `col-5 Valor-div-proposal`
                  }
                >
                  <span
                    className={
                      isTabletOrMobile
                        ? `Valor-item-mobile-break-line`
                        : `Valor-item-proposal Ft-sz-14`
                    }
                  >
                    2 dias para a Cobertura Diária de Internação Hospitalar por
                    Acidente
                  </span>
                </div>
              </div>

              <div
                className={
                  isTabletOrMobile
                    ? `row Container-title-proposal-mobile Pd-top-27`
                    : `row Container-title-2-proposal`
                }
              >
                <span
                  className={
                    isTabletOrMobile
                      ? `Pv-2-title-proposal-mobile`
                      : `Pv-2-title-proposal`
                  }
                >
                  Início de Vigência
                </span>
              </div>

              <div
                className={
                  isTabletOrMobile
                    ? `row Container-item-proposal-mobile`
                    : `row Container-item-vigencia-proposal`
                }
                style={{ marginTop: `36px` }}
              >
                <div
                  className={
                    isTabletOrMobile
                      ? `col-12 Item-div-proposal-mobile`
                      : `col-12 Item-div-proposal`
                  }
                >
                  <span
                    className={
                      isTabletOrMobile
                        ? `Item-proposal-mobile`
                        : `Item-proposal`
                    }
                  >
                    O seguro é valido 24 horas a partir do reconhecimento do 1º
                    pagamento.
                  </span>
                </div>
              </div>

              <div
                className={
                  isTabletOrMobile
                    ? `row Container-title-proposal-mobile Pd-top-40`
                    : `row Container-title-2-proposal`
                }
              >
                <span
                  className={
                    isTabletOrMobile
                      ? `Pv-2-title-proposal-mobile`
                      : `Pv-2-title-proposal`
                  }
                >
                  Bilhete e termos
                </span>
              </div>

              <p
                className={
                  isTabletOrMobile
                    ? `insurance-disclaimer-mobile`
                    : `insurance-disclaimer`
                }
              >
                A contratação é opcional. Para mais informações, consulte as
                condições gerais com restrições do seguro, à disposição no site
                {` `}
                <a
                  className="disclaimer-link"
                  target="_blank"
                  href="https://www.tokiomarine.com.br/afinidades/kikkin"
                  rel="noreferrer"
                >
                  www.tokiomarine.com.br/afinidades/kikkin
                </a>
                . Seguro garantido pela Tokio Marine Seguradora S.A., CNPJ
                33.164.021/0001-00, Código SUSEP nº 06190, através do processo
                SUSEP 15414.900713/2014-94 - Ramo 1601 (Microsseguros de
                Pessoas). O registro do produto é automático e não representa
                aprovação ou recomendação por parte da Susep . SAC Tokio Marine:
                0800 703 9000. Plataforma digital oficial para registro de
                reclamações dos consumidores dos mercados supervisionados:{` `}
                <a
                  className="disclaimer-link"
                  target="_blank"
                  href="https://www.consumidor.gov.br"
                  rel="noreferrer"
                >
                  www.consumidor.gov.br
                </a>
                . Central de Atendimento Susep - 0800 021 8484 - (9:30 às
                17:00).
              </p>

              {termosPdf ? (
                <div
                  className={
                    isTabletOrMobile
                      ? `row Container-item-proposal-mobile Pd-top-32`
                      : `row Container-item-proposal`
                  }
                  style={{ marginTop: `36px` }}
                >
                  <div
                    className={
                      isTabletOrMobile
                        ? `col-12 Item-div-proposal-mobile`
                        : `col-12 Item-div-proposal Mg-bottom-56`
                    }
                  >
                    <div
                      className={
                        isTabletOrMobile
                          ? `Form-check-proposal-mobile`
                          : `Form-check-proposal`
                      }
                    >
                      <div className="insurance-row-check">
                        <input
                          onClick={() => {
                            setChecked(!checked)
                          }}
                          type="button"
                          className={
                            checked
                              ? `insurance-checkbox insurance-checked`
                              : `insurance-checkbox`
                          }
                        />
                        {
                          <span className="insurance-recipient-txt">
                            Declaro que recebi e tomei ciência dos{` `}
                            <a
                              onClick={() => setClicked(true)}
                              href={URL.createObjectURL(termosPdf)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Termos de Contratação do Seguro
                            </a>
                          </span>
                        }
                      </div>
                    </div>
                    {!!errorMessage && (
                      <p className="insurance-error-msg">{errorMessage}</p>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className={
                    isTabletOrMobile
                      ? `row Container-item-proposal-mobile Pd-top-32`
                      : `row Container-item-proposal`
                  }
                  style={{ marginTop: `36px` }}
                >
                  <div
                    className={
                      isTabletOrMobile
                        ? `col-12 Item-div-proposal-mobile Form-check-proposal-mobile`
                        : `col-12 Item-div-proposal Mg-bottom-56 Form-check-proposal`
                    }
                  >
                    <div className="insurance-row-check insurance-placeholder-gradinet" />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="insurance-next">
            <SecondaryButton
              width={`140px`}
              height={`60px`}
              text={`Voltar`}
              OnClick={backOneStep}
            />
            <button
              type="submit"
              onClick={onSubmit}
              className="insurance-next-btn"
            >
              <img src={Arrow} className="insurance-next-arrow" alt=">" />
            </button>
          </div>
        </>
      </div>
    </div>
  )
}
