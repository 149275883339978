import { AxiosError } from "axios"
import {
  PrimaryButton,
  SuccessModal,
  ErrorModal,
  QrcodeReader,
} from "k4n-components"
import { useState, useContext, useEffect } from "react"
import React from "react"
import services from "../../../../services/svcmesh"
import "./styles.scss"
import { Controller, useForm } from "react-hook-form"
import { BalanceContext } from "../../../../context/BalanceProvider"
import { formattedCurrency } from "../../../../services/banking"
import SVGInject from "@iconfu/svg-inject"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../../../../context/AuthProvider"
import { WithdrawAtms } from "../../../../typings/withdraw-atms.dto"
import CustomModal from "../../../features/CustomModal"
import CurrencyInput from "../../../features/CurrencyInput"
import { price_to_number } from "../../../../utils/validation"
import { BankingNavigationContext } from "../../../../context/BankingNavigationContext"
import { Loader } from "@googlemaps/js-api-loader"
import user_pin from "../../../../assets/images/user_pin.png"
import { useResposive } from "../../../../hooks/useResponsive"
import arrow from "../../../../assets/images/arrow_yellow.svg"
import { InformarSaque } from "k4n-svcmesh-sdk"
interface InputFocus {
  total?: boolean
}

interface LatLng {
  lat: number
  lng: number
}

const Withdraw = () => {
  const navigate = useNavigate()
  const [latlong, setLatLong] = useState<LatLng>({ lat: 0, lng: 0 })

  const geolocationOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
  }

  useEffect(() => {
    if (`geolocation` in navigator) {
      navigator.geolocation.watchPosition(
        (position) => {
          if (position) {
            const location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            } as LatLng
            setLatLong(location)
            setLocationError(false)
          }
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) setLocationError(true)
        },
        geolocationOptions,
      )
    } else {
      setLocationError(true)
    }
  }, [latlong])

  const defaultFocus = {
    total: false,
  } as InputFocus
  const {
    control,
    formState: { errors },
  } = useForm({})

  // const watchValue = watch([`total`]) //criar serviço para validar disponibilidade de saldo (valor + tarifa)uthContext)

  const [locationError, setLocationError] = useState(false)
  const { user, current_account } = useContext(AuthContext)
  const [modal, setModal] = useState(false)
  const [sucessModal, setSuccessModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const { balance, loadingBalance } = useContext(BalanceContext)
  const [qrCodeContent, setQrCodeContent] = useState(
    `Banco24Horas/SaqueDigital/v2/a0a0d296-3754-454d-bc0c-b1c4d114467f/ea9dd655/1240004/BRL{20;50;100}`,
  )
  const [totalValue, setTotalValue] = useState<number>(0)
  const [isBiggerThanBalance, setIsBiggerThanBalance] = useState(false)
  const [isEqualToDisponibleCedules, setIsEqualToDisponibleCedules] =
    useState(true)
  const [parsedData, setParsedData] = useState(``)
  const { withdrawPage, changeWithdrawPage } = useContext(
    BankingNavigationContext,
  )
  const [openScan, setOpenScan] = useState(true)
  const { isTablet } = useResposive()

  const confirmWithdraw = async () => {
    console.log(`🚀 ~ file: index.tsx:94 ~ Withdraw ~ parsedData`, parsedData)
    onConfirmWithdraw()
    if (!isBiggerThanBalance && isEqualToDisponibleCedules) {
      const sendObj = {
        uuid: parsedData[3],
        dataHoraTransacao: new Date(),
        nsu: Math.floor(Math.random() * 10000),
        numeroBanco: Number(current_account?.numeroBanco),
        agencia: current_account?.agencia,
        agenciaDigito: current_account?.agenciaDigito,
        conta: current_account?.conta,
        contaDigito: current_account?.contaDigito,
        tipoConta: current_account?.tipoConta,
        nomeCliente: user?.nome,
        cpfCnpj: user?.cpf,
        valor: totalValue,
        codigoIf: `0815`,
      } as InformarSaque

      const res = await services.withdrawService.informNewWithdraw(sendObj)
      try {
        if (res.data.codigoResposta === `00`) {
          console.log(`saque solicitado com sucesso!`)
          console.log(
            `🚀 ~ file: index.tsx:118 ~ confirmWithdraw ~ res`,
            JSON.stringify(res),
          )
        } else {
          // console.log(`erro` + res.data.codigoResposta)
        }
      } catch (error) {
        const err = error as AxiosError<any>
        console.log(JSON.stringify(err))
      }
    }
  }

  const onConfirmWithdraw = () => {
    let value = totalValue
    const is200Available = isCeduleAvaliable(200)
    const is100Available = isCeduleAvaliable(100)
    const is50Available = isCeduleAvaliable(50)
    const is20Available = isCeduleAvaliable(20)
    while (value >= 0) {
      if (value >= 200 && is200Available) {
        value = value - 200
      } else if (value >= 100 && is100Available) {
        value = value - 100
      } else if (value >= 50 && is50Available) {
        value = value - 50
      } else if (value >= 20 && is20Available) {
        value = value - 20
      } else if (value < 20) {
        break
      }
    }

    if (value == 0) {
      setIsEqualToDisponibleCedules(true)
    } else {
      setIsEqualToDisponibleCedules(false)
    }
  }

  const getReadQRCode = async (data: any) => {
    setParsedData(data.split(`/`))
    setQrCodeContent(data)
    setModal(true)
    setOpenScan(false)

    // if (sendObj.valor < balance) {
    //   try {
    //     setModal(true)
    //     const res = await services.withdrawService.informNewWithdraw(sendObj)
    //     if (res.data.codigoResposta === `00`) {
    //       setModal(true)
    //     } else {
    //       console.log(`erro` + res.data.codigoResposta)
    //       setErrorModal(true)
    //     }
    //   } catch (error: any) {
    //     setErrorModal(true)
    //     const err = error as AxiosError<any>
    //     console.log(JSON.stringify(err))
    //   }
    // }
  }
  function displayMap() {
    const mapOptions = {
      center: { lat: latlong.lat, lng: latlong.lng },
      zoom: 15,
      mapId: `ef32f05e2644633a`,
      streetViewControl: false,
      mapTypeControl: false,
      gestureHandling: `greedy`,
    }
    const mapDiv = document.getElementById(`map`)
    if (mapDiv) {
      const map = new google.maps.Map(mapDiv, mapOptions)
      return map
    }
  }

  const getNextATMs = async (map) => {
    const sendObj = {
      latitude: latlong.lat,
      longitude: latlong.lng,
      limite: 5,
    } as WithdrawAtms

    const userPos = { lat: latlong.lat, lng: latlong.lng }
    const icon = user_pin

    const userMarker = new google.maps.Marker({
      map: map,
      position: userPos,
      icon: icon,
    })

    const res = await services.withdrawService.findNearbyATMs(sendObj)
    const markers: google.maps.Marker[] = []
    markers.push(userMarker)

    for (const location in res.data) {
      console.log(res.data[location])
      const markerOptions = {
        map: map,
        position: {
          lat: res.data[location].latitude,
          lng: res.data[location].longitude,
        },
        // icon: ,
      }

      const contentString =
        `<span class="map-info">` +
        res.data[location].logradouro +
        `, ` +
        res.data[location].numeroEndereco +
        `</span>`

      const infowindow = new google.maps.InfoWindow({
        content: contentString,
      })

      const marker = new google.maps.Marker(markerOptions)
      marker.addListener(`click`, () => {
        infowindow.open({
          anchor: marker,
          map,
        })
      })
      markers.push(marker)
    }
    console.log(markers)
    return markers
  }

  const showMap = () => {
    const apiOptions = {
      apiKey: process.env.REACT_APP_GOOGLE_TOKEN || ``,
    }
    const loader = new Loader(apiOptions)

    changeWithdrawPage(2)

    loader.load().then(() => {
      const map = displayMap()
      const markers = getNextATMs(map)
    })
  }

  const isCeduleAvaliable = (value: number) => {
    // apenas dividindo o conteudo do qrcode para pegar a informação necessaria
    let split = qrCodeContent.split(`/`)
    split = split[6].split(`{`)
    split = split[1].split(`}`)
    split = split[0].split(`;`)

    let isAvaliable = 0
    split.forEach((element) => {
      if (value == parseInt(element)) {
        isAvaliable = 1
      }
    })

    if (isAvaliable == 0) {
      return false
    } else {
      return true
    }
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    const fixedNumber = price_to_number(newValue)
    setTotalValue(fixedNumber)

    if (fixedNumber > balance) setIsBiggerThanBalance(true)
    else setIsBiggerThanBalance(false)
  }

  const validatePaymentCurrency = (value: string) => {
    const fixedValue = price_to_number(value)
    if (fixedValue > 0) return true
    else return false
  }

  function AvailableMoneyBills() {
    return (
      <div className="withdraw-money-bill-items-div">
        <div className="withdraw-money-bill-items row">
          <div className="col-6 col-pd-right">
            <div
              className={
                isCeduleAvaliable(20)
                  ? `withdraw-money-bill-item money-bill-item-active`
                  : `withdraw-money-bill-item`
              }
            >
              <span className="withdraw-money-bill-value">R$20</span>
            </div>
          </div>
          <div className="col-6 col-pd-left">
            <div
              className={
                isCeduleAvaliable(50)
                  ? `withdraw-money-bill-item money-bill-item-active`
                  : `withdraw-money-bill-item`
              }
            >
              <span className="withdraw-money-bill-value">R$50</span>
            </div>
          </div>
        </div>
        <div className="withdraw-money-bill-items mt-20 row">
          <div className="col-6 col-pd-right">
            <div
              className={
                isCeduleAvaliable(100)
                  ? `withdraw-money-bill-item money-bill-item-active`
                  : `withdraw-money-bill-item`
              }
            >
              <span className="withdraw-money-bill-value">R$100</span>
            </div>
          </div>
          <div className="col-6 col-pd-left">
            <div
              className={
                isCeduleAvaliable(200)
                  ? `withdraw-money-bill-item money-bill-item-active`
                  : `withdraw-money-bill-item`
              }
            >
              <span className="withdraw-money-bill-value">R$200</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="withdraw-panel">
      {withdrawPage === 1 ? (
        <>
          <SuccessModal
            isOpen={sucessModal}
            onClose={() => {
              setSuccessModal(false)
            }}
          >
            <div className="flex-center">Saque feito com sucesso!</div>
          </SuccessModal>

          <ErrorModal
            isOpen={errorModal}
            onClose={() => {
              setErrorModal(false)
            }}
          >
            <div className="flex-center">
              Houve um erro no processamento de saque, seu dinheiro retornará ao
              saldo em até 24 horas.
            </div>
          </ErrorModal>

          <CustomModal
            isOpen={modal}
            onClose={() => {
              setModal(false)
              setOpenScan(true)
            }}
            isBanking={true}
            modalBottom={true}
          >
            <div
              className={
                isTablet
                  ? `withdraw-modal withdraw-tablet-padding`
                  : `withdraw-modal`
              }
            >
              <div className="withdraw-modal-content">
                <div className="withdraw-title-div">
                  <span className="withdraw-title">Saque digital</span>
                </div>
                <div className="withdraw-balance-div">
                  <span className="withdraw-balance-title">Saldo Kikkin:</span>
                  {!loadingBalance ? (
                    <span className="withdraw-balance-value">
                      {formattedCurrency(balance)}
                    </span>
                  ) : (
                    <div className="emptySpace-pix gradient"></div>
                  )}
                </div>
                <div className="withdraw-available-bills">
                  <div className="withdraw-available-bills-text-div">
                    <span className="withdraw-available-bills-text">
                      Cédulas disponiveis no caixa:
                    </span>
                  </div>
                  {AvailableMoneyBills()}
                  <span className="withdraw-info-msg">
                    Por favor, digite o valor do saque com os valores
                    disponíveis acima
                  </span>
                </div>

                <div className="input-withdraw-container"></div>
                <div className="withdraw-error">
                  <div className="input-withdraw-container">
                    <div className="withdraw-total-input-container">
                      <span className="withdraw-total-label">R$</span>
                      <Controller
                        name="total"
                        control={control}
                        defaultValue="0,00"
                        rules={{
                          required: true,
                          validate: validatePaymentCurrency,
                          onChange: onValueChange,
                        }}
                        render={({ field }) => (
                          <CurrencyInput
                            value={field.value}
                            options={{
                              style: `decimal`,
                              allowNegative: false,
                            }}
                            onChangeEvent={(_, maskedValue) => {
                              field.onChange(maskedValue)
                            }}
                            required={true}
                            className="withdraw-total-input"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="withdraw-error">
                    {isBiggerThanBalance ? (
                      <>
                        <span className="withdraw-input-error">
                          O total do saque não pode ser superior ao saldo da
                          conta
                        </span>
                      </>
                    ) : (
                      <></>
                    )}

                    {!isEqualToDisponibleCedules ? (
                      <>
                        <span className="withdraw-input-error">
                          O total do saque deve ser um múltiplo das notas
                          disponíveis
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="withdraw-submit-btn">
                  <PrimaryButton
                    width={`100%`}
                    height={`60px`}
                    onClick={() => confirmWithdraw()}
                  >
                    Sacar
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </CustomModal>

          <div>
            <div className="withdraw-panel-container">
              <div className="withdraw-header">
                <span
                  className="withdraw-arrow-back"
                  onClick={() => {
                    navigate(`/banking`)
                  }}
                >
                  <img
                    src={arrow}
                    width="27"
                    height="15"
                    alt="/"
                    onLoad={(e) => SVGInject(e.target)}
                  />
                </span>

                <h1 className="withdraw-header-h1">Saque</h1>
                <span className="withdraw-header-txt">
                  Tudo que você precisa pra ter o seu dinheiro em mãos.
                </span>
              </div>
              <div className="withdraw-content">
                <h1 className="withdraw-panel-title">
                  Posicione o QR Code a ser lido exatamente dentro das molduras
                  como indicado abaixo.
                </h1>

                <div className="withdraw-qrReader">
                  {openScan && (
                    <QrcodeReader
                      qrCodeSuccessCallback={(value: string) => {
                        // setLoading(true)
                        getReadQRCode(value)
                        console.log(
                          `🚀 ~ file: index.tsx:496 ~ Withdraw ~ value`,
                          value,
                        )
                      }}
                      start={openScan}
                    />
                  )}
                </div>

                <div className="withdraw-button withdraw-fixed-bottom">
                  <PrimaryButton
                    width={isTablet ? `312px` : `100%`}
                    height={`60px`}
                    onClick={() => showMap()}
                  >
                    ATM mais próximo
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="withdraw-maps">
            {/*  thid div render a map */}
            <div id="map"></div>

            <div className="withdraw-map-button">
              <PrimaryButton
                width={`350px`}
                height={`60px`}
                onClick={() => changeWithdrawPage(1)}
              >
                Sair da exibição de mapa
              </PrimaryButton>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Withdraw
