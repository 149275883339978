import "./styles.scss"
import WaitImg from "../../../assets/images/wait.svg"
import Navbar from "../../features/Navbar"

const WaitAccountOpen = () => {
  return (
    <>
      <Navbar isLogin={true} />
      <div className="wait-account-container">
        <div className="wait-account-title">
          <h1 className="wait-account-h1">Aguardando abertura</h1>
          <img
            src={WaitImg}
            width={160}
            style={{
              filter: `brightness(0) invert(1)`,
            }}
          />
          <h3>Prezado cliente,</h3>
          <h5 className="mx-1">
            Sua conta está em processo de abertura e em breve estará disponível
            para uso. Você receberá um email / sms informando a liberação.
          </h5>
        </div>
      </div>
    </>
  )
}
export default WaitAccountOpen
