import ptBR from "date-fns/locale/pt-BR"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import isToday from "dayjs/plugin/isToday"
import { registerLocale } from "react-datepicker"
import SVGInject from "@iconfu/svg-inject"
import manage from "../../../../../../assets/images/manage.svg"
import iconBoleto from "../../../../../../assets/images/ic-boleto.svg"
import arrowLeft from "../../../../../../assets/images/yellow-arrow-left.svg"
import { BankingNavigationContext } from "../../../../../../context/BankingNavigationContext"
import { useContext, useState } from "react"
import "./styles.scss"
import BoletoCreation from "../../new"
import { BoletoContext } from "../../../../../../context/BoletoContext"
import { SuccessModal } from "k4n-components"
import { AuthContext } from "../../../../../../context/AuthProvider"
import services from "../../../../../../services/svcmesh"
import { useNavigate } from "react-router-dom"
dayjs.extend(isBetween)
dayjs.extend(isToday)

registerLocale(`pt-BR`, ptBR)

const BoletoManagerMobile = () => {
  const { changeBoletoStep } = useContext(BankingNavigationContext)
  const { user } = useContext(AuthContext)
  const [newBoleto, setNewBoleto] = useState(false)
  const { addBcStep1, addBcStep2, recipient, addSearchedFavorites } =
    useContext(BoletoContext)
  const [pdfModalLoading, setPdfModalLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState(1)
  const [base64PdfModal, setBase64PdfModal] = useState(``)
  const [pdfModalName, setPdfModalName] = useState(``)
  const [pdfModalError, setPdfModalError] = useState(false)
  const navigate = useNavigate()

  const handleCancel = () => {
    addBcStep1({})
    addBcStep2({})
    changeBoletoStep(1)
    setNewBoleto(false)
    // changeBoletoStep(1)
  }

  const handleSuccess = async (barCode: string) => {
    addBcStep1({})
    addBcStep2({})
    changeBoletoStep(1)
    setPdfModalLoading(true)
    setNewBoleto(false)
    setShowModal(true)
    setModalType(1)
    addSearchedFavorites(false)
    try {
      const pdf = await services.bankingService.getBoletoPdf(barCode, recipient)
      if (pdf && pdf.data.boletoPdf) {
        setBase64PdfModal(`data:application/pdf;base64,` + pdf.data.boletoPdf)
        setPdfModalLoading(false)
        setPdfModalName(barCode)
      }
    } catch (error) {
      setPdfModalError(true)
    }
  }

  return (
    <>
      {newBoleto ? (
        <>
          <BoletoCreation
            handleSuccess={handleSuccess}
            handleCancel={handleCancel}
          />
        </>
      ) : (
        <>
          <div className="boleto-panel-mobile">
            <div className="boleto-panel-header-mobile">
              <div onClick={() => navigate(`/banking`)}>
                <img
                  src={arrowLeft}
                  alt="/"
                  onLoad={(e) => SVGInject(e.target)}
                />
              </div>
              <h1 className="boleto-panel-h1-mobile">Boleto</h1>
              <span className="boleto-panel-txt-mobile">
                Gerencie e transfira valores de forma simples e gratuita.
              </span>
            </div>
            <div className="boleto-panel-content-mobile">
              <div className="boleto-panel-cards-mobile">
                <div
                  onClick={() => navigate(`/banking/boleto/manager/mobile`)}
                  className="boleto-panel-card-mobile"
                >
                  <span className="boleto-panel-card-img-mobile">
                    <img
                      src={manage}
                      alt="/"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  </span>
                  <h1 className="boleto-panel-card-h1-mobile">
                    Gerenciar boletos
                  </h1>
                </div>
                <div
                  className="boleto-panel-card-mobile"
                  onClick={() => setNewBoleto(true)}
                >
                  <span className="boleto-panel-card-img-mobile">
                    <img
                      src={iconBoleto}
                      alt="/"
                      onLoad={(e) => SVGInject(e.target)}
                    />
                  </span>
                  <h1 className="boleto-panel-card-h1-mobile">
                    Novo <br /> boleto
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <SuccessModal
            isOpen={showModal}
            width={`100%`}
            height={modalType === 1 ? `460px` : `350`}
            onClose={() => setShowModal(false)}
          >
            {modalType === 1 && (
              <>
                <h1 className="pix-modal-sucess-h1">
                  Boleto criado com <br /> sucesso!
                </h1>
                <div className="bm-time-info-div">
                  <span className="bm-time-info-mobile">
                    Em até 2 minutos seu boleto estará disponível
                  </span>
                </div>
                <div className="mb-modal-ok-div-mobile">
                  <button
                    className="mb-modal-ok-btn-mobile"
                    onClick={() => setShowModal(false)}
                  >
                    OK!
                  </button>
                </div>
              </>
            )}
          </SuccessModal>
        </>
      )}
    </>
  )
}

export default BoletoManagerMobile
