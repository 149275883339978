import SVGInject from "@iconfu/svg-inject"
import { PrimaryButton } from "k4n-components"
import { useContext } from "react"
import { FieldValues, useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import { useNavigate } from "react-router-dom"
import Arrow from "../../../assets/images/arrow.svg"
import Logo from "../../../assets/images/kikkin_white_logo.svg"
import { AuthContext } from "../../../context/AuthProvider"
import { ThemeContext } from "../../../context/ThemeContext"
import { useResposive } from "../../../hooks/useResponsive"
import services from "../../../services/svcmesh"
import { validateCpf } from "../../../utils/validation"
import Navbar from "../../features/Navbar"
import "./styles.scss"

const Home = () => {
  const { login, firstLogin } = useContext(AuthContext)
  const { logo, theme } = useContext(ThemeContext)
  const { isTabletOrMobile } = useResposive()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({})

  const onSubmit = async (data: FieldValues) => {
    if (data.cpf) {
      const cpf = data.cpf
      const res = await services.bankingService.checkIfExistByCpf(cpf)
      console.log(res)
      if (!res.data.exist || (res.data.exist && res.data.partial)) {
        console.log(
          `Usuario não existe no partner ou está em onboarding... `,
          cpf,
        )
        navigate(`/onboarding`, { state: { cpf: cpf } })
      } else if (res.data.approved === 0 || res.data.approved === 1) {
        //conta nao aprovada ainda
        navigate(`/waitAccount`)
      } else if (res.data.exist && !res.data.partial && !res.data.first) {
        login(cpf)
      } else if (res.data.exist && !res.data.partial && res.data.first) {
        console.log(
          `User exists in partner, but does not have password! First Login`,
          cpf,
        )
        firstLogin(cpf)
      }
    }
  }

  return (
    <>
      {isTabletOrMobile !== true ? (
        <div className="home">
          <Navbar />
          <div
            className="home-content bg-image"
            style={{
              height: `100%`,
              backgroundSize: `cover`,
              backgroundImage: `url(${theme?.images?.login_background_image})`,
            }}
          >
            <h1 className="home-h1">
              Nosso propósito é fortalecer
              <br /> o setor e ajudar nossos clientes
              <br /> a serem mais felizes e prósperos
            </h1>
            <form className="home-form" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <InputMask
                  mask="999.999.999-99"
                  type="text"
                  placeholder="Digite seu CPF"
                  className="home-input"
                  {...register(`cpf`, {
                    required: true,
                    validate: validateCpf,
                    pattern: /^\d\d\d\.\d\d\d\.\d\d\d-\d\d$/,
                  })}
                />
                {errors.cpf && (
                  <p className="home-error-msg">Por favor, verifique o cpf.</p>
                )}
              </div>
              <button className="home-btn-next" type="submit">
                Vamos lá!
                <img src={Arrow} className="home-btn-arrow" />
              </button>
            </form>
          </div>
        </div>
      ) : (
        <>
          <div className="home-mobile">
            <img
              style={{ maxWidth: 150, height: 60 }}
              src={logo || Logo}
              onLoad={(e) => SVGInject(e.target)}
            />
            <h1 className="home-h1-mobile">Estávamos esperando por você!</h1>
            <form
              className="home-form-mobile"
              onSubmit={handleSubmit(onSubmit)}
            >
              <InputMask
                mask="999.999.999-99"
                maskPlaceholder={null}
                type="text"
                placeholder="Digite seu CPF"
                className="home-input-mobile"
                inputMode="numeric"
                {...register(`cpf`, {
                  required: true,
                  validate: validateCpf,
                  pattern: /^\d\d\d\.\d\d\d\.\d\d\d-\d\d$/,
                })}
              />
              {errors.cpf && (
                <p className="home-error-msg-mobile">
                  Por favor, verifique o cpf.
                </p>
              )}

              <PrimaryButton width={`312px`} height={`70px`}>
                <div className="home-btn-txt-mobile">
                  Vamos lá!
                  <img src={Arrow} className="home-btn-arrow" />
                </div>
              </PrimaryButton>
            </form>
          </div>
        </>
      )}
    </>
  )
}

export default Home
