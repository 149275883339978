import { useContext, useEffect, useState } from "react"
import { BankingNavigationContext } from "../../../context/BankingNavigationContext"
import { InsuranceContext } from "../../../context/InsuranceContext"
import "./styles.scss"

export interface Props {
  navigationType: string
}

const StepNavigation: React.FC<Props> = ({ navigationType }) => {
  const { insuranceStep, boletoCreationStep } = useContext(
    BankingNavigationContext,
  )

  const { hasBeneficiary } = useContext(InsuranceContext)

  const [step, steStep] = useState(0)

  const [hasStep4, setHasStep4] = useState(false)

  useEffect(
    function () {
      switch (navigationType) {
        case `boleto`:
          steStep(boletoCreationStep)
          break
        case `seguro`:
          steStep(insuranceStep)
          if (hasBeneficiary) setHasStep4(true)
          else setHasStep4(false)
          break
      }
    },
    [boletoCreationStep, insuranceStep, hasBeneficiary],
  )

  return (
    <>
      <div
        className={hasStep4 ? `step-navigation  wd-138` : `step-navigation `}
      >
        <div
          className={step === 1 ? `navigation-item-active` : `navigation-item`}
        >
          <div
            className={
              step === 1 ? `navigation-item-txt-active` : `navigation-item-txt`
            }
          >
            1
          </div>
        </div>
        <div
          className={step === 2 ? `navigation-item-active` : `navigation-item`}
        >
          <div
            className={
              step === 2 ? `navigation-item-txt-active` : `navigation-item-txt`
            }
          >
            2
          </div>
        </div>
        <div
          className={step === 3 ? `navigation-item-active` : `navigation-item`}
        >
          <div
            className={
              step === 3 ? `navigation-item-txt-active` : `navigation-item-txt`
            }
          >
            3
          </div>
        </div>
        {hasStep4 && (
          <div
            className={
              step === 4 ? `navigation-item-active` : `navigation-item`
            }
          >
            <div
              className={
                step === 4
                  ? `navigation-item-txt-active`
                  : `navigation-item-txt`
              }
            >
              4
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default StepNavigation
