import "./styles.scss"
import defaultLogo from "../../../assets/images/kikkin_white_logo.svg"
import spinner from "../../../assets/images/spinner.svg"
import SVGInject from "@iconfu/svg-inject"
import { useContext } from "react"
import { ThemeContext } from "../../../context/ThemeContext"

const LoadingOverlay = ({ noTheme = false }) => {
  const { logo } = useContext(ThemeContext)

  return (
    <div className={`loading-overlay-bg ${noTheme ? `default` : ``}`}>
      {!noTheme && (
        <img
          src={logo || defaultLogo}
          alt="logo"
          width={150}
          onLoad={(e) => SVGInject(e.target)}
        />
      )}
      <img
        src={spinner}
        alt="spinner"
        width={100}
        height={100}
        onLoad={(e) => SVGInject(e.target)}
      />
    </div>
  )
}

export default LoadingOverlay
